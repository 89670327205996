import * as Sentry from '@sentry/browser';

export const lazyLoadReplay = () => {
  import('@sentry/react').then(({ Replay }) => {
    const hub = Sentry.getCurrentHub();
    const client = hub.getClient();
    if (!hub.getIntegration(Replay) && client) {
      client.addIntegration(
        new Replay({
          maskAllText: false,
          blockAllMedia: true,
          unmask: [
            '[data-mms--page-header-input=search]',
            '[data-mms--page-header-input=term]',
            '[data-mms--page-header-input=url]',
          ],
        }),
      );
    }
  });
};
