import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Handle values greater than 32 bit */
  BigInt: { input: number; output: number };
  /** Date with time (isoformat) */
  DateTime: { input: Date; output: Date };
  /** Handle values in unix timestamp format */
  DateTimeWithUnixTimeStamp: { input: number; output: number };
  /** Domain & subdomain scalar */
  Domain: { input: string; output: string };
  /** Interval (timedelta) */
  Interval: { input: any; output: any };
  /** The GenericScalar scalar type represents a generic GraphQL scalar value that could be: List or Object. */
  JSON: { input: { [key: string]: any }; output: { [key: string]: any } };
  /** The `URL` scalar can't contain certain characters */
  URL: { input: string; output: string };
};

export type Account = {
  __typename?: 'Account';
  address?: Maybe<Address>;
  billTo?: Maybe<Scalars['String']['output']>;
  billingInfo?: Maybe<BillingInfo>;
  ccEmails?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  company: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dunningCampaignId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  exemptionCertificate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasActiveSubscription?: Maybe<Scalars['Boolean']['output']>;
  hasCanceledSubscription?: Maybe<Scalars['Boolean']['output']>;
  hasFutureSubscription?: Maybe<Scalars['Boolean']['output']>;
  hasLiveSubscription?: Maybe<Scalars['Boolean']['output']>;
  hasPastDueInvoice?: Maybe<Scalars['Boolean']['output']>;
  hasPausedSubscription?: Maybe<Scalars['Boolean']['output']>;
  hostedLoginToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceTemplate?: Maybe<AccountInvoiceTemplate>;
  lastName?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  parentAccountId?: Maybe<Scalars['String']['output']>;
  preferredLocale?: Maybe<Scalars['String']['output']>;
  shippingAddresses?: Maybe<Array<ShippingAddress>>;
  state?: Maybe<Scalars['String']['output']>;
  taxExempt?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type AccountInvoiceTemplate = {
  __typename?: 'AccountInvoiceTemplate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AccountMini = {
  __typename?: 'AccountMini';
  billTo?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  dunningCampaignId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  parentAccountId?: Maybe<Scalars['String']['output']>;
};

export type AdWordsItem = {
  __typename?: 'AdWordsItem';
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  categoryStrings?: Maybe<Array<Scalars['String']['output']>>;
  competition?: Maybe<Scalars['Float']['output']>;
  costPerClick?: Maybe<Scalars['Float']['output']>;
  existsInInventory?: Maybe<Scalars['Boolean']['output']>;
  history?: Maybe<Array<History>>;
  term: Scalars['String']['output'];
  variants?: Maybe<Array<AdWordsItem>>;
  volume?: Maybe<Scalars['Int']['output']>;
};

export type AdWordsItemInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  categoryStrings?: InputMaybe<Array<Scalars['String']['input']>>;
  competition?: InputMaybe<Scalars['Float']['input']>;
  costPerClick?: InputMaybe<Scalars['Float']['input']>;
  history?: InputMaybe<Array<HistoryInput>>;
  term: Scalars['String']['input'];
  volume?: InputMaybe<Scalars['Int']['input']>;
};

export type AdWordsItemWithVariants = {
  __typename?: 'AdWordsItemWithVariants';
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  categoryStrings?: Maybe<Array<Scalars['String']['output']>>;
  competition?: Maybe<Scalars['Float']['output']>;
  costPerClick?: Maybe<Scalars['Float']['output']>;
  history?: Maybe<Array<History>>;
  numVariants?: Maybe<Scalars['Int']['output']>;
  term: Scalars['String']['output'];
  variants?: Maybe<Array<AdWordsItem>>;
  volume?: Maybe<Scalars['Int']['output']>;
};

export type AddOnMini = {
  __typename?: 'AddOnMini';
  accountingCode?: Maybe<Scalars['String']['output']>;
  addOnType?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  externalSku?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  itemId?: Maybe<Scalars['String']['output']>;
  measuredUnitId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  usagePercentage?: Maybe<Scalars['Float']['output']>;
  usageType?: Maybe<Scalars['String']['output']>;
};

export type AddSubheadingInput = {
  briefId: Scalars['String']['input'];
  subheadings: Array<Scalars['String']['input']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
};

export type AddressWithName = {
  __typename?: 'AddressWithName';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
};

export type AdminAddonInput = {
  addonMode?: SubscriptionAddonMode;
  addonType: SubscriptionAddonTypeType;
};

export enum App {
  OPTIMIZE = 'optimize',
  RESEARCH = 'research',
}

export type AppDataParams = {
  __typename?: 'AppDataParams';
  bodyOrArticle?: Maybe<Scalars['String']['output']>;
  brands?: Maybe<Scalars['Boolean']['output']>;
  competitorSites?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
  networkSites?: Maybe<Scalars['String']['output']>;
  people?: Maybe<Scalars['Boolean']['output']>;
  topic: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AppDataParamsInput = {
  bodyOrArticle?: InputMaybe<Scalars['String']['input']>;
  brands?: InputMaybe<Scalars['Boolean']['input']>;
  competitorSites?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  networkSites?: InputMaybe<Scalars['String']['input']>;
  people?: InputMaybe<Scalars['Boolean']['input']>;
  topic: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AppQueriesResponse = {
  __typename?: 'AppQueriesResponse';
  count: Scalars['Int']['output'];
  items: Array<AppQuery>;
};

export type AppQuery = {
  __typename?: 'AppQuery';
  app: Scalars['String']['output'];
  connectCompetitorDomains?: Maybe<Array<Scalars['Domain']['output']>>;
  connectNetworkDomains?: Maybe<Array<Scalars['Domain']['output']>>;
  country?: Maybe<Country>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  jsonMetadata?: Maybe<Scalars['JSON']['output']>;
  language?: Maybe<Language>;
  site?: Maybe<Site>;
  status: AppQueryStatus;
  topic?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
  user?: Maybe<User>;
};

export type AppQueryCreateOrUpdateInput = {
  app: App;
  connectCompetitorDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
  connectNetworkDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
  country: Country;
  jsonMetadata?: InputMaybe<Scalars['JSON']['input']>;
  language: Language;
  topic: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type AppQueryFilter = {
  AND?: InputMaybe<Array<AppQueryFilter>>;
  OR?: InputMaybe<Array<AppQueryFilter>>;
  app?: InputMaybe<Scalars['String']['input']>;
  appContains?: InputMaybe<Scalars['String']['input']>;
  appNe?: InputMaybe<Scalars['String']['input']>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  site?: InputMaybe<Array<SiteFilter>>;
  topic?: InputMaybe<Scalars['String']['input']>;
  topicContains?: InputMaybe<Scalars['String']['input']>;
  topicNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  urlContains?: InputMaybe<Scalars['String']['input']>;
  urlNe?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Array<UserFilter>>;
};

export enum AppQueryStatus {
  COMPLETED = 'COMPLETED',
  STARTED = 'STARTED',
}

export type AppQueryUpdateInput = {
  connectCompetitorDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
  connectNetworkDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
  status?: InputMaybe<AppQueryStatus>;
};

export type AppRuns = {
  __typename?: 'AppRuns';
  current: Scalars['Int']['output'];
  error?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Scalars['Int']['output']>;
  lastReset?: Maybe<Scalars['DateTime']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextReset?: Maybe<Scalars['DateTime']['output']>;
  resetInterval?: Maybe<Scalars['Interval']['output']>;
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  accountId?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  backupPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fraud?: Maybe<FraudInfo>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  primaryPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<BillingInfoUpdatedBy>;
  valid?: Maybe<Scalars['Boolean']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type BillingInfoUpdatedBy = {
  __typename?: 'BillingInfoUpdatedBy';
  country?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
};

export type Brief = {
  __typename?: 'Brief';
  accountManager?: Maybe<User>;
  additionalTopics?: Maybe<Array<BriefSubtopic>>;
  briefStatus: BriefStatus;
  briefTitle?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<BriefComment>>;
  competitorSites?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  csRep?: Maybe<User>;
  cuid?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  errorStatus: BriefErrorStatus;
  focusTopic: Scalars['String']['output'];
  formData?: Maybe<Scalars['JSON']['output']>;
  formDraft?: Maybe<Scalars['Boolean']['output']>;
  formId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metadata?: Maybe<BriefMetadata>;
  org?: Maybe<Organization>;
  page?: Maybe<BriefPage>;
  qaAssignee?: Maybe<User>;
  questions?: Maybe<Array<BriefQuestion>>;
  rankingTitles?: Maybe<Array<BriefRankingTitle>>;
  reportType: BriefType;
  selfServe?: Maybe<Scalars['Boolean']['output']>;
  site?: Maybe<Site>;
  subheadingTerms?: Maybe<Array<BriefSubheadingTerm>>;
  subheadings?: Maybe<Array<BriefSubheading>>;
  subtopics?: Maybe<Array<BriefSubtopic>>;
  titleTerms?: Maybe<Array<BriefTitleTerm>>;
  topic?: Maybe<BriefTopic>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userAudiences?: Maybe<Array<BriefUserAudience>>;
  userIntents?: Maybe<Array<BriefUserIntent>>;
};

export type BriefComment = {
  __typename?: 'BriefComment';
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefDocumentInput = {
  clusterTheme: Scalars['String']['input'];
  comparativeInformation?: InputMaybe<ComparativeInformationInput>;
  contentType: BriefSubtype;
  createOpportunity: CreateOpportunity;
  domain: Scalars['URL']['input'];
  optionalUserInputs?: InputMaybe<DocumentBriefUserOptionalInput>;
  subtypeOptionalInputs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BriefDocumentScratchInput = {
  comparativeInformation?: InputMaybe<ComparativeInformationInput>;
  contentType?: InputMaybe<BriefSubtype>;
  optionalUserInputs?: InputMaybe<DocumentBriefUserOptionalInput>;
  subtypeOptionalInputs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BriefError = {
  __typename?: 'BriefError';
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum BriefErrorStatus {
  ERROR = 'ERROR',
  NONE = 'NONE',
  WARNING = 'WARNING',
}

export type BriefFilters = {
  createdAtType?: InputMaybe<DateFilterType>;
  createdAtVal?: InputMaybe<Scalars['String']['input']>;
  createdAtVal2?: InputMaybe<Scalars['String']['input']>;
  dueDateType?: InputMaybe<DateFilterType>;
  dueDateVal?: InputMaybe<Scalars['String']['input']>;
  dueDateVal2?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  focusTopic?: InputMaybe<Scalars['String']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  qaAssignee?: InputMaybe<Scalars['String']['input']>;
  reportStatuses?: InputMaybe<Array<BriefStatus>>;
  reportTypes?: InputMaybe<Array<BriefType>>;
  search?: InputMaybe<Scalars['String']['input']>;
  selfServe?: InputMaybe<Scalars['Boolean']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  siteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<BriefSortFields>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type BriefFocusTopicInput = {
  contentScoreAvg?: InputMaybe<Scalars['Float']['input']>;
  contentScoreTarget?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  kgKey?: InputMaybe<Scalars['String']['input']>;
  term: Scalars['String']['input'];
  wordCountAvg?: InputMaybe<Scalars['Float']['input']>;
  wordCountTarget?: InputMaybe<Scalars['Float']['input']>;
};

export type BriefLink = {
  __typename?: 'BriefLink';
  id: Scalars['String']['output'];
  parent?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefLinkList = {
  __typename?: 'BriefLinkList';
  id: Scalars['String']['output'];
  links: Array<BriefLink>;
  semanticSimilarity?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefMetadata = {
  __typename?: 'BriefMetadata';
  errors?: Maybe<Array<BriefError>>;
  id?: Maybe<Scalars['String']['output']>;
};

export type BriefOrganizationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BriefPage = {
  __typename?: 'BriefPage';
  id: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type BriefQuestion = {
  __typename?: 'BriefQuestion';
  id?: Maybe<Scalars['String']['output']>;
  semanticSimilarity?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefRankingTitle = {
  __typename?: 'BriefRankingTitle';
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefRelatedTopic = {
  __typename?: 'BriefRelatedTopic';
  distribution?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  powerTopic?: Maybe<Scalars['Boolean']['output']>;
  semanticSimilarity?: Maybe<Scalars['Float']['output']>;
  suggestedDistribution?: Maybe<Scalars['Float']['output']>;
  value: Scalars['String']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefSiteInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type BriefSortFields = {
  direction?: InputMaybe<SortDirectionBrief>;
  field: Scalars['String']['input'];
};

export enum BriefStatus {
  CART = 'CART',
  DELIVERED = 'DELIVERED',
  DELIVERY_READY = 'DELIVERY_READY',
  IN_PROGRESS = 'IN_PROGRESS',
  QA_READY = 'QA_READY',
}

export type BriefSubheading = {
  __typename?: 'BriefSubheading';
  additionalTopics?: Maybe<Array<BriefSubtopic>>;
  comments?: Maybe<Array<BriefComment>>;
  externalLinks?: Maybe<Array<BriefLinkList>>;
  id?: Maybe<Scalars['String']['output']>;
  internalLinks?: Maybe<Array<BriefLinkList>>;
  position?: Maybe<Scalars['Float']['output']>;
  questions?: Maybe<Array<BriefQuestion>>;
  relatedTopics?: Maybe<Array<BriefRelatedTopic>>;
  title?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<BriefSubheadingTopic>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
  wordCount?: Maybe<Scalars['Float']['output']>;
};

export type BriefSubheadingTerm = {
  __typename?: 'BriefSubheadingTerm';
  id?: Maybe<Scalars['String']['output']>;
  semanticSimilarity?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefSubheadingTopic = {
  __typename?: 'BriefSubheadingTopic';
  contentScoreAvg?: Maybe<Scalars['Float']['output']>;
  contentScoreTarget?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  semanticSimilarity?: Maybe<Scalars['Float']['output']>;
  suggestedDistribution?: Maybe<Scalars['Float']['output']>;
  term: Scalars['String']['output'];
  wordCountAvg?: Maybe<Scalars['Float']['output']>;
  wordCountTarget?: Maybe<Scalars['Float']['output']>;
};

export type BriefSubtopic = {
  __typename?: 'BriefSubtopic';
  distribution?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  powerTopic?: Maybe<Scalars['Boolean']['output']>;
  semanticSimilarity?: Maybe<Scalars['Float']['output']>;
  suggestedDistribution?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export enum BriefSubtype {
  COMPARISON_CONTENT = 'COMPARISON_CONTENT',
  FAQ_COLLECTION_CONTENT = 'FAQ_COLLECTION_CONTENT',
  GUIDE_CONTENT = 'GUIDE_CONTENT',
  HOW_TO_TUTORIAL = 'HOW_TO_TUTORIAL',
  LIST_CONTENT = 'LIST_CONTENT',
  LOCAL_TARGET_CONTENT = 'LOCAL_TARGET_CONTENT',
  NEWS_EVENT_CONTENT = 'NEWS_EVENT_CONTENT',
  REVIEW_CONTENT = 'REVIEW_CONTENT',
  UNIVERSAL = 'UNIVERSAL',
}

export type BriefTitleTerm = {
  __typename?: 'BriefTitleTerm';
  id?: Maybe<Scalars['String']['output']>;
  semanticSimilarity?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefTopic = {
  __typename?: 'BriefTopic';
  contentScoreAvg?: Maybe<Scalars['Float']['output']>;
  contentScoreTarget?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  kgKey?: Maybe<Scalars['String']['output']>;
  term: Scalars['String']['output'];
  wordCountAvg?: Maybe<Scalars['Float']['output']>;
  wordCountTarget?: Maybe<Scalars['Float']['output']>;
};

export enum BriefType {
  BASE = 'BASE',
  CREATE = 'CREATE',
  OPTIMIZE = 'OPTIMIZE',
}

export type BriefUpdateInput = {
  accountManager?: InputMaybe<Scalars['String']['input']>;
  additionalTopics?: InputMaybe<Array<SubtopicInput>>;
  briefStatus?: InputMaybe<BriefStatus>;
  briefTitle?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Array<CommentInput>>;
  competitorSites?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  csRep?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  errorStatus?: InputMaybe<BriefErrorStatus>;
  formData?: InputMaybe<Scalars['String']['input']>;
  formDraft?: InputMaybe<Scalars['Boolean']['input']>;
  formId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<MetadataInput>;
  org?: InputMaybe<BriefOrganizationInput>;
  page?: InputMaybe<PageInput>;
  qaAssignee?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  rankingTitles?: InputMaybe<Array<RankingTitleInput>>;
  site?: InputMaybe<BriefSiteInput>;
  subheadingTerms?: InputMaybe<Array<SubheadingTermInput>>;
  subheadings?: InputMaybe<Array<SubheadingInput>>;
  subtopics?: InputMaybe<Array<SubtopicInput>>;
  titleTerms?: InputMaybe<Array<TitleTermInput>>;
  topic?: InputMaybe<BriefFocusTopicInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAudiences?: InputMaybe<Array<UserAudienceInput>>;
  userIntents?: InputMaybe<Array<UserIntentInput>>;
};

export type BriefUserAudience = {
  __typename?: 'BriefUserAudience';
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefUserIntent = {
  __typename?: 'BriefUserIntent';
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BriefsResponse = {
  __typename?: 'BriefsResponse';
  count: Scalars['Int']['output'];
  items: Array<Brief>;
};

export type BusinessReview = {
  __typename?: 'BusinessReview';
  businessReviewId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dateRange: Array<Scalars['DateTime']['output']>;
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  includePaths?: Maybe<Array<Scalars['String']['output']>>;
  includeSubdomains?: Maybe<Array<Scalars['String']['output']>>;
  org?: Maybe<Organization>;
  site?: Maybe<Site>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versions?: Maybe<Array<InventoryVersion>>;
};

export type BusinessReviewRead = {
  __typename?: 'BusinessReviewRead';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<BusinessReview>>;
};

export type CancelAccount = {
  __typename?: 'CancelAccount';
  success: Scalars['Boolean']['output'];
};

export type CancelSubscriptionSchema = {
  __typename?: 'CancelSubscriptionSchema';
  success: Scalars['Boolean']['output'];
};

export enum ClientTier {
  APP_SUMO = 'APP_SUMO',
  CUSTOM = 'CUSTOM',
  FREE = 'FREE',
  OPTIMIZE = 'OPTIMIZE',
  PREMIUM = 'PREMIUM',
  RESEARCH = 'RESEARCH',
  RESEARCH_TRIAL = 'RESEARCH_TRIAL',
  STANDARD = 'STANDARD',
  STANDARD_TRIAL = 'STANDARD_TRIAL',
  STRATEGY = 'STRATEGY',
}

export type ColumnNameInput = {
  invField: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type CommentInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ComparativeInformationInput = {
  competitorDomains?: InputMaybe<Array<Scalars['URL']['input']>>;
  country?: InputMaybe<Country>;
  language?: InputMaybe<Language>;
  networkDomains?: InputMaybe<Array<Scalars['URL']['input']>>;
};

export type ContentGroups = {
  name: Scalars['String']['input'];
  personas: Array<Scalars['String']['input']>;
};

export type ContentPlanClusterItem = {
  __typename?: 'ContentPlanClusterItem';
  page?: Maybe<ContentPlanPage>;
  potentialPosition?: Maybe<Scalars['Int']['output']>;
  potentialTrafficGain?: Maybe<Scalars['Float']['output']>;
  term: Scalars['String']['output'];
};

export type ContentPlanDocumentInput = {
  clusterTerms: Array<Scalars['String']['input']>;
  clusterTheme: Scalars['String']['input'];
  contentGroups: Array<ContentGroups>;
};

export type ContentPlanItem = {
  __typename?: 'ContentPlanItem';
  cluster: Array<ContentPlanClusterItem>;
  clusterPotentialTrafficGainSum: Scalars['Float']['output'];
  headPage?: Maybe<ContentPlanPage>;
  headTerm: Scalars['String']['output'];
  headTermAuthority?: Maybe<Scalars['Float']['output']>;
  headTermPersonalizedDifficulty?: Maybe<Scalars['Float']['output']>;
  potentialPosition?: Maybe<Scalars['Int']['output']>;
  recommendedAction: ContentPlanItemAction;
};

export enum ContentPlanItemAction {
  CREATE = 'CREATE',
  OPTIMIZE = 'OPTIMIZE',
}

export type ContentPlanItemInput = {
  clusterPotentialTrafficGainSum: Scalars['Int']['input'];
  headTerm: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  potentialPosition?: InputMaybe<Scalars['Int']['input']>;
  recommendedAction?: InputMaybe<ContentPlanItemAction>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ContentPlanPage = {
  __typename?: 'ContentPlanPage';
  position?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ContentPlanRecommendations = {
  __typename?: 'ContentPlanRecommendations';
  countCreate: Scalars['Int']['output'];
  countOptimize: Scalars['Int']['output'];
};

export enum Country {
  AR = 'AR',
  AU = 'AU',
  BO = 'BO',
  CA = 'CA',
  CL = 'CL',
  CO = 'CO',
  CR = 'CR',
  EC = 'EC',
  ES = 'ES',
  GB = 'GB',
  GT = 'GT',
  MX = 'MX',
  NI = 'NI',
  NZ = 'NZ',
  PE = 'PE',
  PY = 'PY',
  US = 'US',
  VE = 'VE',
}

export type Coupon = {
  __typename?: 'Coupon';
  appliesToAllItems: Scalars['Boolean']['output'];
  appliesToAllPlans: Scalars['Boolean']['output'];
  appliesToNonPlanCharges?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  couponType: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  discount: CouponDiscount;
  duration?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  hostedPageDescription?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceDescription?: Maybe<Scalars['String']['output']>;
  items?: Maybe<ItemMini>;
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  maxRedemptionsPerAccount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  plans?: Maybe<Array<PlanMini>>;
  redeemBy?: Maybe<Scalars['DateTime']['output']>;
  redemptionResource?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  temporalAmount?: Maybe<Scalars['Int']['output']>;
  temporalUnit?: Maybe<Scalars['String']['output']>;
  uniqueCodeTemplate?: Maybe<Scalars['String']['output']>;
  uniqueCouponCode?: Maybe<Scalars['JSON']['output']>;
  uniqueCouponCodesCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponDiscount = {
  __typename?: 'CouponDiscount';
  currencies?: Maybe<Array<CouponDiscountPricing>>;
  percent?: Maybe<Scalars['Int']['output']>;
  trial?: Maybe<CouponDiscountTrial>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CouponDiscountPricing = {
  __typename?: 'CouponDiscountPricing';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
};

export type CouponDiscountTrial = {
  __typename?: 'CouponDiscountTrial';
  length?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type CouponMini = {
  __typename?: 'CouponMini';
  code?: Maybe<Scalars['String']['output']>;
  couponType?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<CouponDiscount>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CouponRedemption = {
  __typename?: 'CouponRedemption';
  account?: Maybe<AccountMini>;
  coupon?: Maybe<Coupon>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  discounted?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponRedemptionMini = {
  __typename?: 'CouponRedemptionMini';
  coupon?: Maybe<CouponMini>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  discounted?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CreateOpportunity = {
  focusTerm: Scalars['String']['input'];
  funnelStage: FunnelStages;
  idea: Scalars['String']['input'];
  overview: Scalars['String']['input'];
  pointsToCover: Array<Scalars['String']['input']>;
  relatedTerms: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreditPayment = {
  __typename?: 'CreditPayment';
  account?: Maybe<AccountMini>;
  action?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  appliedToInvoice?: Maybe<InvoiceMini>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  originalCreditPaymentId?: Maybe<Scalars['String']['output']>;
  originalInvoice?: Maybe<InvoiceMini>;
  refundTransaction?: Maybe<Transaction>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  voidedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomField = {
  __typename?: 'CustomField';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layout?: Maybe<Scalars['JSON']['output']>;
  modules?: Maybe<Array<Module>>;
  org?: Maybe<Organization>;
  scope?: Maybe<DashboardScope>;
  site?: Maybe<Site>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedByUser?: Maybe<User>;
  users?: Maybe<User>;
};

export type DashboardFilter = {
  AND?: InputMaybe<Array<DashboardFilter>>;
  OR?: InputMaybe<Array<DashboardFilter>>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  createdByUser?: InputMaybe<Array<UserFilter>>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionNe?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  org?: InputMaybe<Array<OrganizationFilter>>;
  scope?: InputMaybe<DashboardScope>;
  scopeNe?: InputMaybe<DashboardScope>;
  scopesIn?: InputMaybe<Array<DashboardScope>>;
  site?: InputMaybe<Array<SiteFilter>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedByUser?: InputMaybe<Array<UserFilter>>;
};

export type DashboardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<Scalars['JSON']['input']>;
  scope?: InputMaybe<DashboardScope>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum DashboardScope {
  INVENTORY = 'INVENTORY',
  ORG = 'ORG',
  PUBLIC = 'PUBLIC',
  USER = 'USER',
}

export enum DateFilterType {
  GTE = 'GTE',
  LTE = 'LTE',
  RANGE = 'RANGE',
}

export type DeleteStatus = {
  __typename?: 'DeleteStatus';
  message: Scalars['String']['output'];
  status?: Maybe<Scalars['Int']['output']>;
};

export type Document = {
  __typename?: 'Document';
  additionalData?: Maybe<DocumentAdditionalData>;
  assigneeTo?: Maybe<User>;
  content?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  inputData: DocumentInputData;
  org: Organization;
  prioritization?: Maybe<DocumentPrioritization>;
  scope?: Maybe<DocumentScope>;
  site: Site;
  status?: Maybe<DocumentStatus>;
  steps?: Maybe<Array<DocumentStep>>;
  subtype: DocumentSubtype;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String']['output'];
  type: DocumentType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type DocumentAdditionalData =
  | DocumentAnalysisData
  | DocumentBriefData
  | DocumentContentPlanData;

export type DocumentAnalysisData = {
  __typename?: 'DocumentAnalysisData';
  allClusterThemes?: Maybe<Array<Scalars['String']['output']>>;
  clusters?: Maybe<Array<DocumentClusterData>>;
  clustersTotalCount?: Maybe<Scalars['Int']['output']>;
  keywordUniverseCount?: Maybe<Scalars['Int']['output']>;
  processingTime?: Maybe<Scalars['Float']['output']>;
  serpsFetchedForKeywords?: Maybe<Scalars['Int']['output']>;
};

export type DocumentBriefData = {
  __typename?: 'DocumentBriefData';
  processingTime?: Maybe<Scalars['Float']['output']>;
};

export type DocumentBriefUserInput = {
  __typename?: 'DocumentBriefUserInput';
  buyerOrInformationJourney?: Maybe<Scalars['String']['output']>;
  contextLinguistic?: Maybe<Scalars['String']['output']>;
  expertise?: Maybe<Scalars['String']['output']>;
  factStatements?: Maybe<Scalars['String']['output']>;
  intentAnalysis?: Maybe<Scalars['String']['output']>;
  marketingAndMessagingReferences?: Maybe<Array<Scalars['String']['output']>>;
  personalExperience?: Maybe<Scalars['String']['output']>;
  personalizationDetail?: Maybe<Scalars['String']['output']>;
  pointOfView?: Maybe<Scalars['String']['output']>;
  proofStatements?: Maybe<Scalars['String']['output']>;
  structuring?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  targetMarket?: Maybe<Scalars['String']['output']>;
  tone?: Maybe<Scalars['String']['output']>;
  voice?: Maybe<Scalars['String']['output']>;
};

export type DocumentBriefUserOptionalInput = {
  buyerOrInformationJourney?: InputMaybe<Scalars['String']['input']>;
  contextLinguistic?: InputMaybe<Scalars['String']['input']>;
  expertise?: InputMaybe<Scalars['String']['input']>;
  factStatements?: InputMaybe<Scalars['String']['input']>;
  intentAnalysis?: InputMaybe<Scalars['String']['input']>;
  marketingAndMessagingReferences?: InputMaybe<
    Array<Scalars['String']['input']>
  >;
  personalExperience?: InputMaybe<Scalars['String']['input']>;
  personalizationDetail?: InputMaybe<Scalars['String']['input']>;
  pointOfView?: InputMaybe<Scalars['String']['input']>;
  proofStatements?: InputMaybe<Scalars['String']['input']>;
  structuring?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  targetMarket?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Scalars['String']['input']>;
  voice?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentClusterData = {
  __typename?: 'DocumentClusterData';
  contentGroups?: Maybe<Array<DocumentContentGroups>>;
  terms?: Maybe<Array<Scalars['String']['output']>>;
  theme: Scalars['String']['output'];
};

export type DocumentContentGroups = {
  __typename?: 'DocumentContentGroups';
  name?: Maybe<Scalars['String']['output']>;
  personas?: Maybe<Array<Scalars['String']['output']>>;
};

export type DocumentContentOpportunities = {
  __typename?: 'DocumentContentOpportunities';
  documentSubtype?: Maybe<BriefSubtype>;
  focusTerm: Scalars['String']['output'];
  funnelStage: FunnelStages;
  idea: Scalars['String']['output'];
  overview: Scalars['String']['output'];
  pointsToCover: Array<Scalars['String']['output']>;
  relatedTerms: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type DocumentContentPlanData = {
  __typename?: 'DocumentContentPlanData';
  opportunitiesCreate?: Maybe<Array<DocumentContentOpportunities>>;
  opportunitiesOptimize?: Maybe<Array<DocumentOptimizeOpportunities>>;
  processingTime?: Maybe<Scalars['Float']['output']>;
  recommendedBriefs: ContentPlanRecommendations;
  theme: Scalars['String']['output'];
};

export type DocumentCreateBriefFromPlanInput = {
  createBriefInput?: InputMaybe<BriefDocumentInput>;
  documentId: Scalars['String']['input'];
  optimizeBriefInput?: InputMaybe<InputData>;
  tagTitles?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentCreateEstimation = {
  __typename?: 'DocumentCreateEstimation';
  documentSubtype: DocumentSubtype;
  documentType: DocumentType;
  status: DocumentCreateEstimationStatus;
};

export enum DocumentCreateEstimationStatus {
  ALLOWED_TO_CREATE = 'ALLOWED_TO_CREATE',
  LIMIT_DENIED = 'LIMIT_DENIED',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
}

export type DocumentCreateInput = {
  briefDocumentInput?: InputMaybe<BriefDocumentScratchInput>;
  documentPrioritization?: InputMaybe<DocumentPrioritization>;
  documentScope?: InputMaybe<DocumentScope>;
  documentSubtype: DocumentSubtype;
  documentType: DocumentType;
  inputData: InputData;
  tagTitles?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  writingDocumentHtml?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentCreatePlansFromAnalysisInput = {
  contentPlanDocumentInput: Array<ContentPlanDocumentInput>;
  documentId: Scalars['String']['input'];
  tagTitles?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

export type DocumentFilter = {
  AND?: InputMaybe<Array<DocumentFilter>>;
  OR?: InputMaybe<Array<DocumentFilter>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  org?: InputMaybe<Array<OrganizationFilter>>;
  prioritization?: InputMaybe<DocumentPrioritization>;
  prioritizationNe?: InputMaybe<DocumentPrioritization>;
  prioritizationsIn?: InputMaybe<Array<DocumentPrioritization>>;
  scope?: InputMaybe<DocumentScope>;
  scopeNe?: InputMaybe<DocumentScope>;
  scopesIn?: InputMaybe<Array<DocumentScope>>;
  site?: InputMaybe<Array<SiteFilter>>;
  status?: InputMaybe<DocumentStatus>;
  statusNe?: InputMaybe<DocumentStatus>;
  statusesIn2?: InputMaybe<Array<DocumentStatus>>;
  subtype?: InputMaybe<DocumentSubtype>;
  subtypeNe?: InputMaybe<DocumentSubtype>;
  subtypesIn?: InputMaybe<Array<DocumentSubtype>>;
  tags?: InputMaybe<Array<TagFilter>>;
  termsIntersect?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DocumentType>;
  typeNe?: InputMaybe<DocumentType>;
  typesIn?: InputMaybe<Array<DocumentType>>;
  urlsIntersect?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DocumentInputData = {
  __typename?: 'DocumentInputData';
  domain?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  terms?: Maybe<Array<Scalars['String']['output']>>;
  urls?: Maybe<Array<Scalars['URL']['output']>>;
};

export type DocumentOptimizeOpportunities = {
  __typename?: 'DocumentOptimizeOpportunities';
  term: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export enum DocumentPrioritization {
  HIGHEST_COVERAGE_CLUSTERS = 'HIGHEST_COVERAGE_CLUSTERS',
  HIGHEST_MARKET_SHARE_CLUSTERS = 'HIGHEST_MARKET_SHARE_CLUSTERS',
  HIGHEST_OPPORTUNITY_CLUSTERS = 'HIGHEST_OPPORTUNITY_CLUSTERS',
  HIGHEST_OPPORTUNITY_CLUSTERS_INVENTORY = 'HIGHEST_OPPORTUNITY_CLUSTERS_INVENTORY',
  HIGHEST_TOTAL_TRAFFIC_POTENTIAL_CLUSTERS = 'HIGHEST_TOTAL_TRAFFIC_POTENTIAL_CLUSTERS',
  HIGHEST_TRAFFIC_CLUSTERS = 'HIGHEST_TRAFFIC_CLUSTERS',
  MOST_RELEVANT_CLUSTERS = 'MOST_RELEVANT_CLUSTERS',
  MOST_RELEVANT_CLUSTERS_INVENTORY = 'MOST_RELEVANT_CLUSTERS_INVENTORY',
  QUICK_WIN_CLUSTERS = 'QUICK_WIN_CLUSTERS',
}

export enum DocumentScope {
  PAGE = 'PAGE',
  PAGES = 'PAGES',
  SITE_LEVEL = 'SITE_LEVEL',
  TOPIC = 'TOPIC',
  TOPIC_WITH_PAGE = 'TOPIC_WITH_PAGE',
  TOPIC_WITH_PAGES = 'TOPIC_WITH_PAGES',
  TOPIC_WITH_SITE = 'TOPIC_WITH_SITE',
  WORD_LIST = 'WORD_LIST',
  WORD_LIST_WITH_SITE = 'WORD_LIST_WITH_SITE',
}

export enum DocumentStatus {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum DocumentStep {
  ANALYSING_CONTENT_STRUCTURE = 'ANALYSING_CONTENT_STRUCTURE',
  CALCULATING_CLUSTER_METRICS = 'CALCULATING_CLUSTER_METRICS',
  CALCULATING_EXISITING_CONTENT_PROFILE = 'CALCULATING_EXISITING_CONTENT_PROFILE',
  CHECKING_CONTENT_QUALITY = 'CHECKING_CONTENT_QUALITY',
  EXTRACTING_CLAIMS_GROUPED_BY_CONTEXT = 'EXTRACTING_CLAIMS_GROUPED_BY_CONTEXT',
  FETCHING_SERPS = 'FETCHING_SERPS',
  GENERATING_CLUSTERS = 'GENERATING_CLUSTERS',
  GENERATING_COMPETITIVE_ANALYSIS = 'GENERATING_COMPETITIVE_ANALYSIS',
  GENERATING_CONTENT_OUTLINE = 'GENERATING_CONTENT_OUTLINE',
  GENERATING_EXECUTIVE_SUMMARY = 'GENERATING_EXECUTIVE_SUMMARY',
  GENERATING_INTENT_ANALYSIS = 'GENERATING_INTENT_ANALYSIS',
  GENERATING_KEYWORD_STRATEGY = 'GENERATING_KEYWORD_STRATEGY',
  GENERATING_KEYWORD_UNIVERSE = 'GENERATING_KEYWORD_UNIVERSE',
  GENERATING_LINKING_RECOMMENDATIONS = 'GENERATING_LINKING_RECOMMENDATIONS',
  GENERATING_PAIN_POINTS_OF_AUDIENCE = 'GENERATING_PAIN_POINTS_OF_AUDIENCE',
  GENERATING_PLANS_FROM_CONTEXTS = 'GENERATING_PLANS_FROM_CONTEXTS',
  GENERATING_TARGETING_DETAILS = 'GENERATING_TARGETING_DETAILS',
  PRIORITIZING_CLUSTERS = 'PRIORITIZING_CLUSTERS',
}

export enum DocumentSubtype {
  COMPARISON_CONTENT = 'COMPARISON_CONTENT',
  FAQ_COLLECTION_CONTENT = 'FAQ_COLLECTION_CONTENT',
  GUIDE_CONTENT = 'GUIDE_CONTENT',
  HOW_TO_TUTORIAL = 'HOW_TO_TUTORIAL',
  LIST_CONTENT = 'LIST_CONTENT',
  LOCAL_TARGET_CONTENT = 'LOCAL_TARGET_CONTENT',
  NEWS_EVENT_CONTENT = 'NEWS_EVENT_CONTENT',
  NONE = 'NONE',
  PRODUCT_ECOMMERCE = 'PRODUCT_ECOMMERCE',
  REVIEW_CONTENT = 'REVIEW_CONTENT',
  UNIVERSAL = 'UNIVERSAL',
}

export enum DocumentType {
  BRIEF_CREATE = 'BRIEF_CREATE',
  BRIEF_OPTIMIZE = 'BRIEF_OPTIMIZE',
  CLUSTER_ANALYSIS = 'CLUSTER_ANALYSIS',
  CONTENT_IDEATION = 'CONTENT_IDEATION',
  CONTENT_PLAN = 'CONTENT_PLAN',
  KEYWORD_AND_AUDIENCE_INTENT_ANALYSIS = 'KEYWORD_AND_AUDIENCE_INTENT_ANALYSIS',
  QUALITY_AND_FACT_CLAIM_MANAGEMENT = 'QUALITY_AND_FACT_CLAIM_MANAGEMENT',
  SEARCH_RESULTS_PAGE_ANALYSIS = 'SEARCH_RESULTS_PAGE_ANALYSIS',
  WRITING = 'WRITING',
}

export type DocumentUpdateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  tagTitles?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentsAllotment = {
  __typename?: 'DocumentsAllotment';
  analysisPlanning: DocumentsAllotmentInfo;
  briefs: DocumentsAllotmentInfo;
  resetDate: ResetDate;
};

export type DocumentsAllotmentInfo = {
  __typename?: 'DocumentsAllotmentInfo';
  created: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DocumentsConnection = {
  __typename?: 'DocumentsConnection';
  analysisPlanning: DocumentsConnectionInfo;
  briefs: DocumentsConnectionInfo;
};

export type DocumentsConnectionInfo = {
  __typename?: 'DocumentsConnectionInfo';
  current: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DocumentsCreateEstimateInput = {
  documentSubtype?: InputMaybe<DocumentSubtype>;
  documentType: DocumentType;
};

export type DocumentsResponse = {
  __typename?: 'DocumentsResponse';
  count: Scalars['Int']['output'];
  items: Array<Document>;
};

export type ErrorInput = {
  id: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export type ExcelGenerationContext = {
  contentScore?: InputMaybe<Scalars['Int']['input']>;
  distributionExact?: InputMaybe<Scalars['Boolean']['input']>;
  matches?: InputMaybe<Scalars['JSON']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  selectedTopics?: InputMaybe<Scalars['JSON']['input']>;
  selectedVariants?: InputMaybe<Scalars['JSON']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  variantMatches?: InputMaybe<Scalars['JSON']['input']>;
  wordCount?: InputMaybe<Scalars['Int']['input']>;
};

export type ExcelGenerationData = {
  kg?: InputMaybe<Scalars['JSON']['input']>;
  kgNews?: InputMaybe<Scalars['JSON']['input']>;
  lr?: InputMaybe<Scalars['JSON']['input']>;
  params?: InputMaybe<AppDataParamsInput>;
  qts?: InputMaybe<Scalars['JSON']['input']>;
  reports?: InputMaybe<Array<Scalars['JSON']['input']>>;
  scores?: InputMaybe<Scalars['JSON']['input']>;
  scoresNews?: InputMaybe<Scalars['JSON']['input']>;
};

export enum ExcelGenerationFunctions {
  NEWSROOM = 'newsroom',
  OPTIMIZE = 'optimize',
  REPORTS = 'reports',
}

export type ExcelGenerationResponse = {
  __typename?: 'ExcelGenerationResponse';
  data: Scalars['String']['output'];
  format: Scalars['String']['output'];
};

export type Filter = {
  __typename?: 'Filter';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  inventoryType?: Maybe<InventoryType>;
  org?: Maybe<Organization>;
  site?: Maybe<Site>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type FilterContextInput = {
  excludePaths?: InputMaybe<Array<Scalars['URL']['input']>>;
  includePath?: InputMaybe<Scalars['URL']['input']>;
};

export type FilterFilter = {
  AND?: InputMaybe<Array<FilterFilter>>;
  OR?: InputMaybe<Array<FilterFilter>>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  inventoryType?: InputMaybe<InventoryType>;
  inventoryTypeNe?: InputMaybe<InventoryType>;
  inventoryTypesIn?: InputMaybe<Array<InventoryType>>;
  org?: InputMaybe<Array<OrganizationFilter>>;
  site?: InputMaybe<Array<SiteFilter>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FilterInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  inventoryType?: InputMaybe<InventoryType>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FollowUpInitialInput = {
  resolver: GenaiResolver;
  response: Scalars['String']['input'];
};

export type FollowUpInput = {
  followUpPromptInput?: InputMaybe<Scalars['String']['input']>;
  followUpType: FollowUpType;
  previousResponse: Scalars['String']['input'];
};

export enum FollowUpType {
  CONTINUE_WRITING = 'continueWriting',
  CUSTOM = 'custom',
  MAKE_LONGER = 'makeLonger',
  TRY_AGAIN = 'tryAgain',
}

export type Form = {
  __typename?: 'Form';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  groups?: Maybe<Array<FormGroup>>;
  id: Scalars['ID']['output'];
  orderHidden?: Maybe<Scalars['JSON']['output']>;
  orderVisible?: Maybe<Scalars['JSON']['output']>;
  org?: Maybe<Organization>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<FormType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormField = {
  __typename?: 'FormField';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldData?: Maybe<Scalars['JSON']['output']>;
  group?: Maybe<FormGroup>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type: FormFieldType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormFieldInput = {
  fieldData?: InputMaybe<Scalars['JSON']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<FormFieldType>;
};

export enum FormFieldType {
  AUTO_COMPLETE = 'autoComplete',
  CHECKBOX = 'checkbox',
  CONTENT = 'content',
  DROPDOWN = 'dropdown',
  DUAL_INPUT = 'dualInput',
  HIERARCHICAL_OPTIONS = 'hierarchicalOptions',
  HORIZONTAL_RULE = 'horizontalRule',
  INPUT = 'input',
  MULTI_INPUT = 'multiInput',
  QUESTIONS_MODULE = 'questionsModule',
  RADIO = 'radio',
  RELATED_PAGES = 'relatedPages',
  TEXTAREA = 'textarea',
  TOP_TWENTY = 'topTwenty',
  USER_LOOKUP = 'userLookup',
  WORD_COUNT_RECOMMENDATION = 'wordCountRecommendation',
}

export type FormFilter = {
  AND?: InputMaybe<Array<FormFilter>>;
  OR?: InputMaybe<Array<FormFilter>>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Array<UserFilter>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  org?: InputMaybe<Array<OrganizationFilter>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FormType>;
  typeNe?: InputMaybe<FormType>;
  typesIn?: InputMaybe<Array<FormType>>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FormGroup = {
  __typename?: 'FormGroup';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fields?: Maybe<Array<FormField>>;
  form?: Maybe<Form>;
  id: Scalars['ID']['output'];
  orderHidden?: Maybe<Scalars['JSON']['output']>;
  orderVisible?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: FormGroupType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormGroupInput = {
  formId?: InputMaybe<Scalars['String']['input']>;
  orderHidden?: InputMaybe<Scalars['JSON']['input']>;
  orderVisible?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FormGroupType>;
};

export enum FormGroupType {
  SECTION = 'section',
  TAB_SECTION = 'tab_section',
}

export type FormInput = {
  orderHidden?: InputMaybe<Scalars['JSON']['input']>;
  orderVisible?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FormType>;
};

export enum FormType {
  REPORT = 'report',
}

export type FraudInfo = {
  __typename?: 'FraudInfo';
  decision?: Maybe<Scalars['String']['output']>;
  riskRulesTriggered?: Maybe<Scalars['JSON']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
};

export enum FunnelStages {
  AWARENESS = 'AWARENESS',
  CONSIDERATION = 'CONSIDERATION',
  DECISION = 'DECISION',
}

export type GenAiPromptResponse = {
  __typename?: 'GenAiPromptResponse';
  response: Scalars['String']['output'];
};

export enum GenaiResolver {
  GENAI_CHANGE_TONE = 'genaiChangeTone',
  GENAI_CREATE_OUTLINE = 'genaiCreateOutline',
  GENAI_CREATE_SUMMARY = 'genaiCreateSummary',
  GENAI_CUSTOM_PROMPT = 'genaiCustomPrompt',
  GENAI_EXPAND_CONCEPT = 'genaiExpandConcept',
  GENAI_FIX_SPELLING = 'genaiFixSpelling',
  GENAI_FOLLOW_UP_CONTINUE_WRITING = 'genaiFollowUpContinueWriting',
  GENAI_FOLLOW_UP_CUSTOM = 'genaiFollowUpCustom',
  GENAI_FOLLOW_UP_MAKE_LONGER = 'genaiFollowUpMakeLonger',
  GENAI_FOLLOW_UP_TRY_AGAIN = 'genaiFollowUpTryAgain',
  GENAI_IMPROVE_WRITING = 'genaiImproveWriting',
  GENAI_MAKE_LONGER = 'genaiMakeLonger',
  GENAI_MAKE_SHORTER = 'genaiMakeShorter',
}

export type GenerateSubheadingInput = {
  questions?: InputMaybe<Array<Scalars['String']['input']>>;
  subheading: Scalars['String']['input'];
};

export type GenerationContextInput = {
  distributionExact?: Scalars['Boolean']['input'];
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type GetAppDataResponse = {
  __typename?: 'GetAppDataResponse';
  appRuns: AppRuns;
  compareKgsNews?: Maybe<Scalars['JSON']['output']>;
  keywords?: Maybe<Scalars['JSON']['output']>;
  kg?: Maybe<Scalars['JSON']['output']>;
  kgNews?: Maybe<Scalars['JSON']['output']>;
  lr?: Maybe<Scalars['JSON']['output']>;
  params: AppDataParams;
  qts?: Maybe<Scalars['JSON']['output']>;
  scores?: Maybe<Scalars['JSON']['output']>;
  scoresNews?: Maybe<Scalars['JSON']['output']>;
};

export type GetInventoryItemInput = {
  fields: PageTopicRequestFields;
  siteId: Scalars['String']['input'];
};

export type GuestAccess = {
  __typename?: 'GuestAccess';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  options: Scalars['JSON']['output'];
  purpose: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GuestAccessCreate = {
  __typename?: 'GuestAccessCreate';
  token: Scalars['String']['output'];
};

export type GuestAccessCreateMany = {
  __typename?: 'GuestAccessCreateMany';
  tokens: Array<Scalars['String']['output']>;
};

export type GuestAccessOptions = {
  resourceId: Scalars['String']['input'];
  resourceType: Scalars['String']['input'];
  resourceView: Scalars['String']['input'];
};

export type GuestAccessValidate = {
  __typename?: 'GuestAccessValidate';
  guestAccess?: Maybe<GuestAccess>;
  guestUser?: Maybe<User>;
  isValid: Scalars['Boolean']['output'];
};

export type History = {
  __typename?: 'History';
  volume?: Maybe<Scalars['Int']['output']>;
  yearMonth?: Maybe<Scalars['String']['output']>;
};

export type HistoryInput = {
  volume?: InputMaybe<Scalars['Int']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};

export type InputData = {
  domain?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  terms?: InputMaybe<Array<Scalars['String']['input']>>;
  urls?: InputMaybe<Array<Scalars['URL']['input']>>;
};

export type InquirePremium = {
  __typename?: 'InquirePremium';
  status: Scalars['Int']['output'];
};

export enum Intent {
  INFO_COMPARISON = 'INFO_COMPARISON',
  INFO_KNOW = 'INFO_KNOW',
  INFO_KNOW_SIMPLE = 'INFO_KNOW_SIMPLE',
  LOCAL = 'LOCAL',
  TRANSACTIONAL = 'TRANSACTIONAL',
  WEBSITE_QUERY = 'WEBSITE_QUERY',
}

export type IntentData = {
  __typename?: 'IntentData';
  intentBreakdown?: Maybe<SerpIntentTopic>;
  primarySerpIntent?: Maybe<Intent>;
};

export type IntentDataInput = {
  intentBreakdown?: InputMaybe<SerpIntentTopicInput>;
  primarySerpIntent?: InputMaybe<Intent>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  key: Scalars['String']['output'];
  page?: Maybe<Page>;
  pageTopic?: Maybe<PageTopic>;
  project?: Maybe<Project>;
  topic?: Maybe<Topic>;
  type: InventoryItemType;
};

export type InventoryItemPotential = {
  __typename?: 'InventoryItemPotential';
  page?: Maybe<Page>;
  pageTopic?: Maybe<PageTopic>;
  topic?: Maybe<Topic>;
  versions?: Maybe<Array<InventoryVersion>>;
};

export enum InventoryItemType {
  PAGE = 'PAGE',
  PAGE_TOPIC = 'PAGE_TOPIC',
  PROJECT_PAGE = 'PROJECT_PAGE',
  PROJECT_PAGE_TOPIC = 'PROJECT_PAGE_TOPIC',
  PROJECT_TOPIC = 'PROJECT_TOPIC',
  TOPIC = 'TOPIC',
}

export type InventoryItemUpdate = {
  hiddenByUser?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['BigInt']['input'];
};

export type InventoryItemsAddInput = {
  order?: InputMaybe<OrderInput>;
  projectData?: InputMaybe<ProjectInputBase>;
  term?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryItemsAddResponse = {
  __typename?: 'InventoryItemsAddResponse';
  projects: Array<Maybe<Project>>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type InventoryItemsResponse = {
  __typename?: 'InventoryItemsResponse';
  count: Scalars['Int']['output'];
  items: Array<InventoryItem>;
};

export type InventoryItemsUpdateInput = {
  pages?: InputMaybe<Array<InventoryItemUpdate>>;
  resources?: InputMaybe<Array<InventoryItemUpdate>>;
  siteId: Scalars['String']['input'];
  topics?: InputMaybe<Array<InventoryItemUpdate>>;
};

export enum InventoryLevelEnum {
  BRONZE = 'bronze',
  GOLD = 'gold',
  PRO = 'pro',
  SILVER = 'silver',
  TRIAL = 'trial',
}

export type InventorySortFields = {
  direction?: InputMaybe<SortDirection>;
  field: Scalars['String']['input'];
  table?: InputMaybe<Scalars['String']['input']>;
};

export enum InventoryType {
  BRIEFS = 'BRIEFS',
  PAGES = 'PAGES',
  PAGE_TOPICS = 'PAGE_TOPICS',
  PLANS = 'PLANS',
  PROJECTS = 'PROJECTS',
  SAVED_WRITING = 'SAVED_WRITING',
  TOPICS = 'TOPICS',
}

export type InventoryVersion = {
  __typename?: 'InventoryVersion';
  failed?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  status?: Maybe<VersionStatusEnum>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  trafficDelta?: Maybe<Scalars['Float']['output']>;
  trafficDeltaPct?: Maybe<Scalars['Float']['output']>;
  trafficValue?: Maybe<Scalars['Float']['output']>;
  trafficValueDelta?: Maybe<Scalars['Float']['output']>;
  trafficValueDeltaPct?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Int']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  account?: Maybe<AccountMini>;
  address?: Maybe<InvoiceAddress>;
  balance?: Maybe<Scalars['Float']['output']>;
  billingInfoId?: Maybe<Scalars['String']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  collectionMethod?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creditPayments?: Maybe<Array<CreditPayment>>;
  currency?: Maybe<Scalars['String']['output']>;
  customerNotes?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  dunningCampaignId?: Maybe<Scalars['String']['output']>;
  hasMoreLineItems?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lineItems?: Maybe<Array<LineItem>>;
  netTerms?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Float']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  previousInvoiceId?: Maybe<Scalars['String']['output']>;
  refundableAmount?: Maybe<Scalars['Float']['output']>;
  shippingAddress?: Maybe<ShippingAddress>;
  state?: Maybe<Scalars['String']['output']>;
  subscriptionIds?: Maybe<Scalars['JSON']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  taxInfo?: Maybe<TaxInfo>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transactions?: Maybe<Array<Transaction>>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
  vatReverseChargeNotes?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAddress = {
  __typename?: 'InvoiceAddress';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nameOnAccount?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCollection = {
  __typename?: 'InvoiceCollection';
  chargeInvoice?: Maybe<Invoice>;
  creditInvoices?: Maybe<Array<Invoice>>;
  object?: Maybe<Scalars['String']['output']>;
};

export type InvoiceMini = {
  __typename?: 'InvoiceMini';
  id?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ItemMini = {
  __typename?: 'ItemMini';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type KgMetrics = {
  __typename?: 'KGMetrics';
  averageContentScore?: Maybe<Scalars['Float']['output']>;
  averageWordCount?: Maybe<Scalars['Float']['output']>;
  highestContentScore?: Maybe<Scalars['Int']['output']>;
  highestWordCount?: Maybe<Scalars['Int']['output']>;
  targetContentScore?: Maybe<Scalars['Int']['output']>;
  targetWordCount?: Maybe<Scalars['Int']['output']>;
};

export type KgMetricsInput = {
  averageContentScore?: InputMaybe<Scalars['Float']['input']>;
  averageWordCount?: InputMaybe<Scalars['Float']['input']>;
  highestContentScore?: InputMaybe<Scalars['Int']['input']>;
  highestWordCount?: InputMaybe<Scalars['Int']['input']>;
  targetContentScore?: InputMaybe<Scalars['Int']['input']>;
  targetWordCount?: InputMaybe<Scalars['Int']['input']>;
};

export type KnowledgeGraph = {
  __typename?: 'KnowledgeGraph';
  knowledgeGraphItems?: Maybe<Array<KnowledgeGraphItem>>;
  metrics: KgMetrics;
  organicSerpItemPagesKgData?: Maybe<Array<OrganicSerpItemPageKgData>>;
  userProvidedPage?: Maybe<OrganicSerpItemPageKgData>;
};

export type KnowledgeGraphItem = {
  __typename?: 'KnowledgeGraphItem';
  existsInInventory?: Maybe<Scalars['Boolean']['output']>;
  recommendedMentions?: Maybe<Scalars['Int']['output']>;
  relevanceScore?: Maybe<Scalars['Float']['output']>;
  term: Scalars['String']['output'];
  userProvidedPageMentions?: Maybe<Scalars['Int']['output']>;
  variants?: Maybe<Array<AdWordsItem>>;
};

export type KnowledgeGraphItemInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  categoryStrings?: InputMaybe<Array<Scalars['String']['input']>>;
  competition?: InputMaybe<Scalars['Float']['input']>;
  costPerClick?: InputMaybe<Scalars['Float']['input']>;
  history?: InputMaybe<Array<HistoryInput>>;
  numVariants?: InputMaybe<Scalars['Int']['input']>;
  recommendedMentions?: InputMaybe<Scalars['Int']['input']>;
  relevanceScore?: InputMaybe<Scalars['Float']['input']>;
  term: Scalars['String']['input'];
  userProvidedPageMentions?: InputMaybe<Scalars['Int']['input']>;
  variants?: InputMaybe<Array<ResearchItemInput>>;
  volume?: InputMaybe<Scalars['Int']['input']>;
};

export enum Language {
  EN = 'EN',
  ES = 'ES',
}

export type LineItem = {
  __typename?: 'LineItem';
  account?: Maybe<AccountMini>;
  accountingCode?: Maybe<Scalars['String']['output']>;
  addOnCode?: Maybe<Scalars['String']['output']>;
  addOnId?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  avalaraServiceType?: Maybe<Scalars['Int']['output']>;
  avalaraTransactionType?: Maybe<Scalars['Int']['output']>;
  billForAccountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creditApplied?: Maybe<Scalars['Float']['output']>;
  creditReasonCode?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalSku?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  itemCode?: Maybe<Scalars['String']['output']>;
  itemId?: Maybe<Scalars['String']['output']>;
  legacyCategory?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originalLineItemInvoiceId?: Maybe<Scalars['String']['output']>;
  planCode?: Maybe<Scalars['String']['output']>;
  planId?: Maybe<Scalars['String']['output']>;
  previousLineItemId?: Maybe<Scalars['String']['output']>;
  productCode?: Maybe<Scalars['String']['output']>;
  prorationRate?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  refund?: Maybe<Scalars['Boolean']['output']>;
  refundedQuantity?: Maybe<Scalars['Int']['output']>;
  revenueScheduleType?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<ShippingAddress>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  taxCode?: Maybe<Scalars['String']['output']>;
  taxExempt?: Maybe<Scalars['Boolean']['output']>;
  taxInfo?: Maybe<TaxInfo>;
  taxable?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unitAmount?: Maybe<Scalars['Float']['output']>;
  unitAmountDecimal?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type LinkInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LinkListInput = {
  id: Scalars['String']['input'];
  links?: InputMaybe<Array<LinkInput>>;
  semanticSimilarity?: InputMaybe<Scalars['Float']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LinkRecommendation = {
  __typename?: 'LinkRecommendation';
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LinkRecommendationInput = {
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type LinkingRecommendationForTerm = {
  __typename?: 'LinkingRecommendationForTerm';
  recommendations?: Maybe<Array<LinkRecommendation>>;
  term: Scalars['String']['output'];
};

export type LinkingRecommendationForTermInput = {
  recommendations?: InputMaybe<Array<LinkRecommendationInput>>;
  term: Scalars['String']['input'];
};

export type MentionItem = {
  __typename?: 'MentionItem';
  count: Scalars['Int']['output'];
  term: Scalars['String']['output'];
};

export type MentionItemInput = {
  count: Scalars['Int']['input'];
  term: Scalars['String']['input'];
};

export type MetadataInput = {
  errors?: InputMaybe<Array<ErrorInput>>;
  id: Scalars['String']['input'];
};

export type Module = {
  __typename?: 'Module';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdByUser?: Maybe<User>;
  dashboard?: Maybe<Dashboard>;
  description?: Maybe<Scalars['String']['output']>;
  forkSource: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  scope?: Maybe<DashboardScope>;
  site?: Maybe<Site>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedByUser?: Maybe<User>;
};

export type ModuleFilter = {
  AND?: InputMaybe<Array<ModuleFilter>>;
  OR?: InputMaybe<Array<ModuleFilter>>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  createdByUser?: InputMaybe<Array<UserFilter>>;
  dashboard?: InputMaybe<Array<DashboardFilter>>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionNe?: InputMaybe<Scalars['String']['input']>;
  forkSource?: InputMaybe<Scalars['String']['input']>;
  forkSourceContains?: InputMaybe<Scalars['String']['input']>;
  forkSourceNe?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  readOnlyNe?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<DashboardScope>;
  scopeNe?: InputMaybe<DashboardScope>;
  scopesIn?: InputMaybe<Array<DashboardScope>>;
  site?: InputMaybe<Array<SiteFilter>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedByUser?: InputMaybe<Array<UserFilter>>;
};

export type ModuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  forkSource?: InputMaybe<Scalars['String']['input']>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<DashboardScope>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMember: User;
  addSubheading: Brief;
  adminSubscriptionAddonCreate: Array<SubscriptionAddon>;
  adminSubscriptionCreate: SubscriptionEntity;
  adminSubscriptionUpdate: SubscriptionEntity;
  appQueryCreateOrUpdate: AppQuery;
  appQueryUpdate: AppQuery;
  businessReviewCreate: BusinessReview;
  cancelAccount: CancelAccount;
  cancelSubscriptionSite: CancelSubscriptionSchema;
  cancelSubscriptionUser: CancelSubscriptionSchema;
  createBasicReport: Brief;
  createFreeSiteSubscription: SubscribeToPlan;
  createPaidSiteSubscription: SubscribeToPlan;
  createUserSubscription: SubscribeToPlan;
  dashboardCreate: Dashboard;
  dashboardUpdate: Dashboard;
  deleteReport: Scalars['Boolean']['output'];
  documentDelete: DeleteStatus;
  documentUpdate: Document;
  documentsCreate: Array<Document>;
  documentsCreateBriefsFromPlan: Array<Document>;
  documentsCreatePlansFromAnalysis: Array<Document>;
  downgradeSiteSubscription: SubscribeToPlan;
  filterCreate: Filter;
  filterDelete: DeleteStatus;
  filterUpdate: Filter;
  formCreate: Form;
  formFieldCreate: FormField;
  formFieldUpdate: FormField;
  formGroupCreate: FormGroup;
  formGroupUpdate: FormGroup;
  formUpdate: Form;
  generateReport: RequestStatus;
  guestAccessCreate: GuestAccessCreate;
  guestAccessCreateMany: GuestAccessCreateMany;
  guestAccessValidate: GuestAccessValidate;
  inquirePremium: InquirePremium;
  inventoryItemsAdd: InventoryItemsAddResponse;
  inventoryItemsUpdate: Scalars['Boolean']['output'];
  moduleCreate: Module;
  moduleUpdate: Module;
  organizationCreate: Organization;
  organizationUpdate: Organization;
  permissionsWrite: RequestStatus;
  planCreate: Plan;
  planDelete: DeleteStatus;
  projectUpsert: Project;
  projectsCreateWithSavedWriting: Array<Project>;
  projectsDelete: Array<DeleteStatus>;
  projectsPlaceOrders: ProjectsPlaceOrdersResponse;
  projectsUpsert: Array<Project>;
  purchaseAppRuns: PurchaseAppRuns;
  purchaseCredits: PurchaseCredits;
  recurlyCreateAccount: Account;
  recurlyUpsertBillingInfo: Account;
  removeProjectOrder: Project;
  savedViewCreate: SavedView;
  savedViewDelete: DeleteStatus;
  savedViewUpdate: SavedView;
  setupAccount: SetupAccountResult;
  siteCreate: Site;
  siteCreateGovernedInventory: Site;
  siteDelete: DeleteStatus;
  siteUpdate: Site;
  /** For a given subscription cancel a subscription addon. */
  subscriptionAddonCancel: RequestStatus;
  /** For a given subscription create a new subscription addon. */
  subscriptionAddonCreate: SubscriptionAddon;
  subscriptionAddonPriceSet: Array<SubscriptionAddonPrice>;
  subscriptionUpdate: SubscriptionUpdateResponse;
  tagCreateOrUpdate: Tag;
  tagDelete: DeleteStatus;
  updateReport: Brief;
  upgradeFreeToPaidSiteSubscription: SubscribeToPlan;
  upgradeFreeToTrialSiteSubscription: SubscribeToPlan;
  upgradeTrialToStandardSiteSubscription: SubscribeToPlan;
  userCreate: User;
  userDelete: DeleteStatus;
  userUpdate: User;
};

export type MutationAddMemberArgs = {
  fields: UserCreateInput;
  orgId: Scalars['String']['input'];
};

export type MutationAddSubheadingArgs = {
  data: AddSubheadingInput;
  returnParams?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminSubscriptionAddonCreateArgs = {
  addonInput: AdminAddonInput;
  count?: Scalars['Int']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationAdminSubscriptionCreateArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  subscriptionInput: SubscriptionCreateInput;
};

export type MutationAdminSubscriptionUpdateArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  subscriptionInput: SubscriptionUpdateInput;
};

export type MutationAppQueryCreateOrUpdateArgs = {
  appQueryInput: AppQueryCreateOrUpdateInput;
  siteId: Scalars['String']['input'];
};

export type MutationAppQueryUpdateArgs = {
  appQueryId: Scalars['String']['input'];
  appQueryInput: AppQueryUpdateInput;
};

export type MutationBusinessReviewCreateArgs = {
  includePaths: Array<Scalars['String']['input']>;
  includeSubdomains: Array<Scalars['String']['input']>;
  invVersions: Array<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};

export type MutationCancelAccountArgs = {
  orgId: Scalars['String']['input'];
};

export type MutationCancelSubscriptionSiteArgs = {
  subscriptionId: Scalars['String']['input'];
  subscriptionSiteId: Scalars['String']['input'];
};

export type MutationCancelSubscriptionUserArgs = {
  deleteUserId: Scalars['String']['input'];
  subscriptionUuid: Scalars['String']['input'];
};

export type MutationCreateBasicReportArgs = {
  briefType: Scalars['String']['input'];
  selfServe?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateFreeSiteSubscriptionArgs = {
  site: SiteInput;
};

export type MutationCreatePaidSiteSubscriptionArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  planCode: Scalars['String']['input'];
  siteInput: SiteInput;
};

export type MutationCreateUserSubscriptionArgs = {
  planCode: Scalars['String']['input'];
  userInput: UserCreateInput;
};

export type MutationDashboardCreateArgs = {
  dashboardInput: DashboardInput;
  siteId: Scalars['String']['input'];
};

export type MutationDashboardUpdateArgs = {
  dashboardId: Scalars['String']['input'];
  dashboardInput: DashboardInput;
};

export type MutationDeleteReportArgs = {
  reportId: Scalars['String']['input'];
};

export type MutationDocumentDeleteArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationDocumentUpdateArgs = {
  documentId: Scalars['String']['input'];
  documentUpdateInput: DocumentUpdateInput;
};

export type MutationDocumentsCreateArgs = {
  documentCountry?: InputMaybe<Country>;
  documentCreateInputs: Array<DocumentCreateInput>;
  documentLanguage?: InputMaybe<Language>;
  orgId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationDocumentsCreateBriefsFromPlanArgs = {
  documentsCreateBriefsFromPlanInputs: Array<DocumentCreateBriefFromPlanInput>;
  orgId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationDocumentsCreatePlansFromAnalysisArgs = {
  documentsCreatePlansFromAnalysisInputs: Array<DocumentCreatePlansFromAnalysisInput>;
  orgId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationDowngradeSiteSubscriptionArgs = {
  subscriptionUuid: Scalars['String']['input'];
};

export type MutationFilterCreateArgs = {
  filterInput: FilterInput;
  siteId: Scalars['String']['input'];
};

export type MutationFilterDeleteArgs = {
  filterId: Scalars['String']['input'];
};

export type MutationFilterUpdateArgs = {
  filterId: Scalars['String']['input'];
  filterInput: FilterInput;
};

export type MutationFormCreateArgs = {
  formInput: FormInput;
  orgId: Scalars['String']['input'];
};

export type MutationFormFieldCreateArgs = {
  formFieldInput: FormFieldInput;
};

export type MutationFormFieldUpdateArgs = {
  formFieldId: Scalars['String']['input'];
  formFieldInput: FormFieldInput;
};

export type MutationFormGroupCreateArgs = {
  formGroupInput: FormGroupInput;
};

export type MutationFormGroupUpdateArgs = {
  formGroupId: Scalars['String']['input'];
  formGroupInput: FormGroupInput;
};

export type MutationFormUpdateArgs = {
  formId: Scalars['String']['input'];
  formInput: FormInput;
};

export type MutationGenerateReportArgs = {
  projectId: Scalars['String']['input'];
  subheadings: Array<GenerateSubheadingInput>;
};

export type MutationGuestAccessCreateArgs = {
  purpose: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  tokenOptions?: InputMaybe<GuestAccessOptions>;
};

export type MutationGuestAccessCreateManyArgs = {
  purpose: Scalars['String']['input'];
  resources: Array<GuestAccessOptions>;
  siteId: Scalars['String']['input'];
};

export type MutationGuestAccessValidateArgs = {
  guestAccessToken: Scalars['String']['input'];
};

export type MutationInquirePremiumArgs = {
  planLevel?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInventoryItemsAddArgs = {
  items: Array<InventoryItemsAddInput>;
  siteId: Scalars['String']['input'];
};

export type MutationInventoryItemsUpdateArgs = {
  items: InventoryItemsUpdateInput;
};

export type MutationModuleCreateArgs = {
  dashboardId: Scalars['String']['input'];
  moduleInput: ModuleInput;
};

export type MutationModuleUpdateArgs = {
  moduleId: Scalars['String']['input'];
  moduleInput: ModuleInput;
};

export type MutationOrganizationCreateArgs = {
  organizationInput: OrganizationInput;
};

export type MutationOrganizationUpdateArgs = {
  organizationId: Scalars['String']['input'];
  organizationInput: OrganizationInput;
};

export type MutationPermissionsWriteArgs = {
  entityId: Scalars['String']['input'];
  permissions: Array<PermissionItemInput>;
  scope: PermissionScope;
};

export type MutationPlanCreateArgs = {
  siteId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationPlanDeleteArgs = {
  planId: Scalars['String']['input'];
};

export type MutationProjectUpsertArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  projectInput: ProjectInputBase;
  siteId: Scalars['String']['input'];
  topic?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MutationProjectsCreateWithSavedWritingArgs = {
  items: Array<ProjectsCreateWithSavedWritingInput>;
  siteId: Scalars['String']['input'];
};

export type MutationProjectsDeleteArgs = {
  projectIds: Array<Scalars['String']['input']>;
};

export type MutationProjectsPlaceOrdersArgs = {
  dryRun: Scalars['Boolean']['input'];
  items: Array<ProjectOrderInput>;
  siteId: Scalars['String']['input'];
};

export type MutationProjectsUpsertArgs = {
  assigneeId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  planTitle?: InputMaybe<Scalars['String']['input']>;
  projects: Array<ProjectsUpsertProjectInput>;
  publishedOn?: InputMaybe<Scalars['DateTime']['input']>;
  siteId: Scalars['String']['input'];
};

export type MutationPurchaseAppRunsArgs = {
  num: Scalars['Int']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationPurchaseCreditsArgs = {
  num: Scalars['Int']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationRecurlyCreateAccountArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRecurlyUpsertBillingInfoArgs = {
  accountCode?: InputMaybe<Scalars['String']['input']>;
  recurlyToken: Scalars['String']['input'];
};

export type MutationRemoveProjectOrderArgs = {
  projectId: Scalars['String']['input'];
};

export type MutationSavedViewCreateArgs = {
  savedViewInput: SavedViewInput;
  siteId: Scalars['String']['input'];
};

export type MutationSavedViewDeleteArgs = {
  savedViewId: Scalars['String']['input'];
};

export type MutationSavedViewUpdateArgs = {
  savedViewId: Scalars['String']['input'];
  savedViewInput: SavedViewInput;
};

export type MutationSetupAccountArgs = {
  company: Scalars['String']['input'];
  country: Scalars['String']['input'];
  domain: Scalars['Domain']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  onboardingStep?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  utm: Scalars['JSON']['input'];
};

export type MutationSiteCreateArgs = {
  addonComplimentary?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<SiteCreateOptions>;
  orgId: Scalars['String']['input'];
  siteInput: SiteInput;
};

export type MutationSiteCreateGovernedInventoryArgs = {
  orgId: Scalars['String']['input'];
  siteInput: SiteInput;
};

export type MutationSiteDeleteArgs = {
  siteId: Scalars['String']['input'];
};

export type MutationSiteUpdateArgs = {
  siteId: Scalars['String']['input'];
  siteInput: SiteInput;
};

export type MutationSubscriptionAddonCancelArgs = {
  subscriptionAddonId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type MutationSubscriptionAddonCreateArgs = {
  addonTypeId: Scalars['String']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationSubscriptionAddonPriceSetArgs = {
  subscriptionAddonPrices: Array<SubscriptionAddonPriceInput>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationSubscriptionUpdateArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  onboardingStep?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  subscriptionPlanId: Scalars['String']['input'];
};

export type MutationTagCreateOrUpdateArgs = {
  siteId: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  tagInput: TagUpdateInput;
};

export type MutationTagDeleteArgs = {
  tagId: Scalars['String']['input'];
};

export type MutationUpdateReportArgs = {
  data: BriefUpdateInput;
  reportId: Scalars['String']['input'];
};

export type MutationUpgradeFreeToPaidSiteSubscriptionArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  planCode: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationUpgradeFreeToTrialSiteSubscriptionArgs = {
  siteId: Scalars['String']['input'];
};

export type MutationUpgradeTrialToStandardSiteSubscriptionArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  planCode: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  subscriptionUuid: Scalars['String']['input'];
};

export type MutationUserCreateArgs = {
  orgId: Scalars['String']['input'];
  userInput: UserCreateInput;
};

export type MutationUserDeleteArgs = {
  userId: Scalars['String']['input'];
};

export type MutationUserUpdateArgs = {
  userId: Scalars['String']['input'];
  userInput: UserUpdateInput;
};

export type OnPageMetrics = SeoPageMetrics & {
  __typename?: 'OnPageMetrics';
  externalLinkCount: Scalars['Int']['output'];
  h2Count: Scalars['Int']['output'];
  imagesCount: Scalars['Int']['output'];
  internalLinkCount: Scalars['Int']['output'];
  videoCount: Scalars['Int']['output'];
  wordCount: Scalars['Int']['output'];
};

export type OnPageMetricsAveragesInput = {
  externalLinkCount?: InputMaybe<Scalars['Float']['input']>;
  h2Count?: InputMaybe<Scalars['Float']['input']>;
  imagesCount?: InputMaybe<Scalars['Float']['input']>;
  internalLinkCount?: InputMaybe<Scalars['Float']['input']>;
  videoCount?: InputMaybe<Scalars['Float']['input']>;
  wordCount?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderInput = {
  selfServe: Scalars['Boolean']['input'];
  type: OrderType;
};

export enum OrderType {
  CREATE = 'create',
  OPTIMIZE = 'optimize',
}

export type OrganicSerpItem = {
  __typename?: 'OrganicSerpItem';
  description?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type OrganicSerpItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  intent?: InputMaybe<Intent>;
  position?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type OrganicSerpItemPage = SeoPageItem &
  SeoPageMetrics & {
    __typename?: 'OrganicSerpItemPage';
    externalLinkCount: Scalars['Int']['output'];
    h2Count: Scalars['Int']['output'];
    imagesCount: Scalars['Int']['output'];
    internalLinkCount: Scalars['Int']['output'];
    position?: Maybe<Scalars['Int']['output']>;
    url: Scalars['String']['output'];
    videoCount: Scalars['Int']['output'];
    wordCount: Scalars['Int']['output'];
  };

export type OrganicSerpItemPageInput = {
  externalLinkCount?: InputMaybe<Scalars['Int']['input']>;
  h2Count?: InputMaybe<Scalars['Int']['input']>;
  imagesCount?: InputMaybe<Scalars['Int']['input']>;
  internalLinkCount?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  videoCount?: InputMaybe<Scalars['Int']['input']>;
  wordCount?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganicSerpItemPageKgData = {
  __typename?: 'OrganicSerpItemPageKgData';
  contentScore?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  mentions?: Maybe<Array<MentionItem>>;
  position?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  wordCount?: Maybe<Scalars['Int']['output']>;
};

export type OrganicSerpItemPageKgDataInput = {
  contentScore?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mentions?: InputMaybe<Array<MentionItemInput>>;
  position?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  wordCount?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganicSerpItemWithIntent = {
  __typename?: 'OrganicSerpItemWithIntent';
  description?: Maybe<Scalars['String']['output']>;
  intent?: Maybe<Intent>;
  position?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  address?: Maybe<Scalars['String']['output']>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  bodyFont?: Maybe<Scalars['String']['output']>;
  brandColor?: Maybe<Scalars['String']['output']>;
  clientTier?: Maybe<ClientTier>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultForm?: Maybe<Scalars['String']['output']>;
  documentAllotment?: Maybe<DocumentsAllotment>;
  headerFont?: Maybe<Scalars['String']['output']>;
  highlightColor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  maxUsers?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  primaryContact?: Maybe<User>;
  recurlyAccountCode?: Maybe<Scalars['String']['output']>;
  recurlyAccountCodeTest?: Maybe<Scalars['String']['output']>;
  selfReportOrdering?: Maybe<Scalars['Boolean']['output']>;
  sfdcAccountId?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<Site>>;
  slug: Scalars['String']['output'];
  subscription?: Maybe<SubscriptionEntity>;
  topicsMax?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<Array<User>>;
};

export type OrganizationFilter = {
  AND?: InputMaybe<Array<OrganizationFilter>>;
  OR?: InputMaybe<Array<OrganizationFilter>>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  backgroundColorContains?: InputMaybe<Scalars['String']['input']>;
  backgroundColorNe?: InputMaybe<Scalars['String']['input']>;
  bodyFont?: InputMaybe<Scalars['String']['input']>;
  bodyFontContains?: InputMaybe<Scalars['String']['input']>;
  bodyFontNe?: InputMaybe<Scalars['String']['input']>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  brandColorContains?: InputMaybe<Scalars['String']['input']>;
  brandColorNe?: InputMaybe<Scalars['String']['input']>;
  defaultForm?: InputMaybe<Scalars['String']['input']>;
  defaultFormContains?: InputMaybe<Scalars['String']['input']>;
  defaultFormNe?: InputMaybe<Scalars['String']['input']>;
  headerFont?: InputMaybe<Scalars['String']['input']>;
  headerFontContains?: InputMaybe<Scalars['String']['input']>;
  headerFontNe?: InputMaybe<Scalars['String']['input']>;
  highlightColor?: InputMaybe<Scalars['String']['input']>;
  highlightColorContains?: InputMaybe<Scalars['String']['input']>;
  highlightColorNe?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  logoContains?: InputMaybe<Scalars['String']['input']>;
  logoNe?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameNe?: InputMaybe<Scalars['String']['input']>;
  primaryContact?: InputMaybe<Array<UserFilter>>;
  recurlyAccountCode?: InputMaybe<Scalars['String']['input']>;
  recurlyAccountCodeContains?: InputMaybe<Scalars['String']['input']>;
  recurlyAccountCodeNe?: InputMaybe<Scalars['String']['input']>;
  recurlyAccountCodeTest?: InputMaybe<Scalars['String']['input']>;
  recurlyAccountCodeTestContains?: InputMaybe<Scalars['String']['input']>;
  recurlyAccountCodeTestNe?: InputMaybe<Scalars['String']['input']>;
  selfReportOrdering?: InputMaybe<Scalars['Boolean']['input']>;
  selfReportOrderingNe?: InputMaybe<Scalars['Boolean']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcAccountIdContains?: InputMaybe<Scalars['String']['input']>;
  sfdcAccountIdNe?: InputMaybe<Scalars['String']['input']>;
  sites?: InputMaybe<Array<SiteFilter>>;
};

export type OrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  bodyFont?: InputMaybe<Scalars['String']['input']>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  defaultForm?: InputMaybe<Scalars['String']['input']>;
  headerFont?: InputMaybe<Scalars['String']['input']>;
  highlightColor?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primaryContactId?: InputMaybe<Scalars['String']['input']>;
  recurlyAccountCode?: InputMaybe<Scalars['String']['input']>;
  recurlyAccountCodeTest?: InputMaybe<Scalars['String']['input']>;
  selfReportOrdering?: InputMaybe<Scalars['Boolean']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationsResponse = {
  __typename?: 'OrganizationsResponse';
  count: Scalars['Int']['output'];
  items: Array<Organization>;
};

export type Page = {
  __typename?: 'Page';
  authority?: Maybe<Scalars['Float']['output']>;
  avgContentScore?: Maybe<Scalars['Float']['output']>;
  avgMmCompetitiveAdvantage?: Maybe<Scalars['Float']['output']>;
  avgMmDifficulty?: Maybe<Scalars['Float']['output']>;
  avgMmPersonalizedAdvantage?: Maybe<Scalars['Float']['output']>;
  avgPersonalizedMmDifficulty?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  externalContentLinkCount?: Maybe<Scalars['Int']['output']>;
  externalContentLinks?: Maybe<Scalars['String']['output']>;
  hiddenByCs?: Maybe<Scalars['Boolean']['output']>;
  hiddenByUser?: Maybe<Scalars['Boolean']['output']>;
  httpCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalContentLinkCount?: Maybe<Scalars['Int']['output']>;
  internalContentLinks?: Maybe<Scalars['String']['output']>;
  inventoryId?: Maybe<Scalars['Int']['output']>;
  maxPotentialRank?: Maybe<Scalars['Int']['output']>;
  maxRank?: Maybe<Scalars['Int']['output']>;
  minPotentialRank?: Maybe<Scalars['Int']['output']>;
  minRank?: Maybe<Scalars['Int']['output']>;
  numRankingTopics?: Maybe<Scalars['Int']['output']>;
  numTopics?: Maybe<Scalars['Int']['output']>;
  pageType?: Maybe<Scalars['String']['output']>;
  potentialTraffic?: Maybe<Scalars['Float']['output']>;
  potentialTrafficUpside?: Maybe<Scalars['Float']['output']>;
  potentialTrafficValue?: Maybe<Scalars['Float']['output']>;
  screenshotLink?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sumContentMentions?: Maybe<Scalars['Int']['output']>;
  sumTitleMentions?: Maybe<Scalars['Int']['output']>;
  sumVolume?: Maybe<Scalars['BigInt']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topValuedTopic?: Maybe<Scalars['String']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  trafficDelta?: Maybe<Scalars['Float']['output']>;
  trafficDeltaPct?: Maybe<Scalars['Float']['output']>;
  trafficValue?: Maybe<Scalars['Float']['output']>;
  trafficValueDelta?: Maybe<Scalars['Float']['output']>;
  unrealizedTrafficValue?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlHash?: Maybe<Scalars['String']['output']>;
  userIntent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<InventoryVersion>;
  versionTrafficPct?: Maybe<Scalars['Float']['output']>;
  versionTrafficValuePct?: Maybe<Scalars['Float']['output']>;
  wordCount?: Maybe<Scalars['Int']['output']>;
};

export type PageInput = {
  id: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PageProcessor = {
  __typename?: 'PageProcessor';
  html?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PageTopic = {
  __typename?: 'PageTopic';
  contentMentions?: Maybe<Scalars['Int']['output']>;
  hiddenByCs?: Maybe<Scalars['Boolean']['output']>;
  hiddenByUser?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  page?: Maybe<Page>;
  pageId?: Maybe<Scalars['BigInt']['output']>;
  potentialRank?: Maybe<Scalars['Int']['output']>;
  potentialTraffic?: Maybe<Scalars['Float']['output']>;
  potentialTrafficUpside?: Maybe<Scalars['Float']['output']>;
  potentialTrafficValue?: Maybe<Scalars['Float']['output']>;
  prePriority?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  rankDelta?: Maybe<Scalars['Int']['output']>;
  rankDeltaPotential?: Maybe<Scalars['Int']['output']>;
  titleMentions?: Maybe<Scalars['Int']['output']>;
  topic?: Maybe<Topic>;
  topicId?: Maybe<Scalars['BigInt']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  trafficDelta?: Maybe<Scalars['Float']['output']>;
  trafficDeltaPct?: Maybe<Scalars['Float']['output']>;
  trafficValue?: Maybe<Scalars['Float']['output']>;
  trafficValueDelta?: Maybe<Scalars['Float']['output']>;
  trafficValueDeltaPct?: Maybe<Scalars['Float']['output']>;
  unrealizedTrafficValue?: Maybe<Scalars['Float']['output']>;
  userIntent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<InventoryVersion>;
  versionId?: Maybe<Scalars['Int']['output']>;
  versionTrafficPct?: Maybe<Scalars['Float']['output']>;
  versionTrafficValuePct?: Maybe<Scalars['Float']['output']>;
};

export type PageTopicRequestFields = {
  topic?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  accountType?: Maybe<Scalars['String']['output']>;
  billingAgreementId?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  ccBinCountry?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  firstSix?: Maybe<Scalars['String']['output']>;
  gatewayCode?: Maybe<Scalars['String']['output']>;
  gatewayToken?: Maybe<Scalars['String']['output']>;
  lastFour?: Maybe<Scalars['String']['output']>;
  lastTwo?: Maybe<Scalars['String']['output']>;
  nameOnAccount?: Maybe<Scalars['String']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  routingNumberBank?: Maybe<Scalars['String']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  action: PermissionAction;
  resource: PermissionResource;
  status: PermissionStatus;
};

export enum PermissionAction {
  ACCESS_DATA = 'ACCESS_DATA',
  ACCESS_FULL_DATA = 'ACCESS_FULL_DATA',
  ADD_TO_INVENTORY = 'ADD_TO_INVENTORY',
  ADMINISTER = 'ADMINISTER',
  BUY_ADDON = 'BUY_ADDON',
  BUY_SUBSCRIPTION = 'BUY_SUBSCRIPTION',
  CANCEL_ACCOUNT = 'CANCEL_ACCOUNT',
  CANCEL_ADDON = 'CANCEL_ADDON',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  CHANGE_PERMISSIONS = 'CHANGE_PERMISSIONS',
  CREATE = 'CREATE',
  CREATE_BRIEF = 'CREATE_BRIEF',
  CREATE_BRIEF_SUBTYPES = 'CREATE_BRIEF_SUBTYPES',
  CREATE_MORE_THAN_LIMIT = 'CREATE_MORE_THAN_LIMIT',
  CREATE_PLANNING_ANALYSIS = 'CREATE_PLANNING_ANALYSIS',
  DELETE = 'DELETE',
  EXPORT = 'EXPORT',
  EXPORT_EXCEL = 'EXPORT_EXCEL',
  EXPORT_GOOGLE_DOC = 'EXPORT_GOOGLE_DOC',
  EXPORT_WORD = 'EXPORT_WORD',
  FILTER = 'FILTER',
  GENERATE = 'GENERATE',
  GENERATE_GEN_2 = 'GENERATE_GEN_2',
  MANAGE_COMPETITOR_NETWORK = 'MANAGE_COMPETITOR_NETWORK',
  MANAGE_INVENTORIES = 'MANAGE_INVENTORIES',
  ORDER = 'ORDER',
  ORDER_MANAGED_BRIEF = 'ORDER_MANAGED_BRIEF',
  PURCHASE_APP_RUNS = 'PURCHASE_APP_RUNS',
  PURCHASE_CREDITS = 'PURCHASE_CREDITS',
  SORT = 'SORT',
  USE_APP_RUNS = 'USE_APP_RUNS',
}

export type PermissionItemInput = {
  action: PermissionAction;
  priority?: InputMaybe<Scalars['Int']['input']>;
  resource: PermissionResource;
  status?: InputMaybe<PermissionStatus>;
};

export enum PermissionResource {
  ALL_ORGANIZATIONS = 'ALL_ORGANIZATIONS',
  BRIEFS = 'BRIEFS',
  DOCUMENTS = 'DOCUMENTS',
  EDITOR = 'EDITOR',
  GENAI = 'GENAI',
  NEWSROOM = 'NEWSROOM',
  OPTIMIZE = 'OPTIMIZE',
  ORG_ADMIN = 'ORG_ADMIN',
  PROJECTS = 'PROJECTS',
  RESEARCH_APPLICATION = 'RESEARCH_APPLICATION',
  RESEARCH_CONNECT = 'RESEARCH_CONNECT',
  RESEARCH_HEATMAP = 'RESEARCH_HEATMAP',
  RESEARCH_HEATMAP_WEBSITE = 'RESEARCH_HEATMAP_WEBSITE',
  RESEARCH_KEYWORDS = 'RESEARCH_KEYWORDS',
  RESEARCH_QUESTIONS = 'RESEARCH_QUESTIONS',
  RESEARCH_REFLECT = 'RESEARCH_REFLECT',
  RESEARCH_SERP_X_RAY = 'RESEARCH_SERP_X_RAY',
  RESEARCH_TOPIC_NAVIGATOR = 'RESEARCH_TOPIC_NAVIGATOR',
  SITE_INVENTORY = 'SITE_INVENTORY',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum PermissionScope {
  ORGANIZATION = 'ORGANIZATION',
  SITE = 'SITE',
  USER = 'USER',
}

export enum PermissionStatus {
  ALLOW = 'ALLOW',
  DENY = 'DENY',
}

export type PermissionsEntityAdmin = {
  __typename?: 'PermissionsEntityAdmin';
  permissions: Array<Permission>;
  resourceActionPairs: Array<ResourceActionPair>;
};

export type Plan = {
  __typename?: 'Plan';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  projects?: Maybe<Array<Project>>;
  site?: Maybe<Site>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type PlanFilter = {
  AND?: InputMaybe<Array<PlanFilter>>;
  OR?: InputMaybe<Array<PlanFilter>>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  site?: InputMaybe<Array<SiteFilter>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlanMini = {
  __typename?: 'PlanMini';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
};

export type Project = {
  __typename?: 'Project';
  assignee?: Maybe<User>;
  briefCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  briefId?: Maybe<Scalars['String']['output']>;
  briefSelfServe?: Maybe<Scalars['Boolean']['output']>;
  briefStatus?: Maybe<BriefStatus>;
  briefStatusError?: Maybe<Scalars['String']['output']>;
  briefStatusLastSynced?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  publishedOn?: Maybe<Scalars['DateTime']['output']>;
  site?: Maybe<Site>;
  topic?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  writing?: Maybe<Scalars['String']['output']>;
  writingCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  writingReference?: Maybe<Scalars['String']['output']>;
  writingUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProjectFilter = {
  AND?: InputMaybe<Array<ProjectFilter>>;
  OR?: InputMaybe<Array<ProjectFilter>>;
  assignee?: InputMaybe<Array<UserFilter>>;
  briefId?: InputMaybe<Scalars['String']['input']>;
  briefIdContains?: InputMaybe<Scalars['String']['input']>;
  briefIdNe?: InputMaybe<Scalars['String']['input']>;
  briefSelfServe?: InputMaybe<Scalars['Boolean']['input']>;
  briefSelfServeNe?: InputMaybe<Scalars['Boolean']['input']>;
  briefStatus?: InputMaybe<BriefStatus>;
  briefStatusError?: InputMaybe<Scalars['String']['input']>;
  briefStatusErrorContains?: InputMaybe<Scalars['String']['input']>;
  briefStatusErrorNe?: InputMaybe<Scalars['String']['input']>;
  briefStatusLastSyncedGt?: InputMaybe<Scalars['DateTime']['input']>;
  briefStatusLastSyncedGte?: InputMaybe<Scalars['DateTime']['input']>;
  briefStatusLastSyncedLt?: InputMaybe<Scalars['DateTime']['input']>;
  briefStatusLastSyncedLte?: InputMaybe<Scalars['DateTime']['input']>;
  briefStatusNe?: InputMaybe<BriefStatus>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  dueDateGt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDateGte?: InputMaybe<Scalars['DateTime']['input']>;
  dueDateLt?: InputMaybe<Scalars['DateTime']['input']>;
  dueDateLte?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesNe?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Array<PlanFilter>>;
  publishedOnGt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedOnGte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedOnLt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedOnLte?: InputMaybe<Scalars['DateTime']['input']>;
  site?: InputMaybe<Array<SiteFilter>>;
  topic?: InputMaybe<Scalars['String']['input']>;
  topicContains?: InputMaybe<Scalars['String']['input']>;
  topicNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  urlContains?: InputMaybe<Scalars['String']['input']>;
  urlNe?: InputMaybe<Scalars['String']['input']>;
  writingCreatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  writingCreatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  writingCreatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  writingCreatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  writingReference?: InputMaybe<Scalars['String']['input']>;
  writingReferenceContains?: InputMaybe<Scalars['String']['input']>;
  writingReferenceNe?: InputMaybe<Scalars['String']['input']>;
  writingUpdatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  writingUpdatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  writingUpdatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  writingUpdatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectInput = {
  projectData?: InputMaybe<ProjectInputBase>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectInputBase = {
  assigneeId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  planTitle?: InputMaybe<Scalars['String']['input']>;
  publishedOn?: InputMaybe<Scalars['DateTime']['input']>;
  writing?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectOrderInput = {
  project: ProjectInput;
  selfServe: Scalars['Boolean']['input'];
  type: OrderType;
};

export type ProjectsCreateWithSavedWritingInput = {
  planTitle?: InputMaybe<Scalars['String']['input']>;
  topic: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectsPlaceOrdersResponse = {
  __typename?: 'ProjectsPlaceOrdersResponse';
  allSucceeded: Scalars['Boolean']['output'];
  costActual: Scalars['Int']['output'];
  costPredicted: Scalars['Int']['output'];
  created: Array<Project>;
  creditsAfter: Scalars['Int']['output'];
  creditsBefore: Scalars['Int']['output'];
  dryRun: Array<Project>;
};

export type ProjectsUpsertProjectInput = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PurchaseAppRuns = {
  __typename?: 'PurchaseAppRuns';
  credits?: Maybe<Scalars['Int']['output']>;
  current: Scalars['Int']['output'];
  error?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Scalars['Int']['output']>;
  lastReset?: Maybe<Scalars['DateTime']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextReset?: Maybe<Scalars['DateTime']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  resetInterval?: Maybe<Scalars['Interval']['output']>;
  warningCode?: Maybe<Scalars['String']['output']>;
};

export type PurchaseCredits = {
  __typename?: 'PurchaseCredits';
  newCredits: Scalars['Int']['output'];
  site: Site;
};

export type Query = {
  __typename?: 'Query';
  appQueries: AppQueriesResponse;
  businessReviews: BusinessReviewRead;
  dashboard: Dashboard;
  dashboards: Array<Maybe<Dashboard>>;
  document?: Maybe<Document>;
  documents: DocumentsResponse;
  documentsCreateEstimate: Array<DocumentCreateEstimation>;
  exportConnect: ExcelGenerationResponse;
  exportContentPlanner: ExcelGenerationResponse;
  exportHeatmap: ExcelGenerationResponse;
  exportInventoryItems: ExcelGenerationResponse;
  exportSerpXray: ExcelGenerationResponse;
  exportTable: ExcelGenerationResponse;
  filter?: Maybe<Filter>;
  filters: Array<Maybe<Filter>>;
  form?: Maybe<Form>;
  forms: Array<Maybe<Form>>;
  genaiChangeTone: GenAiPromptResponse;
  genaiCreateOutline: GenAiPromptResponse;
  genaiCreateSummary: GenAiPromptResponse;
  genaiCustomPrompt: GenAiPromptResponse;
  genaiExpandConcept: GenAiPromptResponse;
  genaiFixSpelling: GenAiPromptResponse;
  genaiFollowUpContinueWriting: GenAiPromptResponse;
  genaiFollowUpCustom: GenAiPromptResponse;
  genaiFollowUpMakeLonger: GenAiPromptResponse;
  genaiFollowUpTryAgain: GenAiPromptResponse;
  genaiImproveWriting: GenAiPromptResponse;
  genaiMakeLonger: GenAiPromptResponse;
  genaiMakeShorter: GenAiPromptResponse;
  getAppData: GetAppDataResponse;
  getExcel: ExcelGenerationResponse;
  getInventoryItemPotential: InventoryItemPotential;
  inventoryItem: InventoryItem;
  inventoryItems: InventoryItemsResponse;
  inventorySubdomains: Array<Maybe<Scalars['String']['output']>>;
  inventoryVersion: InventoryVersion;
  inventoryVersions: Array<Maybe<InventoryVersion>>;
  module?: Maybe<Module>;
  modules: Array<Module>;
  organization?: Maybe<Organization>;
  organizations: OrganizationsResponse;
  permissions: Array<Permission>;
  permissionsEntityAdmin: PermissionsEntityAdmin;
  plan?: Maybe<Plan>;
  plans: Array<Maybe<Plan>>;
  project?: Maybe<Project>;
  projects: Array<Maybe<Project>>;
  readReport: Brief;
  readReports: BriefsResponse;
  recurlyGetAccount: Account;
  recurlyGetCoupon: Coupon;
  recurlyGetCreditInvoices: Array<Invoice>;
  recurlyGetInvoice: Invoice;
  recurlyGetRedemptions: Array<CouponRedemption>;
  recurlyGetSubscription: RecurlySubscription;
  recurlyGetSubscriptions: Array<RecurlySubscription>;
  recurlyGetTransactions: Array<Transaction>;
  savedView: SavedView;
  savedViews: Array<SavedView>;
  savedViewsEach: Array<SavedViewsEachResItem>;
  seoAdwordsKeywords: Array<AdWordsItem>;
  seoAdwordsQuestions: Array<AdWordsItem>;
  seoBulkAdwordsKeywords: Array<AdWordsItemWithVariants>;
  seoConnect: SeoConnect;
  seoContentPlanner: Array<ContentPlanItem>;
  seoKnowledgeGraph: KnowledgeGraph;
  seoPageProcessor: PageProcessor;
  seoSerpData: SerpData;
  seoSerpIntents: SerpIntentsResponse;
  seoSerpPageData: SeoSerpPageResponse;
  seoSerpQuestions: Array<AdWordsItem>;
  seoSerpQuestionsOld: QuestionTitleSuggestions;
  seoSerpUserIntentData: SerpUserIntentDataResponse;
  seoWebsiteHeatmap: Array<WebsiteSerpItemPagesKgData>;
  signIn: SignInResponse;
  signProsperStackPayload: Scalars['String']['output'];
  site: Site;
  siteAggregateStats: SiteAggregateStats;
  siteRecommendedTopics: SiteRecommendedTopics;
  sites: Array<Site>;
  subscription?: Maybe<SubscriptionEntity>;
  subscriptionAddonTypesAvailable: SubscriptionAddonTypesResponse;
  subscriptionPlans: SubscriptionPlansResponse;
  subscriptionRenewalInvoicePreview?: Maybe<InvoiceCollection>;
  tags: TagsResponse;
  user?: Maybe<User>;
  users: Array<Maybe<User>>;
  validateEmail: ValidateEmailResponse;
};

export type QueryAppQueriesArgs = {
  filters?: InputMaybe<Array<AppQueryFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBusinessReviewsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InventorySortFields>;
};

export type QueryDashboardArgs = {
  id: Scalars['String']['input'];
};

export type QueryDashboardsArgs = {
  filters?: InputMaybe<Array<DashboardFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};

export type QueryDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryDocumentsArgs = {
  filters?: InputMaybe<Array<DocumentFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SortField>>;
};

export type QueryDocumentsCreateEstimateArgs = {
  documentCreateEstimate: Array<DocumentsCreateEstimateInput>;
  orgId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type QueryExportConnectArgs = {
  generationContext: GenerationContextInput;
  seoConnectData: SeoConnectInput;
};

export type QueryExportContentPlannerArgs = {
  contentPlanInput: Array<ContentPlanItemInput>;
};

export type QueryExportHeatmapArgs = {
  filterContext: FilterContextInput;
  generationContext: GenerationContextInput;
  knowledgeGraphItems: Array<KnowledgeGraphItemInput>;
  organicSerpItemPageKgData: Array<OrganicSerpItemPageKgDataInput>;
  userProvidedPage?: InputMaybe<OrganicSerpItemPageKgDataInput>;
};

export type QueryExportInventoryItemsArgs = {
  columnNames: Array<ColumnNameInput>;
  filters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  limit?: Scalars['Int']['input'];
  mode?: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
};

export type QueryExportSerpXrayArgs = {
  generationContext: GenerationContextInput;
  intentData: IntentDataInput;
  kgMetrics: Array<KgMetricsInput>;
  kgMetricsAverage: KgMetricsInput;
  organicSerpItemPageAverages: OnPageMetricsAveragesInput;
  organicSerpItemPageKgData: Array<OrganicSerpItemPageKgDataInput>;
  organicSerpItemPages: Array<OrganicSerpItemPageInput>;
  organicSerpItems: Array<OrganicSerpItemInput>;
  serpExportUserProvidedPage?: InputMaybe<SerpExportUserProvidedPageInput>;
  userProvidedPage?: InputMaybe<UserProvidedPageInput>;
};

export type QueryExportTableArgs = {
  generationContext: GenerationContextInput;
  keywordsTabData?: InputMaybe<Array<AdWordsItemInput>>;
  questionsTabData?: InputMaybe<Array<AdWordsItemInput>>;
  reflectTabData?: InputMaybe<Array<ReflectItemInput>>;
  topicModelTabData?: InputMaybe<Array<KnowledgeGraphItemInput>>;
};

export type QueryFilterArgs = {
  id: Scalars['String']['input'];
};

export type QueryFiltersArgs = {
  filters?: InputMaybe<Array<FilterFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFormArgs = {
  id: Scalars['String']['input'];
};

export type QueryFormsArgs = {
  filters?: InputMaybe<Array<FormFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGenaiChangeToneArgs = {
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  tone: Tone;
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiCreateOutlineArgs = {
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiCreateSummaryArgs = {
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiCustomPromptArgs = {
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiExpandConceptArgs = {
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiFixSpellingArgs = {
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
};

export type QueryGenaiFollowUpContinueWritingArgs = {
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiFollowUpCustomArgs = {
  contentInput?: InputMaybe<Scalars['String']['input']>;
  customPrompt: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiFollowUpMakeLongerArgs = {
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiFollowUpTryAgainArgs = {
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiImproveWritingArgs = {
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiMakeLongerArgs = {
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGenaiMakeShorterArgs = {
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
};

export type QueryGetAppDataArgs = {
  app: Scalars['String']['input'];
  params: AppDataParamsInput;
  siteId: Scalars['String']['input'];
};

export type QueryGetExcelArgs = {
  context: ExcelGenerationContext;
  data: ExcelGenerationData;
  fn: ExcelGenerationFunctions;
};

export type QueryGetInventoryItemPotentialArgs = {
  fields: PageTopicRequestFields;
  siteId: Scalars['String']['input'];
};

export type QueryInventoryItemArgs = {
  invItemInput: GetInventoryItemInput;
};

export type QueryInventoryItemsArgs = {
  filters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  isManualQuery?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mode: Scalars['String']['input'];
  navigationFilters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
};

export type QueryInventorySubdomainsArgs = {
  domain: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type QueryInventoryVersionArgs = {
  siteId: Scalars['String']['input'];
};

export type QueryInventoryVersionsArgs = {
  siteId: Scalars['String']['input'];
  startTime: Scalars['DateTimeWithUnixTimeStamp']['input'];
};

export type QueryModuleArgs = {
  id: Scalars['String']['input'];
};

export type QueryModulesArgs = {
  filters?: InputMaybe<Array<ModuleFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};

export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};

export type QueryOrganizationsArgs = {
  filters?: InputMaybe<Array<OrganizationFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPermissionsArgs = {
  siteId: Scalars['String']['input'];
};

export type QueryPermissionsEntityAdminArgs = {
  entityId: Scalars['String']['input'];
  scope: PermissionScope;
};

export type QueryPlanArgs = {
  id: Scalars['String']['input'];
};

export type QueryPlansArgs = {
  filters?: InputMaybe<Array<PlanFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProjectArgs = {
  id: Scalars['String']['input'];
};

export type QueryProjectsArgs = {
  filters?: InputMaybe<Array<ProjectFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryReadReportArgs = {
  reportId: Scalars['String']['input'];
};

export type QueryReadReportsArgs = {
  filters: BriefFilters;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRecurlyGetAccountArgs = {
  accountCode: Scalars['String']['input'];
};

export type QueryRecurlyGetCouponArgs = {
  couponId: Scalars['String']['input'];
};

export type QueryRecurlyGetCreditInvoicesArgs = {
  siteId: Scalars['String']['input'];
};

export type QueryRecurlyGetInvoiceArgs = {
  invoiceNumber: Scalars['String']['input'];
};

export type QueryRecurlyGetRedemptionsArgs = {
  accountCode: Scalars['String']['input'];
};

export type QueryRecurlyGetSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type QueryRecurlyGetSubscriptionsArgs = {
  accountCode: Scalars['String']['input'];
};

export type QueryRecurlyGetTransactionsArgs = {
  accountCode: Scalars['String']['input'];
};

export type QuerySavedViewArgs = {
  id: Scalars['String']['input'];
};

export type QuerySavedViewsArgs = {
  filters?: InputMaybe<Array<SavedViewFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};

export type QuerySavedViewsEachArgs = {
  each?: InputMaybe<Array<InventoryType>>;
  filters?: InputMaybe<Array<SavedViewFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};

export type QuerySeoAdwordsKeywordsArgs = {
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
};

export type QuerySeoAdwordsQuestionsArgs = {
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
};

export type QuerySeoBulkAdwordsKeywordsArgs = {
  country?: Country;
  language?: Language;
  terms: Array<Scalars['String']['input']>;
};

export type QuerySeoConnectArgs = {
  competitorSites?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: Country;
  domain: Scalars['String']['input'];
  language?: Language;
  networkSites?: InputMaybe<Array<Scalars['String']['input']>>;
  term: Scalars['String']['input'];
};

export type QuerySeoContentPlannerArgs = {
  country: Country;
  language: Language;
  siteId: Scalars['String']['input'];
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type QuerySeoKnowledgeGraphArgs = {
  country: Country;
  language: Language;
  skipPageScrapping?: InputMaybe<Scalars['Boolean']['input']>;
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  useSerpCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySeoPageProcessorArgs = {
  url: Scalars['String']['input'];
};

export type QuerySeoSerpDataArgs = {
  country: Country;
  language: Language;
  returnTopN?: Scalars['Int']['input'];
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type QuerySeoSerpIntentsArgs = {
  language?: Language;
  siteId: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};

export type QuerySeoSerpPageDataArgs = {
  country?: Country;
  language?: Language;
  returnTopN?: Scalars['Int']['input'];
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySeoSerpQuestionsArgs = {
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
};

export type QuerySeoSerpQuestionsOldArgs = {
  country?: Country;
  language?: Language;
  term: Scalars['String']['input'];
};

export type QuerySeoSerpUserIntentDataArgs = {
  country: Country;
  language: Language;
  returnTopN?: Scalars['Int']['input'];
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type QuerySeoWebsiteHeatmapArgs = {
  excludePaths?: InputMaybe<Array<Scalars['URL']['input']>>;
  includePath: Scalars['URL']['input'];
  kgTerms: Array<Scalars['String']['input']>;
  language?: Language;
  term: Scalars['String']['input'];
};

export type QuerySignInArgs = {
  email: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySignProsperStackPayloadArgs = {
  payload: Scalars['String']['input'];
};

export type QuerySiteArgs = {
  id: Scalars['String']['input'];
};

export type QuerySiteAggregateStatsArgs = {
  siteId: Scalars['String']['input'];
};

export type QuerySiteRecommendedTopicsArgs = {
  country: Country;
  domain: Scalars['String']['input'];
};

export type QuerySitesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type QuerySubscriptionAddonTypesAvailableArgs = {
  filters?: InputMaybe<Array<SubscriptionAddonTypesAvailableFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SortField>>;
};

export type QuerySubscriptionPlansArgs = {
  filters?: InputMaybe<Array<SubscriptionPlanFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SortField>>;
};

export type QuerySubscriptionRenewalInvoicePreviewArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTagsArgs = {
  filters?: InputMaybe<Array<TagFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SortField>>;
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUsersArgs = {
  filters?: InputMaybe<Array<UserFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryValidateEmailArgs = {
  email: Scalars['String']['input'];
};

export type QuestionInput = {
  id: Scalars['String']['input'];
  semanticSimilarity?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuestionServiceItem = {
  __typename?: 'QuestionServiceItem';
  similarity: Scalars['Float']['output'];
  term: Scalars['String']['output'];
};

export type QuestionTitleSuggestions = {
  __typename?: 'QuestionTitleSuggestions';
  questions?: Maybe<Array<QuestionServiceItem>>;
  suggestedSubHeadingTerms?: Maybe<Array<QuestionServiceItem>>;
  suggestedTitleTerms?: Maybe<Array<QuestionServiceItem>>;
  topRankingSubHeadings?: Maybe<Array<QuestionServiceItem>>;
  topRankingTitles?: Maybe<Array<Scalars['String']['output']>>;
};

export type RankingTitleInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecurlySubscription = {
  __typename?: 'RecurlySubscription';
  account?: Maybe<AccountMini>;
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  addOns?: Maybe<Array<RecurlySubscriptionAddon>>;
  addOnsTotal?: Maybe<Scalars['Float']['output']>;
  autoRenew?: Maybe<Scalars['Boolean']['output']>;
  bankAccountAuthorizedAt?: Maybe<Scalars['DateTime']['output']>;
  billingInfoId?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  collectionMethod?: Maybe<Scalars['String']['output']>;
  couponRedemptions?: Maybe<Array<CouponRedemptionMini>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']['output']>;
  currentPeriodStartedAt?: Maybe<Scalars['DateTime']['output']>;
  currentTermEndsAt?: Maybe<Scalars['DateTime']['output']>;
  currentTermStartedAt?: Maybe<Scalars['DateTime']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  customerNotes?: Maybe<Scalars['String']['output']>;
  expirationReason?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  gatewayCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  netTerms?: Maybe<Scalars['Int']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  pausedAt?: Maybe<Scalars['DateTime']['output']>;
  pendingChange?: Maybe<SubscriptionChange>;
  plan: PlanMini;
  poNumber?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  remainingBillingCycles?: Maybe<Scalars['Int']['output']>;
  remainingPauseCycles?: Maybe<Scalars['Int']['output']>;
  renewalBillingCycles?: Maybe<Scalars['Int']['output']>;
  revenueScheduleType?: Maybe<Scalars['String']['output']>;
  shipping?: Maybe<SubscriptionShipping>;
  state?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  taxInfo?: Maybe<TaxInfo>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalBillingCycles?: Maybe<Scalars['Int']['output']>;
  trialEndsAt?: Maybe<Scalars['DateTime']['output']>;
  trialStartedAt?: Maybe<Scalars['DateTime']['output']>;
  unitAmount?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['String']['output'];
};

export type RecurlySubscriptionAddon = {
  __typename?: 'RecurlySubscriptionAddon';
  addOn?: Maybe<AddOnMini>;
  addOnSource?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  revenueScheduleType?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  tierType?: Maybe<Scalars['String']['output']>;
  tiers?: Maybe<Array<SubscriptionAddOnTier>>;
  unitAmount?: Maybe<Scalars['Float']['output']>;
  unitAmountDecimal?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  usagePercentage?: Maybe<Scalars['Float']['output']>;
};

export type ReflectItemInput = {
  maxRank?: InputMaybe<Scalars['Int']['input']>;
  mmPersonalizedAdvantage?: InputMaybe<Scalars['Float']['input']>;
  mmPersonalizedDifficulty?: InputMaybe<Scalars['Float']['input']>;
  numPages?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
  topRankingPage?: InputMaybe<Scalars['String']['input']>;
  topRankingPageTitle?: InputMaybe<Scalars['String']['input']>;
  volume?: InputMaybe<Scalars['Int']['input']>;
};

export type RelatedTopicInput = {
  distribution?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  powerTopic?: InputMaybe<Scalars['Boolean']['input']>;
  semanticSimilarity?: InputMaybe<Scalars['Float']['input']>;
  suggestedDistribution?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestStatus = {
  __typename?: 'RequestStatus';
  message: Scalars['String']['output'];
  status?: Maybe<Scalars['Int']['output']>;
};

export type ResearchItemInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  categoryStrings?: InputMaybe<Array<Scalars['String']['input']>>;
  competition?: InputMaybe<Scalars['Float']['input']>;
  costPerClick?: InputMaybe<Scalars['Float']['input']>;
  history?: InputMaybe<Array<HistoryInput>>;
  term: Scalars['String']['input'];
  volume?: InputMaybe<Scalars['Int']['input']>;
};

export type ResetDate = {
  __typename?: 'ResetDate';
  last: Scalars['DateTime']['output'];
  next: Scalars['DateTime']['output'];
};

export type ResourceActionPair = {
  __typename?: 'ResourceActionPair';
  action: PermissionAction;
  resource: PermissionResource;
};

export type SeoConnect = {
  __typename?: 'SEOConnect';
  competitionLinkingRecommendations?: Maybe<
    Array<LinkingRecommendationForTerm>
  >;
  externalLinkingRecommendations?: Maybe<Array<LinkingRecommendationForTerm>>;
  internalLinkingRecommendations?: Maybe<Array<LinkingRecommendationForTerm>>;
  networkLinkingRecommendations?: Maybe<Array<LinkingRecommendationForTerm>>;
};

export type SeoConnectInput = {
  competitionLinkingRecommendations?: InputMaybe<
    Array<LinkingRecommendationForTermInput>
  >;
  externalLinkingRecommendations?: InputMaybe<
    Array<LinkingRecommendationForTermInput>
  >;
  internalLinkingRecommendations?: InputMaybe<
    Array<LinkingRecommendationForTermInput>
  >;
  networkLinkingRecommendations?: InputMaybe<
    Array<LinkingRecommendationForTermInput>
  >;
};

export type SavedView = {
  __typename?: 'SavedView';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Scalars['JSON']['output']>;
  hidden?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  inventoryType: InventoryType;
  order?: Maybe<Scalars['JSON']['output']>;
  org?: Maybe<Organization>;
  pinned?: Maybe<Scalars['JSON']['output']>;
  sharing?: Maybe<SavedViewSharing>;
  site?: Maybe<Site>;
  sorting?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trackedByUsers?: Maybe<Array<User>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type SavedViewFilter = {
  AND?: InputMaybe<Array<SavedViewFilter>>;
  OR?: InputMaybe<Array<SavedViewFilter>>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Array<UserFilter>>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionNe?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  inventoryType?: InputMaybe<InventoryType>;
  inventoryTypeNe?: InputMaybe<InventoryType>;
  inventoryTypesIn?: InputMaybe<Array<InventoryType>>;
  org?: InputMaybe<Array<OrganizationFilter>>;
  sharing?: InputMaybe<SavedViewSharing>;
  sharingNe?: InputMaybe<SavedViewSharing>;
  sharingsIn?: InputMaybe<Array<SavedViewSharing>>;
  site?: InputMaybe<Array<SiteFilter>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Array<UserFilter>>;
};

export type SavedViewInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['JSON']['input']>;
  hidden?: InputMaybe<Scalars['JSON']['input']>;
  inventoryType?: InputMaybe<InventoryType>;
  order?: InputMaybe<Scalars['JSON']['input']>;
  pinned?: InputMaybe<Scalars['JSON']['input']>;
  sharing?: InputMaybe<SavedViewSharing>;
  sorting?: InputMaybe<SortFields>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum SavedViewSharing {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  SHARED = 'SHARED',
}

export type SavedViewsEachResItem = {
  __typename?: 'SavedViewsEachResItem';
  inventoryType: InventoryType;
  items: Array<SavedView>;
};

export type SavedViewsTrackedInput = {
  connect?: InputMaybe<Scalars['String']['input']>;
  disconnect?: InputMaybe<Scalars['String']['input']>;
};

export type SeoPageItem = {
  externalLinkCount: Scalars['Int']['output'];
  h2Count: Scalars['Int']['output'];
  imagesCount: Scalars['Int']['output'];
  internalLinkCount: Scalars['Int']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  videoCount: Scalars['Int']['output'];
  wordCount: Scalars['Int']['output'];
};

export type SeoPageMetrics = {
  externalLinkCount: Scalars['Int']['output'];
  h2Count: Scalars['Int']['output'];
  imagesCount: Scalars['Int']['output'];
  internalLinkCount: Scalars['Int']['output'];
  videoCount: Scalars['Int']['output'];
  wordCount: Scalars['Int']['output'];
};

export type SeoSerpPageResponse = {
  __typename?: 'SeoSerpPageResponse';
  organicSerpItemPageAverages?: Maybe<OnPageMetrics>;
  organicSerpItemPages?: Maybe<Array<OrganicSerpItemPage>>;
  userProvidedPage?: Maybe<UserProvidedPage>;
};

export type SerpData = {
  __typename?: 'SerpData';
  intentData?: Maybe<IntentData>;
  organicSerpItems: Array<OrganicSerpItem>;
  serpFeaturesPresent: Array<SerpItemType>;
  userProvidedPage?: Maybe<OrganicSerpItem>;
};

export type SerpExportUserProvidedPageInput = {
  intent?: InputMaybe<Intent>;
  position?: InputMaybe<Scalars['Int']['input']>;
  score: Scalars['Int']['input'];
};

export type SerpIntentTopic = {
  __typename?: 'SerpIntentTopic';
  infoComparison: Scalars['Int']['output'];
  infoKnow: Scalars['Int']['output'];
  infoKnowSimple: Scalars['Int']['output'];
  local: Scalars['Int']['output'];
  transactional: Scalars['Int']['output'];
  websiteQuery: Scalars['Int']['output'];
};

export type SerpIntentTopicInput = {
  infoComparison: Scalars['Int']['input'];
  infoKnow: Scalars['Int']['input'];
  infoKnowSimple: Scalars['Int']['input'];
  local: Scalars['Int']['input'];
  transactional: Scalars['Int']['input'];
  websiteQuery: Scalars['Int']['input'];
};

export type SerpIntentsResponse = {
  __typename?: 'SerpIntentsResponse';
  intent?: Maybe<SerpIntentTopic>;
  urls?: Maybe<Array<OrganicSerpItemWithIntent>>;
};

export enum SerpItemType {
  BOTTOM_ADS = 'BOTTOM_ADS',
  FEATURED_SNIPPET = 'FEATURED_SNIPPET',
  IMAGES = 'IMAGES',
  IMAGE_PACK = 'IMAGE_PACK',
  JOBS = 'JOBS',
  JOBS_PACK = 'JOBS_PACK',
  KNOWLEDGE_CARD = 'KNOWLEDGE_CARD',
  KNOWLEDGE_PANEL = 'KNOWLEDGE_PANEL',
  LOCAL_PACK = 'LOCAL_PACK',
  NAVIGATION = 'NAVIGATION',
  NEWS = 'NEWS',
  NEWS_BOX = 'NEWS_BOX',
  ORGANIC = 'ORGANIC',
  OVERVIEW = 'OVERVIEW',
  POPULAR_PRODUCTS = 'POPULAR_PRODUCTS',
  RECIPES = 'RECIPES',
  RELATED_QUESTIONS = 'RELATED_QUESTIONS',
  RELATED_SEARCHES = 'RELATED_SEARCHES',
  REVIEWS = 'REVIEWS',
  SHOPPING_RESULTS = 'SHOPPING_RESULTS',
  SITE_LINKS = 'SITE_LINKS',
  SPELLING = 'SPELLING',
  TOP_ADS = 'TOP_ADS',
  TWITTER = 'TWITTER',
  VIDEOS = 'VIDEOS',
}

export type SerpUserIntentDataResponse = {
  __typename?: 'SerpUserIntentDataResponse';
  intentData: IntentData;
  organicSerpItemIntents: Array<OrganicSerpItemWithIntent>;
  userProvidedPage?: Maybe<OrganicSerpItemWithIntent>;
};

export type SetupAccountResult = {
  __typename?: 'SetupAccountResult';
  org: Organization;
  permissions: Array<Permission>;
  site: Site;
  subscription?: Maybe<SubscriptionEntity>;
  user: User;
};

export type ShareByRank = {
  __typename?: 'ShareByRank';
  rank?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  accountId?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type ShippingMethodMini = {
  __typename?: 'ShippingMethodMini';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  org?: Maybe<Organization>;
  permissions: Array<Permission>;
  site?: Maybe<Site>;
  subscription?: Maybe<SubscriptionEntity>;
  user: User;
};

export type Site = {
  __typename?: 'Site';
  appQueries?: Maybe<Array<AppQuery>>;
  appRuns?: Maybe<Scalars['Int']['output']>;
  appRunsExtra?: Maybe<Scalars['Int']['output']>;
  appRunsLastReset?: Maybe<Scalars['DateTime']['output']>;
  appRunsLimit?: Maybe<Scalars['Int']['output']>;
  appRunsResetInterval?: Maybe<Scalars['Interval']['output']>;
  appSumoCodes?: Maybe<Array<Scalars['String']['output']>>;
  autoUpdate?: Maybe<Scalars['Boolean']['output']>;
  briefCredits?: Maybe<Scalars['Int']['output']>;
  cancelled: Scalars['Boolean']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  competitorDomains?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  defaultSerpCountry?: Maybe<Scalars['String']['output']>;
  displayContentData?: Maybe<Scalars['Boolean']['output']>;
  documentBriefUserInput?: Maybe<DocumentBriefUserInput>;
  documentsConnection?: Maybe<DocumentsConnection>;
  domain: Scalars['String']['output'];
  dunning?: Maybe<Scalars['Boolean']['output']>;
  dunningCardUpdated?: Maybe<Scalars['Boolean']['output']>;
  dunningExpired?: Maybe<Scalars['Boolean']['output']>;
  excludePaths?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  includePaths?: Maybe<Array<Scalars['String']['output']>>;
  invId?: Maybe<Scalars['Int']['output']>;
  level: SiteLevel;
  networkDomains?: Maybe<Array<Scalars['String']['output']>>;
  org?: Maybe<Organization>;
  plans?: Maybe<Array<Plan>>;
  projects?: Maybe<Array<Project>>;
  recommendedTopics?: Maybe<Scalars['Int']['output']>;
  savedViews?: Maybe<Array<SavedView>>;
  subdomains?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  topicsAllocated?: Maybe<Scalars['Int']['output']>;
  topicsInUse?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  valuePerVisit?: Maybe<Scalars['Int']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type SiteAggregateBriefStats = {
  __typename?: 'SiteAggregateBriefStats';
  cart: Scalars['Int']['output'];
  delivered: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  qaReady: Scalars['Int']['output'];
};

export type SiteAggregateStats = {
  __typename?: 'SiteAggregateStats';
  appRuns: AppRuns;
  briefs: SiteAggregateBriefStats;
  briefsAll: Scalars['Int']['output'];
  pageTopicsAll?: Maybe<Scalars['Int']['output']>;
  pagesAll?: Maybe<Scalars['Int']['output']>;
  plansAll: Scalars['Int']['output'];
  projectsAll: Scalars['Int']['output'];
  projectsWithPlan: Scalars['Int']['output'];
  projectsWithWriting: Scalars['Int']['output'];
  topicsAll?: Maybe<Scalars['Int']['output']>;
};

export type SiteCreateOptions = {
  skipInventory: Scalars['Boolean']['input'];
  skipSemrush: Scalars['Boolean']['input'];
};

export type SiteFilter = {
  AND?: InputMaybe<Array<SiteFilter>>;
  OR?: InputMaybe<Array<SiteFilter>>;
  appRuns?: InputMaybe<Scalars['Int']['input']>;
  appRunsExtra?: InputMaybe<Scalars['Int']['input']>;
  appRunsExtraGt?: InputMaybe<Scalars['Int']['input']>;
  appRunsExtraGte?: InputMaybe<Scalars['Int']['input']>;
  appRunsExtraLt?: InputMaybe<Scalars['Int']['input']>;
  appRunsExtraLte?: InputMaybe<Scalars['Int']['input']>;
  appRunsExtraNe?: InputMaybe<Scalars['Int']['input']>;
  appRunsGt?: InputMaybe<Scalars['Int']['input']>;
  appRunsGte?: InputMaybe<Scalars['Int']['input']>;
  appRunsLastReset?: InputMaybe<Scalars['DateTime']['input']>;
  appRunsLastResetGt?: InputMaybe<Scalars['DateTime']['input']>;
  appRunsLastResetGte?: InputMaybe<Scalars['DateTime']['input']>;
  appRunsLastResetLt?: InputMaybe<Scalars['DateTime']['input']>;
  appRunsLastResetLte?: InputMaybe<Scalars['DateTime']['input']>;
  appRunsLastResetNe?: InputMaybe<Scalars['DateTime']['input']>;
  appRunsLimit?: InputMaybe<Scalars['Int']['input']>;
  appRunsLimitGt?: InputMaybe<Scalars['Int']['input']>;
  appRunsLimitGte?: InputMaybe<Scalars['Int']['input']>;
  appRunsLimitLt?: InputMaybe<Scalars['Int']['input']>;
  appRunsLimitLte?: InputMaybe<Scalars['Int']['input']>;
  appRunsLimitNe?: InputMaybe<Scalars['Int']['input']>;
  appRunsLt?: InputMaybe<Scalars['Int']['input']>;
  appRunsLte?: InputMaybe<Scalars['Int']['input']>;
  appRunsNe?: InputMaybe<Scalars['Int']['input']>;
  briefCredits?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsGt?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsGte?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLastReset?: InputMaybe<Scalars['DateTime']['input']>;
  briefCreditsLastResetGt?: InputMaybe<Scalars['DateTime']['input']>;
  briefCreditsLastResetGte?: InputMaybe<Scalars['DateTime']['input']>;
  briefCreditsLastResetLt?: InputMaybe<Scalars['DateTime']['input']>;
  briefCreditsLastResetLte?: InputMaybe<Scalars['DateTime']['input']>;
  briefCreditsLastResetNe?: InputMaybe<Scalars['DateTime']['input']>;
  briefCreditsLimit?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLimitGt?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLimitGte?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLimitLt?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLimitLte?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLimitNe?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLt?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsLte?: InputMaybe<Scalars['Int']['input']>;
  briefCreditsNe?: InputMaybe<Scalars['Int']['input']>;
  canOrderManagedBriefs?: InputMaybe<Scalars['Boolean']['input']>;
  canOrderManagedBriefsNe?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  cancelledAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  cancelledAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  cancelledAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  cancelledAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  cancelledNe?: InputMaybe<Scalars['Boolean']['input']>;
  createdAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Array<UserFilter>>;
  domain?: InputMaybe<Scalars['String']['input']>;
  domainContains?: InputMaybe<Scalars['String']['input']>;
  domainNe?: InputMaybe<Scalars['String']['input']>;
  dunning?: InputMaybe<Scalars['Boolean']['input']>;
  dunningCardUpdated?: InputMaybe<Scalars['Boolean']['input']>;
  dunningCardUpdatedNe?: InputMaybe<Scalars['Boolean']['input']>;
  dunningExpired?: InputMaybe<Scalars['Boolean']['input']>;
  dunningExpiredNe?: InputMaybe<Scalars['Boolean']['input']>;
  dunningNe?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringCreditsSubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecurringCreditsSubscriptionsNe?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  invId?: InputMaybe<Scalars['Int']['input']>;
  invIdNe?: InputMaybe<Scalars['Int']['input']>;
  invIdsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  level?: InputMaybe<SiteLevel>;
  levelNe?: InputMaybe<SiteLevel>;
  levelsIn?: InputMaybe<Array<SiteLevel>>;
  org?: InputMaybe<Array<OrganizationFilter>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleNe?: InputMaybe<Scalars['String']['input']>;
  updatedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  visibleNe?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteInput = {
  appRuns?: InputMaybe<Scalars['Int']['input']>;
  appRunsExtra?: InputMaybe<Scalars['Int']['input']>;
  appRunsLastReset?: InputMaybe<Scalars['DateTime']['input']>;
  appRunsLimit?: InputMaybe<Scalars['Int']['input']>;
  appRunsResetInterval?: InputMaybe<Scalars['Interval']['input']>;
  applicationAccess?: InputMaybe<Array<Scalars['String']['input']>>;
  autoUpdate?: InputMaybe<Scalars['Int']['input']>;
  briefCredits?: InputMaybe<Scalars['Int']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  competitorDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  defaultSerpCountry?: InputMaybe<Scalars['String']['input']>;
  displayContentData?: InputMaybe<Scalars['Boolean']['input']>;
  documentBriefUserInput?: InputMaybe<DocumentBriefUserOptionalInput>;
  domain?: InputMaybe<Scalars['String']['input']>;
  dunning?: InputMaybe<Scalars['Boolean']['input']>;
  dunningCardUpdated?: InputMaybe<Scalars['Boolean']['input']>;
  dunningExpired?: InputMaybe<Scalars['Boolean']['input']>;
  invId?: InputMaybe<Scalars['Int']['input']>;
  inventoryLevel?: InputMaybe<InventoryLevelEnum>;
  level?: InputMaybe<SiteLevel>;
  networkDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  pathsExclude?: InputMaybe<Array<Scalars['String']['input']>>;
  pathsInclude?: InputMaybe<Array<Scalars['String']['input']>>;
  subdomains?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  topicsAllocated?: InputMaybe<Scalars['Int']['input']>;
  valuePerVisit?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SiteLevel {
  APPSUMO = 'appsumo',
  CLASSIC = 'classic',
  FREE = 'free',
  NONE = 'none',
  PREMIUM = 'premium',
  STANDARD = 'standard',
}

export type SiteRecommendedTopics = {
  __typename?: 'SiteRecommendedTopics';
  topicCount: Scalars['Int']['output'];
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortDirectionBrief {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortField = {
  direction?: InputMaybe<SortDirection>;
  field: Scalars['String']['input'];
};

export type SortFields = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
  table?: InputMaybe<Scalars['String']['input']>;
};

/** An interface to change the items in a list. set can not be used with others. add and remove can be used together. */
export type StrTagItemConnection = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SubheadingInput = {
  additionalTopics?: InputMaybe<Array<SubtopicInput>>;
  comments?: InputMaybe<Array<CommentInput>>;
  externalLinks?: InputMaybe<Array<LinkListInput>>;
  id: Scalars['String']['input'];
  internalLinks?: InputMaybe<Array<LinkListInput>>;
  position?: InputMaybe<Scalars['Float']['input']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  relatedTopics?: InputMaybe<Array<RelatedTopicInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<SubheadingTopicInput>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  wordCount?: InputMaybe<Scalars['Float']['input']>;
};

export type SubheadingTermInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  semanticSimilarity?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubheadingTopicInput = {
  contentScoreAvg?: InputMaybe<Scalars['Float']['input']>;
  contentScoreTarget?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  semanticSimilarity?: InputMaybe<Scalars['Float']['input']>;
  suggestedDistribution?: InputMaybe<Scalars['Float']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  wordCountAvg?: InputMaybe<Scalars['Float']['input']>;
  wordCountTarget?: InputMaybe<Scalars['Float']['input']>;
};

export type SubscribeToPlan = {
  __typename?: 'SubscribeToPlan';
  billingInfo?: Maybe<BillingInfo>;
  org?: Maybe<Organization>;
  site?: Maybe<Site>;
  subscription?: Maybe<RecurlySubscription>;
  user?: Maybe<User>;
};

export type SubscriptionAddOnTier = {
  __typename?: 'SubscriptionAddOnTier';
  endingQuantity?: Maybe<Scalars['Int']['output']>;
  unitAmount?: Maybe<Scalars['Float']['output']>;
  unitAmountDecimal?: Maybe<Scalars['String']['output']>;
  usagePercentage?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionAddon = {
  __typename?: 'SubscriptionAddon';
  addonState?: Maybe<SubscriptionAddonState>;
  addonType: SubscriptionAddonType;
  id: Scalars['ID']['output'];
  mode?: Maybe<SubscriptionAddonMode>;
  site?: Maybe<Site>;
  subscription?: Maybe<SubscriptionEntity>;
};

export enum SubscriptionAddonMode {
  COMPLIMENTARY = 'COMPLIMENTARY',
  CONTRACT = 'CONTRACT',
  SELF_SERVE = 'SELF_SERVE',
}

export type SubscriptionAddonPrice = {
  __typename?: 'SubscriptionAddonPrice';
  addonType: SubscriptionAddonTypeType;
  price: Scalars['Int']['output'];
};

export type SubscriptionAddonPriceInput = {
  addonType: SubscriptionAddonTypeType;
  priceSet?: InputMaybe<Scalars['Int']['input']>;
  priceUnset?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SubscriptionAddonState {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CANCELLING = 'CANCELLING',
}

export type SubscriptionAddonType = {
  __typename?: 'SubscriptionAddonType';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  type: SubscriptionAddonTypeType;
  validFromDate: Scalars['DateTime']['output'];
  validUntilDate: Scalars['DateTime']['output'];
};

export enum SubscriptionAddonTypeType {
  APPLICATION_QUERIES_RECURRING_INCREASE = 'APPLICATION_QUERIES_RECURRING_INCREASE',
  BULK_DOCUMENT_DELIVERY = 'BULK_DOCUMENT_DELIVERY',
  BULK_EXPORT_DATA_REQUESTS = 'BULK_EXPORT_DATA_REQUESTS',
  CUSTOM_CONFIGURATIONS = 'CUSTOM_CONFIGURATIONS',
  CUSTOM_DATA_DELIVERY = 'CUSTOM_DATA_DELIVERY',
  CUSTOM_DOCUMENTS = 'CUSTOM_DOCUMENTS',
  CUSTOM_TERMS_LENGTH = 'CUSTOM_TERMS_LENGTH',
  DOCUMENTS_ANALYSIS_PLANNING_INCREASE_1 = 'DOCUMENTS_ANALYSIS_PLANNING_INCREASE_1',
  DOCUMENTS_ANALYSIS_PLANNING_INCREASE_10 = 'DOCUMENTS_ANALYSIS_PLANNING_INCREASE_10',
  DOCUMENTS_BRIEFS_INCREASE_1 = 'DOCUMENTS_BRIEFS_INCREASE_1',
  DOCUMENTS_BRIEFS_INCREASE_10 = 'DOCUMENTS_BRIEFS_INCREASE_10',
  INVENTORY_EXPORT = 'INVENTORY_EXPORT',
  SITE_ADDITIONAL = 'SITE_ADDITIONAL',
  TOPICS_INCREASE_1_000 = 'TOPICS_INCREASE_1_000',
  TOPICS_INCREASE_1_000_000 = 'TOPICS_INCREASE_1_000_000',
  TOPICS_INCREASE_10_000 = 'TOPICS_INCREASE_10_000',
  TOPICS_INCREASE_100 = 'TOPICS_INCREASE_100',
  TOPICS_INCREASE_100_000 = 'TOPICS_INCREASE_100_000',
  TRAINING_HELP_VIA_CX = 'TRAINING_HELP_VIA_CX',
  USERS_INCREASE_OF_1 = 'USERS_INCREASE_OF_1',
  USERS_INCREASE_OF_3 = 'USERS_INCREASE_OF_3',
}

export type SubscriptionAddonTypesAvailableFilter = {
  AND?: InputMaybe<Array<SubscriptionAddonTypesAvailableFilter>>;
  OR?: InputMaybe<Array<SubscriptionAddonTypesAvailableFilter>>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  price?: InputMaybe<Scalars['Int']['input']>;
  priceGt?: InputMaybe<Scalars['Int']['input']>;
  priceLt?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<SubscriptionAddonTypeType>;
  typeNe?: InputMaybe<SubscriptionAddonTypeType>;
  typesIn?: InputMaybe<Array<SubscriptionAddonTypeType>>;
  validFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  validFromDateGt?: InputMaybe<Scalars['DateTime']['input']>;
  validFromDateGte?: InputMaybe<Scalars['DateTime']['input']>;
  validFromDateLt?: InputMaybe<Scalars['DateTime']['input']>;
  validFromDateLte?: InputMaybe<Scalars['DateTime']['input']>;
  validUntilDate?: InputMaybe<Scalars['DateTime']['input']>;
  validUntilDateGt?: InputMaybe<Scalars['DateTime']['input']>;
  validUntilDateGte?: InputMaybe<Scalars['DateTime']['input']>;
  validUntilDateLt?: InputMaybe<Scalars['DateTime']['input']>;
  validUntilDateLte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionAddonTypesResponse = {
  __typename?: 'SubscriptionAddonTypesResponse';
  count: Scalars['Int']['output'];
  items: Array<SubscriptionAddonType>;
};

export type SubscriptionChange = {
  __typename?: 'SubscriptionChange';
  activateAt?: Maybe<Scalars['DateTime']['output']>;
  activated?: Maybe<Scalars['Boolean']['output']>;
  addOns?: Maybe<Array<RecurlySubscriptionAddon>>;
  billingInfo?: Maybe<SubscriptionChangeBillingInfo>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceCollection?: Maybe<InvoiceCollection>;
  object?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<PlanMini>;
  quantity?: Maybe<Scalars['Int']['output']>;
  revenueScheduleType?: Maybe<Scalars['String']['output']>;
  shipping?: Maybe<SubscriptionShipping>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  taxInclusive?: Maybe<Scalars['Boolean']['output']>;
  unitAmount?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionChangeBillingInfo = {
  __typename?: 'SubscriptionChangeBillingInfo';
  threeDSecureActionResultTokenId?: Maybe<Scalars['String']['output']>;
};

export enum SubscriptionConversionMethod {
  BARTER = 'BARTER',
  SALES = 'SALES',
  SELF_SERVE = 'SELF_SERVE',
}

export type SubscriptionCreateInput = {
  conversionMethod?: SubscriptionConversionMethod;
  demo: Scalars['Boolean']['input'];
  dunning?: Scalars['Boolean']['input'];
  endsOnDate?: InputMaybe<Scalars['DateTime']['input']>;
  orgId: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  startedOnDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: SubscriptionState;
  subscriptionPlanId: Scalars['String']['input'];
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  addons?: Maybe<Array<SubscriptionAddon>>;
  conversionMethod: SubscriptionConversionMethod;
  createdAt: Scalars['DateTime']['output'];
  demo?: Maybe<Scalars['Boolean']['output']>;
  dunning?: Maybe<Scalars['Boolean']['output']>;
  endsOnDate: Scalars['DateTime']['output'];
  externalSubscriptionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  org: Organization;
  price?: Maybe<Scalars['Int']['output']>;
  site?: Maybe<Site>;
  startedOnDate: Scalars['DateTime']['output'];
  state: SubscriptionState;
  subscriptionAddonPrices?: Maybe<Array<SubscriptionAddonPrice>>;
  subscriptionPlan: SubscriptionPlan;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  active: Scalars['Boolean']['output'];
  analysisAndPlanningDocumentAllocationLimit: Scalars['Int']['output'];
  appQueryCount: Scalars['Int']['output'];
  briefDocumentAllocationLimit: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  packageName?: Maybe<Scalars['String']['output']>;
  planCode: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  term: SubscriptionPlanTerm;
  termLength: Scalars['Int']['output'];
  type: SubscriptionPlanType;
  userCanDowngradePackage: Scalars['Boolean']['output'];
  userCanUpgradePackage: Scalars['Boolean']['output'];
  usersArePurchasable: Scalars['Boolean']['output'];
  usersCountDefault?: Maybe<Scalars['Int']['output']>;
  writingDocumentAllocationLimit?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionPlanFilter = {
  AND?: InputMaybe<Array<SubscriptionPlanFilter>>;
  OR?: InputMaybe<Array<SubscriptionPlanFilter>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  analysisAndPlanningDocumentAllocationLimit?: InputMaybe<
    Scalars['Int']['input']
  >;
  analysisAndPlanningDocumentAllocationLimitGt?: InputMaybe<
    Scalars['Int']['input']
  >;
  analysisAndPlanningDocumentAllocationLimitLt?: InputMaybe<
    Scalars['Int']['input']
  >;
  analysisAndPlanningDocumentAllocationLimitNe?: InputMaybe<
    Scalars['Int']['input']
  >;
  briefDocumentAllocationLimit?: InputMaybe<Scalars['Int']['input']>;
  briefDocumentAllocationLimitGt?: InputMaybe<Scalars['Int']['input']>;
  briefDocumentAllocationLimitLt?: InputMaybe<Scalars['Int']['input']>;
  briefDocumentAllocationLimitNe?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  idsIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  packageNameContains?: InputMaybe<Scalars['String']['input']>;
  packageNameNe?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  priceGt?: InputMaybe<Scalars['Int']['input']>;
  priceLt?: InputMaybe<Scalars['Int']['input']>;
  priceNe?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<SubscriptionPlanTerm>;
  termNe?: InputMaybe<SubscriptionPlanTerm>;
  termsIn?: InputMaybe<Array<SubscriptionPlanTerm>>;
  type?: InputMaybe<SubscriptionPlanType>;
  typeNe?: InputMaybe<SubscriptionPlanType>;
  typesIn?: InputMaybe<Array<SubscriptionPlanType>>;
  userCanDowngradePackage?: InputMaybe<Scalars['Boolean']['input']>;
  userCanUpgradePackage?: InputMaybe<Scalars['Boolean']['input']>;
  usersArePurchasable?: InputMaybe<Scalars['Boolean']['input']>;
  usersCountDefault?: InputMaybe<Scalars['Int']['input']>;
  usersCountDefaultGt?: InputMaybe<Scalars['Int']['input']>;
  usersCountDefaultLt?: InputMaybe<Scalars['Int']['input']>;
  usersCountDefaultNe?: InputMaybe<Scalars['Int']['input']>;
  writingDocumentAllocationLimit?: InputMaybe<Scalars['Int']['input']>;
  writingDocumentAllocationLimitGt?: InputMaybe<Scalars['Int']['input']>;
  writingDocumentAllocationLimitLt?: InputMaybe<Scalars['Int']['input']>;
  writingDocumentAllocationLimitNe?: InputMaybe<Scalars['Int']['input']>;
};

export enum SubscriptionPlanTerm {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export enum SubscriptionPlanType {
  CUSTOM = 'CUSTOM',
  FREE = 'FREE',
  OPTIMIZE = 'OPTIMIZE',
  RESEARCH = 'RESEARCH',
  RESEARCH_TRIAL = 'RESEARCH_TRIAL',
  STRATEGY = 'STRATEGY',
}

export type SubscriptionPlansResponse = {
  __typename?: 'SubscriptionPlansResponse';
  count: Scalars['Int']['output'];
  items: Array<SubscriptionPlan>;
};

export type SubscriptionShipping = {
  __typename?: 'SubscriptionShipping';
  address?: Maybe<ShippingAddress>;
  amount?: Maybe<Scalars['Float']['output']>;
  method?: Maybe<ShippingMethodMini>;
  object?: Maybe<Scalars['String']['output']>;
};

export enum SubscriptionState {
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
}

export type SubscriptionUpdateInput = {
  conversionMethod?: InputMaybe<SubscriptionConversionMethod>;
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  dunning?: InputMaybe<Scalars['Boolean']['input']>;
  endsOnDate?: InputMaybe<Scalars['DateTime']['input']>;
  externalSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  startedOnDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<SubscriptionState>;
  subscriptionPlanId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionUpdateResponse = {
  __typename?: 'SubscriptionUpdateResponse';
  org: Organization;
  permissions: Array<Permission>;
  subscription: SubscriptionEntity;
};

export type SubtopicInput = {
  distribution?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  powerTopic?: InputMaybe<Scalars['Boolean']['input']>;
  semanticSimilarity?: InputMaybe<Scalars['Float']['input']>;
  suggestedDistribution?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID']['output'];
  site: Site;
  terms?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  urlTerms?: Maybe<Array<UrlTerm>>;
  urls?: Maybe<Array<Scalars['URL']['output']>>;
};

export type TagFilter = {
  AND?: InputMaybe<Array<TagFilter>>;
  OR?: InputMaybe<Array<TagFilter>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  site?: InputMaybe<Array<SiteFilter>>;
  termsIntersect?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
};

export type TagUpdateInput = {
  documents?: InputMaybe<StrTagItemConnection>;
  terms?: InputMaybe<StrTagItemConnection>;
  title?: InputMaybe<Scalars['String']['input']>;
  urlTerms?: InputMaybe<UrlTermInputTagItemConnection>;
  urls?: InputMaybe<StrTagItemConnection>;
};

export type TagsResponse = {
  __typename?: 'TagsResponse';
  count: Scalars['Int']['output'];
  items: Array<Tag>;
};

export type TaxDetail = {
  __typename?: 'TaxDetail';
  rate?: Maybe<Scalars['Float']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TaxInfo = {
  __typename?: 'TaxInfo';
  rate?: Maybe<Scalars['Float']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  taxDetails?: Maybe<Array<TaxDetail>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TitleTermInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  semanticSimilarity?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Tone {
  CONFIDENT = 'confident',
  CONVERSATIONAL = 'conversational',
  FRIENDLY = 'friendly',
  INFORMATIVE = 'informative',
  PROFESSIONAL = 'professional',
}

export type Topic = {
  __typename?: 'Topic';
  avgPageAuthority?: Maybe<Scalars['Float']['output']>;
  competition?: Maybe<Scalars['Float']['output']>;
  cpc?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['Float']['output']>;
  favouredUserIntent?: Maybe<Scalars['String']['output']>;
  fracturedUserIntent?: Maybe<Scalars['Boolean']['output']>;
  hiddenByCs?: Maybe<Scalars['Boolean']['output']>;
  hiddenByUser?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  inventoryId?: Maybe<Scalars['Int']['output']>;
  kgKey?: Maybe<Scalars['String']['output']>;
  maxPotentialRank?: Maybe<Scalars['Int']['output']>;
  maxRank?: Maybe<Scalars['Int']['output']>;
  minPotentialRank?: Maybe<Scalars['Int']['output']>;
  mmDifficulty?: Maybe<Scalars['Float']['output']>;
  mmPersonalizedAdvantage?: Maybe<Scalars['Float']['output']>;
  mmPersonalizedDifficulty?: Maybe<Scalars['Float']['output']>;
  monthlyTrends?: Maybe<Scalars['String']['output']>;
  numPages?: Maybe<Scalars['Int']['output']>;
  potentialTraffic?: Maybe<Scalars['Float']['output']>;
  potentialTrafficUpside?: Maybe<Scalars['Float']['output']>;
  potentialTrafficValue?: Maybe<Scalars['Float']['output']>;
  serpFeatures?: Maybe<Scalars['String']['output']>;
  serpFeaturesSet?: Maybe<Array<Scalars['String']['output']>>;
  serpFeaturesTrafficShare?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sumExternalContentLinks?: Maybe<Scalars['Int']['output']>;
  sumInternalContentLinks?: Maybe<Scalars['Int']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  topRankingPage?: Maybe<Scalars['String']['output']>;
  topRankingPageTitle?: Maybe<Scalars['String']['output']>;
  traffic?: Maybe<Scalars['Float']['output']>;
  trafficDelta?: Maybe<Scalars['Float']['output']>;
  trafficDeltaPct?: Maybe<Scalars['Float']['output']>;
  trafficValue?: Maybe<Scalars['Float']['output']>;
  trafficValueDelta?: Maybe<Scalars['Float']['output']>;
  trafficValueDeltaPct?: Maybe<Scalars['Float']['output']>;
  unrealizedTrafficValue?: Maybe<Scalars['Float']['output']>;
  userIntent?: Maybe<Scalars['String']['output']>;
  valueShareByRank?: Maybe<Array<ShareByRank>>;
  version?: Maybe<InventoryVersion>;
  versionTrafficPct?: Maybe<Scalars['Float']['output']>;
  versionTrafficValuePct?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeShareByRank?: Maybe<Array<ShareByRank>>;
};

export type Transaction = {
  __typename?: 'Transaction';
  account?: Maybe<AccountMini>;
  amount?: Maybe<Scalars['Float']['output']>;
  avsCheck?: Maybe<Scalars['String']['output']>;
  backupPaymentMethodUsed?: Maybe<Scalars['Boolean']['output']>;
  billingAddress?: Maybe<AddressWithName>;
  collectedAt?: Maybe<Scalars['DateTime']['output']>;
  collectionMethod?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customerMessage?: Maybe<Scalars['String']['output']>;
  customerMessageLocale?: Maybe<Scalars['String']['output']>;
  cvvCheck?: Maybe<Scalars['String']['output']>;
  gatewayApprovalCode?: Maybe<Scalars['String']['output']>;
  gatewayMessage?: Maybe<Scalars['String']['output']>;
  gatewayReference?: Maybe<Scalars['String']['output']>;
  gatewayResponseCode?: Maybe<Scalars['String']['output']>;
  gatewayResponseTime?: Maybe<Scalars['Float']['output']>;
  gatewayResponseValues?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  invoice?: Maybe<InvoiceMini>;
  ipAddressCountry?: Maybe<Scalars['String']['output']>;
  ipAddressV4?: Maybe<Scalars['String']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originalTransactionId?: Maybe<Scalars['String']['output']>;
  paymentGateway?: Maybe<TransactionPaymentGateway>;
  paymentMethod?: Maybe<PaymentMethod>;
  refunded?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
  statusMessage?: Maybe<Scalars['String']['output']>;
  subscriptionIds?: Maybe<Scalars['JSON']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['String']['output'];
  voidedAt?: Maybe<Scalars['DateTime']['output']>;
  voidedByInvoice?: Maybe<InvoiceMini>;
};

export type TransactionPaymentGateway = {
  __typename?: 'TransactionPaymentGateway';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type UrlTerm = {
  __typename?: 'UrlTerm';
  term: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export type UrlTermInput = {
  term: Scalars['String']['input'];
  url: Scalars['URL']['input'];
};

/** An interface to change the items in a list. set can not be used with others. add and remove can be used together. */
export type UrlTermInputTagItemConnection = {
  add?: InputMaybe<Array<UrlTermInput>>;
  remove?: InputMaybe<Array<UrlTermInput>>;
  set?: InputMaybe<Array<UrlTermInput>>;
};

export type User = {
  __typename?: 'User';
  appQueries?: Maybe<Array<AppQuery>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultApplicationSerpCountry?: Maybe<Scalars['String']['output']>;
  defaultDashboard?: Maybe<Dashboard>;
  displayExactDistribution?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  filtersCreated?: Maybe<Array<Filter>>;
  filtersUpdated?: Maybe<Array<Filter>>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  guestAccessCreated?: Maybe<Array<GuestAccess>>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  onboardingProgress?: Maybe<Scalars['JSON']['output']>;
  onboardingStep?: Maybe<Scalars['String']['output']>;
  org?: Maybe<Organization>;
  orgPc?: Maybe<Organization>;
  orgRole?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  projectsAssigned?: Maybe<Array<Project>>;
  savedViewsCreated?: Maybe<Array<SavedView>>;
  savedViewsTracked?: Maybe<Array<SavedView>>;
  savedViewsUpdated?: Maybe<Array<SavedView>>;
  sfdcContactId?: Maybe<Scalars['String']['output']>;
  shouldReceiveBriefOrderedNotifications?: Maybe<Scalars['Boolean']['output']>;
  sitesCreated?: Maybe<Array<Site>>;
  suspended?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserAudienceInput = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreateInput = {
  defaultApplicationSerpCountry?: InputMaybe<Scalars['String']['input']>;
  defaultDashboard?: InputMaybe<Scalars['String']['input']>;
  displayExactDistribution?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  onboardingProgress?: InputMaybe<Scalars['JSON']['input']>;
  onboardingStep?: InputMaybe<Scalars['String']['input']>;
  orgRole?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  savedViewsTrackedIds?: InputMaybe<Array<SavedViewsTrackedInput>>;
  shouldReceiveBriefOrderedNotifications?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilter>>;
  OR?: InputMaybe<Array<UserFilter>>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailNe?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idNe?: InputMaybe<Scalars['ID']['input']>;
  org?: InputMaybe<Array<OrganizationFilter>>;
  orgPc?: InputMaybe<Array<OrganizationFilter>>;
};

export type UserIntentInput = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserProvidedPage = SeoPageItem &
  SeoPageMetrics & {
    __typename?: 'UserProvidedPage';
    description?: Maybe<Scalars['String']['output']>;
    externalLinkCount: Scalars['Int']['output'];
    h2Count: Scalars['Int']['output'];
    imagesCount: Scalars['Int']['output'];
    internalLinkCount: Scalars['Int']['output'];
    position?: Maybe<Scalars['Int']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
    videoCount: Scalars['Int']['output'];
    wordCount: Scalars['Int']['output'];
  };

export type UserProvidedPageInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalLinkCount?: InputMaybe<Scalars['Int']['input']>;
  h2Count?: InputMaybe<Scalars['Int']['input']>;
  imagesCount?: InputMaybe<Scalars['Int']['input']>;
  internalLinkCount?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  videoCount?: InputMaybe<Scalars['Int']['input']>;
  wordCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UserUpdateInput = {
  defaultApplicationSerpCountry?: InputMaybe<Scalars['String']['input']>;
  defaultDashboard?: InputMaybe<Scalars['String']['input']>;
  displayExactDistribution?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  onboardingProgress?: InputMaybe<Scalars['JSON']['input']>;
  onboardingStep?: InputMaybe<Scalars['String']['input']>;
  orgRole?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  savedViewsTrackedIds?: InputMaybe<Array<SavedViewsTrackedInput>>;
  shouldReceiveBriefOrderedNotifications?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateEmailResponse = {
  __typename?: 'ValidateEmailResponse';
  allowedDomain: Scalars['Boolean']['output'];
  exists: Scalars['Boolean']['output'];
  formatValid: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export enum VersionStatusEnum {
  CREATED = 'created',
  FINISHED = 'finished',
  OUTDATED = 'outdated',
  STAGE1_ANALYZING = 'stage1_analyzing',
  STAGE1_CALCULATED = 'stage1_calculated',
  STAGE1_DATA_COLLECTED = 'stage1_data_collected',
  STAGE1_ENRICHING = 'stage1_enriching',
  STAGE1_EXHAUSTED = 'stage1_exhausted',
  STAGE1_IN_PROGRESS = 'stage1_in_progress',
  STAGE2_AGGREGATES_CALCULATED = 'stage2_aggregates_calculated',
  STAGE2_AUTHORITY_CALCULATED = 'stage2_authority_calculated',
  STAGE2_CONTENT_SCORES_CALCULATED = 'stage2_content_scores_calculated',
  STAGE2_DIFFICULTY_CALCULATED = 'stage2_difficulty_calculated',
  STAGE2_INSIGHTS_CALCULATED = 'stage2_insights_calculated',
  STAGE2_OPP_SCORES_PAGES_CALCULATED = 'stage2_opp_scores_pages_calculated',
  STAGE2_OPP_SCORES_TOPICS_CALCULATED = 'stage2_opp_scores_topics_calculated',
  STAGE2_ORPHAN_ENTITIES_CALCULATED = 'stage2_orphan_entities_calculated',
  STAGE2_PRE_PRIORITY_CALCULATED = 'stage2_pre_priority_calculated',
  STAGE2_SERP_DATA_COLLECTED = 'stage2_serp_data_collected',
  STAGE2_SERP_FEATURES_COLLECTED = 'stage2_serp_features_collected',
  STAGE2_SERP_FEATURES_COLLECTING = 'stage2_serp_features_collecting',
  STAGE2_TRAFFIC_CALCULATED = 'stage2_traffic_calculated',
  STAGE2_USER_INTENT_COLLECTED = 'stage2_user_intent_collected',
}

export type WebsiteSerpItemPagesKgData = {
  __typename?: 'WebsiteSerpItemPagesKgData';
  contentScore?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  mentions?: Maybe<Array<MentionItem>>;
  position?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  wordCount?: Maybe<Scalars['Int']['output']>;
};

export type AddFromResearchMutationVariables = Exact<{
  tagInput: TagUpdateInput;
  tagId: Scalars['String']['input'];
  items: Array<InventoryItemsAddInput>;
  siteId: Scalars['String']['input'];
}>;

export type AddFromResearchMutation = {
  __typename?: 'Mutation';
  tagCreateOrUpdate: {
    __typename?: 'Tag';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title: string;
    terms?: Array<string> | null;
  };
  inventoryItemsAdd: {
    __typename?: 'InventoryItemsAddResponse';
    status?: number | null;
    projects: Array<{
      __typename?: 'Project';
      id: string;
      briefId?: string | null;
      topic?: string | null;
      url?: string | null;
      plan?: { __typename?: 'Plan'; id: string; title: string } | null;
    } | null>;
  };
};

export type AddMemberMutationVariables = Exact<{
  fields: UserCreateInput;
  orgId: Scalars['String']['input'];
}>;

export type AddMemberMutation = {
  __typename?: 'Mutation';
  addMember: {
    __typename?: 'User';
    createdAt?: Date | null;
    defaultApplicationSerpCountry?: string | null;
    displayExactDistribution?: boolean | null;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    id: string;
    lastName?: string | null;
    onboardingProgress?: { [key: string]: any } | null;
    onboardingStep?: string | null;
    orgRole?: string | null;
    phone?: string | null;
    shouldReceiveBriefOrderedNotifications?: boolean | null;
    suspended?: boolean | null;
    sfdcContactId?: string | null;
    updatedAt?: Date | null;
    org?: {
      __typename?: 'Organization';
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      sites?: Array<{
        __typename?: 'Site';
        appRuns?: number | null;
        appRunsExtra?: number | null;
        appRunsLastReset?: Date | null;
        appRunsLimit?: number | null;
        appRunsResetInterval?: any | null;
        appSumoCodes?: Array<string> | null;
        briefCredits?: number | null;
        cancelled: boolean;
        cancelledAt?: Date | null;
        competitorDomains?: Array<string> | null;
        createdAt?: Date | null;
        defaultSerpCountry?: string | null;
        displayContentData?: boolean | null;
        domain: string;
        dunning?: boolean | null;
        dunningCardUpdated?: boolean | null;
        dunningExpired?: boolean | null;
        id: string;
        invId?: number | null;
        level: SiteLevel;
        networkDomains?: Array<string> | null;
        title?: string | null;
        updatedAt?: Date | null;
        visible: boolean;
        documentBriefUserInput?: {
          __typename?: 'DocumentBriefUserInput';
          buyerOrInformationJourney?: string | null;
          contextLinguistic?: string | null;
          expertise?: string | null;
          factStatements?: string | null;
          intentAnalysis?: string | null;
          marketingAndMessagingReferences?: Array<string> | null;
          personalExperience?: string | null;
          personalizationDetail?: string | null;
          pointOfView?: string | null;
          proofStatements?: string | null;
          structuring?: string | null;
          style?: string | null;
          targetMarket?: string | null;
          tone?: string | null;
          voice?: string | null;
        } | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        createdAt?: Date | null;
        defaultApplicationSerpCountry?: string | null;
        displayExactDistribution?: boolean | null;
        email: string;
        firstName?: string | null;
        fullName?: string | null;
        id: string;
        lastName?: string | null;
        onboardingProgress?: { [key: string]: any } | null;
        onboardingStep?: string | null;
        orgRole?: string | null;
        phone?: string | null;
        shouldReceiveBriefOrderedNotifications?: boolean | null;
        suspended?: boolean | null;
        sfdcContactId?: string | null;
        updatedAt?: Date | null;
      }> | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    } | null;
  };
};

export type AdminPermissionsUpdateMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  scope: PermissionScope;
  permissions: Array<PermissionItemInput>;
}>;

export type AdminPermissionsUpdateMutation = {
  __typename?: 'Mutation';
  permissionsWrite: {
    __typename?: 'RequestStatus';
    message: string;
    status?: number | null;
  };
};

export type AdminSubscriptionAddonCreateMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  addonInput: AdminAddonInput;
  coupon?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminSubscriptionAddonCreateMutation = {
  __typename?: 'Mutation';
  adminSubscriptionAddonCreate: Array<{
    __typename?: 'SubscriptionAddon';
    id: string;
  }>;
};

export type AdminSubscriptionCreateMutationVariables = Exact<{
  subscriptionInput: SubscriptionCreateInput;
}>;

export type AdminSubscriptionCreateMutation = {
  __typename?: 'Mutation';
  adminSubscriptionCreate: {
    __typename?: 'SubscriptionEntity';
    createdAt: Date;
    dunning?: boolean | null;
    endsOnDate: Date;
    id: string;
    externalSubscriptionId?: string | null;
    conversionMethod: SubscriptionConversionMethod;
    startedOnDate: Date;
    state: SubscriptionState;
    price?: number | null;
    demo?: boolean | null;
    subscriptionPlan: {
      __typename?: 'SubscriptionPlan';
      active: boolean;
      appQueryCount: number;
      analysisAndPlanningDocumentAllocationLimit: number;
      briefDocumentAllocationLimit: number;
      id: string;
      planCode: string;
      price: number;
      term: SubscriptionPlanTerm;
      termLength: number;
      type: SubscriptionPlanType;
      userCanDowngradePackage: boolean;
      userCanUpgradePackage: boolean;
      usersArePurchasable: boolean;
      usersCountDefault?: number | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
    addons?: Array<{
      __typename?: 'SubscriptionAddon';
      id: string;
      addonState?: SubscriptionAddonState | null;
      mode?: SubscriptionAddonMode | null;
      addonType: {
        __typename?: 'SubscriptionAddonType';
        id: string;
        description?: string | null;
        price?: number | null;
        type: SubscriptionAddonTypeType;
        validFromDate: Date;
        validUntilDate: Date;
      };
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
    }> | null;
  };
};

export type AdminSubscriptionUpdateMutationVariables = Exact<{
  coupon?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  subscriptionInput: SubscriptionUpdateInput;
}>;

export type AdminSubscriptionUpdateMutation = {
  __typename?: 'Mutation';
  adminSubscriptionUpdate: {
    __typename?: 'SubscriptionEntity';
    createdAt: Date;
    dunning?: boolean | null;
    endsOnDate: Date;
    id: string;
    externalSubscriptionId?: string | null;
    conversionMethod: SubscriptionConversionMethod;
    startedOnDate: Date;
    state: SubscriptionState;
    price?: number | null;
    demo?: boolean | null;
    subscriptionPlan: {
      __typename?: 'SubscriptionPlan';
      active: boolean;
      appQueryCount: number;
      analysisAndPlanningDocumentAllocationLimit: number;
      briefDocumentAllocationLimit: number;
      id: string;
      planCode: string;
      price: number;
      term: SubscriptionPlanTerm;
      termLength: number;
      type: SubscriptionPlanType;
      userCanDowngradePackage: boolean;
      userCanUpgradePackage: boolean;
      usersArePurchasable: boolean;
      usersCountDefault?: number | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
    addons?: Array<{
      __typename?: 'SubscriptionAddon';
      id: string;
      addonState?: SubscriptionAddonState | null;
      mode?: SubscriptionAddonMode | null;
      addonType: {
        __typename?: 'SubscriptionAddonType';
        id: string;
        description?: string | null;
        price?: number | null;
        type: SubscriptionAddonTypeType;
        validFromDate: Date;
        validUntilDate: Date;
      };
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
    }> | null;
  };
};

export type AppQueryCreateOrUpdateMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  app: App;
  connectCompetitorDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
  connectNetworkDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
}>;

export type AppQueryCreateOrUpdateMutation = {
  __typename?: 'Mutation';
  appQueryCreateOrUpdate: {
    __typename?: 'AppQuery';
    app: string;
    connectCompetitorDomains?: Array<string> | null;
    connectNetworkDomains?: Array<string> | null;
    country?: Country | null;
    createdAt?: Date | null;
    id: string;
    jsonMetadata?: { [key: string]: any } | null;
    language?: Language | null;
    status: AppQueryStatus;
    topic?: string | null;
    updatedAt?: Date | null;
    url?: string | null;
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  };
};

export type AppQueryUpdateMutationVariables = Exact<{
  appQueryId: Scalars['String']['input'];
  status?: InputMaybe<AppQueryStatus>;
  competitorDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
  networkDomains?: InputMaybe<Array<Scalars['Domain']['input']>>;
}>;

export type AppQueryUpdateMutation = {
  __typename?: 'Mutation';
  appQueryUpdate: {
    __typename?: 'AppQuery';
    app: string;
    connectCompetitorDomains?: Array<string> | null;
    connectNetworkDomains?: Array<string> | null;
    country?: Country | null;
    createdAt?: Date | null;
    id: string;
    jsonMetadata?: { [key: string]: any } | null;
    language?: Language | null;
    status: AppQueryStatus;
    topic?: string | null;
    updatedAt?: Date | null;
    url?: string | null;
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  };
};

export type DocumentDeleteMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DocumentDeleteMutation = {
  __typename?: 'Mutation';
  documentDelete: {
    __typename?: 'DeleteStatus';
    message: string;
    status?: number | null;
  };
};

export type DocumentUpdateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: DocumentUpdateInput;
}>;

export type DocumentUpdateMutation = {
  __typename?: 'Mutation';
  documentUpdate: {
    __typename?: 'Document';
    steps?: Array<DocumentStep> | null;
    prioritization?: DocumentPrioritization | null;
    scope?: DocumentScope | null;
    createdAt?: Date | null;
    id: string;
    status?: DocumentStatus | null;
    subtype: DocumentSubtype;
    title: string;
    type: DocumentType;
    updatedAt?: Date | null;
    assigneeTo?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    inputData: {
      __typename?: 'DocumentInputData';
      terms?: Array<string> | null;
      urls?: Array<string> | null;
      domain?: string | null;
      location?: string | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    };
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
    updatedBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  };
};

export type DocumentsCreateMutationVariables = Exact<{
  country: Country;
  inputs: Array<DocumentCreateInput>;
  language: Language;
  siteId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
}>;

export type DocumentsCreateMutation = {
  __typename?: 'Mutation';
  documentsCreate: Array<{
    __typename?: 'Document';
    steps?: Array<DocumentStep> | null;
    prioritization?: DocumentPrioritization | null;
    scope?: DocumentScope | null;
    createdAt?: Date | null;
    id: string;
    status?: DocumentStatus | null;
    subtype: DocumentSubtype;
    title: string;
    type: DocumentType;
    updatedAt?: Date | null;
    assigneeTo?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    inputData: {
      __typename?: 'DocumentInputData';
      terms?: Array<string> | null;
      urls?: Array<string> | null;
      domain?: string | null;
      location?: string | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    };
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
    updatedBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  }>;
};

export type DocumentsCreateBriefsFromPlanMutationVariables = Exact<{
  inputs: Array<DocumentCreateBriefFromPlanInput>;
  orgId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
}>;

export type DocumentsCreateBriefsFromPlanMutation = {
  __typename?: 'Mutation';
  documentsCreateBriefsFromPlan: Array<{
    __typename?: 'Document';
    steps?: Array<DocumentStep> | null;
    prioritization?: DocumentPrioritization | null;
    scope?: DocumentScope | null;
    createdAt?: Date | null;
    id: string;
    status?: DocumentStatus | null;
    subtype: DocumentSubtype;
    title: string;
    type: DocumentType;
    updatedAt?: Date | null;
    assigneeTo?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    inputData: {
      __typename?: 'DocumentInputData';
      terms?: Array<string> | null;
      urls?: Array<string> | null;
      domain?: string | null;
      location?: string | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    };
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
    updatedBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  }>;
};

export type DocumentsCreatePlansFromAnalysisMutationVariables = Exact<{
  inputs: Array<DocumentCreatePlansFromAnalysisInput>;
  orgId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
}>;

export type DocumentsCreatePlansFromAnalysisMutation = {
  __typename?: 'Mutation';
  documentsCreatePlansFromAnalysis: Array<{
    __typename?: 'Document';
    steps?: Array<DocumentStep> | null;
    prioritization?: DocumentPrioritization | null;
    scope?: DocumentScope | null;
    createdAt?: Date | null;
    id: string;
    status?: DocumentStatus | null;
    subtype: DocumentSubtype;
    title: string;
    type: DocumentType;
    updatedAt?: Date | null;
    assigneeTo?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    inputData: {
      __typename?: 'DocumentInputData';
      terms?: Array<string> | null;
      urls?: Array<string> | null;
      domain?: string | null;
      location?: string | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    };
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
    updatedBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  }>;
};

export type FormCreateMutationVariables = Exact<{
  formInput: FormInput;
  orgId: Scalars['String']['input'];
}>;

export type FormCreateMutation = {
  __typename?: 'Mutation';
  formCreate: {
    __typename?: 'Form';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    type?: FormType | null;
    orderVisible?: { [key: string]: any } | null;
    orderHidden?: { [key: string]: any } | null;
  };
};

export type FormFieldCreateMutationVariables = Exact<{
  formFieldInput: FormFieldInput;
}>;

export type FormFieldCreateMutation = {
  __typename?: 'Mutation';
  formFieldCreate: {
    __typename?: 'FormField';
    id: string;
    note?: string | null;
    type: FormFieldType;
    fieldData?: { [key: string]: any } | null;
    required?: boolean | null;
    group?: { __typename?: 'FormGroup'; id: string } | null;
  };
};

export type FormFieldUpdateMutationVariables = Exact<{
  formFieldId: Scalars['String']['input'];
  formFieldInput: FormFieldInput;
}>;

export type FormFieldUpdateMutation = {
  __typename?: 'Mutation';
  formFieldUpdate: {
    __typename?: 'FormField';
    id: string;
    note?: string | null;
    type: FormFieldType;
    fieldData?: { [key: string]: any } | null;
    required?: boolean | null;
    group?: { __typename?: 'FormGroup'; id: string } | null;
  };
};

export type FormGroupCreateMutationVariables = Exact<{
  formGroupInput: FormGroupInput;
}>;

export type FormGroupCreateMutation = {
  __typename?: 'Mutation';
  formGroupCreate: {
    __typename?: 'FormGroup';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    type: FormGroupType;
    orderVisible?: { [key: string]: any } | null;
    orderHidden?: { [key: string]: any } | null;
    form?: {
      __typename?: 'Form';
      createdAt?: Date | null;
      updatedAt?: Date | null;
      id: string;
      title?: string | null;
      type?: FormType | null;
      orderVisible?: { [key: string]: any } | null;
      orderHidden?: { [key: string]: any } | null;
    } | null;
    fields?: Array<{
      __typename?: 'FormField';
      id: string;
      note?: string | null;
      type: FormFieldType;
      fieldData?: { [key: string]: any } | null;
      required?: boolean | null;
      group?: { __typename?: 'FormGroup'; id: string } | null;
    }> | null;
  };
};

export type FormGroupUpdateMutationVariables = Exact<{
  formGroupId: Scalars['String']['input'];
  formGroupInput: FormGroupInput;
}>;

export type FormGroupUpdateMutation = {
  __typename?: 'Mutation';
  formGroupUpdate: {
    __typename?: 'FormGroup';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    type: FormGroupType;
    orderVisible?: { [key: string]: any } | null;
    orderHidden?: { [key: string]: any } | null;
    form?: {
      __typename?: 'Form';
      createdAt?: Date | null;
      updatedAt?: Date | null;
      id: string;
      title?: string | null;
      type?: FormType | null;
      orderVisible?: { [key: string]: any } | null;
      orderHidden?: { [key: string]: any } | null;
    } | null;
    fields?: Array<{
      __typename?: 'FormField';
      id: string;
      note?: string | null;
      type: FormFieldType;
      fieldData?: { [key: string]: any } | null;
      required?: boolean | null;
      group?: { __typename?: 'FormGroup'; id: string } | null;
    }> | null;
  };
};

export type FormUpdateMutationVariables = Exact<{
  formInput: FormInput;
  formId: Scalars['String']['input'];
}>;

export type FormUpdateMutation = {
  __typename?: 'Mutation';
  formUpdate: {
    __typename?: 'Form';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    type?: FormType | null;
    orderVisible?: { [key: string]: any } | null;
    orderHidden?: { [key: string]: any } | null;
  };
};

export type GuestAccessCreateMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
  tokenOptions?: InputMaybe<GuestAccessOptions>;
}>;

export type GuestAccessCreateMutation = {
  __typename?: 'Mutation';
  guestAccessCreate: { __typename?: 'GuestAccessCreate'; token: string };
};

export type GuestAccessCreateManyMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
  resources: Array<GuestAccessOptions>;
}>;

export type GuestAccessCreateManyMutation = {
  __typename?: 'Mutation';
  guestAccessCreateMany: {
    __typename?: 'GuestAccessCreateMany';
    tokens: Array<string>;
  };
};

export type GuestAccessValidateMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type GuestAccessValidateMutation = {
  __typename?: 'Mutation';
  guestAccessValidate: {
    __typename?: 'GuestAccessValidate';
    isValid: boolean;
    guestAccess?: {
      __typename?: 'GuestAccess';
      token: string;
      options: { [key: string]: any };
    } | null;
    guestUser?: {
      __typename?: 'User';
      createdAt?: Date | null;
      defaultApplicationSerpCountry?: string | null;
      displayExactDistribution?: boolean | null;
      email: string;
      firstName?: string | null;
      fullName?: string | null;
      id: string;
      lastName?: string | null;
      onboardingProgress?: { [key: string]: any } | null;
      onboardingStep?: string | null;
      orgRole?: string | null;
      phone?: string | null;
      shouldReceiveBriefOrderedNotifications?: boolean | null;
      suspended?: boolean | null;
      sfdcContactId?: string | null;
      updatedAt?: Date | null;
      org?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug: string;
        clientTier?: ClientTier | null;
        sites?: Array<{
          __typename?: 'Site';
          domain: string;
          id: string;
          invId?: number | null;
          level: SiteLevel;
          title?: string | null;
          visible: boolean;
          defaultSerpCountry?: string | null;
        }> | null;
        users?: Array<{
          __typename?: 'User';
          id: string;
          email: string;
          fullName?: string | null;
          createdAt?: Date | null;
          updatedAt?: Date | null;
        }> | null;
      } | null;
    } | null;
  };
};

export type InquirePremiumMutationVariables = Exact<{
  planLevel: Scalars['String']['input'];
}>;

export type InquirePremiumMutation = {
  __typename?: 'Mutation';
  inquirePremium: { __typename?: 'InquirePremium'; status: number };
};

export type InventoryItemsAddMutationVariables = Exact<{
  items: Array<InventoryItemsAddInput>;
  siteId: Scalars['String']['input'];
}>;

export type InventoryItemsAddMutation = {
  __typename?: 'Mutation';
  inventoryItemsAdd: {
    __typename?: 'InventoryItemsAddResponse';
    status?: number | null;
  };
};

export type InventoryItemsUpdateMutationVariables = Exact<{
  items: InventoryItemsUpdateInput;
}>;

export type InventoryItemsUpdateMutation = {
  __typename?: 'Mutation';
  inventoryItemsUpdate: boolean;
};

export type OrganizationCreateMutationVariables = Exact<{
  organizationInput: OrganizationInput;
}>;

export type OrganizationCreateMutation = {
  __typename?: 'Mutation';
  organizationCreate: {
    __typename?: 'Organization';
    address?: string | null;
    createdAt?: Date | null;
    clientTier?: ClientTier | null;
    defaultForm?: string | null;
    headerFont?: string | null;
    highlightColor?: string | null;
    id: string;
    name: string;
    phone?: string | null;
    recurlyAccountCode?: string | null;
    recurlyAccountCodeTest?: string | null;
    sfdcAccountId?: string | null;
    slug: string;
    updatedAt?: Date | null;
    topicsMax?: number | null;
    primaryContact?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  };
};

export type OrganizationUpdateMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  organizationInput: OrganizationInput;
}>;

export type OrganizationUpdateMutation = {
  __typename?: 'Mutation';
  organizationUpdate: {
    __typename?: 'Organization';
    address?: string | null;
    createdAt?: Date | null;
    clientTier?: ClientTier | null;
    defaultForm?: string | null;
    headerFont?: string | null;
    highlightColor?: string | null;
    id: string;
    name: string;
    phone?: string | null;
    recurlyAccountCode?: string | null;
    recurlyAccountCodeTest?: string | null;
    sfdcAccountId?: string | null;
    slug: string;
    updatedAt?: Date | null;
    topicsMax?: number | null;
    primaryContact?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  };
};

export type PlanDeleteMutationVariables = Exact<{
  planId: Scalars['String']['input'];
}>;

export type PlanDeleteMutation = {
  __typename?: 'Mutation';
  planDelete: {
    __typename?: 'DeleteStatus';
    message: string;
    status?: number | null;
  };
};

export type ProjectOrderRemoveMutationVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;

export type ProjectOrderRemoveMutation = {
  __typename?: 'Mutation';
  removeProjectOrder: {
    __typename?: 'Project';
    briefId?: string | null;
    dueDate?: Date | null;
    id: string;
    publishedOn?: Date | null;
    topic?: string | null;
    url?: string | null;
    writingReference?: string | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    plan?: { __typename?: 'Plan'; title: string } | null;
  };
};

export type ProjectsDeleteMutationVariables = Exact<{
  projectIds: Array<Scalars['String']['input']>;
}>;

export type ProjectsDeleteMutation = {
  __typename?: 'Mutation';
  projectsDelete: Array<{
    __typename?: 'DeleteStatus';
    message: string;
    status?: number | null;
  }>;
};

export type PurchaseAppRunsMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  num: Scalars['Int']['input'];
}>;

export type PurchaseAppRunsMutation = {
  __typename?: 'Mutation';
  purchaseAppRuns: {
    __typename?: 'PurchaseAppRuns';
    current: number;
    error?: string | null;
    limit?: number | null;
    lastReset?: Date | null;
    nextReset?: Date | null;
    resetInterval?: any | null;
    extra?: number | null;
    orgId?: string | null;
    credits?: number | null;
    errorCode?: string | null;
    warningCode?: string | null;
  };
};

export type PurchaseCreditsMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  num: Scalars['Int']['input'];
}>;

export type PurchaseCreditsMutation = {
  __typename?: 'Mutation';
  purchaseCredits: {
    __typename?: 'PurchaseCredits';
    newCredits: number;
    site: {
      __typename?: 'Site';
      appRuns?: number | null;
      appRunsExtra?: number | null;
      appRunsLastReset?: Date | null;
      appRunsLimit?: number | null;
      appRunsResetInterval?: any | null;
      appSumoCodes?: Array<string> | null;
      briefCredits?: number | null;
      cancelled: boolean;
      cancelledAt?: Date | null;
      competitorDomains?: Array<string> | null;
      createdAt?: Date | null;
      defaultSerpCountry?: string | null;
      displayContentData?: boolean | null;
      domain: string;
      dunning?: boolean | null;
      dunningCardUpdated?: boolean | null;
      dunningExpired?: boolean | null;
      id: string;
      invId?: number | null;
      level: SiteLevel;
      networkDomains?: Array<string> | null;
      title?: string | null;
      updatedAt?: Date | null;
      visible: boolean;
      documentBriefUserInput?: {
        __typename?: 'DocumentBriefUserInput';
        buyerOrInformationJourney?: string | null;
        contextLinguistic?: string | null;
        expertise?: string | null;
        factStatements?: string | null;
        intentAnalysis?: string | null;
        marketingAndMessagingReferences?: Array<string> | null;
        personalExperience?: string | null;
        personalizationDetail?: string | null;
        pointOfView?: string | null;
        proofStatements?: string | null;
        structuring?: string | null;
        style?: string | null;
        targetMarket?: string | null;
        tone?: string | null;
        voice?: string | null;
      } | null;
    };
  };
};

export type RecurlyAccountCreateMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;

export type RecurlyAccountCreateMutation = {
  __typename?: 'Mutation';
  recurlyCreateAccount: {
    __typename?: 'Account';
    code: string;
    company: string;
    id: string;
  };
};

export type RecurlyUpsertBillingInfoMutationVariables = Exact<{
  accountCode?: InputMaybe<Scalars['String']['input']>;
  recurlyToken: Scalars['String']['input'];
}>;

export type RecurlyUpsertBillingInfoMutation = {
  __typename?: 'Mutation';
  recurlyUpsertBillingInfo: {
    __typename?: 'Account';
    billTo?: string | null;
    ccEmails?: string | null;
    code: string;
    id: string;
    company: string;
    firstName?: string | null;
    email?: string | null;
    lastName?: string | null;
    createdAt?: Date | null;
    deletedAt?: Date | null;
    dunningCampaignId?: string | null;
    exemptionCertificate?: string | null;
    hasActiveSubscription?: boolean | null;
    hasCanceledSubscription?: boolean | null;
    hasFutureSubscription?: boolean | null;
    hasLiveSubscription?: boolean | null;
    hasPastDueInvoice?: boolean | null;
    hasPausedSubscription?: boolean | null;
    object?: string | null;
    parentAccountId?: string | null;
    preferredLocale?: string | null;
    state?: string | null;
    taxExempt?: boolean | null;
    updatedAt?: Date | null;
    username?: string | null;
    vatNumber?: string | null;
    address?: {
      __typename?: 'Address';
      city?: string | null;
      country?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
    } | null;
    billingInfo?: {
      __typename?: 'BillingInfo';
      accountId?: string | null;
      id?: string | null;
      company?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      backupPaymentMethod?: boolean | null;
      primaryPaymentMethod?: boolean | null;
      object?: string | null;
      valid?: boolean | null;
      vatNumber?: string | null;
      updatedAt?: Date | null;
      createdAt?: Date | null;
      address?: {
        __typename?: 'Address';
        city?: string | null;
        country?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      paymentMethod?: {
        __typename?: 'PaymentMethod';
        cardType?: string | null;
        expMonth?: number | null;
        expYear?: number | null;
        firstSix?: string | null;
        gatewayCode?: string | null;
        gatewayToken?: string | null;
        lastFour?: string | null;
        lastTwo?: string | null;
        nameOnAccount?: string | null;
        routingNumber?: string | null;
        routingNumberBank?: string | null;
      } | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      name?: string | null;
      value?: string | null;
    }> | null;
  };
};

export type ReportDeleteMutationVariables = Exact<{
  reportId: Scalars['String']['input'];
}>;

export type ReportDeleteMutation = {
  __typename?: 'Mutation';
  deleteReport: boolean;
};

export type ReportGenerateMutationVariables = Exact<{
  projectId: Scalars['String']['input'];
  subheadings: Array<GenerateSubheadingInput>;
}>;

export type ReportGenerateMutation = {
  __typename?: 'Mutation';
  generateReport: {
    __typename?: 'RequestStatus';
    status?: number | null;
    message: string;
  };
};

export type ReportSubheadingAddMutationVariables = Exact<{
  data: AddSubheadingInput;
}>;

export type ReportSubheadingAddMutation = {
  __typename?: 'Mutation';
  addSubheading: {
    __typename?: 'Brief';
    briefStatus: BriefStatus;
    briefTitle?: string | null;
    competitorSites?: string | null;
    createdAt?: Date | null;
    cuid?: string | null;
    dueDate?: Date | null;
    errorStatus: BriefErrorStatus;
    focusTopic: string;
    formData?: { [key: string]: any } | null;
    formDraft?: boolean | null;
    formId?: string | null;
    id: string;
    reportType: BriefType;
    selfServe?: boolean | null;
    updatedAt?: Date | null;
    accountManager?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    additionalTopics?: Array<{
      __typename?: 'BriefSubtopic';
      distribution?: number | null;
      id?: string | null;
      powerTopic?: boolean | null;
      semanticSimilarity?: number | null;
      suggestedDistribution?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    comments?: Array<{
      __typename?: 'BriefComment';
      createdBy?: string | null;
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    csRep?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    metadata?: {
      __typename?: 'BriefMetadata';
      id?: string | null;
      errors?: Array<{
        __typename?: 'BriefError';
        id?: string | null;
        message?: string | null;
      }> | null;
    } | null;
    org?: {
      __typename?: 'Organization';
      id: string;
      slug: string;
      name: string;
    } | null;
    page?: { __typename?: 'BriefPage'; id: string; url?: string | null } | null;
    qaAssignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    questions?: Array<{
      __typename?: 'BriefQuestion';
      id?: string | null;
      semanticSimilarity?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    rankingTitles?: Array<{
      __typename?: 'BriefRankingTitle';
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    site?: {
      __typename?: 'Site';
      id: string;
      domain: string;
      level: SiteLevel;
    } | null;
    subheadingTerms?: Array<{
      __typename?: 'BriefSubheadingTerm';
      id?: string | null;
      semanticSimilarity?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    subheadings?: Array<{
      __typename?: 'BriefSubheading';
      id?: string | null;
      position?: number | null;
      title?: string | null;
      value?: string | null;
      visible?: boolean | null;
      wordCount?: number | null;
      additionalTopics?: Array<{
        __typename?: 'BriefSubtopic';
        distribution?: number | null;
        id?: string | null;
        powerTopic?: boolean | null;
        semanticSimilarity?: number | null;
        suggestedDistribution?: number | null;
        value?: string | null;
        visible?: boolean | null;
      }> | null;
      comments?: Array<{
        __typename?: 'BriefComment';
        createdBy?: string | null;
        id?: string | null;
        value?: string | null;
        visible?: boolean | null;
      }> | null;
      externalLinks?: Array<{
        __typename?: 'BriefLinkList';
        id: string;
        semanticSimilarity?: number | null;
        visible?: boolean | null;
        links: Array<{
          __typename?: 'BriefLink';
          id: string;
          parent?: string | null;
          title?: string | null;
          url?: string | null;
          visible?: boolean | null;
        }>;
      }> | null;
      internalLinks?: Array<{
        __typename?: 'BriefLinkList';
        id: string;
        semanticSimilarity?: number | null;
        visible?: boolean | null;
        links: Array<{
          __typename?: 'BriefLink';
          id: string;
          parent?: string | null;
          title?: string | null;
          url?: string | null;
          visible?: boolean | null;
        }>;
      }> | null;
      questions?: Array<{
        __typename?: 'BriefQuestion';
        id?: string | null;
        semanticSimilarity?: number | null;
        value?: string | null;
        visible?: boolean | null;
      }> | null;
      relatedTopics?: Array<{
        __typename?: 'BriefRelatedTopic';
        distribution?: number | null;
        id: string;
        powerTopic?: boolean | null;
        semanticSimilarity?: number | null;
        suggestedDistribution?: number | null;
        value: string;
        visible?: boolean | null;
      }> | null;
      topic?: {
        __typename?: 'BriefSubheadingTopic';
        contentScoreAvg?: number | null;
        contentScoreTarget?: number | null;
        id: string;
        semanticSimilarity?: number | null;
        suggestedDistribution?: number | null;
        term: string;
        wordCountAvg?: number | null;
        wordCountTarget?: number | null;
      } | null;
    }> | null;
    subtopics?: Array<{
      __typename?: 'BriefSubtopic';
      distribution?: number | null;
      id?: string | null;
      powerTopic?: boolean | null;
      semanticSimilarity?: number | null;
      suggestedDistribution?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    titleTerms?: Array<{
      __typename?: 'BriefTitleTerm';
      id?: string | null;
      semanticSimilarity?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    topic?: {
      __typename?: 'BriefTopic';
      contentScoreAvg?: number | null;
      contentScoreTarget?: number | null;
      id: string;
      kgKey?: string | null;
      term: string;
      wordCountAvg?: number | null;
      wordCountTarget?: number | null;
    } | null;
    userAudiences?: Array<{
      __typename?: 'BriefUserAudience';
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    userIntents?: Array<{
      __typename?: 'BriefUserIntent';
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
  };
};

export type SavedViewCreateMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  savedViewInput: SavedViewInput;
}>;

export type SavedViewCreateMutation = {
  __typename?: 'Mutation';
  savedViewCreate: {
    __typename?: 'SavedView';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    description?: string | null;
    inventoryType: InventoryType;
    filters?: { [key: string]: any } | null;
    pinned?: { [key: string]: any } | null;
    hidden?: { [key: string]: any } | null;
    order?: { [key: string]: any } | null;
    sorting?: { [key: string]: any } | null;
    sharing?: SavedViewSharing | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  };
};

export type SavedViewDeleteMutationVariables = Exact<{
  savedViewId: Scalars['String']['input'];
}>;

export type SavedViewDeleteMutation = {
  __typename?: 'Mutation';
  savedViewDelete: { __typename?: 'DeleteStatus'; message: string };
};

export type SavedViewUpdateMutationVariables = Exact<{
  savedViewId: Scalars['String']['input'];
  savedViewInput: SavedViewInput;
}>;

export type SavedViewUpdateMutation = {
  __typename?: 'Mutation';
  savedViewUpdate: {
    __typename?: 'SavedView';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    description?: string | null;
    inventoryType: InventoryType;
    filters?: { [key: string]: any } | null;
    pinned?: { [key: string]: any } | null;
    hidden?: { [key: string]: any } | null;
    order?: { [key: string]: any } | null;
    sorting?: { [key: string]: any } | null;
    sharing?: SavedViewSharing | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  };
};

export type SetupAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  company: Scalars['String']['input'];
  domain: Scalars['Domain']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  utm: Scalars['JSON']['input'];
  onboardingStep?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetupAccountMutation = {
  __typename?: 'Mutation';
  setupAccount: {
    __typename?: 'SetupAccountResult';
    user: {
      __typename?: 'User';
      createdAt?: Date | null;
      defaultApplicationSerpCountry?: string | null;
      displayExactDistribution?: boolean | null;
      email: string;
      firstName?: string | null;
      fullName?: string | null;
      id: string;
      lastName?: string | null;
      onboardingProgress?: { [key: string]: any } | null;
      onboardingStep?: string | null;
      orgRole?: string | null;
      phone?: string | null;
      shouldReceiveBriefOrderedNotifications?: boolean | null;
      suspended?: boolean | null;
      sfdcContactId?: string | null;
      updatedAt?: Date | null;
    };
    org: {
      __typename?: 'Organization';
      maxUsers?: number | null;
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      sites?: Array<{
        __typename?: 'Site';
        appRuns?: number | null;
        appRunsExtra?: number | null;
        appRunsLastReset?: Date | null;
        appRunsLimit?: number | null;
        appRunsResetInterval?: any | null;
        appSumoCodes?: Array<string> | null;
        briefCredits?: number | null;
        cancelled: boolean;
        cancelledAt?: Date | null;
        competitorDomains?: Array<string> | null;
        createdAt?: Date | null;
        defaultSerpCountry?: string | null;
        displayContentData?: boolean | null;
        domain: string;
        dunning?: boolean | null;
        dunningCardUpdated?: boolean | null;
        dunningExpired?: boolean | null;
        id: string;
        invId?: number | null;
        level: SiteLevel;
        networkDomains?: Array<string> | null;
        title?: string | null;
        updatedAt?: Date | null;
        visible: boolean;
        documentBriefUserInput?: {
          __typename?: 'DocumentBriefUserInput';
          buyerOrInformationJourney?: string | null;
          contextLinguistic?: string | null;
          expertise?: string | null;
          factStatements?: string | null;
          intentAnalysis?: string | null;
          marketingAndMessagingReferences?: Array<string> | null;
          personalExperience?: string | null;
          personalizationDetail?: string | null;
          pointOfView?: string | null;
          proofStatements?: string | null;
          structuring?: string | null;
          style?: string | null;
          targetMarket?: string | null;
          tone?: string | null;
          voice?: string | null;
        } | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        orgRole?: string | null;
        suspended?: boolean | null;
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      }> | null;
      documentAllotment?: {
        __typename?: 'DocumentsAllotment';
        analysisPlanning: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        briefs: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        resetDate: { __typename?: 'ResetDate'; last: Date; next: Date };
      } | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    };
    site: {
      __typename?: 'Site';
      appRuns?: number | null;
      appRunsExtra?: number | null;
      appRunsLastReset?: Date | null;
      appRunsLimit?: number | null;
      appRunsResetInterval?: any | null;
      appSumoCodes?: Array<string> | null;
      briefCredits?: number | null;
      cancelled: boolean;
      cancelledAt?: Date | null;
      competitorDomains?: Array<string> | null;
      createdAt?: Date | null;
      defaultSerpCountry?: string | null;
      displayContentData?: boolean | null;
      domain: string;
      dunning?: boolean | null;
      dunningCardUpdated?: boolean | null;
      dunningExpired?: boolean | null;
      id: string;
      invId?: number | null;
      level: SiteLevel;
      networkDomains?: Array<string> | null;
      title?: string | null;
      updatedAt?: Date | null;
      visible: boolean;
      documentBriefUserInput?: {
        __typename?: 'DocumentBriefUserInput';
        buyerOrInformationJourney?: string | null;
        contextLinguistic?: string | null;
        expertise?: string | null;
        factStatements?: string | null;
        intentAnalysis?: string | null;
        marketingAndMessagingReferences?: Array<string> | null;
        personalExperience?: string | null;
        personalizationDetail?: string | null;
        pointOfView?: string | null;
        proofStatements?: string | null;
        structuring?: string | null;
        style?: string | null;
        targetMarket?: string | null;
        tone?: string | null;
        voice?: string | null;
      } | null;
    };
    subscription?: {
      __typename?: 'SubscriptionEntity';
      createdAt: Date;
      dunning?: boolean | null;
      endsOnDate: Date;
      id: string;
      externalSubscriptionId?: string | null;
      conversionMethod: SubscriptionConversionMethod;
      startedOnDate: Date;
      state: SubscriptionState;
      price?: number | null;
      demo?: boolean | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        active: boolean;
        appQueryCount: number;
        analysisAndPlanningDocumentAllocationLimit: number;
        briefDocumentAllocationLimit: number;
        id: string;
        planCode: string;
        price: number;
        term: SubscriptionPlanTerm;
        termLength: number;
        type: SubscriptionPlanType;
        userCanDowngradePackage: boolean;
        userCanUpgradePackage: boolean;
        usersArePurchasable: boolean;
        usersCountDefault?: number | null;
      };
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug: string;
        clientTier?: ClientTier | null;
      };
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
      addons?: Array<{
        __typename?: 'SubscriptionAddon';
        id: string;
        addonState?: SubscriptionAddonState | null;
        mode?: SubscriptionAddonMode | null;
        addonType: {
          __typename?: 'SubscriptionAddonType';
          id: string;
          description?: string | null;
          price?: number | null;
          type: SubscriptionAddonTypeType;
          validFromDate: Date;
          validUntilDate: Date;
        };
        site?: {
          __typename?: 'Site';
          cancelled: boolean;
          domain: string;
          id: string;
          level: SiteLevel;
          title?: string | null;
          visible: boolean;
        } | null;
      }> | null;
    } | null;
    permissions: Array<{
      __typename?: 'Permission';
      action: PermissionAction;
      resource: PermissionResource;
      status: PermissionStatus;
    }>;
  };
};

export type InventoryUpdateMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
  siteInput: SiteInput;
}>;

export type InventoryUpdateMutation = {
  __typename?: 'Mutation';
  siteUpdate: {
    __typename?: 'Site';
    appRuns?: number | null;
    appRunsExtra?: number | null;
    appRunsLastReset?: Date | null;
    appRunsLimit?: number | null;
    appRunsResetInterval?: any | null;
    appSumoCodes?: Array<string> | null;
    briefCredits?: number | null;
    cancelled: boolean;
    cancelledAt?: Date | null;
    competitorDomains?: Array<string> | null;
    createdAt?: Date | null;
    defaultSerpCountry?: string | null;
    displayContentData?: boolean | null;
    domain: string;
    dunning?: boolean | null;
    dunningCardUpdated?: boolean | null;
    dunningExpired?: boolean | null;
    id: string;
    invId?: number | null;
    level: SiteLevel;
    networkDomains?: Array<string> | null;
    title?: string | null;
    updatedAt?: Date | null;
    visible: boolean;
    valuePerVisit?: number | null;
    subdomains?: Array<string> | null;
    excludePaths?: Array<string> | null;
    includePaths?: Array<string> | null;
    autoUpdate?: boolean | null;
    recommendedTopics?: number | null;
    topicsAllocated?: number | null;
    topicsInUse?: number | null;
    org?: {
      __typename?: 'Organization';
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      users?: Array<{
        __typename?: 'User';
        createdAt?: Date | null;
        defaultApplicationSerpCountry?: string | null;
        displayExactDistribution?: boolean | null;
        email: string;
        firstName?: string | null;
        fullName?: string | null;
        id: string;
        lastName?: string | null;
        onboardingProgress?: { [key: string]: any } | null;
        onboardingStep?: string | null;
        orgRole?: string | null;
        phone?: string | null;
        shouldReceiveBriefOrderedNotifications?: boolean | null;
        suspended?: boolean | null;
        sfdcContactId?: string | null;
        updatedAt?: Date | null;
      }> | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    } | null;
    documentBriefUserInput?: {
      __typename?: 'DocumentBriefUserInput';
      buyerOrInformationJourney?: string | null;
      contextLinguistic?: string | null;
      expertise?: string | null;
      factStatements?: string | null;
      intentAnalysis?: string | null;
      marketingAndMessagingReferences?: Array<string> | null;
      personalExperience?: string | null;
      personalizationDetail?: string | null;
      pointOfView?: string | null;
      proofStatements?: string | null;
      structuring?: string | null;
      style?: string | null;
      targetMarket?: string | null;
      tone?: string | null;
      voice?: string | null;
    } | null;
  };
};

export type InventoryCreateMutationVariables = Exact<{
  orgId: Scalars['String']['input'];
  siteInput: SiteInput;
  options: SiteCreateOptions;
}>;

export type InventoryCreateMutation = {
  __typename?: 'Mutation';
  siteCreate: {
    __typename?: 'Site';
    appRuns?: number | null;
    appRunsExtra?: number | null;
    appRunsLastReset?: Date | null;
    appRunsLimit?: number | null;
    appRunsResetInterval?: any | null;
    appSumoCodes?: Array<string> | null;
    briefCredits?: number | null;
    cancelled: boolean;
    cancelledAt?: Date | null;
    competitorDomains?: Array<string> | null;
    createdAt?: Date | null;
    defaultSerpCountry?: string | null;
    displayContentData?: boolean | null;
    domain: string;
    dunning?: boolean | null;
    dunningCardUpdated?: boolean | null;
    dunningExpired?: boolean | null;
    id: string;
    invId?: number | null;
    level: SiteLevel;
    networkDomains?: Array<string> | null;
    title?: string | null;
    updatedAt?: Date | null;
    visible: boolean;
    valuePerVisit?: number | null;
    subdomains?: Array<string> | null;
    excludePaths?: Array<string> | null;
    includePaths?: Array<string> | null;
    autoUpdate?: boolean | null;
    recommendedTopics?: number | null;
    topicsAllocated?: number | null;
    topicsInUse?: number | null;
    documentBriefUserInput?: {
      __typename?: 'DocumentBriefUserInput';
      buyerOrInformationJourney?: string | null;
      contextLinguistic?: string | null;
      expertise?: string | null;
      factStatements?: string | null;
      intentAnalysis?: string | null;
      marketingAndMessagingReferences?: Array<string> | null;
      personalExperience?: string | null;
      personalizationDetail?: string | null;
      pointOfView?: string | null;
      proofStatements?: string | null;
      structuring?: string | null;
      style?: string | null;
      targetMarket?: string | null;
      tone?: string | null;
      voice?: string | null;
    } | null;
  };
};

export type InventoryDeleteMutationVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type InventoryDeleteMutation = {
  __typename?: 'Mutation';
  siteDelete: {
    __typename?: 'DeleteStatus';
    status?: number | null;
    message: string;
  };
};

export type SiteCreateGovernedInventoryMutationVariables = Exact<{
  orgId: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  defaultSerpCountry: Scalars['String']['input'];
}>;

export type SiteCreateGovernedInventoryMutation = {
  __typename?: 'Mutation';
  siteCreateGovernedInventory: {
    __typename?: 'Site';
    appRuns?: number | null;
    appRunsExtra?: number | null;
    appRunsLastReset?: Date | null;
    appRunsLimit?: number | null;
    appRunsResetInterval?: any | null;
    appSumoCodes?: Array<string> | null;
    briefCredits?: number | null;
    cancelled: boolean;
    cancelledAt?: Date | null;
    competitorDomains?: Array<string> | null;
    createdAt?: Date | null;
    defaultSerpCountry?: string | null;
    displayContentData?: boolean | null;
    domain: string;
    dunning?: boolean | null;
    dunningCardUpdated?: boolean | null;
    dunningExpired?: boolean | null;
    id: string;
    invId?: number | null;
    level: SiteLevel;
    networkDomains?: Array<string> | null;
    title?: string | null;
    updatedAt?: Date | null;
    visible: boolean;
    documentBriefUserInput?: {
      __typename?: 'DocumentBriefUserInput';
      buyerOrInformationJourney?: string | null;
      contextLinguistic?: string | null;
      expertise?: string | null;
      factStatements?: string | null;
      intentAnalysis?: string | null;
      marketingAndMessagingReferences?: Array<string> | null;
      personalExperience?: string | null;
      personalizationDetail?: string | null;
      pointOfView?: string | null;
      proofStatements?: string | null;
      structuring?: string | null;
      style?: string | null;
      targetMarket?: string | null;
      tone?: string | null;
      voice?: string | null;
    } | null;
  };
};

export type SubscriptionAddonCancelMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  subscriptionAddonId: Scalars['String']['input'];
}>;

export type SubscriptionAddonCancelMutation = {
  __typename?: 'Mutation';
  subscriptionAddonCancel: {
    __typename?: 'RequestStatus';
    message: string;
    status?: number | null;
  };
};

export type SubscriptionAddonCreateMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  addonTypeId: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubscriptionAddonCreateMutation = {
  __typename?: 'Mutation';
  subscriptionAddonCreate: {
    __typename?: 'SubscriptionAddon';
    id: string;
    subscription?: {
      __typename?: 'SubscriptionEntity';
      createdAt: Date;
      dunning?: boolean | null;
      endsOnDate: Date;
      id: string;
      externalSubscriptionId?: string | null;
      conversionMethod: SubscriptionConversionMethod;
      startedOnDate: Date;
      state: SubscriptionState;
      price?: number | null;
      demo?: boolean | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        active: boolean;
        appQueryCount: number;
        analysisAndPlanningDocumentAllocationLimit: number;
        briefDocumentAllocationLimit: number;
        id: string;
        planCode: string;
        price: number;
        term: SubscriptionPlanTerm;
        termLength: number;
        type: SubscriptionPlanType;
        userCanDowngradePackage: boolean;
        userCanUpgradePackage: boolean;
        usersArePurchasable: boolean;
        usersCountDefault?: number | null;
      };
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug: string;
        clientTier?: ClientTier | null;
      };
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
      addons?: Array<{
        __typename?: 'SubscriptionAddon';
        id: string;
        addonState?: SubscriptionAddonState | null;
        mode?: SubscriptionAddonMode | null;
        addonType: {
          __typename?: 'SubscriptionAddonType';
          id: string;
          description?: string | null;
          price?: number | null;
          type: SubscriptionAddonTypeType;
          validFromDate: Date;
          validUntilDate: Date;
        };
        site?: {
          __typename?: 'Site';
          cancelled: boolean;
          domain: string;
          id: string;
          level: SiteLevel;
          title?: string | null;
          visible: boolean;
        } | null;
      }> | null;
    } | null;
    addonType: {
      __typename?: 'SubscriptionAddonType';
      id: string;
      description?: string | null;
      price?: number | null;
      type: SubscriptionAddonTypeType;
      validFromDate: Date;
      validUntilDate: Date;
    };
    site?: {
      __typename?: 'Site';
      appRuns?: number | null;
      appRunsExtra?: number | null;
      appRunsLastReset?: Date | null;
      appRunsLimit?: number | null;
      appRunsResetInterval?: any | null;
      appSumoCodes?: Array<string> | null;
      briefCredits?: number | null;
      cancelled: boolean;
      cancelledAt?: Date | null;
      competitorDomains?: Array<string> | null;
      createdAt?: Date | null;
      defaultSerpCountry?: string | null;
      displayContentData?: boolean | null;
      domain: string;
      dunning?: boolean | null;
      dunningCardUpdated?: boolean | null;
      dunningExpired?: boolean | null;
      id: string;
      invId?: number | null;
      level: SiteLevel;
      networkDomains?: Array<string> | null;
      title?: string | null;
      updatedAt?: Date | null;
      visible: boolean;
      documentBriefUserInput?: {
        __typename?: 'DocumentBriefUserInput';
        buyerOrInformationJourney?: string | null;
        contextLinguistic?: string | null;
        expertise?: string | null;
        factStatements?: string | null;
        intentAnalysis?: string | null;
        marketingAndMessagingReferences?: Array<string> | null;
        personalExperience?: string | null;
        personalizationDetail?: string | null;
        pointOfView?: string | null;
        proofStatements?: string | null;
        structuring?: string | null;
        style?: string | null;
        targetMarket?: string | null;
        tone?: string | null;
        voice?: string | null;
      } | null;
    } | null;
  };
};

export type SubscriptionUpdateMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  subscriptionPlanId: Scalars['String']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubscriptionUpdateMutation = {
  __typename?: 'Mutation';
  subscriptionUpdate: {
    __typename?: 'SubscriptionUpdateResponse';
    org: {
      __typename?: 'Organization';
      maxUsers?: number | null;
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      sites?: Array<{
        __typename?: 'Site';
        appRuns?: number | null;
        appRunsExtra?: number | null;
        appRunsLastReset?: Date | null;
        appRunsLimit?: number | null;
        appRunsResetInterval?: any | null;
        appSumoCodes?: Array<string> | null;
        briefCredits?: number | null;
        cancelled: boolean;
        cancelledAt?: Date | null;
        competitorDomains?: Array<string> | null;
        createdAt?: Date | null;
        defaultSerpCountry?: string | null;
        displayContentData?: boolean | null;
        domain: string;
        dunning?: boolean | null;
        dunningCardUpdated?: boolean | null;
        dunningExpired?: boolean | null;
        id: string;
        invId?: number | null;
        level: SiteLevel;
        networkDomains?: Array<string> | null;
        title?: string | null;
        updatedAt?: Date | null;
        visible: boolean;
        documentBriefUserInput?: {
          __typename?: 'DocumentBriefUserInput';
          buyerOrInformationJourney?: string | null;
          contextLinguistic?: string | null;
          expertise?: string | null;
          factStatements?: string | null;
          intentAnalysis?: string | null;
          marketingAndMessagingReferences?: Array<string> | null;
          personalExperience?: string | null;
          personalizationDetail?: string | null;
          pointOfView?: string | null;
          proofStatements?: string | null;
          structuring?: string | null;
          style?: string | null;
          targetMarket?: string | null;
          tone?: string | null;
          voice?: string | null;
        } | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        orgRole?: string | null;
        suspended?: boolean | null;
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      }> | null;
      documentAllotment?: {
        __typename?: 'DocumentsAllotment';
        analysisPlanning: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        briefs: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        resetDate: { __typename?: 'ResetDate'; last: Date; next: Date };
      } | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    };
    permissions: Array<{
      __typename?: 'Permission';
      action: PermissionAction;
      resource: PermissionResource;
      status: PermissionStatus;
    }>;
    subscription: {
      __typename?: 'SubscriptionEntity';
      createdAt: Date;
      dunning?: boolean | null;
      endsOnDate: Date;
      id: string;
      externalSubscriptionId?: string | null;
      conversionMethod: SubscriptionConversionMethod;
      startedOnDate: Date;
      state: SubscriptionState;
      price?: number | null;
      demo?: boolean | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        active: boolean;
        appQueryCount: number;
        analysisAndPlanningDocumentAllocationLimit: number;
        briefDocumentAllocationLimit: number;
        id: string;
        planCode: string;
        price: number;
        term: SubscriptionPlanTerm;
        termLength: number;
        type: SubscriptionPlanType;
        userCanDowngradePackage: boolean;
        userCanUpgradePackage: boolean;
        usersArePurchasable: boolean;
        usersCountDefault?: number | null;
      };
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug: string;
        clientTier?: ClientTier | null;
      };
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
      addons?: Array<{
        __typename?: 'SubscriptionAddon';
        id: string;
        addonState?: SubscriptionAddonState | null;
        mode?: SubscriptionAddonMode | null;
        addonType: {
          __typename?: 'SubscriptionAddonType';
          id: string;
          description?: string | null;
          price?: number | null;
          type: SubscriptionAddonTypeType;
          validFromDate: Date;
          validUntilDate: Date;
        };
        site?: {
          __typename?: 'Site';
          cancelled: boolean;
          domain: string;
          id: string;
          level: SiteLevel;
          title?: string | null;
          visible: boolean;
        } | null;
      }> | null;
    };
  };
};

export type TagCreateOrUpdateMutationVariables = Exact<{
  tagId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  tagInput: TagUpdateInput;
}>;

export type TagCreateOrUpdateMutation = {
  __typename?: 'Mutation';
  tagCreateOrUpdate: {
    __typename?: 'Tag';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title: string;
    terms?: Array<string> | null;
    documents?: Array<{ __typename?: 'Document'; id: string }> | null;
    createdBy?: { __typename?: 'User'; email: string } | null;
    updatedBy?: { __typename?: 'User'; email: string } | null;
  };
};

export type TagDeleteMutationVariables = Exact<{
  tagId: Scalars['String']['input'];
}>;

export type TagDeleteMutation = {
  __typename?: 'Mutation';
  tagDelete: { __typename?: 'DeleteStatus'; message: string };
};

export type UserDeleteMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserDeleteMutation = {
  __typename?: 'Mutation';
  userDelete: {
    __typename?: 'DeleteStatus';
    message: string;
    status?: number | null;
  };
};

export type UserUpdateMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  userInput: UserUpdateInput;
}>;

export type UserUpdateMutation = {
  __typename?: 'Mutation';
  userUpdate: {
    __typename?: 'User';
    createdAt?: Date | null;
    defaultApplicationSerpCountry?: string | null;
    displayExactDistribution?: boolean | null;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    id: string;
    lastName?: string | null;
    onboardingProgress?: { [key: string]: any } | null;
    onboardingStep?: string | null;
    orgRole?: string | null;
    phone?: string | null;
    shouldReceiveBriefOrderedNotifications?: boolean | null;
    suspended?: boolean | null;
    sfdcContactId?: string | null;
    updatedAt?: Date | null;
  };
};

export type SeoWebsiteHeatmapQueryVariables = Exact<{
  term: Scalars['String']['input'];
  kgTerms: Array<Scalars['String']['input']>;
  includePath: Scalars['URL']['input'];
  excludePaths?: InputMaybe<Array<Scalars['URL']['input']>>;
}>;

export type SeoWebsiteHeatmapQuery = {
  __typename?: 'Query';
  seoWebsiteHeatmap: Array<{
    __typename?: 'WebsiteSerpItemPagesKgData';
    title?: string | null;
    position?: number | null;
    url?: string | null;
    wordCount?: number | null;
    contentScore?: number | null;
    mentions?: Array<{
      __typename?: 'MentionItem';
      term: string;
      count: number;
    }> | null;
  }>;
};

export type AppQueriesQueryVariables = Exact<{
  filters?: InputMaybe<Array<AppQueryFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AppQueriesQuery = {
  __typename?: 'Query';
  appQueries: {
    __typename?: 'AppQueriesResponse';
    count: number;
    items: Array<{
      __typename?: 'AppQuery';
      app: string;
      connectCompetitorDomains?: Array<string> | null;
      connectNetworkDomains?: Array<string> | null;
      country?: Country | null;
      createdAt?: Date | null;
      id: string;
      jsonMetadata?: { [key: string]: any } | null;
      language?: Language | null;
      status: AppQueryStatus;
      topic?: string | null;
      updatedAt?: Date | null;
      url?: string | null;
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
      user?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    }>;
  };
};

export type DocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DocumentQuery = {
  __typename?: 'Query';
  document?: {
    __typename?: 'Document';
    steps?: Array<DocumentStep> | null;
    prioritization?: DocumentPrioritization | null;
    scope?: DocumentScope | null;
    createdAt?: Date | null;
    id: string;
    status?: DocumentStatus | null;
    subtype: DocumentSubtype;
    title: string;
    type: DocumentType;
    updatedAt?: Date | null;
    assigneeTo?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    inputData: {
      __typename?: 'DocumentInputData';
      terms?: Array<string> | null;
      urls?: Array<string> | null;
      domain?: string | null;
      location?: string | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    };
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
    updatedBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  } | null;
};

export type DocumentStorageDataQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DocumentStorageDataQuery = {
  __typename?: 'Query';
  document?: {
    __typename?: 'Document';
    id: string;
    content?: { [key: string]: any } | null;
    subtype: DocumentSubtype;
    title: string;
    inputData: {
      __typename?: 'DocumentInputData';
      terms?: Array<string> | null;
      urls?: Array<string> | null;
      domain?: string | null;
      location?: string | null;
    };
    org: { __typename?: 'Organization'; id: string };
    site: {
      __typename?: 'Site';
      competitorDomains?: Array<string> | null;
      defaultSerpCountry?: string | null;
      domain: string;
      id: string;
      networkDomains?: Array<string> | null;
    };
    additionalData?:
      | {
          __typename?: 'DocumentAnalysisData';
          allClusterThemes?: Array<string> | null;
          processingTime?: number | null;
          clusters?: Array<{
            __typename?: 'DocumentClusterData';
            terms?: Array<string> | null;
            theme: string;
            contentGroups?: Array<{
              __typename?: 'DocumentContentGroups';
              name?: string | null;
              personas?: Array<string> | null;
            }> | null;
          }> | null;
        }
      | { __typename?: 'DocumentBriefData'; processingTime?: number | null }
      | {
          __typename?: 'DocumentContentPlanData';
          theme: string;
          processingTime?: number | null;
          opportunitiesOptimize?: Array<{
            __typename?: 'DocumentOptimizeOpportunities';
            term: string;
            url: string;
          }> | null;
          opportunitiesCreate?: Array<{
            __typename?: 'DocumentContentOpportunities';
            documentSubtype?: BriefSubtype | null;
            focusTerm: string;
            funnelStage: FunnelStages;
            idea: string;
            overview: string;
            pointsToCover: Array<string>;
            relatedTerms: Array<string>;
            title: string;
          }> | null;
          recommendedBriefs: {
            __typename?: 'ContentPlanRecommendations';
            countCreate: number;
            countOptimize: number;
          };
        }
      | null;
  } | null;
};

export type DocumentsQueryVariables = Exact<{
  filters?: InputMaybe<Array<DocumentFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SortField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DocumentsQuery = {
  __typename?: 'Query';
  documents: {
    __typename?: 'DocumentsResponse';
    count: number;
    items: Array<{
      __typename?: 'Document';
      createdAt?: Date | null;
      id: string;
      status?: DocumentStatus | null;
      subtype: DocumentSubtype;
      title: string;
      type: DocumentType;
      updatedAt?: Date | null;
      assigneeTo?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
      createdBy?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
      inputData: {
        __typename?: 'DocumentInputData';
        terms?: Array<string> | null;
        urls?: Array<string> | null;
        domain?: string | null;
        location?: string | null;
      };
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug: string;
        clientTier?: ClientTier | null;
      };
      site: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      };
      tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
      updatedBy?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    }>;
  };
};

export type DocumentsCreateEstimateQueryVariables = Exact<{
  inputs: Array<DocumentsCreateEstimateInput>;
  orgId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
}>;

export type DocumentsCreateEstimateQuery = {
  __typename?: 'Query';
  documentsCreateEstimate: Array<{
    __typename?: 'DocumentCreateEstimation';
    documentType: DocumentType;
    documentSubtype: DocumentSubtype;
    status: DocumentCreateEstimationStatus;
  }>;
};

export type GetExcelQueryVariables = Exact<{
  context: ExcelGenerationContext;
  data: ExcelGenerationData;
  fn: ExcelGenerationFunctions;
}>;

export type GetExcelQuery = {
  __typename?: 'Query';
  getExcel: {
    __typename?: 'ExcelGenerationResponse';
    data: string;
    format: string;
  };
};

export type ExportConnectQueryVariables = Exact<{
  generationContext: GenerationContextInput;
  seoConnectData: SeoConnectInput;
}>;

export type ExportConnectQuery = {
  __typename?: 'Query';
  exportConnect: {
    __typename?: 'ExcelGenerationResponse';
    data: string;
    format: string;
  };
};

export type ExportHeatmapQueryVariables = Exact<{
  generationContext: GenerationContextInput;
  knowledgeGraphItems: Array<KnowledgeGraphItemInput>;
  organicSerpItemPageKgData: Array<OrganicSerpItemPageKgDataInput>;
  userProvidedPage?: InputMaybe<OrganicSerpItemPageKgDataInput>;
  filterContext: FilterContextInput;
}>;

export type ExportHeatmapQuery = {
  __typename?: 'Query';
  exportHeatmap: {
    __typename?: 'ExcelGenerationResponse';
    data: string;
    format: string;
  };
};

export type ExportInventoryItemsQueryVariables = Exact<{
  columnNames: Array<ColumnNameInput>;
  filters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  limit?: Scalars['Int']['input'];
  mode?: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
}>;

export type ExportInventoryItemsQuery = {
  __typename?: 'Query';
  exportInventoryItems: {
    __typename?: 'ExcelGenerationResponse';
    data: string;
    format: string;
  };
};

export type ExportSerpXrayQueryVariables = Exact<{
  generationContext: GenerationContextInput;
  intentData: IntentDataInput;
  kgMetrics: Array<KgMetricsInput>;
  kgMetricsAverage: KgMetricsInput;
  organicSerpItemPageAverages: OnPageMetricsAveragesInput;
  organicSerpItemPageKgData: Array<OrganicSerpItemPageKgDataInput>;
  organicSerpItemPages: Array<OrganicSerpItemPageInput>;
  organicSerpItems: Array<OrganicSerpItemInput>;
  serpExportUserProvidedPage?: InputMaybe<SerpExportUserProvidedPageInput>;
  userProvidedPage?: InputMaybe<UserProvidedPageInput>;
}>;

export type ExportSerpXrayQuery = {
  __typename?: 'Query';
  exportSerpXray: {
    __typename?: 'ExcelGenerationResponse';
    data: string;
    format: string;
  };
};

export type ExportTopicNavigatorQueryVariables = Exact<{
  generationContext: GenerationContextInput;
  keywordsTabData?: InputMaybe<Array<AdWordsItemInput>>;
  questionsTabData?: InputMaybe<Array<AdWordsItemInput>>;
  reflectTabData?: InputMaybe<Array<ReflectItemInput>>;
  topicModelTabData?: InputMaybe<Array<KnowledgeGraphItemInput>>;
}>;

export type ExportTopicNavigatorQuery = {
  __typename?: 'Query';
  exportTable: {
    __typename?: 'ExcelGenerationResponse';
    data: string;
    format: string;
  };
};

export type GetFormQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetFormQuery = {
  __typename?: 'Query';
  form?: {
    __typename?: 'Form';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    type?: FormType | null;
    orderVisible?: { [key: string]: any } | null;
    orderHidden?: { [key: string]: any } | null;
    org?: {
      __typename?: 'Organization';
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      createdAt?: Date | null;
      defaultApplicationSerpCountry?: string | null;
      displayExactDistribution?: boolean | null;
      email: string;
      firstName?: string | null;
      fullName?: string | null;
      id: string;
      lastName?: string | null;
      onboardingProgress?: { [key: string]: any } | null;
      onboardingStep?: string | null;
      orgRole?: string | null;
      phone?: string | null;
      shouldReceiveBriefOrderedNotifications?: boolean | null;
      suspended?: boolean | null;
      sfdcContactId?: string | null;
      updatedAt?: Date | null;
    } | null;
    groups?: Array<{
      __typename?: 'FormGroup';
      createdAt?: Date | null;
      updatedAt?: Date | null;
      id: string;
      title?: string | null;
      type: FormGroupType;
      orderVisible?: { [key: string]: any } | null;
      orderHidden?: { [key: string]: any } | null;
      form?: {
        __typename?: 'Form';
        createdAt?: Date | null;
        updatedAt?: Date | null;
        id: string;
        title?: string | null;
        type?: FormType | null;
        orderVisible?: { [key: string]: any } | null;
        orderHidden?: { [key: string]: any } | null;
      } | null;
      fields?: Array<{
        __typename?: 'FormField';
        id: string;
        note?: string | null;
        type: FormFieldType;
        fieldData?: { [key: string]: any } | null;
        required?: boolean | null;
        group?: { __typename?: 'FormGroup'; id: string } | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetFormsQueryVariables = Exact<{
  filters?: InputMaybe<Array<FormFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetFormsQuery = {
  __typename?: 'Query';
  forms: Array<{
    __typename?: 'Form';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    type?: FormType | null;
    orderVisible?: { [key: string]: any } | null;
    orderHidden?: { [key: string]: any } | null;
    org?: {
      __typename?: 'Organization';
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      createdAt?: Date | null;
      defaultApplicationSerpCountry?: string | null;
      displayExactDistribution?: boolean | null;
      email: string;
      firstName?: string | null;
      fullName?: string | null;
      id: string;
      lastName?: string | null;
      onboardingProgress?: { [key: string]: any } | null;
      onboardingStep?: string | null;
      orgRole?: string | null;
      phone?: string | null;
      shouldReceiveBriefOrderedNotifications?: boolean | null;
      suspended?: boolean | null;
      sfdcContactId?: string | null;
      updatedAt?: Date | null;
    } | null;
    groups?: Array<{
      __typename?: 'FormGroup';
      createdAt?: Date | null;
      updatedAt?: Date | null;
      id: string;
      title?: string | null;
      type: FormGroupType;
      orderVisible?: { [key: string]: any } | null;
      orderHidden?: { [key: string]: any } | null;
      form?: {
        __typename?: 'Form';
        createdAt?: Date | null;
        updatedAt?: Date | null;
        id: string;
        title?: string | null;
        type?: FormType | null;
        orderVisible?: { [key: string]: any } | null;
        orderHidden?: { [key: string]: any } | null;
      } | null;
      fields?: Array<{
        __typename?: 'FormField';
        id: string;
        note?: string | null;
        type: FormFieldType;
        fieldData?: { [key: string]: any } | null;
        required?: boolean | null;
        group?: { __typename?: 'FormGroup'; id: string } | null;
      }> | null;
    }> | null;
  } | null>;
};

export type GenaiChangeToneQueryVariables = Exact<{
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  tone: Tone;
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiChangeToneQuery = {
  __typename?: 'Query';
  genaiChangeTone: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiCreateOutlineQueryVariables = Exact<{
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiCreateOutlineQuery = {
  __typename?: 'Query';
  genaiCreateOutline: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiCreateSummaryQueryVariables = Exact<{
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiCreateSummaryQuery = {
  __typename?: 'Query';
  genaiCreateSummary: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiCustomPromptQueryVariables = Exact<{
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiCustomPromptQuery = {
  __typename?: 'Query';
  genaiCustomPrompt: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiExpandConceptQueryVariables = Exact<{
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  promptInput: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiExpandConceptQuery = {
  __typename?: 'Query';
  genaiExpandConcept: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiFixSpellingQueryVariables = Exact<{
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
}>;

export type GenaiFixSpellingQuery = {
  __typename?: 'Query';
  genaiFixSpelling: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiFollowUpContinueWritingQueryVariables = Exact<{
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiFollowUpContinueWritingQuery = {
  __typename?: 'Query';
  genaiFollowUpContinueWriting: {
    __typename?: 'GenAiPromptResponse';
    response: string;
  };
};

export type GenaiFollowUpCustomQueryVariables = Exact<{
  contentInput?: InputMaybe<Scalars['String']['input']>;
  customPrompt: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiFollowUpCustomQuery = {
  __typename?: 'Query';
  genaiFollowUpCustom: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiFollowUpMakeLongerQueryVariables = Exact<{
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiFollowUpMakeLongerQuery = {
  __typename?: 'Query';
  genaiFollowUpMakeLonger: {
    __typename?: 'GenAiPromptResponse';
    response: string;
  };
};

export type GenaiFollowUpTryAgainQueryVariables = Exact<{
  contentInput?: InputMaybe<Scalars['String']['input']>;
  focusTerm: Scalars['String']['input'];
  initial: FollowUpInitialInput;
  previousFollowUps?: InputMaybe<Array<FollowUpInput>>;
  promptInput?: InputMaybe<Scalars['String']['input']>;
  tone?: InputMaybe<Tone>;
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiFollowUpTryAgainQuery = {
  __typename?: 'Query';
  genaiFollowUpTryAgain: {
    __typename?: 'GenAiPromptResponse';
    response: string;
  };
};

export type GenaiImproveWritingQueryVariables = Exact<{
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiImproveWritingQuery = {
  __typename?: 'Query';
  genaiImproveWriting: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiMakeLongerQueryVariables = Exact<{
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiMakeLongerQuery = {
  __typename?: 'Query';
  genaiMakeLonger: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GenaiMakeShorterQueryVariables = Exact<{
  contentInput: Scalars['String']['input'];
  focusTerm: Scalars['String']['input'];
  topicModelTerms: Array<Scalars['String']['input']>;
}>;

export type GenaiMakeShorterQuery = {
  __typename?: 'Query';
  genaiMakeShorter: { __typename?: 'GenAiPromptResponse'; response: string };
};

export type GetAppDataQueryVariables = Exact<{
  app: Scalars['String']['input'];
  params: AppDataParamsInput;
  siteId: Scalars['String']['input'];
}>;

export type GetAppDataQuery = {
  __typename?: 'Query';
  getAppData: {
    __typename?: 'GetAppDataResponse';
    kg?: { [key: string]: any } | null;
    kgNews?: { [key: string]: any } | null;
    keywords?: { [key: string]: any } | null;
    scores?: { [key: string]: any } | null;
    scoresNews?: { [key: string]: any } | null;
    compareKgsNews?: { [key: string]: any } | null;
    lr?: { [key: string]: any } | null;
    qts?: { [key: string]: any } | null;
    appRuns: {
      __typename?: 'AppRuns';
      current: number;
      error?: string | null;
      extra?: number | null;
      lastReset?: Date | null;
      limit?: number | null;
      nextReset?: Date | null;
      resetInterval?: any | null;
    };
    params: {
      __typename?: 'AppDataParams';
      bodyOrArticle?: string | null;
      brands?: boolean | null;
      country: string;
      language?: string | null;
      people?: boolean | null;
      topic: string;
      url?: string | null;
    };
  };
};

export type GetSitesByOrgQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetSitesByOrgQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    sites?: Array<{
      __typename?: 'Site';
      valuePerVisit?: number | null;
      subdomains?: Array<string> | null;
      excludePaths?: Array<string> | null;
      includePaths?: Array<string> | null;
      autoUpdate?: boolean | null;
      recommendedTopics?: number | null;
      topicsAllocated?: number | null;
      topicsInUse?: number | null;
      appRuns?: number | null;
      appRunsExtra?: number | null;
      appRunsLastReset?: Date | null;
      appRunsLimit?: number | null;
      appRunsResetInterval?: any | null;
      appSumoCodes?: Array<string> | null;
      briefCredits?: number | null;
      cancelled: boolean;
      cancelledAt?: Date | null;
      competitorDomains?: Array<string> | null;
      createdAt?: Date | null;
      defaultSerpCountry?: string | null;
      displayContentData?: boolean | null;
      domain: string;
      dunning?: boolean | null;
      dunningCardUpdated?: boolean | null;
      dunningExpired?: boolean | null;
      id: string;
      invId?: number | null;
      level: SiteLevel;
      networkDomains?: Array<string> | null;
      title?: string | null;
      updatedAt?: Date | null;
      visible: boolean;
      documentBriefUserInput?: {
        __typename?: 'DocumentBriefUserInput';
        buyerOrInformationJourney?: string | null;
        contextLinguistic?: string | null;
        expertise?: string | null;
        factStatements?: string | null;
        intentAnalysis?: string | null;
        marketingAndMessagingReferences?: Array<string> | null;
        personalExperience?: string | null;
        personalizationDetail?: string | null;
        pointOfView?: string | null;
        proofStatements?: string | null;
        structuring?: string | null;
        style?: string | null;
        targetMarket?: string | null;
        tone?: string | null;
        voice?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type InventoryItemQueryVariables = Exact<{
  invItemInput: GetInventoryItemInput;
}>;

export type InventoryItemQuery = {
  __typename?: 'Query';
  inventoryItem: {
    __typename?: 'InventoryItem';
    key: string;
    type: InventoryItemType;
    page?: {
      __typename?: 'Page';
      authority?: number | null;
      avgMmCompetitiveAdvantage?: number | null;
      avgMmDifficulty?: number | null;
      avgMmPersonalizedAdvantage?: number | null;
      avgPersonalizedMmDifficulty?: number | null;
      createdAt?: Date | null;
      externalContentLinkCount?: number | null;
      externalContentLinks?: string | null;
      hiddenByCs?: boolean | null;
      hiddenByUser?: boolean | null;
      id?: number | null;
      internalContentLinkCount?: number | null;
      internalContentLinks?: string | null;
      maxRank?: number | null;
      minRank?: number | null;
      numTopics?: number | null;
      potentialTraffic?: number | null;
      potentialTrafficUpside?: number | null;
      potentialTrafficValue?: number | null;
      source?: string | null;
      sumVolume?: number | null;
      title?: string | null;
      traffic?: number | null;
      trafficDelta?: number | null;
      trafficDeltaPct?: number | null;
      trafficValue?: number | null;
      trafficValueDelta?: number | null;
      updatedAt?: Date | null;
      url?: string | null;
      urlHash?: string | null;
      versionTrafficPct?: number | null;
      versionTrafficValuePct?: number | null;
    } | null;
    topic?: {
      __typename?: 'Topic';
      avgPageAuthority?: number | null;
      competition?: number | null;
      difficulty?: number | null;
      hiddenByCs?: boolean | null;
      hiddenByUser?: boolean | null;
      id?: number | null;
      maxRank?: number | null;
      mmDifficulty?: number | null;
      mmPersonalizedAdvantage?: number | null;
      mmPersonalizedDifficulty?: number | null;
      monthlyTrends?: string | null;
      numPages?: number | null;
      potentialTraffic?: number | null;
      potentialTrafficUpside?: number | null;
      potentialTrafficValue?: number | null;
      serpFeatures?: string | null;
      serpFeaturesSet?: Array<string> | null;
      serpFeaturesTrafficShare?: number | null;
      source?: string | null;
      sumExternalContentLinks?: number | null;
      sumInternalContentLinks?: number | null;
      term?: string | null;
      topRankingPage?: string | null;
      traffic?: number | null;
      trafficDelta?: number | null;
      trafficDeltaPct?: number | null;
      trafficValue?: number | null;
      trafficValueDelta?: number | null;
      trafficValueDeltaPct?: number | null;
      versionTrafficPct?: number | null;
      versionTrafficValuePct?: number | null;
      volume?: number | null;
    } | null;
    pageTopic?: {
      __typename?: 'PageTopic';
      hiddenByCs?: boolean | null;
      hiddenByUser?: boolean | null;
      id?: number | null;
      pageId?: number | null;
      potentialTraffic?: number | null;
      potentialTrafficUpside?: number | null;
      potentialTrafficValue?: number | null;
      prePriority?: number | null;
      rank?: number | null;
      rankDelta?: number | null;
      rankDeltaPotential?: number | null;
      topicId?: number | null;
      traffic?: number | null;
      trafficDelta?: number | null;
      trafficDeltaPct?: number | null;
      trafficValue?: number | null;
      trafficValueDelta?: number | null;
      trafficValueDeltaPct?: number | null;
      versionId?: number | null;
      versionTrafficPct?: number | null;
      versionTrafficValuePct?: number | null;
    } | null;
  };
};

export type InventoryItemsPageTopicsQueryVariables = Exact<{
  filters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  isManualQuery?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  navigationFilters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
}>;

export type InventoryItemsPageTopicsQuery = {
  __typename?: 'Query';
  inventoryItems: {
    __typename?: 'InventoryItemsResponse';
    count: number;
    items: Array<{
      __typename?: 'InventoryItem';
      key: string;
      type: InventoryItemType;
      page?: {
        __typename?: 'Page';
        authority?: number | null;
        avgMmCompetitiveAdvantage?: number | null;
        avgMmDifficulty?: number | null;
        avgMmPersonalizedAdvantage?: number | null;
        avgPersonalizedMmDifficulty?: number | null;
        createdAt?: Date | null;
        externalContentLinkCount?: number | null;
        externalContentLinks?: string | null;
        hiddenByCs?: boolean | null;
        hiddenByUser?: boolean | null;
        id?: number | null;
        internalContentLinkCount?: number | null;
        internalContentLinks?: string | null;
        maxRank?: number | null;
        minRank?: number | null;
        numTopics?: number | null;
        potentialTraffic?: number | null;
        potentialTrafficUpside?: number | null;
        potentialTrafficValue?: number | null;
        source?: string | null;
        sumVolume?: number | null;
        title?: string | null;
        traffic?: number | null;
        trafficDelta?: number | null;
        trafficDeltaPct?: number | null;
        trafficValue?: number | null;
        trafficValueDelta?: number | null;
        updatedAt?: Date | null;
        url?: string | null;
        urlHash?: string | null;
        versionTrafficPct?: number | null;
        versionTrafficValuePct?: number | null;
      } | null;
      topic?: {
        __typename?: 'Topic';
        avgPageAuthority?: number | null;
        competition?: number | null;
        difficulty?: number | null;
        hiddenByCs?: boolean | null;
        hiddenByUser?: boolean | null;
        id?: number | null;
        maxRank?: number | null;
        mmDifficulty?: number | null;
        mmPersonalizedAdvantage?: number | null;
        mmPersonalizedDifficulty?: number | null;
        monthlyTrends?: string | null;
        numPages?: number | null;
        potentialTraffic?: number | null;
        potentialTrafficUpside?: number | null;
        potentialTrafficValue?: number | null;
        serpFeatures?: string | null;
        serpFeaturesSet?: Array<string> | null;
        serpFeaturesTrafficShare?: number | null;
        source?: string | null;
        sumExternalContentLinks?: number | null;
        sumInternalContentLinks?: number | null;
        term?: string | null;
        topRankingPage?: string | null;
        traffic?: number | null;
        trafficDelta?: number | null;
        trafficDeltaPct?: number | null;
        trafficValue?: number | null;
        trafficValueDelta?: number | null;
        trafficValueDeltaPct?: number | null;
        versionTrafficPct?: number | null;
        versionTrafficValuePct?: number | null;
        volume?: number | null;
      } | null;
      pageTopic?: {
        __typename?: 'PageTopic';
        hiddenByCs?: boolean | null;
        hiddenByUser?: boolean | null;
        id?: number | null;
        pageId?: number | null;
        potentialTraffic?: number | null;
        potentialTrafficUpside?: number | null;
        potentialTrafficValue?: number | null;
        prePriority?: number | null;
        rank?: number | null;
        rankDelta?: number | null;
        rankDeltaPotential?: number | null;
        topicId?: number | null;
        traffic?: number | null;
        trafficDelta?: number | null;
        trafficDeltaPct?: number | null;
        trafficValue?: number | null;
        trafficValueDelta?: number | null;
        trafficValueDeltaPct?: number | null;
        versionId?: number | null;
        versionTrafficPct?: number | null;
        versionTrafficValuePct?: number | null;
      } | null;
    }>;
  };
};

export type InventoryItemsPagesQueryVariables = Exact<{
  filters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  isManualQuery?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  navigationFilters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
}>;

export type InventoryItemsPagesQuery = {
  __typename?: 'Query';
  inventoryItems: {
    __typename?: 'InventoryItemsResponse';
    count: number;
    items: Array<{
      __typename?: 'InventoryItem';
      key: string;
      type: InventoryItemType;
      page?: {
        __typename?: 'Page';
        topValuedTopic?: string | null;
        authority?: number | null;
        avgMmCompetitiveAdvantage?: number | null;
        avgMmDifficulty?: number | null;
        avgMmPersonalizedAdvantage?: number | null;
        avgPersonalizedMmDifficulty?: number | null;
        createdAt?: Date | null;
        externalContentLinkCount?: number | null;
        externalContentLinks?: string | null;
        hiddenByCs?: boolean | null;
        hiddenByUser?: boolean | null;
        id?: number | null;
        internalContentLinkCount?: number | null;
        internalContentLinks?: string | null;
        maxRank?: number | null;
        minRank?: number | null;
        numTopics?: number | null;
        potentialTraffic?: number | null;
        potentialTrafficUpside?: number | null;
        potentialTrafficValue?: number | null;
        source?: string | null;
        sumVolume?: number | null;
        title?: string | null;
        traffic?: number | null;
        trafficDelta?: number | null;
        trafficDeltaPct?: number | null;
        trafficValue?: number | null;
        trafficValueDelta?: number | null;
        updatedAt?: Date | null;
        url?: string | null;
        urlHash?: string | null;
        versionTrafficPct?: number | null;
        versionTrafficValuePct?: number | null;
      } | null;
    }>;
  };
};

export type InventoryItemsProjectsQueryVariables = Exact<{
  filters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  isManualQuery?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  navigationFilters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
}>;

export type InventoryItemsProjectsQuery = {
  __typename?: 'Query';
  inventoryItems: {
    __typename?: 'InventoryItemsResponse';
    count: number;
    items: Array<{
      __typename?: 'InventoryItem';
      key: string;
      type: InventoryItemType;
      page?: { __typename?: 'Page'; url?: string | null } | null;
      topic?: { __typename?: 'Topic'; term?: string | null } | null;
      project?: {
        __typename?: 'Project';
        briefCreatedAt?: Date | null;
        briefId?: string | null;
        briefSelfServe?: boolean | null;
        briefStatus?: BriefStatus | null;
        briefStatusError?: string | null;
        briefStatusLastSynced?: Date | null;
        createdAt?: Date | null;
        dueDate?: Date | null;
        id: string;
        notes?: string | null;
        publishedOn?: Date | null;
        topic?: string | null;
        updatedAt?: Date | null;
        url?: string | null;
        writingCreatedAt?: Date | null;
        writingReference?: string | null;
        writingUpdatedAt?: Date | null;
        assignee?: {
          __typename?: 'User';
          id: string;
          email: string;
          fullName?: string | null;
          createdAt?: Date | null;
          updatedAt?: Date | null;
        } | null;
        plan?: { __typename?: 'Plan'; id: string; title: string } | null;
        site?: { __typename?: 'Site'; id: string } | null;
      } | null;
    }>;
  };
};

export type InventoryItemsTopicsQueryVariables = Exact<{
  filters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  isManualQuery?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  navigationFilters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
}>;

export type InventoryItemsTopicsQuery = {
  __typename?: 'Query';
  inventoryItems: {
    __typename?: 'InventoryItemsResponse';
    count: number;
    items: Array<{
      __typename?: 'InventoryItem';
      key: string;
      type: InventoryItemType;
      topic?: {
        __typename?: 'Topic';
        topRankingPage?: string | null;
        avgPageAuthority?: number | null;
        competition?: number | null;
        difficulty?: number | null;
        hiddenByCs?: boolean | null;
        hiddenByUser?: boolean | null;
        id?: number | null;
        maxRank?: number | null;
        mmDifficulty?: number | null;
        mmPersonalizedAdvantage?: number | null;
        mmPersonalizedDifficulty?: number | null;
        monthlyTrends?: string | null;
        numPages?: number | null;
        potentialTraffic?: number | null;
        potentialTrafficUpside?: number | null;
        potentialTrafficValue?: number | null;
        serpFeatures?: string | null;
        serpFeaturesSet?: Array<string> | null;
        serpFeaturesTrafficShare?: number | null;
        source?: string | null;
        sumExternalContentLinks?: number | null;
        sumInternalContentLinks?: number | null;
        term?: string | null;
        traffic?: number | null;
        trafficDelta?: number | null;
        trafficDeltaPct?: number | null;
        trafficValue?: number | null;
        trafficValueDelta?: number | null;
        trafficValueDeltaPct?: number | null;
        versionTrafficPct?: number | null;
        versionTrafficValuePct?: number | null;
        volume?: number | null;
      } | null;
    }>;
  };
};

export type InventoryPageSuggestionsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  sort?: InputMaybe<InventorySortFields>;
}>;

export type InventoryPageSuggestionsQuery = {
  __typename?: 'Query';
  inventoryItems: {
    __typename?: 'InventoryItemsResponse';
    count: number;
    items: Array<{
      __typename?: 'InventoryItem';
      key: string;
      type: InventoryItemType;
      page?: {
        __typename?: 'Page';
        id?: number | null;
        title?: string | null;
        url?: string | null;
      } | null;
    }>;
  };
};

export type InventorySeoTopicsQueryVariables = Exact<{
  terms: Array<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InventorySeoTopicsQuery = {
  __typename?: 'Query';
  inventoryItems: {
    __typename?: 'InventoryItemsResponse';
    count: number;
    items: Array<{
      __typename?: 'InventoryItem';
      key: string;
      type: InventoryItemType;
      topic?: {
        __typename?: 'Topic';
        term?: string | null;
        topRankingPage?: string | null;
        topRankingPageTitle?: string | null;
        maxRank?: number | null;
        mmPersonalizedDifficulty?: number | null;
        mmPersonalizedAdvantage?: number | null;
      } | null;
    }>;
  };
};

export type InventorySeoPageTopicQueryVariables = Exact<{
  invItemInput: GetInventoryItemInput;
}>;

export type InventorySeoPageTopicQuery = {
  __typename?: 'Query';
  inventoryItem: {
    __typename?: 'InventoryItem';
    key: string;
    type: InventoryItemType;
    pageTopic?: { __typename?: 'PageTopic'; rank?: number | null } | null;
  };
};

export type InventorySubdomainsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
  domain: Scalars['String']['input'];
}>;

export type InventorySubdomainsQuery = {
  __typename?: 'Query';
  inventorySubdomains: Array<string | null>;
};

export type GetInventoryVersionQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type GetInventoryVersionQuery = {
  __typename?: 'Query';
  inventoryVersion: {
    __typename?: 'InventoryVersion';
    id: number;
    name: string;
    visible?: number | null;
    status?: VersionStatusEnum | null;
    statusUpdatedAt?: Date | null;
    traffic?: number | null;
    trafficDelta?: number | null;
    trafficDeltaPct?: number | null;
    trafficValue?: number | null;
    trafficValueDelta?: number | null;
    trafficValueDeltaPct?: number | null;
    failed?: number | null;
  };
};

export type GetInventoryVersionsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
  startTime: Scalars['DateTimeWithUnixTimeStamp']['input'];
}>;

export type GetInventoryVersionsQuery = {
  __typename?: 'Query';
  inventoryVersions: Array<{
    __typename?: 'InventoryVersion';
    id: number;
    name: string;
    visible?: number | null;
    status?: VersionStatusEnum | null;
    statusUpdatedAt?: Date | null;
    traffic?: number | null;
    trafficDelta?: number | null;
    trafficDeltaPct?: number | null;
    trafficValue?: number | null;
    trafficValueDelta?: number | null;
    trafficValueDeltaPct?: number | null;
    failed?: number | null;
  } | null>;
};

export type GetOrgQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetOrgQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    address?: string | null;
    createdAt?: Date | null;
    clientTier?: ClientTier | null;
    defaultForm?: string | null;
    headerFont?: string | null;
    highlightColor?: string | null;
    id: string;
    name: string;
    phone?: string | null;
    recurlyAccountCode?: string | null;
    recurlyAccountCodeTest?: string | null;
    sfdcAccountId?: string | null;
    slug: string;
    updatedAt?: Date | null;
    topicsMax?: number | null;
    sites?: Array<{
      __typename?: 'Site';
      briefCredits?: number | null;
      cancelled: boolean;
      displayContentData?: boolean | null;
      domain: string;
      id: string;
      invId?: number | null;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    }> | null;
    users?: Array<{
      __typename?: 'User';
      createdAt?: Date | null;
      defaultApplicationSerpCountry?: string | null;
      displayExactDistribution?: boolean | null;
      email: string;
      firstName?: string | null;
      fullName?: string | null;
      id: string;
      lastName?: string | null;
      onboardingProgress?: { [key: string]: any } | null;
      onboardingStep?: string | null;
      orgRole?: string | null;
      phone?: string | null;
      shouldReceiveBriefOrderedNotifications?: boolean | null;
      suspended?: boolean | null;
      sfdcContactId?: string | null;
      updatedAt?: Date | null;
    }> | null;
    subscription?: { __typename?: 'SubscriptionEntity'; id: string } | null;
    documentAllotment?: {
      __typename?: 'DocumentsAllotment';
      analysisPlanning: {
        __typename?: 'DocumentsAllotmentInfo';
        created: number;
        total: number;
      };
      briefs: {
        __typename?: 'DocumentsAllotmentInfo';
        created: number;
        total: number;
      };
      resetDate: { __typename?: 'ResetDate'; last: Date; next: Date };
    } | null;
    primaryContact?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  } | null;
};

export type OrganizationCountsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type OrganizationCountsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    maxUsers?: number | null;
    documentAllotment?: {
      __typename?: 'DocumentsAllotment';
      analysisPlanning: {
        __typename?: 'DocumentsAllotmentInfo';
        created: number;
        total: number;
      };
      briefs: {
        __typename?: 'DocumentsAllotmentInfo';
        created: number;
        total: number;
      };
      resetDate: { __typename?: 'ResetDate'; last: Date; next: Date };
    } | null;
    sites?: Array<{
      __typename?: 'Site';
      topicsAllocated?: number | null;
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    }> | null;
  } | null;
};

export type GetOrgsQueryVariables = Exact<{
  filters?: InputMaybe<Array<OrganizationFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetOrgsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrganizationsResponse';
    count: number;
    items: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
      createdAt?: Date | null;
      selfReportOrdering?: boolean | null;
      sfdcAccountId?: string | null;
      users?: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      }> | null;
      sites?: Array<{
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      }> | null;
    }>;
  };
};

export type PermissionsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type PermissionsQuery = {
  __typename?: 'Query';
  permissions: Array<{
    __typename?: 'Permission';
    resource: PermissionResource;
    action: PermissionAction;
    status: PermissionStatus;
  }>;
};

export type PermissionsEntityAdminQueryVariables = Exact<{
  entityId: Scalars['String']['input'];
  scope: PermissionScope;
}>;

export type PermissionsEntityAdminQuery = {
  __typename?: 'Query';
  permissionsEntityAdmin: {
    __typename?: 'PermissionsEntityAdmin';
    permissions: Array<{
      __typename?: 'Permission';
      resource: PermissionResource;
      action: PermissionAction;
      status: PermissionStatus;
    }>;
    resourceActionPairs: Array<{
      __typename?: 'ResourceActionPair';
      resource: PermissionResource;
      action: PermissionAction;
    }>;
  };
};

export type GetPlansQueryVariables = Exact<{
  filters?: InputMaybe<Array<PlanFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPlansQuery = {
  __typename?: 'Query';
  plans: Array<{
    __typename?: 'Plan';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title: string;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
  } | null>;
};

export type PlansAndProjectsQueryVariables = Exact<{
  filters?: InputMaybe<Array<PlanFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PlansAndProjectsQuery = {
  __typename?: 'Query';
  plans: Array<{
    __typename?: 'Plan';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title: string;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
    projects?: Array<{
      __typename?: 'Project';
      id: string;
      topic?: string | null;
    }> | null;
  } | null>;
};

export type GetProjectQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    writing?: string | null;
    briefCreatedAt?: Date | null;
    briefId?: string | null;
    briefSelfServe?: boolean | null;
    briefStatus?: BriefStatus | null;
    briefStatusError?: string | null;
    briefStatusLastSynced?: Date | null;
    createdAt?: Date | null;
    dueDate?: Date | null;
    id: string;
    notes?: string | null;
    publishedOn?: Date | null;
    topic?: string | null;
    updatedAt?: Date | null;
    url?: string | null;
    writingCreatedAt?: Date | null;
    writingReference?: string | null;
    writingUpdatedAt?: Date | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    plan?: { __typename?: 'Plan'; id: string; title: string } | null;
    site?: { __typename?: 'Site'; id: string } | null;
  } | null;
};

export type GetProjectsQueryVariables = Exact<{
  filters?: InputMaybe<Array<ProjectFilter>>;
  order?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects: Array<{
    __typename?: 'Project';
    briefStatus?: BriefStatus | null;
    briefSelfServe?: boolean | null;
    createdAt?: Date | null;
    writingReference?: string | null;
    writingUpdatedAt?: Date | null;
    briefId?: string | null;
    dueDate?: Date | null;
    id: string;
    publishedOn?: Date | null;
    topic?: string | null;
    url?: string | null;
    site?: { __typename?: 'Site'; id: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    plan?: { __typename?: 'Plan'; title: string } | null;
  } | null>;
};

export type GetProjectsByBriefIdQueryVariables = Exact<{
  briefId: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
}>;

export type GetProjectsByBriefIdQuery = {
  __typename?: 'Query';
  projects: Array<{
    __typename?: 'Project';
    briefCreatedAt?: Date | null;
    briefId?: string | null;
    briefSelfServe?: boolean | null;
    briefStatus?: BriefStatus | null;
    briefStatusError?: string | null;
    briefStatusLastSynced?: Date | null;
    createdAt?: Date | null;
    dueDate?: Date | null;
    id: string;
    notes?: string | null;
    publishedOn?: Date | null;
    topic?: string | null;
    updatedAt?: Date | null;
    url?: string | null;
    writingCreatedAt?: Date | null;
    writingReference?: string | null;
    writingUpdatedAt?: Date | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    plan?: { __typename?: 'Plan'; id: string; title: string } | null;
    site?: { __typename?: 'Site'; id: string } | null;
  } | null>;
};

export type RecurlyGetAccountQueryVariables = Exact<{
  accountCode: Scalars['String']['input'];
}>;

export type RecurlyGetAccountQuery = {
  __typename?: 'Query';
  recurlyGetAccount: {
    __typename?: 'Account';
    billTo?: string | null;
    ccEmails?: string | null;
    code: string;
    id: string;
    company: string;
    firstName?: string | null;
    email?: string | null;
    lastName?: string | null;
    createdAt?: Date | null;
    deletedAt?: Date | null;
    dunningCampaignId?: string | null;
    exemptionCertificate?: string | null;
    hasActiveSubscription?: boolean | null;
    hasCanceledSubscription?: boolean | null;
    hasFutureSubscription?: boolean | null;
    hasLiveSubscription?: boolean | null;
    hasPastDueInvoice?: boolean | null;
    hasPausedSubscription?: boolean | null;
    object?: string | null;
    parentAccountId?: string | null;
    preferredLocale?: string | null;
    state?: string | null;
    taxExempt?: boolean | null;
    updatedAt?: Date | null;
    username?: string | null;
    vatNumber?: string | null;
    address?: {
      __typename?: 'Address';
      city?: string | null;
      country?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
    } | null;
    billingInfo?: {
      __typename?: 'BillingInfo';
      accountId?: string | null;
      id?: string | null;
      company?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      backupPaymentMethod?: boolean | null;
      primaryPaymentMethod?: boolean | null;
      object?: string | null;
      valid?: boolean | null;
      vatNumber?: string | null;
      updatedAt?: Date | null;
      createdAt?: Date | null;
      address?: {
        __typename?: 'Address';
        city?: string | null;
        country?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      paymentMethod?: {
        __typename?: 'PaymentMethod';
        cardType?: string | null;
        expMonth?: number | null;
        expYear?: number | null;
        firstSix?: string | null;
        gatewayCode?: string | null;
        gatewayToken?: string | null;
        lastFour?: string | null;
        lastTwo?: string | null;
        nameOnAccount?: string | null;
        routingNumber?: string | null;
        routingNumberBank?: string | null;
      } | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      name?: string | null;
      value?: string | null;
    }> | null;
  };
};

export type RecurlyGetCouponQueryVariables = Exact<{
  couponId: Scalars['String']['input'];
}>;

export type RecurlyGetCouponQuery = {
  __typename?: 'Query';
  recurlyGetCoupon: {
    __typename?: 'Coupon';
    appliesToAllItems: boolean;
    appliesToAllPlans: boolean;
    appliesToNonPlanCharges?: boolean | null;
    code: string;
    couponType: string;
    createdAt?: Date | null;
    duration?: string | null;
    expiredAt?: Date | null;
    hostedPageDescription?: string | null;
    id?: string | null;
    invoiceDescription?: string | null;
    maxRedemptions?: number | null;
    maxRedemptionsPerAccount?: number | null;
    name?: string | null;
    object?: string | null;
    redeemBy?: Date | null;
    redemptionResource?: string | null;
    state?: string | null;
    temporalAmount?: number | null;
    temporalUnit?: string | null;
    uniqueCodeTemplate?: string | null;
    uniqueCouponCode?: { [key: string]: any } | null;
    uniqueCouponCodesCount?: number | null;
    updatedAt?: Date | null;
    discount: {
      __typename?: 'CouponDiscount';
      percent?: number | null;
      type?: string | null;
      currencies?: Array<{
        __typename?: 'CouponDiscountPricing';
        amount?: number | null;
        currency?: string | null;
      }> | null;
      trial?: {
        __typename?: 'CouponDiscountTrial';
        length?: number | null;
        unit?: string | null;
      } | null;
    };
    items?: {
      __typename?: 'ItemMini';
      code?: string | null;
      description?: string | null;
      id?: string | null;
      name?: string | null;
      object?: string | null;
      state?: string | null;
    } | null;
    plans?: Array<{
      __typename?: 'PlanMini';
      code?: string | null;
      id?: string | null;
      name?: string | null;
      object?: string | null;
    }> | null;
  };
};

export type RecurlyGetCreditInvoicesQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type RecurlyGetCreditInvoicesQuery = {
  __typename?: 'Query';
  recurlyGetCreditInvoices: Array<{
    __typename?: 'Invoice';
    total?: number | null;
    tax?: number | null;
    account?: { __typename?: 'AccountMini'; code?: string | null } | null;
    lineItems?: Array<{
      __typename?: 'LineItem';
      amount?: number | null;
      currency?: string | null;
      description?: string | null;
      invoiceNumber?: string | null;
      subtotal?: number | null;
      unitAmount?: number | null;
      quantity?: number | null;
      createdAt?: Date | null;
      endDate?: Date | null;
    }> | null;
  }>;
};

export type RecurlyGetRedemptionsQueryVariables = Exact<{
  accountCode: Scalars['String']['input'];
}>;

export type RecurlyGetRedemptionsQuery = {
  __typename?: 'Query';
  recurlyGetRedemptions: Array<{
    __typename?: 'CouponRedemption';
    createdAt?: Date | null;
    currency?: string | null;
    discounted?: number | null;
    id?: string | null;
    object?: string | null;
    removedAt?: Date | null;
    state?: string | null;
    subscriptionId?: string | null;
    updatedAt?: Date | null;
    account?: {
      __typename?: 'AccountMini';
      billTo?: string | null;
      code?: string | null;
      company?: string | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
    } | null;
    coupon?: {
      __typename?: 'Coupon';
      appliesToAllItems: boolean;
      appliesToAllPlans: boolean;
      appliesToNonPlanCharges?: boolean | null;
      code: string;
      couponType: string;
      createdAt?: Date | null;
      duration?: string | null;
      expiredAt?: Date | null;
      hostedPageDescription?: string | null;
      id?: string | null;
      invoiceDescription?: string | null;
      maxRedemptions?: number | null;
      maxRedemptionsPerAccount?: number | null;
      name?: string | null;
      object?: string | null;
      redeemBy?: Date | null;
      redemptionResource?: string | null;
      state?: string | null;
      temporalAmount?: number | null;
      temporalUnit?: string | null;
      uniqueCodeTemplate?: string | null;
      uniqueCouponCode?: { [key: string]: any } | null;
      uniqueCouponCodesCount?: number | null;
      updatedAt?: Date | null;
      discount: {
        __typename?: 'CouponDiscount';
        percent?: number | null;
        type?: string | null;
        currencies?: Array<{
          __typename?: 'CouponDiscountPricing';
          amount?: number | null;
          currency?: string | null;
        }> | null;
        trial?: {
          __typename?: 'CouponDiscountTrial';
          length?: number | null;
          unit?: string | null;
        } | null;
      };
      items?: {
        __typename?: 'ItemMini';
        code?: string | null;
        description?: string | null;
        id?: string | null;
        name?: string | null;
        object?: string | null;
        state?: string | null;
      } | null;
      plans?: Array<{
        __typename?: 'PlanMini';
        code?: string | null;
        id?: string | null;
        name?: string | null;
        object?: string | null;
      }> | null;
    } | null;
  }>;
};

export type RecurlyGetSubscriptionsQueryVariables = Exact<{
  accountCode: Scalars['String']['input'];
}>;

export type RecurlyGetSubscriptionsQuery = {
  __typename?: 'Query';
  recurlyGetSubscriptions: Array<{
    __typename?: 'RecurlySubscription';
    activatedAt?: Date | null;
    addOnsTotal?: number | null;
    autoRenew?: boolean | null;
    bankAccountAuthorizedAt?: Date | null;
    billingInfoId?: string | null;
    canceledAt?: Date | null;
    collectionMethod?: string | null;
    createdAt?: Date | null;
    currency?: string | null;
    currentPeriodEndsAt?: Date | null;
    currentPeriodStartedAt?: Date | null;
    currentTermEndsAt?: Date | null;
    currentTermStartedAt?: Date | null;
    customerNotes?: string | null;
    expirationReason?: string | null;
    expiresAt?: Date | null;
    gatewayCode?: string | null;
    id?: string | null;
    netTerms?: number | null;
    object?: string | null;
    pausedAt?: Date | null;
    poNumber?: string | null;
    quantity?: number | null;
    remainingBillingCycles?: number | null;
    remainingPauseCycles?: number | null;
    renewalBillingCycles?: number | null;
    revenueScheduleType?: string | null;
    state?: string | null;
    subtotal?: number | null;
    tax?: number | null;
    termsAndConditions?: string | null;
    total?: number | null;
    totalBillingCycles?: number | null;
    trialEndsAt?: Date | null;
    trialStartedAt?: Date | null;
    unitAmount?: number | null;
    updatedAt?: Date | null;
    uuid: string;
    account?: {
      __typename?: 'AccountMini';
      code?: string | null;
      company?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    couponRedemptions?: Array<{
      __typename?: 'CouponRedemptionMini';
      createdAt?: Date | null;
      discounted?: number | null;
      id?: string | null;
      object?: string | null;
      state?: string | null;
      coupon?: {
        __typename?: 'CouponMini';
        code?: string | null;
        couponType?: string | null;
        expiredAt?: Date | null;
        id?: string | null;
        name?: string | null;
        object?: string | null;
        state?: string | null;
        discount?: {
          __typename?: 'CouponDiscount';
          percent?: number | null;
          type?: string | null;
          trial?: {
            __typename?: 'CouponDiscountTrial';
            length?: number | null;
            unit?: string | null;
          } | null;
          currencies?: Array<{
            __typename?: 'CouponDiscountPricing';
            amount?: number | null;
            currency?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
    addOns?: Array<{
      __typename?: 'RecurlySubscriptionAddon';
      id?: string | null;
      unitAmount?: number | null;
      quantity?: number | null;
      addOn?: {
        __typename?: 'AddOnMini';
        accountingCode?: string | null;
        addOnType?: string | null;
        code?: string | null;
        externalSku?: string | null;
        id?: string | null;
      } | null;
    }> | null;
    plan: {
      __typename?: 'PlanMini';
      code?: string | null;
      id?: string | null;
      name?: string | null;
      object?: string | null;
    };
    customFields?: Array<{
      __typename?: 'CustomField';
      name?: string | null;
      value?: string | null;
    }> | null;
  }>;
};

export type RecurlyGetTransactionsQueryVariables = Exact<{
  accountCode: Scalars['String']['input'];
}>;

export type RecurlyGetTransactionsQuery = {
  __typename?: 'Query';
  recurlyGetTransactions: Array<{
    __typename?: 'Transaction';
    amount?: number | null;
    createdAt?: Date | null;
    success?: boolean | null;
    type?: string | null;
    status?: string | null;
    statusCode?: string | null;
    statusMessage?: string | null;
    subscriptionIds?: { [key: string]: any } | null;
    uuid: string;
    id: string;
    invoice?: {
      __typename?: 'InvoiceMini';
      id?: string | null;
      number?: string | null;
      state?: string | null;
      type?: string | null;
    } | null;
  }>;
};

export type ReportReadQueryVariables = Exact<{
  reportId: Scalars['String']['input'];
}>;

export type ReportReadQuery = {
  __typename?: 'Query';
  readReport: {
    __typename?: 'Brief';
    briefStatus: BriefStatus;
    briefTitle?: string | null;
    competitorSites?: string | null;
    createdAt?: Date | null;
    cuid?: string | null;
    dueDate?: Date | null;
    errorStatus: BriefErrorStatus;
    focusTopic: string;
    formData?: { [key: string]: any } | null;
    formDraft?: boolean | null;
    formId?: string | null;
    id: string;
    reportType: BriefType;
    selfServe?: boolean | null;
    updatedAt?: Date | null;
    accountManager?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    csRep?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    qaAssignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    additionalTopics?: Array<{
      __typename?: 'BriefSubtopic';
      distribution?: number | null;
      id?: string | null;
      powerTopic?: boolean | null;
      semanticSimilarity?: number | null;
      suggestedDistribution?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    comments?: Array<{
      __typename?: 'BriefComment';
      createdBy?: string | null;
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    metadata?: {
      __typename?: 'BriefMetadata';
      id?: string | null;
      errors?: Array<{
        __typename?: 'BriefError';
        id?: string | null;
        message?: string | null;
      }> | null;
    } | null;
    org?: {
      __typename?: 'Organization';
      id: string;
      slug: string;
      name: string;
    } | null;
    page?: { __typename?: 'BriefPage'; id: string; url?: string | null } | null;
    questions?: Array<{
      __typename?: 'BriefQuestion';
      id?: string | null;
      semanticSimilarity?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    rankingTitles?: Array<{
      __typename?: 'BriefRankingTitle';
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    site?: {
      __typename?: 'Site';
      id: string;
      domain: string;
      level: SiteLevel;
    } | null;
    subheadingTerms?: Array<{
      __typename?: 'BriefSubheadingTerm';
      id?: string | null;
      semanticSimilarity?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    subheadings?: Array<{
      __typename?: 'BriefSubheading';
      id?: string | null;
      position?: number | null;
      title?: string | null;
      value?: string | null;
      visible?: boolean | null;
      wordCount?: number | null;
      additionalTopics?: Array<{
        __typename?: 'BriefSubtopic';
        distribution?: number | null;
        id?: string | null;
        powerTopic?: boolean | null;
        semanticSimilarity?: number | null;
        suggestedDistribution?: number | null;
        value?: string | null;
        visible?: boolean | null;
      }> | null;
      comments?: Array<{
        __typename?: 'BriefComment';
        createdBy?: string | null;
        id?: string | null;
        value?: string | null;
        visible?: boolean | null;
      }> | null;
      externalLinks?: Array<{
        __typename?: 'BriefLinkList';
        id: string;
        semanticSimilarity?: number | null;
        visible?: boolean | null;
        links: Array<{
          __typename?: 'BriefLink';
          id: string;
          parent?: string | null;
          title?: string | null;
          url?: string | null;
          visible?: boolean | null;
        }>;
      }> | null;
      internalLinks?: Array<{
        __typename?: 'BriefLinkList';
        id: string;
        semanticSimilarity?: number | null;
        visible?: boolean | null;
        links: Array<{
          __typename?: 'BriefLink';
          id: string;
          parent?: string | null;
          title?: string | null;
          url?: string | null;
          visible?: boolean | null;
        }>;
      }> | null;
      questions?: Array<{
        __typename?: 'BriefQuestion';
        id?: string | null;
        semanticSimilarity?: number | null;
        value?: string | null;
        visible?: boolean | null;
      }> | null;
      relatedTopics?: Array<{
        __typename?: 'BriefRelatedTopic';
        distribution?: number | null;
        id: string;
        powerTopic?: boolean | null;
        semanticSimilarity?: number | null;
        suggestedDistribution?: number | null;
        value: string;
        visible?: boolean | null;
      }> | null;
      topic?: {
        __typename?: 'BriefSubheadingTopic';
        contentScoreAvg?: number | null;
        contentScoreTarget?: number | null;
        id: string;
        semanticSimilarity?: number | null;
        suggestedDistribution?: number | null;
        term: string;
        wordCountAvg?: number | null;
        wordCountTarget?: number | null;
      } | null;
    }> | null;
    subtopics?: Array<{
      __typename?: 'BriefSubtopic';
      distribution?: number | null;
      id?: string | null;
      powerTopic?: boolean | null;
      semanticSimilarity?: number | null;
      suggestedDistribution?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    titleTerms?: Array<{
      __typename?: 'BriefTitleTerm';
      id?: string | null;
      semanticSimilarity?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    topic?: {
      __typename?: 'BriefTopic';
      contentScoreAvg?: number | null;
      contentScoreTarget?: number | null;
      id: string;
      kgKey?: string | null;
      term: string;
      wordCountAvg?: number | null;
      wordCountTarget?: number | null;
    } | null;
    userAudiences?: Array<{
      __typename?: 'BriefUserAudience';
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    userIntents?: Array<{
      __typename?: 'BriefUserIntent';
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
  };
};

export type ReportsReadQueryVariables = Exact<{
  filters: BriefFilters;
}>;

export type ReportsReadQuery = {
  __typename?: 'Query';
  readReports: {
    __typename?: 'BriefsResponse';
    count: number;
    items: Array<{
      __typename?: 'Brief';
      briefStatus: BriefStatus;
      briefTitle?: string | null;
      competitorSites?: string | null;
      createdAt?: Date | null;
      cuid?: string | null;
      dueDate?: Date | null;
      errorStatus: BriefErrorStatus;
      focusTopic: string;
      formData?: { [key: string]: any } | null;
      formDraft?: boolean | null;
      formId?: string | null;
      id: string;
      reportType: BriefType;
      selfServe?: boolean | null;
      updatedAt?: Date | null;
      accountManager?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
      createdBy?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
      csRep?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
      qaAssignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
      page?: {
        __typename?: 'BriefPage';
        url?: string | null;
        id: string;
      } | null;
      topic?: { __typename?: 'BriefTopic'; id: string; term: string } | null;
      site?: {
        __typename?: 'Site';
        id: string;
        domain: string;
        level: SiteLevel;
      } | null;
      org?: { __typename?: 'Organization'; id: string; name: string } | null;
    }>;
  };
};

export type GetSavedViewsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
  filters?: InputMaybe<Array<SavedViewFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetSavedViewsQuery = {
  __typename?: 'Query';
  savedViews: Array<{
    __typename?: 'SavedView';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    description?: string | null;
    inventoryType: InventoryType;
    filters?: { [key: string]: any } | null;
    pinned?: { [key: string]: any } | null;
    hidden?: { [key: string]: any } | null;
    order?: { [key: string]: any } | null;
    sorting?: { [key: string]: any } | null;
    sharing?: SavedViewSharing | null;
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
    createdBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
    updatedBy?: {
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    } | null;
  }>;
};

export type GetSavedViewsEachQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
  each?: InputMaybe<Array<InventoryType>>;
  filters?: InputMaybe<Array<SavedViewFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetSavedViewsEachQuery = {
  __typename?: 'Query';
  savedViewsEach: Array<{
    __typename?: 'SavedViewsEachResItem';
    inventoryType: InventoryType;
    items: Array<{
      __typename?: 'SavedView';
      createdAt?: Date | null;
      updatedAt?: Date | null;
      id: string;
      title?: string | null;
      description?: string | null;
      inventoryType: InventoryType;
      filters?: { [key: string]: any } | null;
      pinned?: { [key: string]: any } | null;
      hidden?: { [key: string]: any } | null;
      order?: { [key: string]: any } | null;
      sorting?: { [key: string]: any } | null;
      sharing?: SavedViewSharing | null;
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
      createdBy?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
      updatedBy?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    }>;
  }>;
};

export type GetSeoAdwordsKeywordsQueryVariables = Exact<{
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
}>;

export type GetSeoAdwordsKeywordsQuery = {
  __typename?: 'Query';
  seoAdwordsKeywords: Array<{
    __typename?: 'AdWordsItem';
    term: string;
    costPerClick?: number | null;
    volume?: number | null;
    existsInInventory?: boolean | null;
    history?: Array<{
      __typename?: 'History';
      yearMonth?: string | null;
      volume?: number | null;
    }> | null;
  }>;
};

export type GetSeoAdwordsQuestionsQueryVariables = Exact<{
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
}>;

export type GetSeoAdwordsQuestionsQuery = {
  __typename?: 'Query';
  seoAdwordsQuestions: Array<{
    __typename?: 'AdWordsItem';
    term: string;
    costPerClick?: number | null;
    volume?: number | null;
    existsInInventory?: boolean | null;
    history?: Array<{
      __typename?: 'History';
      yearMonth?: string | null;
      volume?: number | null;
    }> | null;
  }>;
};

export type SeoBulkAdwordsKeywordsCompleteQueryVariables = Exact<{
  terms: Array<Scalars['String']['input']>;
  country: Country;
  language: Language;
}>;

export type SeoBulkAdwordsKeywordsCompleteQuery = {
  __typename?: 'Query';
  seoBulkAdwordsKeywords: Array<{
    __typename?: 'AdWordsItemWithVariants';
    term: string;
    volume?: number | null;
    costPerClick?: number | null;
    history?: Array<{
      __typename?: 'History';
      yearMonth?: string | null;
      volume?: number | null;
    }> | null;
    variants?: Array<{
      __typename?: 'AdWordsItem';
      term: string;
      volume?: number | null;
      costPerClick?: number | null;
    }> | null;
  }>;
};

export type SeoBulkAdwordsKeywordsMinimalQueryVariables = Exact<{
  terms: Array<Scalars['String']['input']>;
  country: Country;
  language: Language;
}>;

export type SeoBulkAdwordsKeywordsMinimalQuery = {
  __typename?: 'Query';
  seoBulkAdwordsKeywords: Array<{
    __typename?: 'AdWordsItemWithVariants';
    term: string;
    variants?: Array<{ __typename?: 'AdWordsItem'; term: string }> | null;
  }>;
};

export type SeoConnectQueryVariables = Exact<{
  term: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  country: Country;
  language: Language;
  competitorDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  networkDomains?: InputMaybe<Array<Scalars['String']['input']>>;
}>;

export type SeoConnectQuery = {
  __typename?: 'Query';
  seoConnect: {
    __typename?: 'SEOConnect';
    internalLinkingRecommendations?: Array<{
      __typename?: 'LinkingRecommendationForTerm';
      term: string;
      recommendations?: Array<{
        __typename?: 'LinkRecommendation';
        url: string;
        title: string;
      }> | null;
    }> | null;
    externalLinkingRecommendations?: Array<{
      __typename?: 'LinkingRecommendationForTerm';
      term: string;
      recommendations?: Array<{
        __typename?: 'LinkRecommendation';
        url: string;
        title: string;
      }> | null;
    }> | null;
    networkLinkingRecommendations?: Array<{
      __typename?: 'LinkingRecommendationForTerm';
      term: string;
      recommendations?: Array<{
        __typename?: 'LinkRecommendation';
        url: string;
        title: string;
      }> | null;
    }> | null;
    competitionLinkingRecommendations?: Array<{
      __typename?: 'LinkingRecommendationForTerm';
      term: string;
      recommendations?: Array<{
        __typename?: 'LinkRecommendation';
        url: string;
        title: string;
      }> | null;
    }> | null;
  };
};

export type SeoKnowledgeGraphCompleteQueryVariables = Exact<{
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  skipPageScrapping?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SeoKnowledgeGraphCompleteQuery = {
  __typename?: 'Query';
  seoKnowledgeGraph: {
    __typename?: 'KnowledgeGraph';
    knowledgeGraphItems?: Array<{
      __typename?: 'KnowledgeGraphItem';
      term: string;
      recommendedMentions?: number | null;
      userProvidedPageMentions?: number | null;
    }> | null;
    metrics: {
      __typename?: 'KGMetrics';
      targetWordCount?: number | null;
      averageWordCount?: number | null;
      highestWordCount?: number | null;
      targetContentScore?: number | null;
      averageContentScore?: number | null;
      highestContentScore?: number | null;
    };
    organicSerpItemPagesKgData?: Array<{
      __typename?: 'OrganicSerpItemPageKgData';
      title?: string | null;
      wordCount?: number | null;
      contentScore?: number | null;
      position?: number | null;
      url: string;
      mentions?: Array<{
        __typename?: 'MentionItem';
        term: string;
        count: number;
      }> | null;
    }> | null;
    userProvidedPage?: {
      __typename?: 'OrganicSerpItemPageKgData';
      title?: string | null;
      wordCount?: number | null;
      contentScore?: number | null;
      position?: number | null;
      url: string;
      mentions?: Array<{
        __typename?: 'MentionItem';
        term: string;
        count: number;
      }> | null;
    } | null;
  };
};

export type SeoKnowledgeGraphResearchQueryVariables = Exact<{
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  skipPageScrapping?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SeoKnowledgeGraphResearchQuery = {
  __typename?: 'Query';
  seoKnowledgeGraph: {
    __typename?: 'KnowledgeGraph';
    knowledgeGraphItems?: Array<{
      __typename?: 'KnowledgeGraphItem';
      recommendedMentions?: number | null;
      existsInInventory?: boolean | null;
      relevanceScore?: number | null;
      term: string;
      userProvidedPageMentions?: number | null;
    }> | null;
    metrics: {
      __typename?: 'KGMetrics';
      targetWordCount?: number | null;
      targetContentScore?: number | null;
      averageContentScore?: number | null;
      highestContentScore?: number | null;
    };
    organicSerpItemPagesKgData?: Array<{
      __typename?: 'OrganicSerpItemPageKgData';
      contentScore?: number | null;
      position?: number | null;
      url: string;
      mentions?: Array<{
        __typename?: 'MentionItem';
        term: string;
        count: number;
      }> | null;
    }> | null;
    userProvidedPage?: {
      __typename?: 'OrganicSerpItemPageKgData';
      contentScore?: number | null;
      position?: number | null;
      url: string;
      mentions?: Array<{
        __typename?: 'MentionItem';
        term: string;
        count: number;
      }> | null;
    } | null;
  };
};

export type SeoPageProcessorQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;

export type SeoPageProcessorQuery = {
  __typename?: 'Query';
  seoPageProcessor: {
    __typename?: 'PageProcessor';
    html?: string | null;
    statusCode?: number | null;
    url?: string | null;
  };
};

export type SeoSerpDataQueryVariables = Exact<{
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
  country: Country;
  language: Language;
}>;

export type SeoSerpDataQuery = {
  __typename?: 'Query';
  seoSerpData: {
    __typename?: 'SerpData';
    serpFeaturesPresent: Array<SerpItemType>;
    userProvidedPage?: {
      __typename?: 'OrganicSerpItem';
      url: string;
      position?: number | null;
      description?: string | null;
      title?: string | null;
    } | null;
    organicSerpItems: Array<{
      __typename?: 'OrganicSerpItem';
      url: string;
      position?: number | null;
      description?: string | null;
      title?: string | null;
    }>;
  };
};

export type SeoSerpPageDataQueryVariables = Exact<{
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  country: Country;
  language: Language;
}>;

export type SeoSerpPageDataQuery = {
  __typename?: 'Query';
  seoSerpPageData: {
    __typename?: 'SeoSerpPageResponse';
    userProvidedPage?: {
      __typename?: 'UserProvidedPage';
      externalLinkCount: number;
      description?: string | null;
      position?: number | null;
      title?: string | null;
      url?: string | null;
      h2Count: number;
      imagesCount: number;
      internalLinkCount: number;
      videoCount: number;
      wordCount: number;
    } | null;
    organicSerpItemPages?: Array<{
      __typename?: 'OrganicSerpItemPage';
      externalLinkCount: number;
      position?: number | null;
      url: string;
      h2Count: number;
      imagesCount: number;
      internalLinkCount: number;
      videoCount: number;
      wordCount: number;
    }> | null;
    organicSerpItemPageAverages?: {
      __typename?: 'OnPageMetrics';
      externalLinkCount: number;
      h2Count: number;
      imagesCount: number;
      internalLinkCount: number;
      videoCount: number;
      wordCount: number;
    } | null;
  };
};

export type GetSeoSerpQuestionsQueryVariables = Exact<{
  country: Country;
  language: Language;
  term: Scalars['String']['input'];
}>;

export type GetSeoSerpQuestionsQuery = {
  __typename?: 'Query';
  seoSerpQuestions: Array<{
    __typename?: 'AdWordsItem';
    term: string;
    costPerClick?: number | null;
    volume?: number | null;
    existsInInventory?: boolean | null;
    history?: Array<{
      __typename?: 'History';
      yearMonth?: string | null;
      volume?: number | null;
    }> | null;
  }>;
};

export type SeoSerpQuestionsOldQueryVariables = Exact<{
  term: Scalars['String']['input'];
  country: Country;
  language: Language;
}>;

export type SeoSerpQuestionsOldQuery = {
  __typename?: 'Query';
  seoSerpQuestionsOld: {
    __typename?: 'QuestionTitleSuggestions';
    topRankingTitles?: Array<string> | null;
    questions?: Array<{
      __typename?: 'QuestionServiceItem';
      term: string;
      similarity: number;
    }> | null;
    topRankingSubHeadings?: Array<{
      __typename?: 'QuestionServiceItem';
      term: string;
      similarity: number;
    }> | null;
    suggestedTitleTerms?: Array<{
      __typename?: 'QuestionServiceItem';
      term: string;
      similarity: number;
    }> | null;
    suggestedSubHeadingTerms?: Array<{
      __typename?: 'QuestionServiceItem';
      term: string;
      similarity: number;
    }> | null;
  };
};

export type SeoSerpUserIntentDataQueryVariables = Exact<{
  term: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
  country: Country;
  language: Language;
}>;

export type SeoSerpUserIntentDataQuery = {
  __typename?: 'Query';
  seoSerpUserIntentData: {
    __typename?: 'SerpUserIntentDataResponse';
    intentData: {
      __typename?: 'IntentData';
      primarySerpIntent?: Intent | null;
      intentBreakdown?: {
        __typename?: 'SerpIntentTopic';
        infoKnow: number;
        infoKnowSimple: number;
        infoComparison: number;
        transactional: number;
        local: number;
        websiteQuery: number;
      } | null;
    };
    userProvidedPage?: {
      __typename?: 'OrganicSerpItemWithIntent';
      url: string;
      intent?: Intent | null;
    } | null;
    organicSerpItemIntents: Array<{
      __typename?: 'OrganicSerpItemWithIntent';
      url: string;
      intent?: Intent | null;
    }>;
  };
};

export type SignInQueryVariables = Exact<{
  email: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SignInQuery = {
  __typename?: 'Query';
  signIn: {
    __typename?: 'SignInResponse';
    user: {
      __typename?: 'User';
      createdAt?: Date | null;
      defaultApplicationSerpCountry?: string | null;
      displayExactDistribution?: boolean | null;
      email: string;
      firstName?: string | null;
      fullName?: string | null;
      id: string;
      lastName?: string | null;
      onboardingProgress?: { [key: string]: any } | null;
      onboardingStep?: string | null;
      orgRole?: string | null;
      phone?: string | null;
      shouldReceiveBriefOrderedNotifications?: boolean | null;
      suspended?: boolean | null;
      sfdcContactId?: string | null;
      updatedAt?: Date | null;
    };
    org?: {
      __typename?: 'Organization';
      maxUsers?: number | null;
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      sites?: Array<{
        __typename?: 'Site';
        appRuns?: number | null;
        appRunsExtra?: number | null;
        appRunsLastReset?: Date | null;
        appRunsLimit?: number | null;
        appRunsResetInterval?: any | null;
        appSumoCodes?: Array<string> | null;
        briefCredits?: number | null;
        cancelled: boolean;
        cancelledAt?: Date | null;
        competitorDomains?: Array<string> | null;
        createdAt?: Date | null;
        defaultSerpCountry?: string | null;
        displayContentData?: boolean | null;
        domain: string;
        dunning?: boolean | null;
        dunningCardUpdated?: boolean | null;
        dunningExpired?: boolean | null;
        id: string;
        invId?: number | null;
        level: SiteLevel;
        networkDomains?: Array<string> | null;
        title?: string | null;
        updatedAt?: Date | null;
        visible: boolean;
        documentBriefUserInput?: {
          __typename?: 'DocumentBriefUserInput';
          buyerOrInformationJourney?: string | null;
          contextLinguistic?: string | null;
          expertise?: string | null;
          factStatements?: string | null;
          intentAnalysis?: string | null;
          marketingAndMessagingReferences?: Array<string> | null;
          personalExperience?: string | null;
          personalizationDetail?: string | null;
          pointOfView?: string | null;
          proofStatements?: string | null;
          structuring?: string | null;
          style?: string | null;
          targetMarket?: string | null;
          tone?: string | null;
          voice?: string | null;
        } | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        orgRole?: string | null;
        suspended?: boolean | null;
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      }> | null;
      documentAllotment?: {
        __typename?: 'DocumentsAllotment';
        analysisPlanning: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        briefs: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        resetDate: { __typename?: 'ResetDate'; last: Date; next: Date };
      } | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    } | null;
    site?: {
      __typename?: 'Site';
      appRuns?: number | null;
      appRunsExtra?: number | null;
      appRunsLastReset?: Date | null;
      appRunsLimit?: number | null;
      appRunsResetInterval?: any | null;
      appSumoCodes?: Array<string> | null;
      briefCredits?: number | null;
      cancelled: boolean;
      cancelledAt?: Date | null;
      competitorDomains?: Array<string> | null;
      createdAt?: Date | null;
      defaultSerpCountry?: string | null;
      displayContentData?: boolean | null;
      domain: string;
      dunning?: boolean | null;
      dunningCardUpdated?: boolean | null;
      dunningExpired?: boolean | null;
      id: string;
      invId?: number | null;
      level: SiteLevel;
      networkDomains?: Array<string> | null;
      title?: string | null;
      updatedAt?: Date | null;
      visible: boolean;
      documentBriefUserInput?: {
        __typename?: 'DocumentBriefUserInput';
        buyerOrInformationJourney?: string | null;
        contextLinguistic?: string | null;
        expertise?: string | null;
        factStatements?: string | null;
        intentAnalysis?: string | null;
        marketingAndMessagingReferences?: Array<string> | null;
        personalExperience?: string | null;
        personalizationDetail?: string | null;
        pointOfView?: string | null;
        proofStatements?: string | null;
        structuring?: string | null;
        style?: string | null;
        targetMarket?: string | null;
        tone?: string | null;
        voice?: string | null;
      } | null;
    } | null;
    subscription?: {
      __typename?: 'SubscriptionEntity';
      createdAt: Date;
      dunning?: boolean | null;
      endsOnDate: Date;
      id: string;
      externalSubscriptionId?: string | null;
      conversionMethod: SubscriptionConversionMethod;
      startedOnDate: Date;
      state: SubscriptionState;
      price?: number | null;
      demo?: boolean | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        active: boolean;
        appQueryCount: number;
        analysisAndPlanningDocumentAllocationLimit: number;
        briefDocumentAllocationLimit: number;
        id: string;
        planCode: string;
        price: number;
        term: SubscriptionPlanTerm;
        termLength: number;
        type: SubscriptionPlanType;
        userCanDowngradePackage: boolean;
        userCanUpgradePackage: boolean;
        usersArePurchasable: boolean;
        usersCountDefault?: number | null;
      };
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug: string;
        clientTier?: ClientTier | null;
      };
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
      addons?: Array<{
        __typename?: 'SubscriptionAddon';
        id: string;
        addonState?: SubscriptionAddonState | null;
        mode?: SubscriptionAddonMode | null;
        addonType: {
          __typename?: 'SubscriptionAddonType';
          id: string;
          description?: string | null;
          price?: number | null;
          type: SubscriptionAddonTypeType;
          validFromDate: Date;
          validUntilDate: Date;
        };
        site?: {
          __typename?: 'Site';
          cancelled: boolean;
          domain: string;
          id: string;
          level: SiteLevel;
          title?: string | null;
          visible: boolean;
        } | null;
      }> | null;
    } | null;
    permissions: Array<{
      __typename?: 'Permission';
      action: PermissionAction;
      resource: PermissionResource;
      status: PermissionStatus;
    }>;
  };
};

export type SignProsperStackPayloadQueryVariables = Exact<{
  payload: Scalars['String']['input'];
}>;

export type SignProsperStackPayloadQuery = {
  __typename?: 'Query';
  signProsperStackPayload: string;
};

export type SiteQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type SiteQuery = {
  __typename?: 'Query';
  site: {
    __typename?: 'Site';
    appRuns?: number | null;
    appRunsExtra?: number | null;
    appRunsLastReset?: Date | null;
    appRunsLimit?: number | null;
    appRunsResetInterval?: any | null;
    appSumoCodes?: Array<string> | null;
    briefCredits?: number | null;
    cancelled: boolean;
    cancelledAt?: Date | null;
    competitorDomains?: Array<string> | null;
    createdAt?: Date | null;
    defaultSerpCountry?: string | null;
    displayContentData?: boolean | null;
    domain: string;
    dunning?: boolean | null;
    dunningCardUpdated?: boolean | null;
    dunningExpired?: boolean | null;
    id: string;
    invId?: number | null;
    level: SiteLevel;
    networkDomains?: Array<string> | null;
    title?: string | null;
    updatedAt?: Date | null;
    visible: boolean;
    documentBriefUserInput?: {
      __typename?: 'DocumentBriefUserInput';
      buyerOrInformationJourney?: string | null;
      contextLinguistic?: string | null;
      expertise?: string | null;
      factStatements?: string | null;
      intentAnalysis?: string | null;
      marketingAndMessagingReferences?: Array<string> | null;
      personalExperience?: string | null;
      personalizationDetail?: string | null;
      pointOfView?: string | null;
      proofStatements?: string | null;
      structuring?: string | null;
      style?: string | null;
      targetMarket?: string | null;
      tone?: string | null;
      voice?: string | null;
    } | null;
  };
};

export type SiteAggregateAppRunsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type SiteAggregateAppRunsQuery = {
  __typename?: 'Query';
  siteAggregateStats: {
    __typename?: 'SiteAggregateStats';
    appRuns: {
      __typename?: 'AppRuns';
      current: number;
      error?: string | null;
      extra?: number | null;
      lastReset?: Date | null;
      limit?: number | null;
      nextReset?: Date | null;
      resetInterval?: any | null;
    };
  };
};

export type SiteAggregateBriefsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type SiteAggregateBriefsQuery = {
  __typename?: 'Query';
  siteAggregateStats: {
    __typename?: 'SiteAggregateStats';
    projectsAll: number;
    briefsAll: number;
    briefs: {
      __typename?: 'SiteAggregateBriefStats';
      cart: number;
      delivered: number;
      inProgress: number;
      qaReady: number;
    };
  };
};

export type SiteAggregatePlansQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type SiteAggregatePlansQuery = {
  __typename?: 'Query';
  siteAggregateStats: {
    __typename?: 'SiteAggregateStats';
    plansAll: number;
    projectsWithPlan: number;
  };
};

export type SiteAggregateProjectsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type SiteAggregateProjectsQuery = {
  __typename?: 'Query';
  siteAggregateStats: {
    __typename?: 'SiteAggregateStats';
    projectsAll: number;
    projectsWithPlan: number;
    projectsWithWriting: number;
  };
};

export type SiteAggregateStatsQueryVariables = Exact<{
  siteId: Scalars['String']['input'];
}>;

export type SiteAggregateStatsQuery = {
  __typename?: 'Query';
  siteAggregateStats: {
    __typename?: 'SiteAggregateStats';
    topicsAll?: number | null;
    pagesAll?: number | null;
    pageTopicsAll?: number | null;
    plansAll: number;
    projectsAll: number;
    projectsWithPlan: number;
    projectsWithWriting: number;
    briefsAll: number;
    briefs: {
      __typename?: 'SiteAggregateBriefStats';
      cart: number;
      delivered: number;
      inProgress: number;
      qaReady: number;
    };
    appRuns: {
      __typename?: 'AppRuns';
      current: number;
      error?: string | null;
      extra?: number | null;
      lastReset?: Date | null;
      limit?: number | null;
      nextReset?: Date | null;
      resetInterval?: any | null;
    };
  };
};

export type SiteRecommendedTopicsQueryVariables = Exact<{
  domain: Scalars['String']['input'];
  country: Country;
}>;

export type SiteRecommendedTopicsQuery = {
  __typename?: 'Query';
  siteRecommendedTopics: {
    __typename?: 'SiteRecommendedTopics';
    topicCount: number;
  };
};

export type SubscriptionQueryVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
}>;

export type SubscriptionQuery = {
  __typename?: 'Query';
  subscription?: {
    __typename?: 'SubscriptionEntity';
    createdAt: Date;
    dunning?: boolean | null;
    endsOnDate: Date;
    id: string;
    externalSubscriptionId?: string | null;
    conversionMethod: SubscriptionConversionMethod;
    startedOnDate: Date;
    state: SubscriptionState;
    price?: number | null;
    demo?: boolean | null;
    subscriptionPlan: {
      __typename?: 'SubscriptionPlan';
      active: boolean;
      appQueryCount: number;
      analysisAndPlanningDocumentAllocationLimit: number;
      briefDocumentAllocationLimit: number;
      id: string;
      planCode: string;
      price: number;
      term: SubscriptionPlanTerm;
      termLength: number;
      type: SubscriptionPlanType;
      userCanDowngradePackage: boolean;
      userCanUpgradePackage: boolean;
      usersArePurchasable: boolean;
      usersCountDefault?: number | null;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
    };
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
    addons?: Array<{
      __typename?: 'SubscriptionAddon';
      id: string;
      addonState?: SubscriptionAddonState | null;
      mode?: SubscriptionAddonMode | null;
      addonType: {
        __typename?: 'SubscriptionAddonType';
        id: string;
        description?: string | null;
        price?: number | null;
        type: SubscriptionAddonTypeType;
        validFromDate: Date;
        validUntilDate: Date;
      };
      site?: {
        __typename?: 'Site';
        cancelled: boolean;
        domain: string;
        id: string;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      } | null;
    }> | null;
  } | null;
};

export type SubscriptionAddonTypesAvailableQueryVariables = Exact<{
  filters?: InputMaybe<Array<SubscriptionAddonTypesAvailableFilter>>;
  sort?: InputMaybe<Array<SortField>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SubscriptionAddonTypesAvailableQuery = {
  __typename?: 'Query';
  subscriptionAddonTypesAvailable: {
    __typename?: 'SubscriptionAddonTypesResponse';
    count: number;
    items: Array<{
      __typename?: 'SubscriptionAddonType';
      id: string;
      description?: string | null;
      price?: number | null;
      type: SubscriptionAddonTypeType;
      validFromDate: Date;
      validUntilDate: Date;
    }>;
  };
};

export type SubscriptionPlansQueryVariables = Exact<{
  filters?: InputMaybe<Array<SubscriptionPlanFilter>>;
  sort?: InputMaybe<Array<SortField>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SubscriptionPlansQuery = {
  __typename?: 'Query';
  subscriptionPlans: {
    __typename?: 'SubscriptionPlansResponse';
    count: number;
    items: Array<{
      __typename?: 'SubscriptionPlan';
      active: boolean;
      appQueryCount: number;
      analysisAndPlanningDocumentAllocationLimit: number;
      briefDocumentAllocationLimit: number;
      id: string;
      planCode: string;
      price: number;
      term: SubscriptionPlanTerm;
      termLength: number;
      type: SubscriptionPlanType;
      userCanDowngradePackage: boolean;
      userCanUpgradePackage: boolean;
      usersArePurchasable: boolean;
      usersCountDefault?: number | null;
    }>;
  };
};

export type SubscriptionRenewalInvoicePreviewQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubscriptionRenewalInvoicePreviewQuery = {
  __typename?: 'Query';
  subscriptionRenewalInvoicePreview?: {
    __typename?: 'InvoiceCollection';
    object?: string | null;
    chargeInvoice?: {
      __typename?: 'Invoice';
      balance?: number | null;
      billingInfoId?: string | null;
      closedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerNotes?: string | null;
      discount?: number | null;
      dueAt?: Date | null;
      dunningCampaignId?: string | null;
      hasMoreLineItems?: boolean | null;
      id?: string | null;
      netTerms?: number | null;
      number?: string | null;
      object?: string | null;
      origin?: string | null;
      paid?: number | null;
      poNumber?: string | null;
      previousInvoiceId?: string | null;
      refundableAmount?: number | null;
      state?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      subtotal?: number | null;
      tax?: number | null;
      termsAndConditions?: string | null;
      total?: number | null;
      type?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
      vatReverseChargeNotes?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      address?: {
        __typename?: 'InvoiceAddress';
        city?: string | null;
        company?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        nameOnAccount?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      creditPayments?: Array<{
        __typename?: 'CreditPayment';
        action?: string | null;
        amount?: number | null;
        createdAt?: Date | null;
        currency?: string | null;
        id?: string | null;
        object?: string | null;
        originalCreditPaymentId?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        appliedToInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        originalInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        refundTransaction?: {
          __typename?: 'Transaction';
          amount?: number | null;
          avsCheck?: string | null;
          backupPaymentMethodUsed?: boolean | null;
          collectedAt?: Date | null;
          collectionMethod?: string | null;
          createdAt?: Date | null;
          currency?: string | null;
          customerMessage?: string | null;
          customerMessageLocale?: string | null;
          cvvCheck?: string | null;
          gatewayApprovalCode?: string | null;
          gatewayMessage?: string | null;
          gatewayReference?: string | null;
          gatewayResponseCode?: string | null;
          gatewayResponseTime?: number | null;
          gatewayResponseValues?: { [key: string]: any } | null;
          id: string;
          ipAddressCountry?: string | null;
          ipAddressV4?: string | null;
          object?: string | null;
          origin?: string | null;
          originalTransactionId?: string | null;
          refunded?: boolean | null;
          status?: string | null;
          statusCode?: string | null;
          statusMessage?: string | null;
          subscriptionIds?: { [key: string]: any } | null;
          success?: boolean | null;
          type?: string | null;
          updatedAt?: Date | null;
          uuid: string;
          voidedAt?: Date | null;
          account?: {
            __typename?: 'AccountMini';
            billTo?: string | null;
            code?: string | null;
            company?: string | null;
            dunningCampaignId?: string | null;
            email?: string | null;
            firstName?: string | null;
            id?: string | null;
            lastName?: string | null;
            parentAccountId?: string | null;
          } | null;
          billingAddress?: {
            __typename?: 'AddressWithName';
            city?: string | null;
            country?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            postalCode?: string | null;
            region?: string | null;
            street1?: string | null;
            street2?: string | null;
          } | null;
          invoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
          paymentGateway?: {
            __typename?: 'TransactionPaymentGateway';
            id?: string | null;
            name?: string | null;
            type?: string | null;
          } | null;
          paymentMethod?: {
            __typename?: 'PaymentMethod';
            accountType?: string | null;
            billingAgreementId?: string | null;
            cardType?: string | null;
            ccBinCountry?: string | null;
            expMonth?: number | null;
            expYear?: number | null;
            firstSix?: string | null;
            gatewayCode?: string | null;
            gatewayToken?: string | null;
            lastFour?: string | null;
            lastTwo?: string | null;
            nameOnAccount?: string | null;
            routingNumber?: string | null;
            routingNumberBank?: string | null;
          } | null;
          voidedByInvoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
        } | null;
      }> | null;
      lineItems?: Array<{
        __typename?: 'LineItem';
        accountingCode?: string | null;
        addOnCode?: string | null;
        addOnId?: string | null;
        amount?: number | null;
        avalaraServiceType?: number | null;
        avalaraTransactionType?: number | null;
        billForAccountId?: string | null;
        createdAt?: Date | null;
        creditApplied?: number | null;
        creditReasonCode?: string | null;
        currency?: string | null;
        description?: string | null;
        discount?: number | null;
        endDate?: Date | null;
        externalSku?: string | null;
        id?: string | null;
        invoiceId?: string | null;
        invoiceNumber?: string | null;
        itemCode?: string | null;
        itemId?: string | null;
        legacyCategory?: string | null;
        object?: string | null;
        origin?: string | null;
        originalLineItemInvoiceId?: string | null;
        planCode?: string | null;
        planId?: string | null;
        previousLineItemId?: string | null;
        productCode?: string | null;
        prorationRate?: number | null;
        quantity?: number | null;
        refund?: boolean | null;
        refundedQuantity?: number | null;
        revenueScheduleType?: string | null;
        startDate?: Date | null;
        state?: string | null;
        subscriptionId?: string | null;
        subtotal?: number | null;
        tax?: number | null;
        taxCode?: string | null;
        taxExempt?: boolean | null;
        taxable?: boolean | null;
        type?: string | null;
        unitAmount?: number | null;
        unitAmountDecimal?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        shippingAddress?: {
          __typename?: 'ShippingAddress';
          accountId?: string | null;
          city?: string | null;
          company?: string | null;
          country?: string | null;
          createdAt?: Date | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          nickname?: string | null;
          object?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
          updatedAt?: Date | null;
          vatNumber?: string | null;
        } | null;
        taxInfo?: {
          __typename?: 'TaxInfo';
          rate?: number | null;
          region?: string | null;
          type?: string | null;
          taxDetails?: Array<{
            __typename?: 'TaxDetail';
            rate?: number | null;
            region?: string | null;
            tax?: number | null;
            type?: string | null;
          }> | null;
        } | null;
      }> | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      }> | null;
    } | null;
    creditInvoices?: Array<{
      __typename?: 'Invoice';
      balance?: number | null;
      billingInfoId?: string | null;
      closedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerNotes?: string | null;
      discount?: number | null;
      dueAt?: Date | null;
      dunningCampaignId?: string | null;
      hasMoreLineItems?: boolean | null;
      id?: string | null;
      netTerms?: number | null;
      number?: string | null;
      object?: string | null;
      origin?: string | null;
      paid?: number | null;
      poNumber?: string | null;
      previousInvoiceId?: string | null;
      refundableAmount?: number | null;
      state?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      subtotal?: number | null;
      tax?: number | null;
      termsAndConditions?: string | null;
      total?: number | null;
      type?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
      vatReverseChargeNotes?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      address?: {
        __typename?: 'InvoiceAddress';
        city?: string | null;
        company?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        nameOnAccount?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      creditPayments?: Array<{
        __typename?: 'CreditPayment';
        action?: string | null;
        amount?: number | null;
        createdAt?: Date | null;
        currency?: string | null;
        id?: string | null;
        object?: string | null;
        originalCreditPaymentId?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        appliedToInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        originalInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        refundTransaction?: {
          __typename?: 'Transaction';
          amount?: number | null;
          avsCheck?: string | null;
          backupPaymentMethodUsed?: boolean | null;
          collectedAt?: Date | null;
          collectionMethod?: string | null;
          createdAt?: Date | null;
          currency?: string | null;
          customerMessage?: string | null;
          customerMessageLocale?: string | null;
          cvvCheck?: string | null;
          gatewayApprovalCode?: string | null;
          gatewayMessage?: string | null;
          gatewayReference?: string | null;
          gatewayResponseCode?: string | null;
          gatewayResponseTime?: number | null;
          gatewayResponseValues?: { [key: string]: any } | null;
          id: string;
          ipAddressCountry?: string | null;
          ipAddressV4?: string | null;
          object?: string | null;
          origin?: string | null;
          originalTransactionId?: string | null;
          refunded?: boolean | null;
          status?: string | null;
          statusCode?: string | null;
          statusMessage?: string | null;
          subscriptionIds?: { [key: string]: any } | null;
          success?: boolean | null;
          type?: string | null;
          updatedAt?: Date | null;
          uuid: string;
          voidedAt?: Date | null;
          account?: {
            __typename?: 'AccountMini';
            billTo?: string | null;
            code?: string | null;
            company?: string | null;
            dunningCampaignId?: string | null;
            email?: string | null;
            firstName?: string | null;
            id?: string | null;
            lastName?: string | null;
            parentAccountId?: string | null;
          } | null;
          billingAddress?: {
            __typename?: 'AddressWithName';
            city?: string | null;
            country?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            postalCode?: string | null;
            region?: string | null;
            street1?: string | null;
            street2?: string | null;
          } | null;
          invoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
          paymentGateway?: {
            __typename?: 'TransactionPaymentGateway';
            id?: string | null;
            name?: string | null;
            type?: string | null;
          } | null;
          paymentMethod?: {
            __typename?: 'PaymentMethod';
            accountType?: string | null;
            billingAgreementId?: string | null;
            cardType?: string | null;
            ccBinCountry?: string | null;
            expMonth?: number | null;
            expYear?: number | null;
            firstSix?: string | null;
            gatewayCode?: string | null;
            gatewayToken?: string | null;
            lastFour?: string | null;
            lastTwo?: string | null;
            nameOnAccount?: string | null;
            routingNumber?: string | null;
            routingNumberBank?: string | null;
          } | null;
          voidedByInvoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
        } | null;
      }> | null;
      lineItems?: Array<{
        __typename?: 'LineItem';
        accountingCode?: string | null;
        addOnCode?: string | null;
        addOnId?: string | null;
        amount?: number | null;
        avalaraServiceType?: number | null;
        avalaraTransactionType?: number | null;
        billForAccountId?: string | null;
        createdAt?: Date | null;
        creditApplied?: number | null;
        creditReasonCode?: string | null;
        currency?: string | null;
        description?: string | null;
        discount?: number | null;
        endDate?: Date | null;
        externalSku?: string | null;
        id?: string | null;
        invoiceId?: string | null;
        invoiceNumber?: string | null;
        itemCode?: string | null;
        itemId?: string | null;
        legacyCategory?: string | null;
        object?: string | null;
        origin?: string | null;
        originalLineItemInvoiceId?: string | null;
        planCode?: string | null;
        planId?: string | null;
        previousLineItemId?: string | null;
        productCode?: string | null;
        prorationRate?: number | null;
        quantity?: number | null;
        refund?: boolean | null;
        refundedQuantity?: number | null;
        revenueScheduleType?: string | null;
        startDate?: Date | null;
        state?: string | null;
        subscriptionId?: string | null;
        subtotal?: number | null;
        tax?: number | null;
        taxCode?: string | null;
        taxExempt?: boolean | null;
        taxable?: boolean | null;
        type?: string | null;
        unitAmount?: number | null;
        unitAmountDecimal?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        shippingAddress?: {
          __typename?: 'ShippingAddress';
          accountId?: string | null;
          city?: string | null;
          company?: string | null;
          country?: string | null;
          createdAt?: Date | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          nickname?: string | null;
          object?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
          updatedAt?: Date | null;
          vatNumber?: string | null;
        } | null;
        taxInfo?: {
          __typename?: 'TaxInfo';
          rate?: number | null;
          region?: string | null;
          type?: string | null;
          taxDetails?: Array<{
            __typename?: 'TaxDetail';
            rate?: number | null;
            region?: string | null;
            tax?: number | null;
            type?: string | null;
          }> | null;
        } | null;
      }> | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      }> | null;
    }> | null;
  } | null;
};

export type TagsPrimaryAttributesQueryVariables = Exact<{
  filters?: InputMaybe<Array<TagFilter>>;
  sort?: InputMaybe<Array<SortField>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TagsPrimaryAttributesQuery = {
  __typename?: 'Query';
  tags: {
    __typename?: 'TagsResponse';
    count: number;
    items: Array<{
      __typename?: 'Tag';
      createdAt?: Date | null;
      updatedAt?: Date | null;
      id: string;
      title: string;
    }>;
  };
};

export type TagsQueryVariables = Exact<{
  filters?: InputMaybe<Array<TagFilter>>;
  sort?: InputMaybe<Array<SortField>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TagsQuery = {
  __typename?: 'Query';
  tags: {
    __typename?: 'TagsResponse';
    count: number;
    items: Array<{
      __typename?: 'Tag';
      createdAt?: Date | null;
      updatedAt?: Date | null;
      id: string;
      title: string;
      terms?: Array<string> | null;
      documents?: Array<{ __typename?: 'Document'; id: string }> | null;
      createdBy?: { __typename?: 'User'; email: string } | null;
      updatedBy?: { __typename?: 'User'; email: string } | null;
    }>;
  };
};

export type GetUserQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    createdAt?: Date | null;
    defaultApplicationSerpCountry?: string | null;
    displayExactDistribution?: boolean | null;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    id: string;
    lastName?: string | null;
    onboardingProgress?: { [key: string]: any } | null;
    onboardingStep?: string | null;
    orgRole?: string | null;
    phone?: string | null;
    shouldReceiveBriefOrderedNotifications?: boolean | null;
    suspended?: boolean | null;
    sfdcContactId?: string | null;
    updatedAt?: Date | null;
    org?: {
      __typename?: 'Organization';
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      id: string;
      name: string;
      slug: string;
      clientTier?: ClientTier | null;
      sites?: Array<{
        __typename?: 'Site';
        appSumoCodes?: Array<string> | null;
        briefCredits?: number | null;
        cancelled: boolean;
        displayContentData?: boolean | null;
        domain: string;
        id: string;
        invId?: number | null;
        level: SiteLevel;
        title?: string | null;
        visible: boolean;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      }> | null;
    } | null;
  } | null;
};

export type GhostAsUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GhostAsUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    createdAt?: Date | null;
    defaultApplicationSerpCountry?: string | null;
    displayExactDistribution?: boolean | null;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    id: string;
    lastName?: string | null;
    onboardingProgress?: { [key: string]: any } | null;
    onboardingStep?: string | null;
    orgRole?: string | null;
    phone?: string | null;
    shouldReceiveBriefOrderedNotifications?: boolean | null;
    suspended?: boolean | null;
    sfdcContactId?: string | null;
    updatedAt?: Date | null;
    org?: {
      __typename?: 'Organization';
      recurlyAccountCode?: string | null;
      recurlyAccountCodeTest?: string | null;
      maxUsers?: number | null;
      address?: string | null;
      createdAt?: Date | null;
      clientTier?: ClientTier | null;
      defaultForm?: string | null;
      headerFont?: string | null;
      highlightColor?: string | null;
      id: string;
      name: string;
      phone?: string | null;
      sfdcAccountId?: string | null;
      slug: string;
      updatedAt?: Date | null;
      topicsMax?: number | null;
      sites?: Array<{
        __typename?: 'Site';
        appSumoCodes?: Array<string> | null;
        appRuns?: number | null;
        appRunsExtra?: number | null;
        appRunsLastReset?: Date | null;
        appRunsLimit?: number | null;
        appRunsResetInterval?: any | null;
        briefCredits?: number | null;
        cancelled: boolean;
        cancelledAt?: Date | null;
        competitorDomains?: Array<string> | null;
        createdAt?: Date | null;
        defaultSerpCountry?: string | null;
        displayContentData?: boolean | null;
        domain: string;
        dunning?: boolean | null;
        dunningCardUpdated?: boolean | null;
        dunningExpired?: boolean | null;
        id: string;
        invId?: number | null;
        level: SiteLevel;
        networkDomains?: Array<string> | null;
        title?: string | null;
        updatedAt?: Date | null;
        visible: boolean;
        documentBriefUserInput?: {
          __typename?: 'DocumentBriefUserInput';
          buyerOrInformationJourney?: string | null;
          contextLinguistic?: string | null;
          expertise?: string | null;
          factStatements?: string | null;
          intentAnalysis?: string | null;
          marketingAndMessagingReferences?: Array<string> | null;
          personalExperience?: string | null;
          personalizationDetail?: string | null;
          pointOfView?: string | null;
          proofStatements?: string | null;
          structuring?: string | null;
          style?: string | null;
          targetMarket?: string | null;
          tone?: string | null;
          voice?: string | null;
        } | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        createdAt?: Date | null;
        defaultApplicationSerpCountry?: string | null;
        displayExactDistribution?: boolean | null;
        email: string;
        firstName?: string | null;
        fullName?: string | null;
        id: string;
        lastName?: string | null;
        onboardingProgress?: { [key: string]: any } | null;
        onboardingStep?: string | null;
        orgRole?: string | null;
        phone?: string | null;
        shouldReceiveBriefOrderedNotifications?: boolean | null;
        suspended?: boolean | null;
        sfdcContactId?: string | null;
        updatedAt?: Date | null;
      }> | null;
      subscription?: {
        __typename?: 'SubscriptionEntity';
        createdAt: Date;
        dunning?: boolean | null;
        endsOnDate: Date;
        id: string;
        externalSubscriptionId?: string | null;
        conversionMethod: SubscriptionConversionMethod;
        startedOnDate: Date;
        state: SubscriptionState;
        price?: number | null;
        demo?: boolean | null;
        subscriptionPlan: {
          __typename?: 'SubscriptionPlan';
          active: boolean;
          appQueryCount: number;
          analysisAndPlanningDocumentAllocationLimit: number;
          briefDocumentAllocationLimit: number;
          id: string;
          planCode: string;
          price: number;
          term: SubscriptionPlanTerm;
          termLength: number;
          type: SubscriptionPlanType;
          userCanDowngradePackage: boolean;
          userCanUpgradePackage: boolean;
          usersArePurchasable: boolean;
          usersCountDefault?: number | null;
        };
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          slug: string;
          clientTier?: ClientTier | null;
        };
        site?: {
          __typename?: 'Site';
          cancelled: boolean;
          domain: string;
          id: string;
          level: SiteLevel;
          title?: string | null;
          visible: boolean;
        } | null;
        addons?: Array<{
          __typename?: 'SubscriptionAddon';
          id: string;
          addonState?: SubscriptionAddonState | null;
          mode?: SubscriptionAddonMode | null;
          addonType: {
            __typename?: 'SubscriptionAddonType';
            id: string;
            description?: string | null;
            price?: number | null;
            type: SubscriptionAddonTypeType;
            validFromDate: Date;
            validUntilDate: Date;
          };
          site?: {
            __typename?: 'Site';
            cancelled: boolean;
            domain: string;
            id: string;
            level: SiteLevel;
            title?: string | null;
            visible: boolean;
          } | null;
        }> | null;
      } | null;
      documentAllotment?: {
        __typename?: 'DocumentsAllotment';
        analysisPlanning: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        briefs: {
          __typename?: 'DocumentsAllotmentInfo';
          created: number;
          total: number;
        };
        resetDate: { __typename?: 'ResetDate'; last: Date; next: Date };
      } | null;
      primaryContact?: {
        __typename?: 'User';
        id: string;
        email: string;
        fullName?: string | null;
        createdAt?: Date | null;
        updatedAt?: Date | null;
      } | null;
    } | null;
  } | null;
  subscriptionRenewalInvoicePreview?: {
    __typename?: 'InvoiceCollection';
    object?: string | null;
    chargeInvoice?: {
      __typename?: 'Invoice';
      balance?: number | null;
      billingInfoId?: string | null;
      closedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerNotes?: string | null;
      discount?: number | null;
      dueAt?: Date | null;
      dunningCampaignId?: string | null;
      hasMoreLineItems?: boolean | null;
      id?: string | null;
      netTerms?: number | null;
      number?: string | null;
      object?: string | null;
      origin?: string | null;
      paid?: number | null;
      poNumber?: string | null;
      previousInvoiceId?: string | null;
      refundableAmount?: number | null;
      state?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      subtotal?: number | null;
      tax?: number | null;
      termsAndConditions?: string | null;
      total?: number | null;
      type?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
      vatReverseChargeNotes?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      address?: {
        __typename?: 'InvoiceAddress';
        city?: string | null;
        company?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        nameOnAccount?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      creditPayments?: Array<{
        __typename?: 'CreditPayment';
        action?: string | null;
        amount?: number | null;
        createdAt?: Date | null;
        currency?: string | null;
        id?: string | null;
        object?: string | null;
        originalCreditPaymentId?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        appliedToInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        originalInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        refundTransaction?: {
          __typename?: 'Transaction';
          amount?: number | null;
          avsCheck?: string | null;
          backupPaymentMethodUsed?: boolean | null;
          collectedAt?: Date | null;
          collectionMethod?: string | null;
          createdAt?: Date | null;
          currency?: string | null;
          customerMessage?: string | null;
          customerMessageLocale?: string | null;
          cvvCheck?: string | null;
          gatewayApprovalCode?: string | null;
          gatewayMessage?: string | null;
          gatewayReference?: string | null;
          gatewayResponseCode?: string | null;
          gatewayResponseTime?: number | null;
          gatewayResponseValues?: { [key: string]: any } | null;
          id: string;
          ipAddressCountry?: string | null;
          ipAddressV4?: string | null;
          object?: string | null;
          origin?: string | null;
          originalTransactionId?: string | null;
          refunded?: boolean | null;
          status?: string | null;
          statusCode?: string | null;
          statusMessage?: string | null;
          subscriptionIds?: { [key: string]: any } | null;
          success?: boolean | null;
          type?: string | null;
          updatedAt?: Date | null;
          uuid: string;
          voidedAt?: Date | null;
          account?: {
            __typename?: 'AccountMini';
            billTo?: string | null;
            code?: string | null;
            company?: string | null;
            dunningCampaignId?: string | null;
            email?: string | null;
            firstName?: string | null;
            id?: string | null;
            lastName?: string | null;
            parentAccountId?: string | null;
          } | null;
          billingAddress?: {
            __typename?: 'AddressWithName';
            city?: string | null;
            country?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            postalCode?: string | null;
            region?: string | null;
            street1?: string | null;
            street2?: string | null;
          } | null;
          invoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
          paymentGateway?: {
            __typename?: 'TransactionPaymentGateway';
            id?: string | null;
            name?: string | null;
            type?: string | null;
          } | null;
          paymentMethod?: {
            __typename?: 'PaymentMethod';
            accountType?: string | null;
            billingAgreementId?: string | null;
            cardType?: string | null;
            ccBinCountry?: string | null;
            expMonth?: number | null;
            expYear?: number | null;
            firstSix?: string | null;
            gatewayCode?: string | null;
            gatewayToken?: string | null;
            lastFour?: string | null;
            lastTwo?: string | null;
            nameOnAccount?: string | null;
            routingNumber?: string | null;
            routingNumberBank?: string | null;
          } | null;
          voidedByInvoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
        } | null;
      }> | null;
      lineItems?: Array<{
        __typename?: 'LineItem';
        accountingCode?: string | null;
        addOnCode?: string | null;
        addOnId?: string | null;
        amount?: number | null;
        avalaraServiceType?: number | null;
        avalaraTransactionType?: number | null;
        billForAccountId?: string | null;
        createdAt?: Date | null;
        creditApplied?: number | null;
        creditReasonCode?: string | null;
        currency?: string | null;
        description?: string | null;
        discount?: number | null;
        endDate?: Date | null;
        externalSku?: string | null;
        id?: string | null;
        invoiceId?: string | null;
        invoiceNumber?: string | null;
        itemCode?: string | null;
        itemId?: string | null;
        legacyCategory?: string | null;
        object?: string | null;
        origin?: string | null;
        originalLineItemInvoiceId?: string | null;
        planCode?: string | null;
        planId?: string | null;
        previousLineItemId?: string | null;
        productCode?: string | null;
        prorationRate?: number | null;
        quantity?: number | null;
        refund?: boolean | null;
        refundedQuantity?: number | null;
        revenueScheduleType?: string | null;
        startDate?: Date | null;
        state?: string | null;
        subscriptionId?: string | null;
        subtotal?: number | null;
        tax?: number | null;
        taxCode?: string | null;
        taxExempt?: boolean | null;
        taxable?: boolean | null;
        type?: string | null;
        unitAmount?: number | null;
        unitAmountDecimal?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        shippingAddress?: {
          __typename?: 'ShippingAddress';
          accountId?: string | null;
          city?: string | null;
          company?: string | null;
          country?: string | null;
          createdAt?: Date | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          nickname?: string | null;
          object?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
          updatedAt?: Date | null;
          vatNumber?: string | null;
        } | null;
        taxInfo?: {
          __typename?: 'TaxInfo';
          rate?: number | null;
          region?: string | null;
          type?: string | null;
          taxDetails?: Array<{
            __typename?: 'TaxDetail';
            rate?: number | null;
            region?: string | null;
            tax?: number | null;
            type?: string | null;
          }> | null;
        } | null;
      }> | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      }> | null;
    } | null;
    creditInvoices?: Array<{
      __typename?: 'Invoice';
      balance?: number | null;
      billingInfoId?: string | null;
      closedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerNotes?: string | null;
      discount?: number | null;
      dueAt?: Date | null;
      dunningCampaignId?: string | null;
      hasMoreLineItems?: boolean | null;
      id?: string | null;
      netTerms?: number | null;
      number?: string | null;
      object?: string | null;
      origin?: string | null;
      paid?: number | null;
      poNumber?: string | null;
      previousInvoiceId?: string | null;
      refundableAmount?: number | null;
      state?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      subtotal?: number | null;
      tax?: number | null;
      termsAndConditions?: string | null;
      total?: number | null;
      type?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
      vatReverseChargeNotes?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      address?: {
        __typename?: 'InvoiceAddress';
        city?: string | null;
        company?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        nameOnAccount?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      creditPayments?: Array<{
        __typename?: 'CreditPayment';
        action?: string | null;
        amount?: number | null;
        createdAt?: Date | null;
        currency?: string | null;
        id?: string | null;
        object?: string | null;
        originalCreditPaymentId?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        appliedToInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        originalInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        refundTransaction?: {
          __typename?: 'Transaction';
          amount?: number | null;
          avsCheck?: string | null;
          backupPaymentMethodUsed?: boolean | null;
          collectedAt?: Date | null;
          collectionMethod?: string | null;
          createdAt?: Date | null;
          currency?: string | null;
          customerMessage?: string | null;
          customerMessageLocale?: string | null;
          cvvCheck?: string | null;
          gatewayApprovalCode?: string | null;
          gatewayMessage?: string | null;
          gatewayReference?: string | null;
          gatewayResponseCode?: string | null;
          gatewayResponseTime?: number | null;
          gatewayResponseValues?: { [key: string]: any } | null;
          id: string;
          ipAddressCountry?: string | null;
          ipAddressV4?: string | null;
          object?: string | null;
          origin?: string | null;
          originalTransactionId?: string | null;
          refunded?: boolean | null;
          status?: string | null;
          statusCode?: string | null;
          statusMessage?: string | null;
          subscriptionIds?: { [key: string]: any } | null;
          success?: boolean | null;
          type?: string | null;
          updatedAt?: Date | null;
          uuid: string;
          voidedAt?: Date | null;
          account?: {
            __typename?: 'AccountMini';
            billTo?: string | null;
            code?: string | null;
            company?: string | null;
            dunningCampaignId?: string | null;
            email?: string | null;
            firstName?: string | null;
            id?: string | null;
            lastName?: string | null;
            parentAccountId?: string | null;
          } | null;
          billingAddress?: {
            __typename?: 'AddressWithName';
            city?: string | null;
            country?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            postalCode?: string | null;
            region?: string | null;
            street1?: string | null;
            street2?: string | null;
          } | null;
          invoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
          paymentGateway?: {
            __typename?: 'TransactionPaymentGateway';
            id?: string | null;
            name?: string | null;
            type?: string | null;
          } | null;
          paymentMethod?: {
            __typename?: 'PaymentMethod';
            accountType?: string | null;
            billingAgreementId?: string | null;
            cardType?: string | null;
            ccBinCountry?: string | null;
            expMonth?: number | null;
            expYear?: number | null;
            firstSix?: string | null;
            gatewayCode?: string | null;
            gatewayToken?: string | null;
            lastFour?: string | null;
            lastTwo?: string | null;
            nameOnAccount?: string | null;
            routingNumber?: string | null;
            routingNumberBank?: string | null;
          } | null;
          voidedByInvoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
        } | null;
      }> | null;
      lineItems?: Array<{
        __typename?: 'LineItem';
        accountingCode?: string | null;
        addOnCode?: string | null;
        addOnId?: string | null;
        amount?: number | null;
        avalaraServiceType?: number | null;
        avalaraTransactionType?: number | null;
        billForAccountId?: string | null;
        createdAt?: Date | null;
        creditApplied?: number | null;
        creditReasonCode?: string | null;
        currency?: string | null;
        description?: string | null;
        discount?: number | null;
        endDate?: Date | null;
        externalSku?: string | null;
        id?: string | null;
        invoiceId?: string | null;
        invoiceNumber?: string | null;
        itemCode?: string | null;
        itemId?: string | null;
        legacyCategory?: string | null;
        object?: string | null;
        origin?: string | null;
        originalLineItemInvoiceId?: string | null;
        planCode?: string | null;
        planId?: string | null;
        previousLineItemId?: string | null;
        productCode?: string | null;
        prorationRate?: number | null;
        quantity?: number | null;
        refund?: boolean | null;
        refundedQuantity?: number | null;
        revenueScheduleType?: string | null;
        startDate?: Date | null;
        state?: string | null;
        subscriptionId?: string | null;
        subtotal?: number | null;
        tax?: number | null;
        taxCode?: string | null;
        taxExempt?: boolean | null;
        taxable?: boolean | null;
        type?: string | null;
        unitAmount?: number | null;
        unitAmountDecimal?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        shippingAddress?: {
          __typename?: 'ShippingAddress';
          accountId?: string | null;
          city?: string | null;
          company?: string | null;
          country?: string | null;
          createdAt?: Date | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          nickname?: string | null;
          object?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
          updatedAt?: Date | null;
          vatNumber?: string | null;
        } | null;
        taxInfo?: {
          __typename?: 'TaxInfo';
          rate?: number | null;
          region?: string | null;
          type?: string | null;
          taxDetails?: Array<{
            __typename?: 'TaxDetail';
            rate?: number | null;
            region?: string | null;
            tax?: number | null;
            type?: string | null;
          }> | null;
        } | null;
      }> | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{
  filters?: InputMaybe<Array<UserFilter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    createdAt?: Date | null;
    defaultApplicationSerpCountry?: string | null;
    displayExactDistribution?: boolean | null;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    id: string;
    lastName?: string | null;
    onboardingProgress?: { [key: string]: any } | null;
    onboardingStep?: string | null;
    orgRole?: string | null;
    phone?: string | null;
    shouldReceiveBriefOrderedNotifications?: boolean | null;
    suspended?: boolean | null;
    sfdcContactId?: string | null;
    updatedAt?: Date | null;
  } | null>;
};

export type ValidateEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ValidateEmailQuery = {
  __typename?: 'Query';
  validateEmail: {
    __typename?: 'ValidateEmailResponse';
    valid: boolean;
    exists: boolean;
    allowedDomain: boolean;
    formatValid: boolean;
    message: string;
  };
};

export type AppDataParamsFieldsFragment = {
  __typename?: 'AppDataParams';
  bodyOrArticle?: string | null;
  brands?: boolean | null;
  country: string;
  language?: string | null;
  people?: boolean | null;
  topic: string;
  url?: string | null;
};

export type AppRunsFieldsFragment = {
  __typename?: 'AppRuns';
  current: number;
  error?: string | null;
  extra?: number | null;
  lastReset?: Date | null;
  limit?: number | null;
  nextReset?: Date | null;
  resetInterval?: any | null;
};

export type GetAppDataResponseFieldsFragment = {
  __typename?: 'GetAppDataResponse';
  compareKgsNews?: { [key: string]: any } | null;
  keywords?: { [key: string]: any } | null;
  kg?: { [key: string]: any } | null;
  kgNews?: { [key: string]: any } | null;
  lr?: { [key: string]: any } | null;
  qts?: { [key: string]: any } | null;
  scores?: { [key: string]: any } | null;
  scoresNews?: { [key: string]: any } | null;
};

export type AppQueryFieldsBaseFragment = {
  __typename?: 'AppQuery';
  app: string;
  connectCompetitorDomains?: Array<string> | null;
  connectNetworkDomains?: Array<string> | null;
  country?: Country | null;
  createdAt?: Date | null;
  id: string;
  jsonMetadata?: { [key: string]: any } | null;
  language?: Language | null;
  status: AppQueryStatus;
  topic?: string | null;
  updatedAt?: Date | null;
  url?: string | null;
};

export type AppQueryFieldsFragment = {
  __typename?: 'AppQuery';
  app: string;
  connectCompetitorDomains?: Array<string> | null;
  connectNetworkDomains?: Array<string> | null;
  country?: Country | null;
  createdAt?: Date | null;
  id: string;
  jsonMetadata?: { [key: string]: any } | null;
  language?: Language | null;
  status: AppQueryStatus;
  topic?: string | null;
  updatedAt?: Date | null;
  url?: string | null;
  site?: {
    __typename?: 'Site';
    cancelled: boolean;
    domain: string;
    id: string;
    level: SiteLevel;
    title?: string | null;
    visible: boolean;
  } | null;
  user?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
};

export type BriefCommentFieldsFragment = {
  __typename?: 'BriefComment';
  createdBy?: string | null;
  id?: string | null;
  value?: string | null;
  visible?: boolean | null;
};

export type BriefLinkListFieldsFragment = {
  __typename?: 'BriefLinkList';
  id: string;
  semanticSimilarity?: number | null;
  visible?: boolean | null;
  links: Array<{
    __typename?: 'BriefLink';
    id: string;
    parent?: string | null;
    title?: string | null;
    url?: string | null;
    visible?: boolean | null;
  }>;
};

export type BriefQuestionFieldsFragment = {
  __typename?: 'BriefQuestion';
  id?: string | null;
  semanticSimilarity?: number | null;
  value?: string | null;
  visible?: boolean | null;
};

export type BriefSubheadingFieldsFragment = {
  __typename?: 'BriefSubheading';
  id?: string | null;
  position?: number | null;
  title?: string | null;
  value?: string | null;
  visible?: boolean | null;
  wordCount?: number | null;
  additionalTopics?: Array<{
    __typename?: 'BriefSubtopic';
    distribution?: number | null;
    id?: string | null;
    powerTopic?: boolean | null;
    semanticSimilarity?: number | null;
    suggestedDistribution?: number | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  comments?: Array<{
    __typename?: 'BriefComment';
    createdBy?: string | null;
    id?: string | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  externalLinks?: Array<{
    __typename?: 'BriefLinkList';
    id: string;
    semanticSimilarity?: number | null;
    visible?: boolean | null;
    links: Array<{
      __typename?: 'BriefLink';
      id: string;
      parent?: string | null;
      title?: string | null;
      url?: string | null;
      visible?: boolean | null;
    }>;
  }> | null;
  internalLinks?: Array<{
    __typename?: 'BriefLinkList';
    id: string;
    semanticSimilarity?: number | null;
    visible?: boolean | null;
    links: Array<{
      __typename?: 'BriefLink';
      id: string;
      parent?: string | null;
      title?: string | null;
      url?: string | null;
      visible?: boolean | null;
    }>;
  }> | null;
  questions?: Array<{
    __typename?: 'BriefQuestion';
    id?: string | null;
    semanticSimilarity?: number | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  relatedTopics?: Array<{
    __typename?: 'BriefRelatedTopic';
    distribution?: number | null;
    id: string;
    powerTopic?: boolean | null;
    semanticSimilarity?: number | null;
    suggestedDistribution?: number | null;
    value: string;
    visible?: boolean | null;
  }> | null;
  topic?: {
    __typename?: 'BriefSubheadingTopic';
    contentScoreAvg?: number | null;
    contentScoreTarget?: number | null;
    id: string;
    semanticSimilarity?: number | null;
    suggestedDistribution?: number | null;
    term: string;
    wordCountAvg?: number | null;
    wordCountTarget?: number | null;
  } | null;
};

export type BriefSubtopicFieldsFragment = {
  __typename?: 'BriefSubtopic';
  distribution?: number | null;
  id?: string | null;
  powerTopic?: boolean | null;
  semanticSimilarity?: number | null;
  suggestedDistribution?: number | null;
  value?: string | null;
  visible?: boolean | null;
};

export type BriefTopicFieldsFragment = {
  __typename?: 'BriefTopic';
  contentScoreAvg?: number | null;
  contentScoreTarget?: number | null;
  id: string;
  kgKey?: string | null;
  term: string;
  wordCountAvg?: number | null;
  wordCountTarget?: number | null;
};

export type BriefFieldsBaseFragment = {
  __typename?: 'Brief';
  id: string;
  selfServe?: boolean | null;
  briefStatus: BriefStatus;
  briefTitle?: string | null;
  createdAt?: Date | null;
};

export type BriefFieldsFragment = {
  __typename?: 'Brief';
  briefStatus: BriefStatus;
  briefTitle?: string | null;
  competitorSites?: string | null;
  createdAt?: Date | null;
  cuid?: string | null;
  dueDate?: Date | null;
  errorStatus: BriefErrorStatus;
  focusTopic: string;
  formData?: { [key: string]: any } | null;
  formDraft?: boolean | null;
  formId?: string | null;
  id: string;
  reportType: BriefType;
  selfServe?: boolean | null;
  updatedAt?: Date | null;
  page?: { __typename?: 'BriefPage'; url?: string | null; id: string } | null;
  topic?: { __typename?: 'BriefTopic'; id: string; term: string } | null;
  site?: {
    __typename?: 'Site';
    id: string;
    domain: string;
    level: SiteLevel;
  } | null;
  org?: { __typename?: 'Organization'; id: string; name: string } | null;
};

export type BriefFieldsAllFragment = {
  __typename?: 'Brief';
  briefStatus: BriefStatus;
  briefTitle?: string | null;
  competitorSites?: string | null;
  createdAt?: Date | null;
  cuid?: string | null;
  dueDate?: Date | null;
  errorStatus: BriefErrorStatus;
  focusTopic: string;
  formData?: { [key: string]: any } | null;
  formDraft?: boolean | null;
  formId?: string | null;
  id: string;
  reportType: BriefType;
  selfServe?: boolean | null;
  updatedAt?: Date | null;
  accountManager?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  additionalTopics?: Array<{
    __typename?: 'BriefSubtopic';
    distribution?: number | null;
    id?: string | null;
    powerTopic?: boolean | null;
    semanticSimilarity?: number | null;
    suggestedDistribution?: number | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  comments?: Array<{
    __typename?: 'BriefComment';
    createdBy?: string | null;
    id?: string | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  createdBy?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  csRep?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  metadata?: {
    __typename?: 'BriefMetadata';
    id?: string | null;
    errors?: Array<{
      __typename?: 'BriefError';
      id?: string | null;
      message?: string | null;
    }> | null;
  } | null;
  org?: {
    __typename?: 'Organization';
    id: string;
    slug: string;
    name: string;
  } | null;
  page?: { __typename?: 'BriefPage'; id: string; url?: string | null } | null;
  qaAssignee?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  questions?: Array<{
    __typename?: 'BriefQuestion';
    id?: string | null;
    semanticSimilarity?: number | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  rankingTitles?: Array<{
    __typename?: 'BriefRankingTitle';
    id?: string | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  site?: {
    __typename?: 'Site';
    id: string;
    domain: string;
    level: SiteLevel;
  } | null;
  subheadingTerms?: Array<{
    __typename?: 'BriefSubheadingTerm';
    id?: string | null;
    semanticSimilarity?: number | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  subheadings?: Array<{
    __typename?: 'BriefSubheading';
    id?: string | null;
    position?: number | null;
    title?: string | null;
    value?: string | null;
    visible?: boolean | null;
    wordCount?: number | null;
    additionalTopics?: Array<{
      __typename?: 'BriefSubtopic';
      distribution?: number | null;
      id?: string | null;
      powerTopic?: boolean | null;
      semanticSimilarity?: number | null;
      suggestedDistribution?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    comments?: Array<{
      __typename?: 'BriefComment';
      createdBy?: string | null;
      id?: string | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    externalLinks?: Array<{
      __typename?: 'BriefLinkList';
      id: string;
      semanticSimilarity?: number | null;
      visible?: boolean | null;
      links: Array<{
        __typename?: 'BriefLink';
        id: string;
        parent?: string | null;
        title?: string | null;
        url?: string | null;
        visible?: boolean | null;
      }>;
    }> | null;
    internalLinks?: Array<{
      __typename?: 'BriefLinkList';
      id: string;
      semanticSimilarity?: number | null;
      visible?: boolean | null;
      links: Array<{
        __typename?: 'BriefLink';
        id: string;
        parent?: string | null;
        title?: string | null;
        url?: string | null;
        visible?: boolean | null;
      }>;
    }> | null;
    questions?: Array<{
      __typename?: 'BriefQuestion';
      id?: string | null;
      semanticSimilarity?: number | null;
      value?: string | null;
      visible?: boolean | null;
    }> | null;
    relatedTopics?: Array<{
      __typename?: 'BriefRelatedTopic';
      distribution?: number | null;
      id: string;
      powerTopic?: boolean | null;
      semanticSimilarity?: number | null;
      suggestedDistribution?: number | null;
      value: string;
      visible?: boolean | null;
    }> | null;
    topic?: {
      __typename?: 'BriefSubheadingTopic';
      contentScoreAvg?: number | null;
      contentScoreTarget?: number | null;
      id: string;
      semanticSimilarity?: number | null;
      suggestedDistribution?: number | null;
      term: string;
      wordCountAvg?: number | null;
      wordCountTarget?: number | null;
    } | null;
  }> | null;
  subtopics?: Array<{
    __typename?: 'BriefSubtopic';
    distribution?: number | null;
    id?: string | null;
    powerTopic?: boolean | null;
    semanticSimilarity?: number | null;
    suggestedDistribution?: number | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  titleTerms?: Array<{
    __typename?: 'BriefTitleTerm';
    id?: string | null;
    semanticSimilarity?: number | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  topic?: {
    __typename?: 'BriefTopic';
    contentScoreAvg?: number | null;
    contentScoreTarget?: number | null;
    id: string;
    kgKey?: string | null;
    term: string;
    wordCountAvg?: number | null;
    wordCountTarget?: number | null;
  } | null;
  userAudiences?: Array<{
    __typename?: 'BriefUserAudience';
    id?: string | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
  userIntents?: Array<{
    __typename?: 'BriefUserIntent';
    id?: string | null;
    value?: string | null;
    visible?: boolean | null;
  }> | null;
};

export type DocumentFieldsBaseFragment = {
  __typename?: 'Document';
  createdAt?: Date | null;
  id: string;
  status?: DocumentStatus | null;
  subtype: DocumentSubtype;
  title: string;
  type: DocumentType;
  updatedAt?: Date | null;
  assigneeTo?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  inputData: {
    __typename?: 'DocumentInputData';
    terms?: Array<string> | null;
    urls?: Array<string> | null;
    domain?: string | null;
    location?: string | null;
  };
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug: string;
    clientTier?: ClientTier | null;
  };
  site: {
    __typename?: 'Site';
    cancelled: boolean;
    domain: string;
    id: string;
    level: SiteLevel;
    title?: string | null;
    visible: boolean;
  };
  tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
  updatedBy?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
};

export type DocumentFieldsFragment = {
  __typename?: 'Document';
  steps?: Array<DocumentStep> | null;
  prioritization?: DocumentPrioritization | null;
  scope?: DocumentScope | null;
  createdAt?: Date | null;
  id: string;
  status?: DocumentStatus | null;
  subtype: DocumentSubtype;
  title: string;
  type: DocumentType;
  updatedAt?: Date | null;
  assigneeTo?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  createdBy?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  inputData: {
    __typename?: 'DocumentInputData';
    terms?: Array<string> | null;
    urls?: Array<string> | null;
    domain?: string | null;
    location?: string | null;
  };
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug: string;
    clientTier?: ClientTier | null;
  };
  site: {
    __typename?: 'Site';
    cancelled: boolean;
    domain: string;
    id: string;
    level: SiteLevel;
    title?: string | null;
    visible: boolean;
  };
  tags?: Array<{ __typename?: 'Tag'; id: string; title: string }> | null;
  updatedBy?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
};

export type FormFieldsFragment = {
  __typename?: 'Form';
  createdAt?: Date | null;
  updatedAt?: Date | null;
  id: string;
  title?: string | null;
  type?: FormType | null;
  orderVisible?: { [key: string]: any } | null;
  orderHidden?: { [key: string]: any } | null;
};

export type FormFieldFieldsFragment = {
  __typename?: 'FormField';
  id: string;
  note?: string | null;
  type: FormFieldType;
  fieldData?: { [key: string]: any } | null;
  required?: boolean | null;
  group?: { __typename?: 'FormGroup'; id: string } | null;
};

export type FormGroupFieldsFragment = {
  __typename?: 'FormGroup';
  createdAt?: Date | null;
  updatedAt?: Date | null;
  id: string;
  title?: string | null;
  type: FormGroupType;
  orderVisible?: { [key: string]: any } | null;
  orderHidden?: { [key: string]: any } | null;
  form?: {
    __typename?: 'Form';
    createdAt?: Date | null;
    updatedAt?: Date | null;
    id: string;
    title?: string | null;
    type?: FormType | null;
    orderVisible?: { [key: string]: any } | null;
    orderHidden?: { [key: string]: any } | null;
  } | null;
  fields?: Array<{
    __typename?: 'FormField';
    id: string;
    note?: string | null;
    type: FormFieldType;
    fieldData?: { [key: string]: any } | null;
    required?: boolean | null;
    group?: { __typename?: 'FormGroup'; id: string } | null;
  }> | null;
};

export type InventoryItemFieldsBaseFragment = {
  __typename?: 'InventoryItem';
  key: string;
  type: InventoryItemType;
  page?: {
    __typename?: 'Page';
    id?: number | null;
    url?: string | null;
  } | null;
  pageTopic?: {
    __typename?: 'PageTopic';
    id?: number | null;
    pageId?: number | null;
    topicId?: number | null;
  } | null;
  topic?: {
    __typename?: 'Topic';
    id?: number | null;
    term?: string | null;
  } | null;
};

export type InventoryPageFragment = {
  __typename?: 'Page';
  authority?: number | null;
  avgMmCompetitiveAdvantage?: number | null;
  avgMmDifficulty?: number | null;
  avgMmPersonalizedAdvantage?: number | null;
  avgPersonalizedMmDifficulty?: number | null;
  createdAt?: Date | null;
  externalContentLinkCount?: number | null;
  externalContentLinks?: string | null;
  hiddenByCs?: boolean | null;
  hiddenByUser?: boolean | null;
  id?: number | null;
  internalContentLinkCount?: number | null;
  internalContentLinks?: string | null;
  maxRank?: number | null;
  minRank?: number | null;
  numTopics?: number | null;
  potentialTraffic?: number | null;
  potentialTrafficUpside?: number | null;
  potentialTrafficValue?: number | null;
  source?: string | null;
  sumVolume?: number | null;
  title?: string | null;
  traffic?: number | null;
  trafficDelta?: number | null;
  trafficDeltaPct?: number | null;
  trafficValue?: number | null;
  trafficValueDelta?: number | null;
  updatedAt?: Date | null;
  url?: string | null;
  urlHash?: string | null;
  versionTrafficPct?: number | null;
  versionTrafficValuePct?: number | null;
};

export type InventoryPageTopicFragment = {
  __typename?: 'PageTopic';
  hiddenByCs?: boolean | null;
  hiddenByUser?: boolean | null;
  id?: number | null;
  pageId?: number | null;
  potentialTraffic?: number | null;
  potentialTrafficUpside?: number | null;
  potentialTrafficValue?: number | null;
  prePriority?: number | null;
  rank?: number | null;
  rankDelta?: number | null;
  rankDeltaPotential?: number | null;
  topicId?: number | null;
  traffic?: number | null;
  trafficDelta?: number | null;
  trafficDeltaPct?: number | null;
  trafficValue?: number | null;
  trafficValueDelta?: number | null;
  trafficValueDeltaPct?: number | null;
  versionId?: number | null;
  versionTrafficPct?: number | null;
  versionTrafficValuePct?: number | null;
};

export type InventoryTopicFragment = {
  __typename?: 'Topic';
  avgPageAuthority?: number | null;
  competition?: number | null;
  difficulty?: number | null;
  hiddenByCs?: boolean | null;
  hiddenByUser?: boolean | null;
  id?: number | null;
  maxRank?: number | null;
  mmDifficulty?: number | null;
  mmPersonalizedAdvantage?: number | null;
  mmPersonalizedDifficulty?: number | null;
  monthlyTrends?: string | null;
  numPages?: number | null;
  potentialTraffic?: number | null;
  potentialTrafficUpside?: number | null;
  potentialTrafficValue?: number | null;
  serpFeatures?: string | null;
  serpFeaturesSet?: Array<string> | null;
  serpFeaturesTrafficShare?: number | null;
  source?: string | null;
  sumExternalContentLinks?: number | null;
  sumInternalContentLinks?: number | null;
  term?: string | null;
  topRankingPage?: string | null;
  traffic?: number | null;
  trafficDelta?: number | null;
  trafficDeltaPct?: number | null;
  trafficValue?: number | null;
  trafficValueDelta?: number | null;
  trafficValueDeltaPct?: number | null;
  versionTrafficPct?: number | null;
  versionTrafficValuePct?: number | null;
  volume?: number | null;
};

export type InventoryVersionFieldsFragment = {
  __typename?: 'InventoryVersion';
  id: number;
  name: string;
  visible?: number | null;
  status?: VersionStatusEnum | null;
  statusUpdatedAt?: Date | null;
  traffic?: number | null;
  trafficDelta?: number | null;
  trafficDeltaPct?: number | null;
  trafficValue?: number | null;
  trafficValueDelta?: number | null;
  trafficValueDeltaPct?: number | null;
  failed?: number | null;
};

export type OrganizationFieldsBaseFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  slug: string;
  clientTier?: ClientTier | null;
};

export type DocumentsAllotmentFieldsFragment = {
  __typename?: 'DocumentsAllotment';
  analysisPlanning: {
    __typename?: 'DocumentsAllotmentInfo';
    created: number;
    total: number;
  };
  briefs: {
    __typename?: 'DocumentsAllotmentInfo';
    created: number;
    total: number;
  };
  resetDate: { __typename?: 'ResetDate'; last: Date; next: Date };
};

export type OrganizationFieldsFragment = {
  __typename?: 'Organization';
  address?: string | null;
  createdAt?: Date | null;
  clientTier?: ClientTier | null;
  defaultForm?: string | null;
  headerFont?: string | null;
  highlightColor?: string | null;
  id: string;
  name: string;
  phone?: string | null;
  recurlyAccountCode?: string | null;
  recurlyAccountCodeTest?: string | null;
  sfdcAccountId?: string | null;
  slug: string;
  updatedAt?: Date | null;
  topicsMax?: number | null;
  primaryContact?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
};

export type PermissionFieldsFragment = {
  __typename?: 'Permission';
  action: PermissionAction;
  resource: PermissionResource;
  status: PermissionStatus;
};

export type PlanFieldsFragment = {
  __typename?: 'Plan';
  createdAt?: Date | null;
  updatedAt?: Date | null;
  id: string;
  title: string;
};

export type ProjectBaseFieldsFragment = {
  __typename?: 'Project';
  briefId?: string | null;
  dueDate?: Date | null;
  id: string;
  publishedOn?: Date | null;
  topic?: string | null;
  url?: string | null;
  writingReference?: string | null;
  assignee?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  plan?: { __typename?: 'Plan'; title: string } | null;
};

export type ProjectMainFieldsFragment = {
  __typename?: 'Project';
  briefCreatedAt?: Date | null;
  briefId?: string | null;
  briefSelfServe?: boolean | null;
  briefStatus?: BriefStatus | null;
  briefStatusError?: string | null;
  briefStatusLastSynced?: Date | null;
  createdAt?: Date | null;
  dueDate?: Date | null;
  id: string;
  notes?: string | null;
  publishedOn?: Date | null;
  topic?: string | null;
  updatedAt?: Date | null;
  url?: string | null;
  writingCreatedAt?: Date | null;
  writingReference?: string | null;
  writingUpdatedAt?: Date | null;
  assignee?: {
    __typename?: 'User';
    id: string;
    email: string;
    fullName?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
  } | null;
  plan?: { __typename?: 'Plan'; id: string; title: string } | null;
  site?: { __typename?: 'Site'; id: string } | null;
};

export type RecurlyAccountFieldsFragment = {
  __typename?: 'Account';
  billTo?: string | null;
  ccEmails?: string | null;
  code: string;
  id: string;
  company: string;
  firstName?: string | null;
  email?: string | null;
  lastName?: string | null;
  createdAt?: Date | null;
  deletedAt?: Date | null;
  dunningCampaignId?: string | null;
  exemptionCertificate?: string | null;
  hasActiveSubscription?: boolean | null;
  hasCanceledSubscription?: boolean | null;
  hasFutureSubscription?: boolean | null;
  hasLiveSubscription?: boolean | null;
  hasPastDueInvoice?: boolean | null;
  hasPausedSubscription?: boolean | null;
  object?: string | null;
  parentAccountId?: string | null;
  preferredLocale?: string | null;
  state?: string | null;
  taxExempt?: boolean | null;
  updatedAt?: Date | null;
  username?: string | null;
  vatNumber?: string | null;
};

export type RecurlyAccountMiniFieldsFragment = {
  __typename?: 'AccountMini';
  billTo?: string | null;
  code?: string | null;
  company?: string | null;
  email?: string | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
};

export type RecurlyCouponFieldsFragment = {
  __typename?: 'Coupon';
  appliesToAllItems: boolean;
  appliesToAllPlans: boolean;
  appliesToNonPlanCharges?: boolean | null;
  code: string;
  couponType: string;
  createdAt?: Date | null;
  duration?: string | null;
  expiredAt?: Date | null;
  hostedPageDescription?: string | null;
  id?: string | null;
  invoiceDescription?: string | null;
  maxRedemptions?: number | null;
  maxRedemptionsPerAccount?: number | null;
  name?: string | null;
  object?: string | null;
  redeemBy?: Date | null;
  redemptionResource?: string | null;
  state?: string | null;
  temporalAmount?: number | null;
  temporalUnit?: string | null;
  uniqueCodeTemplate?: string | null;
  uniqueCouponCode?: { [key: string]: any } | null;
  uniqueCouponCodesCount?: number | null;
  updatedAt?: Date | null;
  discount: {
    __typename?: 'CouponDiscount';
    percent?: number | null;
    type?: string | null;
    currencies?: Array<{
      __typename?: 'CouponDiscountPricing';
      amount?: number | null;
      currency?: string | null;
    }> | null;
    trial?: {
      __typename?: 'CouponDiscountTrial';
      length?: number | null;
      unit?: string | null;
    } | null;
  };
  items?: {
    __typename?: 'ItemMini';
    code?: string | null;
    description?: string | null;
    id?: string | null;
    name?: string | null;
    object?: string | null;
    state?: string | null;
  } | null;
  plans?: Array<{
    __typename?: 'PlanMini';
    code?: string | null;
    id?: string | null;
    name?: string | null;
    object?: string | null;
  }> | null;
};

export type RecurlyCouponRedemptionFieldsFragment = {
  __typename?: 'CouponRedemption';
  createdAt?: Date | null;
  currency?: string | null;
  discounted?: number | null;
  id?: string | null;
  object?: string | null;
  removedAt?: Date | null;
  state?: string | null;
  subscriptionId?: string | null;
  updatedAt?: Date | null;
};

export type RecurlyCouponDiscountFieldsFragment = {
  __typename?: 'CouponDiscount';
  percent?: number | null;
  type?: string | null;
  currencies?: Array<{
    __typename?: 'CouponDiscountPricing';
    amount?: number | null;
    currency?: string | null;
  }> | null;
  trial?: {
    __typename?: 'CouponDiscountTrial';
    length?: number | null;
    unit?: string | null;
  } | null;
};

export type RecurlyCouponMiniFieldsFragment = {
  __typename?: 'CouponMini';
  code?: string | null;
  couponType?: string | null;
  expiredAt?: Date | null;
  id?: string | null;
  name?: string | null;
  object?: string | null;
  state?: string | null;
  discount?: {
    __typename?: 'CouponDiscount';
    percent?: number | null;
    type?: string | null;
    currencies?: Array<{
      __typename?: 'CouponDiscountPricing';
      amount?: number | null;
      currency?: string | null;
    }> | null;
    trial?: {
      __typename?: 'CouponDiscountTrial';
      length?: number | null;
      unit?: string | null;
    } | null;
  } | null;
};

export type RecurlyCouponRedemptionsFieldsFragment = {
  __typename?: 'CouponRedemptionMini';
  createdAt?: Date | null;
  discounted?: number | null;
  id?: string | null;
  object?: string | null;
  state?: string | null;
  coupon?: {
    __typename?: 'CouponMini';
    code?: string | null;
    couponType?: string | null;
    expiredAt?: Date | null;
    id?: string | null;
    name?: string | null;
    object?: string | null;
    state?: string | null;
    discount?: {
      __typename?: 'CouponDiscount';
      percent?: number | null;
      type?: string | null;
      currencies?: Array<{
        __typename?: 'CouponDiscountPricing';
        amount?: number | null;
        currency?: string | null;
      }> | null;
      trial?: {
        __typename?: 'CouponDiscountTrial';
        length?: number | null;
        unit?: string | null;
      } | null;
    } | null;
  } | null;
};

export type RecurlyInvoiceFieldsFragment = {
  __typename?: 'Invoice';
  balance?: number | null;
  billingInfoId?: string | null;
  closedAt?: Date | null;
  collectionMethod?: string | null;
  createdAt?: Date | null;
  currency?: string | null;
  customerNotes?: string | null;
  discount?: number | null;
  dueAt?: Date | null;
  dunningCampaignId?: string | null;
  hasMoreLineItems?: boolean | null;
  id?: string | null;
  netTerms?: number | null;
  number?: string | null;
  object?: string | null;
  origin?: string | null;
  paid?: number | null;
  poNumber?: string | null;
  previousInvoiceId?: string | null;
  refundableAmount?: number | null;
  state?: string | null;
  subscriptionIds?: { [key: string]: any } | null;
  subtotal?: number | null;
  tax?: number | null;
  termsAndConditions?: string | null;
  total?: number | null;
  type?: string | null;
  updatedAt?: Date | null;
  vatNumber?: string | null;
  vatReverseChargeNotes?: string | null;
  account?: {
    __typename?: 'AccountMini';
    billTo?: string | null;
    code?: string | null;
    company?: string | null;
    dunningCampaignId?: string | null;
    email?: string | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    parentAccountId?: string | null;
  } | null;
  address?: {
    __typename?: 'InvoiceAddress';
    city?: string | null;
    company?: string | null;
    country?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    nameOnAccount?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    region?: string | null;
    street1?: string | null;
    street2?: string | null;
  } | null;
  creditPayments?: Array<{
    __typename?: 'CreditPayment';
    action?: string | null;
    amount?: number | null;
    createdAt?: Date | null;
    currency?: string | null;
    id?: string | null;
    object?: string | null;
    originalCreditPaymentId?: string | null;
    updatedAt?: Date | null;
    uuid?: string | null;
    voidedAt?: Date | null;
    account?: {
      __typename?: 'AccountMini';
      billTo?: string | null;
      code?: string | null;
      company?: string | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
    } | null;
    appliedToInvoice?: {
      __typename?: 'InvoiceMini';
      id?: string | null;
      number?: string | null;
      state?: string | null;
      type?: string | null;
    } | null;
    originalInvoice?: {
      __typename?: 'InvoiceMini';
      id?: string | null;
      number?: string | null;
      state?: string | null;
      type?: string | null;
    } | null;
    refundTransaction?: {
      __typename?: 'Transaction';
      amount?: number | null;
      avsCheck?: string | null;
      backupPaymentMethodUsed?: boolean | null;
      collectedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerMessage?: string | null;
      customerMessageLocale?: string | null;
      cvvCheck?: string | null;
      gatewayApprovalCode?: string | null;
      gatewayMessage?: string | null;
      gatewayReference?: string | null;
      gatewayResponseCode?: string | null;
      gatewayResponseTime?: number | null;
      gatewayResponseValues?: { [key: string]: any } | null;
      id: string;
      ipAddressCountry?: string | null;
      ipAddressV4?: string | null;
      object?: string | null;
      origin?: string | null;
      originalTransactionId?: string | null;
      refunded?: boolean | null;
      status?: string | null;
      statusCode?: string | null;
      statusMessage?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      success?: boolean | null;
      type?: string | null;
      updatedAt?: Date | null;
      uuid: string;
      voidedAt?: Date | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      billingAddress?: {
        __typename?: 'AddressWithName';
        city?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      invoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
      paymentGateway?: {
        __typename?: 'TransactionPaymentGateway';
        id?: string | null;
        name?: string | null;
        type?: string | null;
      } | null;
      paymentMethod?: {
        __typename?: 'PaymentMethod';
        accountType?: string | null;
        billingAgreementId?: string | null;
        cardType?: string | null;
        ccBinCountry?: string | null;
        expMonth?: number | null;
        expYear?: number | null;
        firstSix?: string | null;
        gatewayCode?: string | null;
        gatewayToken?: string | null;
        lastFour?: string | null;
        lastTwo?: string | null;
        nameOnAccount?: string | null;
        routingNumber?: string | null;
        routingNumberBank?: string | null;
      } | null;
      voidedByInvoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
    } | null;
  }> | null;
  lineItems?: Array<{
    __typename?: 'LineItem';
    accountingCode?: string | null;
    addOnCode?: string | null;
    addOnId?: string | null;
    amount?: number | null;
    avalaraServiceType?: number | null;
    avalaraTransactionType?: number | null;
    billForAccountId?: string | null;
    createdAt?: Date | null;
    creditApplied?: number | null;
    creditReasonCode?: string | null;
    currency?: string | null;
    description?: string | null;
    discount?: number | null;
    endDate?: Date | null;
    externalSku?: string | null;
    id?: string | null;
    invoiceId?: string | null;
    invoiceNumber?: string | null;
    itemCode?: string | null;
    itemId?: string | null;
    legacyCategory?: string | null;
    object?: string | null;
    origin?: string | null;
    originalLineItemInvoiceId?: string | null;
    planCode?: string | null;
    planId?: string | null;
    previousLineItemId?: string | null;
    productCode?: string | null;
    prorationRate?: number | null;
    quantity?: number | null;
    refund?: boolean | null;
    refundedQuantity?: number | null;
    revenueScheduleType?: string | null;
    startDate?: Date | null;
    state?: string | null;
    subscriptionId?: string | null;
    subtotal?: number | null;
    tax?: number | null;
    taxCode?: string | null;
    taxExempt?: boolean | null;
    taxable?: boolean | null;
    type?: string | null;
    unitAmount?: number | null;
    unitAmountDecimal?: string | null;
    updatedAt?: Date | null;
    uuid?: string | null;
    account?: {
      __typename?: 'AccountMini';
      billTo?: string | null;
      code?: string | null;
      company?: string | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
    } | null;
    shippingAddress?: {
      __typename?: 'ShippingAddress';
      accountId?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      createdAt?: Date | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      nickname?: string | null;
      object?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
    } | null;
    taxInfo?: {
      __typename?: 'TaxInfo';
      rate?: number | null;
      region?: string | null;
      type?: string | null;
      taxDetails?: Array<{
        __typename?: 'TaxDetail';
        rate?: number | null;
        region?: string | null;
        tax?: number | null;
        type?: string | null;
      }> | null;
    } | null;
  }> | null;
  shippingAddress?: {
    __typename?: 'ShippingAddress';
    accountId?: string | null;
    city?: string | null;
    company?: string | null;
    country?: string | null;
    createdAt?: Date | null;
    email?: string | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    nickname?: string | null;
    object?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    region?: string | null;
    street1?: string | null;
    street2?: string | null;
    updatedAt?: Date | null;
    vatNumber?: string | null;
  } | null;
  taxInfo?: {
    __typename?: 'TaxInfo';
    rate?: number | null;
    region?: string | null;
    type?: string | null;
    taxDetails?: Array<{
      __typename?: 'TaxDetail';
      rate?: number | null;
      region?: string | null;
      tax?: number | null;
      type?: string | null;
    }> | null;
  } | null;
  transactions?: Array<{
    __typename?: 'Transaction';
    amount?: number | null;
    avsCheck?: string | null;
    backupPaymentMethodUsed?: boolean | null;
    collectedAt?: Date | null;
    collectionMethod?: string | null;
    createdAt?: Date | null;
    currency?: string | null;
    customerMessage?: string | null;
    customerMessageLocale?: string | null;
    cvvCheck?: string | null;
    gatewayApprovalCode?: string | null;
    gatewayMessage?: string | null;
    gatewayReference?: string | null;
    gatewayResponseCode?: string | null;
    gatewayResponseTime?: number | null;
    gatewayResponseValues?: { [key: string]: any } | null;
    id: string;
    ipAddressCountry?: string | null;
    ipAddressV4?: string | null;
    object?: string | null;
    origin?: string | null;
    originalTransactionId?: string | null;
    refunded?: boolean | null;
    status?: string | null;
    statusCode?: string | null;
    statusMessage?: string | null;
    subscriptionIds?: { [key: string]: any } | null;
    success?: boolean | null;
    type?: string | null;
    updatedAt?: Date | null;
    uuid: string;
    voidedAt?: Date | null;
    account?: {
      __typename?: 'AccountMini';
      billTo?: string | null;
      code?: string | null;
      company?: string | null;
      dunningCampaignId?: string | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      parentAccountId?: string | null;
    } | null;
    billingAddress?: {
      __typename?: 'AddressWithName';
      city?: string | null;
      country?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
    } | null;
    invoice?: {
      __typename?: 'InvoiceMini';
      id?: string | null;
      number?: string | null;
      state?: string | null;
      type?: string | null;
    } | null;
    paymentGateway?: {
      __typename?: 'TransactionPaymentGateway';
      id?: string | null;
      name?: string | null;
      type?: string | null;
    } | null;
    paymentMethod?: {
      __typename?: 'PaymentMethod';
      accountType?: string | null;
      billingAgreementId?: string | null;
      cardType?: string | null;
      ccBinCountry?: string | null;
      expMonth?: number | null;
      expYear?: number | null;
      firstSix?: string | null;
      gatewayCode?: string | null;
      gatewayToken?: string | null;
      lastFour?: string | null;
      lastTwo?: string | null;
      nameOnAccount?: string | null;
      routingNumber?: string | null;
      routingNumberBank?: string | null;
    } | null;
    voidedByInvoice?: {
      __typename?: 'InvoiceMini';
      id?: string | null;
      number?: string | null;
      state?: string | null;
      type?: string | null;
    } | null;
  }> | null;
};

export type RecurlyInvoiceCollectionFieldsFragment = {
  __typename?: 'InvoiceCollection';
  object?: string | null;
  chargeInvoice?: {
    __typename?: 'Invoice';
    balance?: number | null;
    billingInfoId?: string | null;
    closedAt?: Date | null;
    collectionMethod?: string | null;
    createdAt?: Date | null;
    currency?: string | null;
    customerNotes?: string | null;
    discount?: number | null;
    dueAt?: Date | null;
    dunningCampaignId?: string | null;
    hasMoreLineItems?: boolean | null;
    id?: string | null;
    netTerms?: number | null;
    number?: string | null;
    object?: string | null;
    origin?: string | null;
    paid?: number | null;
    poNumber?: string | null;
    previousInvoiceId?: string | null;
    refundableAmount?: number | null;
    state?: string | null;
    subscriptionIds?: { [key: string]: any } | null;
    subtotal?: number | null;
    tax?: number | null;
    termsAndConditions?: string | null;
    total?: number | null;
    type?: string | null;
    updatedAt?: Date | null;
    vatNumber?: string | null;
    vatReverseChargeNotes?: string | null;
    account?: {
      __typename?: 'AccountMini';
      billTo?: string | null;
      code?: string | null;
      company?: string | null;
      dunningCampaignId?: string | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      parentAccountId?: string | null;
    } | null;
    address?: {
      __typename?: 'InvoiceAddress';
      city?: string | null;
      company?: string | null;
      country?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      nameOnAccount?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
    } | null;
    creditPayments?: Array<{
      __typename?: 'CreditPayment';
      action?: string | null;
      amount?: number | null;
      createdAt?: Date | null;
      currency?: string | null;
      id?: string | null;
      object?: string | null;
      originalCreditPaymentId?: string | null;
      updatedAt?: Date | null;
      uuid?: string | null;
      voidedAt?: Date | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
      } | null;
      appliedToInvoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
      originalInvoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
      refundTransaction?: {
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      } | null;
    }> | null;
    lineItems?: Array<{
      __typename?: 'LineItem';
      accountingCode?: string | null;
      addOnCode?: string | null;
      addOnId?: string | null;
      amount?: number | null;
      avalaraServiceType?: number | null;
      avalaraTransactionType?: number | null;
      billForAccountId?: string | null;
      createdAt?: Date | null;
      creditApplied?: number | null;
      creditReasonCode?: string | null;
      currency?: string | null;
      description?: string | null;
      discount?: number | null;
      endDate?: Date | null;
      externalSku?: string | null;
      id?: string | null;
      invoiceId?: string | null;
      invoiceNumber?: string | null;
      itemCode?: string | null;
      itemId?: string | null;
      legacyCategory?: string | null;
      object?: string | null;
      origin?: string | null;
      originalLineItemInvoiceId?: string | null;
      planCode?: string | null;
      planId?: string | null;
      previousLineItemId?: string | null;
      productCode?: string | null;
      prorationRate?: number | null;
      quantity?: number | null;
      refund?: boolean | null;
      refundedQuantity?: number | null;
      revenueScheduleType?: string | null;
      startDate?: Date | null;
      state?: string | null;
      subscriptionId?: string | null;
      subtotal?: number | null;
      tax?: number | null;
      taxCode?: string | null;
      taxExempt?: boolean | null;
      taxable?: boolean | null;
      type?: string | null;
      unitAmount?: number | null;
      unitAmountDecimal?: string | null;
      updatedAt?: Date | null;
      uuid?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
      } | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
    }> | null;
    shippingAddress?: {
      __typename?: 'ShippingAddress';
      accountId?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      createdAt?: Date | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      nickname?: string | null;
      object?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
    } | null;
    taxInfo?: {
      __typename?: 'TaxInfo';
      rate?: number | null;
      region?: string | null;
      type?: string | null;
      taxDetails?: Array<{
        __typename?: 'TaxDetail';
        rate?: number | null;
        region?: string | null;
        tax?: number | null;
        type?: string | null;
      }> | null;
    } | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      amount?: number | null;
      avsCheck?: string | null;
      backupPaymentMethodUsed?: boolean | null;
      collectedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerMessage?: string | null;
      customerMessageLocale?: string | null;
      cvvCheck?: string | null;
      gatewayApprovalCode?: string | null;
      gatewayMessage?: string | null;
      gatewayReference?: string | null;
      gatewayResponseCode?: string | null;
      gatewayResponseTime?: number | null;
      gatewayResponseValues?: { [key: string]: any } | null;
      id: string;
      ipAddressCountry?: string | null;
      ipAddressV4?: string | null;
      object?: string | null;
      origin?: string | null;
      originalTransactionId?: string | null;
      refunded?: boolean | null;
      status?: string | null;
      statusCode?: string | null;
      statusMessage?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      success?: boolean | null;
      type?: string | null;
      updatedAt?: Date | null;
      uuid: string;
      voidedAt?: Date | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      billingAddress?: {
        __typename?: 'AddressWithName';
        city?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      invoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
      paymentGateway?: {
        __typename?: 'TransactionPaymentGateway';
        id?: string | null;
        name?: string | null;
        type?: string | null;
      } | null;
      paymentMethod?: {
        __typename?: 'PaymentMethod';
        accountType?: string | null;
        billingAgreementId?: string | null;
        cardType?: string | null;
        ccBinCountry?: string | null;
        expMonth?: number | null;
        expYear?: number | null;
        firstSix?: string | null;
        gatewayCode?: string | null;
        gatewayToken?: string | null;
        lastFour?: string | null;
        lastTwo?: string | null;
        nameOnAccount?: string | null;
        routingNumber?: string | null;
        routingNumberBank?: string | null;
      } | null;
      voidedByInvoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
    }> | null;
  } | null;
  creditInvoices?: Array<{
    __typename?: 'Invoice';
    balance?: number | null;
    billingInfoId?: string | null;
    closedAt?: Date | null;
    collectionMethod?: string | null;
    createdAt?: Date | null;
    currency?: string | null;
    customerNotes?: string | null;
    discount?: number | null;
    dueAt?: Date | null;
    dunningCampaignId?: string | null;
    hasMoreLineItems?: boolean | null;
    id?: string | null;
    netTerms?: number | null;
    number?: string | null;
    object?: string | null;
    origin?: string | null;
    paid?: number | null;
    poNumber?: string | null;
    previousInvoiceId?: string | null;
    refundableAmount?: number | null;
    state?: string | null;
    subscriptionIds?: { [key: string]: any } | null;
    subtotal?: number | null;
    tax?: number | null;
    termsAndConditions?: string | null;
    total?: number | null;
    type?: string | null;
    updatedAt?: Date | null;
    vatNumber?: string | null;
    vatReverseChargeNotes?: string | null;
    account?: {
      __typename?: 'AccountMini';
      billTo?: string | null;
      code?: string | null;
      company?: string | null;
      dunningCampaignId?: string | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      parentAccountId?: string | null;
    } | null;
    address?: {
      __typename?: 'InvoiceAddress';
      city?: string | null;
      company?: string | null;
      country?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      nameOnAccount?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
    } | null;
    creditPayments?: Array<{
      __typename?: 'CreditPayment';
      action?: string | null;
      amount?: number | null;
      createdAt?: Date | null;
      currency?: string | null;
      id?: string | null;
      object?: string | null;
      originalCreditPaymentId?: string | null;
      updatedAt?: Date | null;
      uuid?: string | null;
      voidedAt?: Date | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
      } | null;
      appliedToInvoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
      originalInvoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
      refundTransaction?: {
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      } | null;
    }> | null;
    lineItems?: Array<{
      __typename?: 'LineItem';
      accountingCode?: string | null;
      addOnCode?: string | null;
      addOnId?: string | null;
      amount?: number | null;
      avalaraServiceType?: number | null;
      avalaraTransactionType?: number | null;
      billForAccountId?: string | null;
      createdAt?: Date | null;
      creditApplied?: number | null;
      creditReasonCode?: string | null;
      currency?: string | null;
      description?: string | null;
      discount?: number | null;
      endDate?: Date | null;
      externalSku?: string | null;
      id?: string | null;
      invoiceId?: string | null;
      invoiceNumber?: string | null;
      itemCode?: string | null;
      itemId?: string | null;
      legacyCategory?: string | null;
      object?: string | null;
      origin?: string | null;
      originalLineItemInvoiceId?: string | null;
      planCode?: string | null;
      planId?: string | null;
      previousLineItemId?: string | null;
      productCode?: string | null;
      prorationRate?: number | null;
      quantity?: number | null;
      refund?: boolean | null;
      refundedQuantity?: number | null;
      revenueScheduleType?: string | null;
      startDate?: Date | null;
      state?: string | null;
      subscriptionId?: string | null;
      subtotal?: number | null;
      tax?: number | null;
      taxCode?: string | null;
      taxExempt?: boolean | null;
      taxable?: boolean | null;
      type?: string | null;
      unitAmount?: number | null;
      unitAmountDecimal?: string | null;
      updatedAt?: Date | null;
      uuid?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
      } | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
    }> | null;
    shippingAddress?: {
      __typename?: 'ShippingAddress';
      accountId?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      createdAt?: Date | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      nickname?: string | null;
      object?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
    } | null;
    taxInfo?: {
      __typename?: 'TaxInfo';
      rate?: number | null;
      region?: string | null;
      type?: string | null;
      taxDetails?: Array<{
        __typename?: 'TaxDetail';
        rate?: number | null;
        region?: string | null;
        tax?: number | null;
        type?: string | null;
      }> | null;
    } | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      amount?: number | null;
      avsCheck?: string | null;
      backupPaymentMethodUsed?: boolean | null;
      collectedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerMessage?: string | null;
      customerMessageLocale?: string | null;
      cvvCheck?: string | null;
      gatewayApprovalCode?: string | null;
      gatewayMessage?: string | null;
      gatewayReference?: string | null;
      gatewayResponseCode?: string | null;
      gatewayResponseTime?: number | null;
      gatewayResponseValues?: { [key: string]: any } | null;
      id: string;
      ipAddressCountry?: string | null;
      ipAddressV4?: string | null;
      object?: string | null;
      origin?: string | null;
      originalTransactionId?: string | null;
      refunded?: boolean | null;
      status?: string | null;
      statusCode?: string | null;
      statusMessage?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      success?: boolean | null;
      type?: string | null;
      updatedAt?: Date | null;
      uuid: string;
      voidedAt?: Date | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      billingAddress?: {
        __typename?: 'AddressWithName';
        city?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      invoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
      paymentGateway?: {
        __typename?: 'TransactionPaymentGateway';
        id?: string | null;
        name?: string | null;
        type?: string | null;
      } | null;
      paymentMethod?: {
        __typename?: 'PaymentMethod';
        accountType?: string | null;
        billingAgreementId?: string | null;
        cardType?: string | null;
        ccBinCountry?: string | null;
        expMonth?: number | null;
        expYear?: number | null;
        firstSix?: string | null;
        gatewayCode?: string | null;
        gatewayToken?: string | null;
        lastFour?: string | null;
        lastTwo?: string | null;
        nameOnAccount?: string | null;
        routingNumber?: string | null;
        routingNumberBank?: string | null;
      } | null;
      voidedByInvoice?: {
        __typename?: 'InvoiceMini';
        id?: string | null;
        number?: string | null;
        state?: string | null;
        type?: string | null;
      } | null;
    }> | null;
  }> | null;
};

export type RecurlyShippingAddressFieldsFragment = {
  __typename?: 'ShippingAddress';
  accountId?: string | null;
  city?: string | null;
  company?: string | null;
  country?: string | null;
  createdAt?: Date | null;
  email?: string | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  nickname?: string | null;
  object?: string | null;
  phone?: string | null;
  postalCode?: string | null;
  region?: string | null;
  street1?: string | null;
  street2?: string | null;
  updatedAt?: Date | null;
  vatNumber?: string | null;
};

export type RecurlyTransactionFieldsFragment = {
  __typename?: 'Transaction';
  amount?: number | null;
  avsCheck?: string | null;
  backupPaymentMethodUsed?: boolean | null;
  collectedAt?: Date | null;
  collectionMethod?: string | null;
  createdAt?: Date | null;
  currency?: string | null;
  customerMessage?: string | null;
  customerMessageLocale?: string | null;
  cvvCheck?: string | null;
  gatewayApprovalCode?: string | null;
  gatewayMessage?: string | null;
  gatewayReference?: string | null;
  gatewayResponseCode?: string | null;
  gatewayResponseTime?: number | null;
  gatewayResponseValues?: { [key: string]: any } | null;
  id: string;
  ipAddressCountry?: string | null;
  ipAddressV4?: string | null;
  object?: string | null;
  origin?: string | null;
  originalTransactionId?: string | null;
  refunded?: boolean | null;
  status?: string | null;
  statusCode?: string | null;
  statusMessage?: string | null;
  subscriptionIds?: { [key: string]: any } | null;
  success?: boolean | null;
  type?: string | null;
  updatedAt?: Date | null;
  uuid: string;
  voidedAt?: Date | null;
  account?: {
    __typename?: 'AccountMini';
    billTo?: string | null;
    code?: string | null;
    company?: string | null;
    dunningCampaignId?: string | null;
    email?: string | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    parentAccountId?: string | null;
  } | null;
  billingAddress?: {
    __typename?: 'AddressWithName';
    city?: string | null;
    country?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    region?: string | null;
    street1?: string | null;
    street2?: string | null;
  } | null;
  invoice?: {
    __typename?: 'InvoiceMini';
    id?: string | null;
    number?: string | null;
    state?: string | null;
    type?: string | null;
  } | null;
  paymentGateway?: {
    __typename?: 'TransactionPaymentGateway';
    id?: string | null;
    name?: string | null;
    type?: string | null;
  } | null;
  paymentMethod?: {
    __typename?: 'PaymentMethod';
    accountType?: string | null;
    billingAgreementId?: string | null;
    cardType?: string | null;
    ccBinCountry?: string | null;
    expMonth?: number | null;
    expYear?: number | null;
    firstSix?: string | null;
    gatewayCode?: string | null;
    gatewayToken?: string | null;
    lastFour?: string | null;
    lastTwo?: string | null;
    nameOnAccount?: string | null;
    routingNumber?: string | null;
    routingNumberBank?: string | null;
  } | null;
  voidedByInvoice?: {
    __typename?: 'InvoiceMini';
    id?: string | null;
    number?: string | null;
    state?: string | null;
    type?: string | null;
  } | null;
};

export type RecurlyTaxInfoFieldsFragment = {
  __typename?: 'TaxInfo';
  rate?: number | null;
  region?: string | null;
  type?: string | null;
  taxDetails?: Array<{
    __typename?: 'TaxDetail';
    rate?: number | null;
    region?: string | null;
    tax?: number | null;
    type?: string | null;
  }> | null;
};

export type RecurlyInvoiceMiniFieldsFragment = {
  __typename?: 'InvoiceMini';
  id?: string | null;
  number?: string | null;
  state?: string | null;
  type?: string | null;
};

export type SubscriptionFieldsUserFragment = {
  __typename?: 'SubscribeToPlan';
  org?: {
    __typename?: 'Organization';
    users?: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      fullName?: string | null;
      createdAt?: Date | null;
      updatedAt?: Date | null;
    }> | null;
  } | null;
  user?: {
    __typename?: 'User';
    createdAt?: Date | null;
    defaultApplicationSerpCountry?: string | null;
    displayExactDistribution?: boolean | null;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    id: string;
    lastName?: string | null;
    onboardingProgress?: { [key: string]: any } | null;
    onboardingStep?: string | null;
    orgRole?: string | null;
    phone?: string | null;
    shouldReceiveBriefOrderedNotifications?: boolean | null;
    suspended?: boolean | null;
    sfdcContactId?: string | null;
    updatedAt?: Date | null;
  } | null;
  subscription?: {
    __typename?: 'RecurlySubscription';
    activatedAt?: Date | null;
    addOnsTotal?: number | null;
    autoRenew?: boolean | null;
    bankAccountAuthorizedAt?: Date | null;
    billingInfoId?: string | null;
    canceledAt?: Date | null;
    collectionMethod?: string | null;
    createdAt?: Date | null;
    currency?: string | null;
    currentPeriodEndsAt?: Date | null;
    currentPeriodStartedAt?: Date | null;
    currentTermEndsAt?: Date | null;
    currentTermStartedAt?: Date | null;
    customerNotes?: string | null;
    expirationReason?: string | null;
    expiresAt?: Date | null;
    gatewayCode?: string | null;
    id?: string | null;
    netTerms?: number | null;
    object?: string | null;
    pausedAt?: Date | null;
    poNumber?: string | null;
    quantity?: number | null;
    remainingBillingCycles?: number | null;
    remainingPauseCycles?: number | null;
    renewalBillingCycles?: number | null;
    revenueScheduleType?: string | null;
    state?: string | null;
    subtotal?: number | null;
    tax?: number | null;
    termsAndConditions?: string | null;
    total?: number | null;
    totalBillingCycles?: number | null;
    trialEndsAt?: Date | null;
    trialStartedAt?: Date | null;
    unitAmount?: number | null;
    updatedAt?: Date | null;
    uuid: string;
    customFields?: Array<{
      __typename?: 'CustomField';
      name?: string | null;
      value?: string | null;
    }> | null;
    plan: {
      __typename?: 'PlanMini';
      code?: string | null;
      id?: string | null;
      name?: string | null;
      object?: string | null;
    };
  } | null;
};

export type SubscriptionFieldsSiteFragment = {
  __typename?: 'SubscribeToPlan';
  org?: {
    __typename?: 'Organization';
    recurlyAccountCode?: string | null;
    recurlyAccountCodeTest?: string | null;
    sites?: Array<{
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    }> | null;
  } | null;
  site?: {
    __typename?: 'Site';
    appRuns?: number | null;
    appRunsExtra?: number | null;
    appRunsLastReset?: Date | null;
    appRunsLimit?: number | null;
    appRunsResetInterval?: any | null;
    appSumoCodes?: Array<string> | null;
    briefCredits?: number | null;
    cancelled: boolean;
    cancelledAt?: Date | null;
    competitorDomains?: Array<string> | null;
    createdAt?: Date | null;
    defaultSerpCountry?: string | null;
    displayContentData?: boolean | null;
    domain: string;
    dunning?: boolean | null;
    dunningCardUpdated?: boolean | null;
    dunningExpired?: boolean | null;
    id: string;
    invId?: number | null;
    level: SiteLevel;
    networkDomains?: Array<string> | null;
    title?: string | null;
    updatedAt?: Date | null;
    visible: boolean;
    documentBriefUserInput?: {
      __typename?: 'DocumentBriefUserInput';
      buyerOrInformationJourney?: string | null;
      contextLinguistic?: string | null;
      expertise?: string | null;
      factStatements?: string | null;
      intentAnalysis?: string | null;
      marketingAndMessagingReferences?: Array<string> | null;
      personalExperience?: string | null;
      personalizationDetail?: string | null;
      pointOfView?: string | null;
      proofStatements?: string | null;
      structuring?: string | null;
      style?: string | null;
      targetMarket?: string | null;
      tone?: string | null;
      voice?: string | null;
    } | null;
  } | null;
  subscription?: {
    __typename?: 'RecurlySubscription';
    activatedAt?: Date | null;
    addOnsTotal?: number | null;
    autoRenew?: boolean | null;
    bankAccountAuthorizedAt?: Date | null;
    billingInfoId?: string | null;
    canceledAt?: Date | null;
    collectionMethod?: string | null;
    createdAt?: Date | null;
    currency?: string | null;
    currentPeriodEndsAt?: Date | null;
    currentPeriodStartedAt?: Date | null;
    currentTermEndsAt?: Date | null;
    currentTermStartedAt?: Date | null;
    customerNotes?: string | null;
    expirationReason?: string | null;
    expiresAt?: Date | null;
    gatewayCode?: string | null;
    id?: string | null;
    netTerms?: number | null;
    object?: string | null;
    pausedAt?: Date | null;
    poNumber?: string | null;
    quantity?: number | null;
    remainingBillingCycles?: number | null;
    remainingPauseCycles?: number | null;
    renewalBillingCycles?: number | null;
    revenueScheduleType?: string | null;
    state?: string | null;
    subtotal?: number | null;
    tax?: number | null;
    termsAndConditions?: string | null;
    total?: number | null;
    totalBillingCycles?: number | null;
    trialEndsAt?: Date | null;
    trialStartedAt?: Date | null;
    unitAmount?: number | null;
    updatedAt?: Date | null;
    uuid: string;
    customFields?: Array<{
      __typename?: 'CustomField';
      name?: string | null;
      value?: string | null;
    }> | null;
    plan: {
      __typename?: 'PlanMini';
      code?: string | null;
      id?: string | null;
      name?: string | null;
      object?: string | null;
    };
  } | null;
};

export type RecurlySubscriptionFieldsFragment = {
  __typename?: 'RecurlySubscription';
  activatedAt?: Date | null;
  addOnsTotal?: number | null;
  autoRenew?: boolean | null;
  bankAccountAuthorizedAt?: Date | null;
  billingInfoId?: string | null;
  canceledAt?: Date | null;
  collectionMethod?: string | null;
  createdAt?: Date | null;
  currency?: string | null;
  currentPeriodEndsAt?: Date | null;
  currentPeriodStartedAt?: Date | null;
  currentTermEndsAt?: Date | null;
  currentTermStartedAt?: Date | null;
  customerNotes?: string | null;
  expirationReason?: string | null;
  expiresAt?: Date | null;
  gatewayCode?: string | null;
  id?: string | null;
  netTerms?: number | null;
  object?: string | null;
  pausedAt?: Date | null;
  poNumber?: string | null;
  quantity?: number | null;
  remainingBillingCycles?: number | null;
  remainingPauseCycles?: number | null;
  renewalBillingCycles?: number | null;
  revenueScheduleType?: string | null;
  state?: string | null;
  subtotal?: number | null;
  tax?: number | null;
  termsAndConditions?: string | null;
  total?: number | null;
  totalBillingCycles?: number | null;
  trialEndsAt?: Date | null;
  trialStartedAt?: Date | null;
  unitAmount?: number | null;
  updatedAt?: Date | null;
  uuid: string;
  customFields?: Array<{
    __typename?: 'CustomField';
    name?: string | null;
    value?: string | null;
  }> | null;
  plan: {
    __typename?: 'PlanMini';
    code?: string | null;
    id?: string | null;
    name?: string | null;
    object?: string | null;
  };
};

export type RecurlySubscriptionAddOnFieldsFragment = {
  __typename?: 'RecurlySubscriptionAddon';
  addOnSource?: string | null;
  createdAt?: Date | null;
  expiredAt?: Date | null;
  id?: string | null;
  object?: string | null;
  quantity?: number | null;
  revenueScheduleType?: string | null;
  subscriptionId?: string | null;
  tierType?: string | null;
  unitAmount?: number | null;
  unitAmountDecimal?: string | null;
  updatedAt?: Date | null;
  usagePercentage?: number | null;
  addOn?: {
    __typename?: 'AddOnMini';
    accountingCode?: string | null;
    addOnType?: string | null;
    code?: string | null;
    externalSku?: string | null;
    id?: string | null;
    itemId?: string | null;
    measuredUnitId?: string | null;
    name?: string | null;
    object?: string | null;
    usagePercentage?: number | null;
    usageType?: string | null;
  } | null;
  tiers?: Array<{
    __typename?: 'SubscriptionAddOnTier';
    endingQuantity?: number | null;
    unitAmount?: number | null;
    unitAmountDecimal?: string | null;
    usagePercentage?: string | null;
  }> | null;
};

export type RecurlySubscriptionChangeFieldsFragment = {
  __typename?: 'SubscriptionChange';
  activateAt?: Date | null;
  activated?: boolean | null;
  createdAt?: Date | null;
  deletedAt?: Date | null;
  id?: string | null;
  object?: string | null;
  quantity?: number | null;
  revenueScheduleType?: string | null;
  subscriptionId?: string | null;
  taxInclusive?: boolean | null;
  unitAmount?: number | null;
  updatedAt?: Date | null;
  addOns?: Array<{
    __typename?: 'RecurlySubscriptionAddon';
    addOnSource?: string | null;
    createdAt?: Date | null;
    expiredAt?: Date | null;
    id?: string | null;
    object?: string | null;
    quantity?: number | null;
    revenueScheduleType?: string | null;
    subscriptionId?: string | null;
    tierType?: string | null;
    unitAmount?: number | null;
    unitAmountDecimal?: string | null;
    updatedAt?: Date | null;
    usagePercentage?: number | null;
    addOn?: {
      __typename?: 'AddOnMini';
      accountingCode?: string | null;
      addOnType?: string | null;
      code?: string | null;
      externalSku?: string | null;
      id?: string | null;
      itemId?: string | null;
      measuredUnitId?: string | null;
      name?: string | null;
      object?: string | null;
      usagePercentage?: number | null;
      usageType?: string | null;
    } | null;
    tiers?: Array<{
      __typename?: 'SubscriptionAddOnTier';
      endingQuantity?: number | null;
      unitAmount?: number | null;
      unitAmountDecimal?: string | null;
      usagePercentage?: string | null;
    }> | null;
  }> | null;
  billingInfo?: {
    __typename?: 'SubscriptionChangeBillingInfo';
    threeDSecureActionResultTokenId?: string | null;
  } | null;
  customFields?: Array<{
    __typename?: 'CustomField';
    name?: string | null;
    value?: string | null;
  }> | null;
  invoiceCollection?: {
    __typename?: 'InvoiceCollection';
    object?: string | null;
    chargeInvoice?: {
      __typename?: 'Invoice';
      balance?: number | null;
      billingInfoId?: string | null;
      closedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerNotes?: string | null;
      discount?: number | null;
      dueAt?: Date | null;
      dunningCampaignId?: string | null;
      hasMoreLineItems?: boolean | null;
      id?: string | null;
      netTerms?: number | null;
      number?: string | null;
      object?: string | null;
      origin?: string | null;
      paid?: number | null;
      poNumber?: string | null;
      previousInvoiceId?: string | null;
      refundableAmount?: number | null;
      state?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      subtotal?: number | null;
      tax?: number | null;
      termsAndConditions?: string | null;
      total?: number | null;
      type?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
      vatReverseChargeNotes?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      address?: {
        __typename?: 'InvoiceAddress';
        city?: string | null;
        company?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        nameOnAccount?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      creditPayments?: Array<{
        __typename?: 'CreditPayment';
        action?: string | null;
        amount?: number | null;
        createdAt?: Date | null;
        currency?: string | null;
        id?: string | null;
        object?: string | null;
        originalCreditPaymentId?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        appliedToInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        originalInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        refundTransaction?: {
          __typename?: 'Transaction';
          amount?: number | null;
          avsCheck?: string | null;
          backupPaymentMethodUsed?: boolean | null;
          collectedAt?: Date | null;
          collectionMethod?: string | null;
          createdAt?: Date | null;
          currency?: string | null;
          customerMessage?: string | null;
          customerMessageLocale?: string | null;
          cvvCheck?: string | null;
          gatewayApprovalCode?: string | null;
          gatewayMessage?: string | null;
          gatewayReference?: string | null;
          gatewayResponseCode?: string | null;
          gatewayResponseTime?: number | null;
          gatewayResponseValues?: { [key: string]: any } | null;
          id: string;
          ipAddressCountry?: string | null;
          ipAddressV4?: string | null;
          object?: string | null;
          origin?: string | null;
          originalTransactionId?: string | null;
          refunded?: boolean | null;
          status?: string | null;
          statusCode?: string | null;
          statusMessage?: string | null;
          subscriptionIds?: { [key: string]: any } | null;
          success?: boolean | null;
          type?: string | null;
          updatedAt?: Date | null;
          uuid: string;
          voidedAt?: Date | null;
          account?: {
            __typename?: 'AccountMini';
            billTo?: string | null;
            code?: string | null;
            company?: string | null;
            dunningCampaignId?: string | null;
            email?: string | null;
            firstName?: string | null;
            id?: string | null;
            lastName?: string | null;
            parentAccountId?: string | null;
          } | null;
          billingAddress?: {
            __typename?: 'AddressWithName';
            city?: string | null;
            country?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            postalCode?: string | null;
            region?: string | null;
            street1?: string | null;
            street2?: string | null;
          } | null;
          invoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
          paymentGateway?: {
            __typename?: 'TransactionPaymentGateway';
            id?: string | null;
            name?: string | null;
            type?: string | null;
          } | null;
          paymentMethod?: {
            __typename?: 'PaymentMethod';
            accountType?: string | null;
            billingAgreementId?: string | null;
            cardType?: string | null;
            ccBinCountry?: string | null;
            expMonth?: number | null;
            expYear?: number | null;
            firstSix?: string | null;
            gatewayCode?: string | null;
            gatewayToken?: string | null;
            lastFour?: string | null;
            lastTwo?: string | null;
            nameOnAccount?: string | null;
            routingNumber?: string | null;
            routingNumberBank?: string | null;
          } | null;
          voidedByInvoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
        } | null;
      }> | null;
      lineItems?: Array<{
        __typename?: 'LineItem';
        accountingCode?: string | null;
        addOnCode?: string | null;
        addOnId?: string | null;
        amount?: number | null;
        avalaraServiceType?: number | null;
        avalaraTransactionType?: number | null;
        billForAccountId?: string | null;
        createdAt?: Date | null;
        creditApplied?: number | null;
        creditReasonCode?: string | null;
        currency?: string | null;
        description?: string | null;
        discount?: number | null;
        endDate?: Date | null;
        externalSku?: string | null;
        id?: string | null;
        invoiceId?: string | null;
        invoiceNumber?: string | null;
        itemCode?: string | null;
        itemId?: string | null;
        legacyCategory?: string | null;
        object?: string | null;
        origin?: string | null;
        originalLineItemInvoiceId?: string | null;
        planCode?: string | null;
        planId?: string | null;
        previousLineItemId?: string | null;
        productCode?: string | null;
        prorationRate?: number | null;
        quantity?: number | null;
        refund?: boolean | null;
        refundedQuantity?: number | null;
        revenueScheduleType?: string | null;
        startDate?: Date | null;
        state?: string | null;
        subscriptionId?: string | null;
        subtotal?: number | null;
        tax?: number | null;
        taxCode?: string | null;
        taxExempt?: boolean | null;
        taxable?: boolean | null;
        type?: string | null;
        unitAmount?: number | null;
        unitAmountDecimal?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        shippingAddress?: {
          __typename?: 'ShippingAddress';
          accountId?: string | null;
          city?: string | null;
          company?: string | null;
          country?: string | null;
          createdAt?: Date | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          nickname?: string | null;
          object?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
          updatedAt?: Date | null;
          vatNumber?: string | null;
        } | null;
        taxInfo?: {
          __typename?: 'TaxInfo';
          rate?: number | null;
          region?: string | null;
          type?: string | null;
          taxDetails?: Array<{
            __typename?: 'TaxDetail';
            rate?: number | null;
            region?: string | null;
            tax?: number | null;
            type?: string | null;
          }> | null;
        } | null;
      }> | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      }> | null;
    } | null;
    creditInvoices?: Array<{
      __typename?: 'Invoice';
      balance?: number | null;
      billingInfoId?: string | null;
      closedAt?: Date | null;
      collectionMethod?: string | null;
      createdAt?: Date | null;
      currency?: string | null;
      customerNotes?: string | null;
      discount?: number | null;
      dueAt?: Date | null;
      dunningCampaignId?: string | null;
      hasMoreLineItems?: boolean | null;
      id?: string | null;
      netTerms?: number | null;
      number?: string | null;
      object?: string | null;
      origin?: string | null;
      paid?: number | null;
      poNumber?: string | null;
      previousInvoiceId?: string | null;
      refundableAmount?: number | null;
      state?: string | null;
      subscriptionIds?: { [key: string]: any } | null;
      subtotal?: number | null;
      tax?: number | null;
      termsAndConditions?: string | null;
      total?: number | null;
      type?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
      vatReverseChargeNotes?: string | null;
      account?: {
        __typename?: 'AccountMini';
        billTo?: string | null;
        code?: string | null;
        company?: string | null;
        dunningCampaignId?: string | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        parentAccountId?: string | null;
      } | null;
      address?: {
        __typename?: 'InvoiceAddress';
        city?: string | null;
        company?: string | null;
        country?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        nameOnAccount?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
      } | null;
      creditPayments?: Array<{
        __typename?: 'CreditPayment';
        action?: string | null;
        amount?: number | null;
        createdAt?: Date | null;
        currency?: string | null;
        id?: string | null;
        object?: string | null;
        originalCreditPaymentId?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        appliedToInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        originalInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        refundTransaction?: {
          __typename?: 'Transaction';
          amount?: number | null;
          avsCheck?: string | null;
          backupPaymentMethodUsed?: boolean | null;
          collectedAt?: Date | null;
          collectionMethod?: string | null;
          createdAt?: Date | null;
          currency?: string | null;
          customerMessage?: string | null;
          customerMessageLocale?: string | null;
          cvvCheck?: string | null;
          gatewayApprovalCode?: string | null;
          gatewayMessage?: string | null;
          gatewayReference?: string | null;
          gatewayResponseCode?: string | null;
          gatewayResponseTime?: number | null;
          gatewayResponseValues?: { [key: string]: any } | null;
          id: string;
          ipAddressCountry?: string | null;
          ipAddressV4?: string | null;
          object?: string | null;
          origin?: string | null;
          originalTransactionId?: string | null;
          refunded?: boolean | null;
          status?: string | null;
          statusCode?: string | null;
          statusMessage?: string | null;
          subscriptionIds?: { [key: string]: any } | null;
          success?: boolean | null;
          type?: string | null;
          updatedAt?: Date | null;
          uuid: string;
          voidedAt?: Date | null;
          account?: {
            __typename?: 'AccountMini';
            billTo?: string | null;
            code?: string | null;
            company?: string | null;
            dunningCampaignId?: string | null;
            email?: string | null;
            firstName?: string | null;
            id?: string | null;
            lastName?: string | null;
            parentAccountId?: string | null;
          } | null;
          billingAddress?: {
            __typename?: 'AddressWithName';
            city?: string | null;
            country?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            phone?: string | null;
            postalCode?: string | null;
            region?: string | null;
            street1?: string | null;
            street2?: string | null;
          } | null;
          invoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
          paymentGateway?: {
            __typename?: 'TransactionPaymentGateway';
            id?: string | null;
            name?: string | null;
            type?: string | null;
          } | null;
          paymentMethod?: {
            __typename?: 'PaymentMethod';
            accountType?: string | null;
            billingAgreementId?: string | null;
            cardType?: string | null;
            ccBinCountry?: string | null;
            expMonth?: number | null;
            expYear?: number | null;
            firstSix?: string | null;
            gatewayCode?: string | null;
            gatewayToken?: string | null;
            lastFour?: string | null;
            lastTwo?: string | null;
            nameOnAccount?: string | null;
            routingNumber?: string | null;
            routingNumberBank?: string | null;
          } | null;
          voidedByInvoice?: {
            __typename?: 'InvoiceMini';
            id?: string | null;
            number?: string | null;
            state?: string | null;
            type?: string | null;
          } | null;
        } | null;
      }> | null;
      lineItems?: Array<{
        __typename?: 'LineItem';
        accountingCode?: string | null;
        addOnCode?: string | null;
        addOnId?: string | null;
        amount?: number | null;
        avalaraServiceType?: number | null;
        avalaraTransactionType?: number | null;
        billForAccountId?: string | null;
        createdAt?: Date | null;
        creditApplied?: number | null;
        creditReasonCode?: string | null;
        currency?: string | null;
        description?: string | null;
        discount?: number | null;
        endDate?: Date | null;
        externalSku?: string | null;
        id?: string | null;
        invoiceId?: string | null;
        invoiceNumber?: string | null;
        itemCode?: string | null;
        itemId?: string | null;
        legacyCategory?: string | null;
        object?: string | null;
        origin?: string | null;
        originalLineItemInvoiceId?: string | null;
        planCode?: string | null;
        planId?: string | null;
        previousLineItemId?: string | null;
        productCode?: string | null;
        prorationRate?: number | null;
        quantity?: number | null;
        refund?: boolean | null;
        refundedQuantity?: number | null;
        revenueScheduleType?: string | null;
        startDate?: Date | null;
        state?: string | null;
        subscriptionId?: string | null;
        subtotal?: number | null;
        tax?: number | null;
        taxCode?: string | null;
        taxExempt?: boolean | null;
        taxable?: boolean | null;
        type?: string | null;
        unitAmount?: number | null;
        unitAmountDecimal?: string | null;
        updatedAt?: Date | null;
        uuid?: string | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
        } | null;
        shippingAddress?: {
          __typename?: 'ShippingAddress';
          accountId?: string | null;
          city?: string | null;
          company?: string | null;
          country?: string | null;
          createdAt?: Date | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          nickname?: string | null;
          object?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
          updatedAt?: Date | null;
          vatNumber?: string | null;
        } | null;
        taxInfo?: {
          __typename?: 'TaxInfo';
          rate?: number | null;
          region?: string | null;
          type?: string | null;
          taxDetails?: Array<{
            __typename?: 'TaxDetail';
            rate?: number | null;
            region?: string | null;
            tax?: number | null;
            type?: string | null;
          }> | null;
        } | null;
      }> | null;
      shippingAddress?: {
        __typename?: 'ShippingAddress';
        accountId?: string | null;
        city?: string | null;
        company?: string | null;
        country?: string | null;
        createdAt?: Date | null;
        email?: string | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        object?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        region?: string | null;
        street1?: string | null;
        street2?: string | null;
        updatedAt?: Date | null;
        vatNumber?: string | null;
      } | null;
      taxInfo?: {
        __typename?: 'TaxInfo';
        rate?: number | null;
        region?: string | null;
        type?: string | null;
        taxDetails?: Array<{
          __typename?: 'TaxDetail';
          rate?: number | null;
          region?: string | null;
          tax?: number | null;
          type?: string | null;
        }> | null;
      } | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        amount?: number | null;
        avsCheck?: string | null;
        backupPaymentMethodUsed?: boolean | null;
        collectedAt?: Date | null;
        collectionMethod?: string | null;
        createdAt?: Date | null;
        currency?: string | null;
        customerMessage?: string | null;
        customerMessageLocale?: string | null;
        cvvCheck?: string | null;
        gatewayApprovalCode?: string | null;
        gatewayMessage?: string | null;
        gatewayReference?: string | null;
        gatewayResponseCode?: string | null;
        gatewayResponseTime?: number | null;
        gatewayResponseValues?: { [key: string]: any } | null;
        id: string;
        ipAddressCountry?: string | null;
        ipAddressV4?: string | null;
        object?: string | null;
        origin?: string | null;
        originalTransactionId?: string | null;
        refunded?: boolean | null;
        status?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
        subscriptionIds?: { [key: string]: any } | null;
        success?: boolean | null;
        type?: string | null;
        updatedAt?: Date | null;
        uuid: string;
        voidedAt?: Date | null;
        account?: {
          __typename?: 'AccountMini';
          billTo?: string | null;
          code?: string | null;
          company?: string | null;
          dunningCampaignId?: string | null;
          email?: string | null;
          firstName?: string | null;
          id?: string | null;
          lastName?: string | null;
          parentAccountId?: string | null;
        } | null;
        billingAddress?: {
          __typename?: 'AddressWithName';
          city?: string | null;
          country?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
          postalCode?: string | null;
          region?: string | null;
          street1?: string | null;
          street2?: string | null;
        } | null;
        invoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
        paymentGateway?: {
          __typename?: 'TransactionPaymentGateway';
          id?: string | null;
          name?: string | null;
          type?: string | null;
        } | null;
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          accountType?: string | null;
          billingAgreementId?: string | null;
          cardType?: string | null;
          ccBinCountry?: string | null;
          expMonth?: number | null;
          expYear?: number | null;
          firstSix?: string | null;
          gatewayCode?: string | null;
          gatewayToken?: string | null;
          lastFour?: string | null;
          lastTwo?: string | null;
          nameOnAccount?: string | null;
          routingNumber?: string | null;
          routingNumberBank?: string | null;
        } | null;
        voidedByInvoice?: {
          __typename?: 'InvoiceMini';
          id?: string | null;
          number?: string | null;
          state?: string | null;
          type?: string | null;
        } | null;
      }> | null;
    }> | null;
  } | null;
  plan?: {
    __typename?: 'PlanMini';
    code?: string | null;
    id?: string | null;
    name?: string | null;
    object?: string | null;
  } | null;
  shipping?: {
    __typename?: 'SubscriptionShipping';
    amount?: number | null;
    object?: string | null;
    address?: {
      __typename?: 'ShippingAddress';
      accountId?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      createdAt?: Date | null;
      email?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      nickname?: string | null;
      object?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      region?: string | null;
      street1?: string | null;
      street2?: string | null;
      updatedAt?: Date | null;
      vatNumber?: string | null;
    } | null;
    method?: {
      __typename?: 'ShippingMethodMini';
      code?: string | null;
      id?: string | null;
      name?: string | null;
      object?: string | null;
    } | null;
  } | null;
};

export type RecurlySubscriptionShippingFieldsFragment = {
  __typename?: 'SubscriptionShipping';
  amount?: number | null;
  object?: string | null;
  address?: {
    __typename?: 'ShippingAddress';
    accountId?: string | null;
    city?: string | null;
    company?: string | null;
    country?: string | null;
    createdAt?: Date | null;
    email?: string | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    nickname?: string | null;
    object?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    region?: string | null;
    street1?: string | null;
    street2?: string | null;
    updatedAt?: Date | null;
    vatNumber?: string | null;
  } | null;
  method?: {
    __typename?: 'ShippingMethodMini';
    code?: string | null;
    id?: string | null;
    name?: string | null;
    object?: string | null;
  } | null;
};

export type SubscriptionAddonTypeFieldsFragment = {
  __typename?: 'SubscriptionAddonType';
  id: string;
  description?: string | null;
  price?: number | null;
  type: SubscriptionAddonTypeType;
  validFromDate: Date;
  validUntilDate: Date;
};

export type SubscriptionPlanFieldsFragment = {
  __typename?: 'SubscriptionPlan';
  active: boolean;
  appQueryCount: number;
  analysisAndPlanningDocumentAllocationLimit: number;
  briefDocumentAllocationLimit: number;
  id: string;
  planCode: string;
  price: number;
  term: SubscriptionPlanTerm;
  termLength: number;
  type: SubscriptionPlanType;
  userCanDowngradePackage: boolean;
  userCanUpgradePackage: boolean;
  usersArePurchasable: boolean;
  usersCountDefault?: number | null;
};

export type SubscriptionEntityFieldsFragment = {
  __typename?: 'SubscriptionEntity';
  createdAt: Date;
  dunning?: boolean | null;
  endsOnDate: Date;
  id: string;
  externalSubscriptionId?: string | null;
  conversionMethod: SubscriptionConversionMethod;
  startedOnDate: Date;
  state: SubscriptionState;
  price?: number | null;
  demo?: boolean | null;
  subscriptionPlan: {
    __typename?: 'SubscriptionPlan';
    active: boolean;
    appQueryCount: number;
    analysisAndPlanningDocumentAllocationLimit: number;
    briefDocumentAllocationLimit: number;
    id: string;
    planCode: string;
    price: number;
    term: SubscriptionPlanTerm;
    termLength: number;
    type: SubscriptionPlanType;
    userCanDowngradePackage: boolean;
    userCanUpgradePackage: boolean;
    usersArePurchasable: boolean;
    usersCountDefault?: number | null;
  };
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug: string;
    clientTier?: ClientTier | null;
  };
  site?: {
    __typename?: 'Site';
    cancelled: boolean;
    domain: string;
    id: string;
    level: SiteLevel;
    title?: string | null;
    visible: boolean;
  } | null;
  addons?: Array<{
    __typename?: 'SubscriptionAddon';
    id: string;
    addonState?: SubscriptionAddonState | null;
    mode?: SubscriptionAddonMode | null;
    addonType: {
      __typename?: 'SubscriptionAddonType';
      id: string;
      description?: string | null;
      price?: number | null;
      type: SubscriptionAddonTypeType;
      validFromDate: Date;
      validUntilDate: Date;
    };
    site?: {
      __typename?: 'Site';
      cancelled: boolean;
      domain: string;
      id: string;
      level: SiteLevel;
      title?: string | null;
      visible: boolean;
    } | null;
  }> | null;
};

export type RecurlyAddressFieldsFragment = {
  __typename?: 'Address';
  city?: string | null;
  country?: string | null;
  phone?: string | null;
  postalCode?: string | null;
  region?: string | null;
  street1?: string | null;
  street2?: string | null;
};

export type RecurlyBillingInfoFieldsFragment = {
  __typename?: 'BillingInfo';
  accountId?: string | null;
  id?: string | null;
  company?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  backupPaymentMethod?: boolean | null;
  primaryPaymentMethod?: boolean | null;
  object?: string | null;
  valid?: boolean | null;
  vatNumber?: string | null;
  updatedAt?: Date | null;
  createdAt?: Date | null;
  address?: {
    __typename?: 'Address';
    city?: string | null;
    country?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    region?: string | null;
    street1?: string | null;
    street2?: string | null;
  } | null;
  paymentMethod?: {
    __typename?: 'PaymentMethod';
    cardType?: string | null;
    expMonth?: number | null;
    expYear?: number | null;
    firstSix?: string | null;
    gatewayCode?: string | null;
    gatewayToken?: string | null;
    lastFour?: string | null;
    lastTwo?: string | null;
    nameOnAccount?: string | null;
    routingNumber?: string | null;
    routingNumberBank?: string | null;
  } | null;
};

export type RecurlyPaymentMethodFieldsFragment = {
  __typename?: 'PaymentMethod';
  cardType?: string | null;
  expMonth?: number | null;
  expYear?: number | null;
  firstSix?: string | null;
  gatewayCode?: string | null;
  gatewayToken?: string | null;
  lastFour?: string | null;
  lastTwo?: string | null;
  nameOnAccount?: string | null;
  routingNumber?: string | null;
  routingNumberBank?: string | null;
};

export type RecurlyPlanMiniFieldsFragment = {
  __typename?: 'PlanMini';
  code?: string | null;
  id?: string | null;
  name?: string | null;
  object?: string | null;
};

export type AdWordsItemFieldsFragment = {
  __typename?: 'AdWordsItem';
  term: string;
  costPerClick?: number | null;
  volume?: number | null;
  existsInInventory?: boolean | null;
  history?: Array<{
    __typename?: 'History';
    yearMonth?: string | null;
    volume?: number | null;
  }> | null;
};

export type KgItemFragment = {
  __typename?: 'KnowledgeGraphItem';
  recommendedMentions?: number | null;
  existsInInventory?: boolean | null;
  relevanceScore?: number | null;
  term: string;
  userProvidedPageMentions?: number | null;
};

export type OrganicSerpItemPageKgDataFieldsFragment = {
  __typename?: 'OrganicSerpItemPageKgData';
  contentScore?: number | null;
  position?: number | null;
  url: string;
  mentions?: Array<{
    __typename?: 'MentionItem';
    term: string;
    count: number;
  }> | null;
};

export type SavedViewFieldsFragment = {
  __typename?: 'SavedView';
  createdAt?: Date | null;
  updatedAt?: Date | null;
  id: string;
  title?: string | null;
  description?: string | null;
  inventoryType: InventoryType;
  filters?: { [key: string]: any } | null;
  pinned?: { [key: string]: any } | null;
  hidden?: { [key: string]: any } | null;
  order?: { [key: string]: any } | null;
  sorting?: { [key: string]: any } | null;
  sharing?: SavedViewSharing | null;
};

export type SiteFieldsBaseFragment = {
  __typename?: 'Site';
  cancelled: boolean;
  domain: string;
  id: string;
  level: SiteLevel;
  title?: string | null;
  visible: boolean;
};

export type SiteFieldsFragment = {
  __typename?: 'Site';
  briefCredits?: number | null;
  cancelled: boolean;
  displayContentData?: boolean | null;
  domain: string;
  id: string;
  invId?: number | null;
  level: SiteLevel;
  title?: string | null;
  visible: boolean;
};

export type SiteFieldsAllFragment = {
  __typename?: 'Site';
  appRuns?: number | null;
  appRunsExtra?: number | null;
  appRunsLastReset?: Date | null;
  appRunsLimit?: number | null;
  appRunsResetInterval?: any | null;
  appSumoCodes?: Array<string> | null;
  briefCredits?: number | null;
  cancelled: boolean;
  cancelledAt?: Date | null;
  competitorDomains?: Array<string> | null;
  createdAt?: Date | null;
  defaultSerpCountry?: string | null;
  displayContentData?: boolean | null;
  domain: string;
  dunning?: boolean | null;
  dunningCardUpdated?: boolean | null;
  dunningExpired?: boolean | null;
  id: string;
  invId?: number | null;
  level: SiteLevel;
  networkDomains?: Array<string> | null;
  title?: string | null;
  updatedAt?: Date | null;
  visible: boolean;
  documentBriefUserInput?: {
    __typename?: 'DocumentBriefUserInput';
    buyerOrInformationJourney?: string | null;
    contextLinguistic?: string | null;
    expertise?: string | null;
    factStatements?: string | null;
    intentAnalysis?: string | null;
    marketingAndMessagingReferences?: Array<string> | null;
    personalExperience?: string | null;
    personalizationDetail?: string | null;
    pointOfView?: string | null;
    proofStatements?: string | null;
    structuring?: string | null;
    style?: string | null;
    targetMarket?: string | null;
    tone?: string | null;
    voice?: string | null;
  } | null;
};

export type SiteInventoryDataFieldsFragment = {
  __typename?: 'Site';
  valuePerVisit?: number | null;
  subdomains?: Array<string> | null;
  excludePaths?: Array<string> | null;
  includePaths?: Array<string> | null;
  autoUpdate?: boolean | null;
  recommendedTopics?: number | null;
  topicsAllocated?: number | null;
  topicsInUse?: number | null;
};

export type UserFieldsBaseFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  fullName?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
};

export type UserFieldsFragment = {
  __typename?: 'User';
  createdAt?: Date | null;
  defaultApplicationSerpCountry?: string | null;
  displayExactDistribution?: boolean | null;
  email: string;
  firstName?: string | null;
  fullName?: string | null;
  id: string;
  lastName?: string | null;
  onboardingProgress?: { [key: string]: any } | null;
  onboardingStep?: string | null;
  orgRole?: string | null;
  phone?: string | null;
  shouldReceiveBriefOrderedNotifications?: boolean | null;
  suspended?: boolean | null;
  sfdcContactId?: string | null;
  updatedAt?: Date | null;
};

export type HistoryFieldsFragment = {
  __typename?: 'History';
  yearMonth?: string | null;
  volume?: number | null;
};

export type AccountKeySpecifier = (
  | 'address'
  | 'billTo'
  | 'billingInfo'
  | 'ccEmails'
  | 'code'
  | 'company'
  | 'createdAt'
  | 'customFields'
  | 'deletedAt'
  | 'dunningCampaignId'
  | 'email'
  | 'exemptionCertificate'
  | 'firstName'
  | 'hasActiveSubscription'
  | 'hasCanceledSubscription'
  | 'hasFutureSubscription'
  | 'hasLiveSubscription'
  | 'hasPastDueInvoice'
  | 'hasPausedSubscription'
  | 'hostedLoginToken'
  | 'id'
  | 'invoiceTemplate'
  | 'lastName'
  | 'object'
  | 'parentAccountId'
  | 'preferredLocale'
  | 'shippingAddresses'
  | 'state'
  | 'taxExempt'
  | 'updatedAt'
  | 'username'
  | 'vatNumber'
  | AccountKeySpecifier
)[];
export type AccountFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  billTo?: FieldPolicy<any> | FieldReadFunction<any>;
  billingInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  ccEmails?: FieldPolicy<any> | FieldReadFunction<any>;
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customFields?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  dunningCampaignId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  exemptionCertificate?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  hasActiveSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  hasCanceledSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  hasFutureSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  hasLiveSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  hasPastDueInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  hasPausedSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  hostedLoginToken?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  invoiceTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  parentAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredLocale?: FieldPolicy<any> | FieldReadFunction<any>;
  shippingAddresses?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  taxExempt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  vatNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountInvoiceTemplateKeySpecifier = (
  | 'id'
  | 'name'
  | AccountInvoiceTemplateKeySpecifier
)[];
export type AccountInvoiceTemplateFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AccountMiniKeySpecifier = (
  | 'billTo'
  | 'code'
  | 'company'
  | 'dunningCampaignId'
  | 'email'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'parentAccountId'
  | AccountMiniKeySpecifier
)[];
export type AccountMiniFieldPolicy = {
  billTo?: FieldPolicy<any> | FieldReadFunction<any>;
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  dunningCampaignId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  parentAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdWordsItemKeySpecifier = (
  | 'categoryIds'
  | 'categoryStrings'
  | 'competition'
  | 'costPerClick'
  | 'existsInInventory'
  | 'history'
  | 'term'
  | 'variants'
  | 'volume'
  | AdWordsItemKeySpecifier
)[];
export type AdWordsItemFieldPolicy = {
  categoryIds?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  competition?: FieldPolicy<any> | FieldReadFunction<any>;
  costPerClick?: FieldPolicy<any> | FieldReadFunction<any>;
  existsInInventory?: FieldPolicy<any> | FieldReadFunction<any>;
  history?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  variants?: FieldPolicy<any> | FieldReadFunction<any>;
  volume?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdWordsItemWithVariantsKeySpecifier = (
  | 'categoryIds'
  | 'categoryStrings'
  | 'competition'
  | 'costPerClick'
  | 'history'
  | 'numVariants'
  | 'term'
  | 'variants'
  | 'volume'
  | AdWordsItemWithVariantsKeySpecifier
)[];
export type AdWordsItemWithVariantsFieldPolicy = {
  categoryIds?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  competition?: FieldPolicy<any> | FieldReadFunction<any>;
  costPerClick?: FieldPolicy<any> | FieldReadFunction<any>;
  history?: FieldPolicy<any> | FieldReadFunction<any>;
  numVariants?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  variants?: FieldPolicy<any> | FieldReadFunction<any>;
  volume?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddOnMiniKeySpecifier = (
  | 'accountingCode'
  | 'addOnType'
  | 'code'
  | 'externalSku'
  | 'id'
  | 'itemId'
  | 'measuredUnitId'
  | 'name'
  | 'object'
  | 'usagePercentage'
  | 'usageType'
  | AddOnMiniKeySpecifier
)[];
export type AddOnMiniFieldPolicy = {
  accountingCode?: FieldPolicy<any> | FieldReadFunction<any>;
  addOnType?: FieldPolicy<any> | FieldReadFunction<any>;
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  externalSku?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  itemId?: FieldPolicy<any> | FieldReadFunction<any>;
  measuredUnitId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  usagePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  usageType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressKeySpecifier = (
  | 'city'
  | 'country'
  | 'phone'
  | 'postalCode'
  | 'region'
  | 'street1'
  | 'street2'
  | AddressKeySpecifier
)[];
export type AddressFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  street1?: FieldPolicy<any> | FieldReadFunction<any>;
  street2?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressWithNameKeySpecifier = (
  | 'city'
  | 'country'
  | 'firstName'
  | 'lastName'
  | 'phone'
  | 'postalCode'
  | 'region'
  | 'street1'
  | 'street2'
  | AddressWithNameKeySpecifier
)[];
export type AddressWithNameFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  street1?: FieldPolicy<any> | FieldReadFunction<any>;
  street2?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppDataParamsKeySpecifier = (
  | 'bodyOrArticle'
  | 'brands'
  | 'competitorSites'
  | 'country'
  | 'language'
  | 'networkSites'
  | 'people'
  | 'topic'
  | 'url'
  | AppDataParamsKeySpecifier
)[];
export type AppDataParamsFieldPolicy = {
  bodyOrArticle?: FieldPolicy<any> | FieldReadFunction<any>;
  brands?: FieldPolicy<any> | FieldReadFunction<any>;
  competitorSites?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  networkSites?: FieldPolicy<any> | FieldReadFunction<any>;
  people?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppQueriesResponseKeySpecifier = (
  | 'count'
  | 'items'
  | AppQueriesResponseKeySpecifier
)[];
export type AppQueriesResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppQueryKeySpecifier = (
  | 'app'
  | 'connectCompetitorDomains'
  | 'connectNetworkDomains'
  | 'country'
  | 'createdAt'
  | 'id'
  | 'jsonMetadata'
  | 'language'
  | 'site'
  | 'status'
  | 'topic'
  | 'updatedAt'
  | 'url'
  | 'user'
  | AppQueryKeySpecifier
)[];
export type AppQueryFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>;
  connectCompetitorDomains?: FieldPolicy<any> | FieldReadFunction<any>;
  connectNetworkDomains?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  jsonMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppRunsKeySpecifier = (
  | 'current'
  | 'error'
  | 'extra'
  | 'lastReset'
  | 'limit'
  | 'nextReset'
  | 'resetInterval'
  | AppRunsKeySpecifier
)[];
export type AppRunsFieldPolicy = {
  current?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  extra?: FieldPolicy<any> | FieldReadFunction<any>;
  lastReset?: FieldPolicy<any> | FieldReadFunction<any>;
  limit?: FieldPolicy<any> | FieldReadFunction<any>;
  nextReset?: FieldPolicy<any> | FieldReadFunction<any>;
  resetInterval?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BillingInfoKeySpecifier = (
  | 'accountId'
  | 'address'
  | 'backupPaymentMethod'
  | 'company'
  | 'createdAt'
  | 'firstName'
  | 'fraud'
  | 'id'
  | 'lastName'
  | 'object'
  | 'paymentMethod'
  | 'primaryPaymentMethod'
  | 'updatedAt'
  | 'updatedBy'
  | 'valid'
  | 'vatNumber'
  | BillingInfoKeySpecifier
)[];
export type BillingInfoFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  backupPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  fraud?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  primaryPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
  vatNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BillingInfoUpdatedByKeySpecifier = (
  | 'country'
  | 'ip'
  | BillingInfoUpdatedByKeySpecifier
)[];
export type BillingInfoUpdatedByFieldPolicy = {
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  ip?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefKeySpecifier = (
  | 'accountManager'
  | 'additionalTopics'
  | 'briefStatus'
  | 'briefTitle'
  | 'comments'
  | 'competitorSites'
  | 'createdAt'
  | 'createdBy'
  | 'csRep'
  | 'cuid'
  | 'dueDate'
  | 'errorStatus'
  | 'focusTopic'
  | 'formData'
  | 'formDraft'
  | 'formId'
  | 'id'
  | 'metadata'
  | 'org'
  | 'page'
  | 'qaAssignee'
  | 'questions'
  | 'rankingTitles'
  | 'reportType'
  | 'selfServe'
  | 'site'
  | 'subheadingTerms'
  | 'subheadings'
  | 'subtopics'
  | 'titleTerms'
  | 'topic'
  | 'updatedAt'
  | 'userAudiences'
  | 'userIntents'
  | BriefKeySpecifier
)[];
export type BriefFieldPolicy = {
  accountManager?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalTopics?: FieldPolicy<any> | FieldReadFunction<any>;
  briefStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  briefTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  competitorSites?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  csRep?: FieldPolicy<any> | FieldReadFunction<any>;
  cuid?: FieldPolicy<any> | FieldReadFunction<any>;
  dueDate?: FieldPolicy<any> | FieldReadFunction<any>;
  errorStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  focusTopic?: FieldPolicy<any> | FieldReadFunction<any>;
  formData?: FieldPolicy<any> | FieldReadFunction<any>;
  formDraft?: FieldPolicy<any> | FieldReadFunction<any>;
  formId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  metadata?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  page?: FieldPolicy<any> | FieldReadFunction<any>;
  qaAssignee?: FieldPolicy<any> | FieldReadFunction<any>;
  questions?: FieldPolicy<any> | FieldReadFunction<any>;
  rankingTitles?: FieldPolicy<any> | FieldReadFunction<any>;
  reportType?: FieldPolicy<any> | FieldReadFunction<any>;
  selfServe?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  subheadingTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  subheadings?: FieldPolicy<any> | FieldReadFunction<any>;
  subtopics?: FieldPolicy<any> | FieldReadFunction<any>;
  titleTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userAudiences?: FieldPolicy<any> | FieldReadFunction<any>;
  userIntents?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefCommentKeySpecifier = (
  | 'createdBy'
  | 'id'
  | 'value'
  | 'visible'
  | BriefCommentKeySpecifier
)[];
export type BriefCommentFieldPolicy = {
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefErrorKeySpecifier = (
  | 'id'
  | 'message'
  | BriefErrorKeySpecifier
)[];
export type BriefErrorFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefLinkKeySpecifier = (
  | 'id'
  | 'parent'
  | 'title'
  | 'url'
  | 'visible'
  | BriefLinkKeySpecifier
)[];
export type BriefLinkFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  parent?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefLinkListKeySpecifier = (
  | 'id'
  | 'links'
  | 'semanticSimilarity'
  | 'visible'
  | BriefLinkListKeySpecifier
)[];
export type BriefLinkListFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  links?: FieldPolicy<any> | FieldReadFunction<any>;
  semanticSimilarity?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefMetadataKeySpecifier = (
  | 'errors'
  | 'id'
  | BriefMetadataKeySpecifier
)[];
export type BriefMetadataFieldPolicy = {
  errors?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefPageKeySpecifier = ('id' | 'url' | BriefPageKeySpecifier)[];
export type BriefPageFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefQuestionKeySpecifier = (
  | 'id'
  | 'semanticSimilarity'
  | 'value'
  | 'visible'
  | BriefQuestionKeySpecifier
)[];
export type BriefQuestionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  semanticSimilarity?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefRankingTitleKeySpecifier = (
  | 'id'
  | 'value'
  | 'visible'
  | BriefRankingTitleKeySpecifier
)[];
export type BriefRankingTitleFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefRelatedTopicKeySpecifier = (
  | 'distribution'
  | 'id'
  | 'powerTopic'
  | 'semanticSimilarity'
  | 'suggestedDistribution'
  | 'value'
  | 'visible'
  | BriefRelatedTopicKeySpecifier
)[];
export type BriefRelatedTopicFieldPolicy = {
  distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  powerTopic?: FieldPolicy<any> | FieldReadFunction<any>;
  semanticSimilarity?: FieldPolicy<any> | FieldReadFunction<any>;
  suggestedDistribution?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefSubheadingKeySpecifier = (
  | 'additionalTopics'
  | 'comments'
  | 'externalLinks'
  | 'id'
  | 'internalLinks'
  | 'position'
  | 'questions'
  | 'relatedTopics'
  | 'title'
  | 'topic'
  | 'value'
  | 'visible'
  | 'wordCount'
  | BriefSubheadingKeySpecifier
)[];
export type BriefSubheadingFieldPolicy = {
  additionalTopics?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  externalLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  internalLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  questions?: FieldPolicy<any> | FieldReadFunction<any>;
  relatedTopics?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefSubheadingTermKeySpecifier = (
  | 'id'
  | 'semanticSimilarity'
  | 'value'
  | 'visible'
  | BriefSubheadingTermKeySpecifier
)[];
export type BriefSubheadingTermFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  semanticSimilarity?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefSubheadingTopicKeySpecifier = (
  | 'contentScoreAvg'
  | 'contentScoreTarget'
  | 'id'
  | 'semanticSimilarity'
  | 'suggestedDistribution'
  | 'term'
  | 'wordCountAvg'
  | 'wordCountTarget'
  | BriefSubheadingTopicKeySpecifier
)[];
export type BriefSubheadingTopicFieldPolicy = {
  contentScoreAvg?: FieldPolicy<any> | FieldReadFunction<any>;
  contentScoreTarget?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  semanticSimilarity?: FieldPolicy<any> | FieldReadFunction<any>;
  suggestedDistribution?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCountAvg?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCountTarget?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefSubtopicKeySpecifier = (
  | 'distribution'
  | 'id'
  | 'powerTopic'
  | 'semanticSimilarity'
  | 'suggestedDistribution'
  | 'value'
  | 'visible'
  | BriefSubtopicKeySpecifier
)[];
export type BriefSubtopicFieldPolicy = {
  distribution?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  powerTopic?: FieldPolicy<any> | FieldReadFunction<any>;
  semanticSimilarity?: FieldPolicy<any> | FieldReadFunction<any>;
  suggestedDistribution?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefTitleTermKeySpecifier = (
  | 'id'
  | 'semanticSimilarity'
  | 'value'
  | 'visible'
  | BriefTitleTermKeySpecifier
)[];
export type BriefTitleTermFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  semanticSimilarity?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefTopicKeySpecifier = (
  | 'contentScoreAvg'
  | 'contentScoreTarget'
  | 'id'
  | 'kgKey'
  | 'term'
  | 'wordCountAvg'
  | 'wordCountTarget'
  | BriefTopicKeySpecifier
)[];
export type BriefTopicFieldPolicy = {
  contentScoreAvg?: FieldPolicy<any> | FieldReadFunction<any>;
  contentScoreTarget?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  kgKey?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCountAvg?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCountTarget?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefUserAudienceKeySpecifier = (
  | 'id'
  | 'value'
  | 'visible'
  | BriefUserAudienceKeySpecifier
)[];
export type BriefUserAudienceFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefUserIntentKeySpecifier = (
  | 'id'
  | 'value'
  | 'visible'
  | BriefUserIntentKeySpecifier
)[];
export type BriefUserIntentFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BriefsResponseKeySpecifier = (
  | 'count'
  | 'items'
  | BriefsResponseKeySpecifier
)[];
export type BriefsResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BusinessReviewKeySpecifier = (
  | 'businessReviewId'
  | 'createdAt'
  | 'createdBy'
  | 'dateRange'
  | 'deliveredAt'
  | 'id'
  | 'includePaths'
  | 'includeSubdomains'
  | 'org'
  | 'site'
  | 'status'
  | 'updatedAt'
  | 'versions'
  | BusinessReviewKeySpecifier
)[];
export type BusinessReviewFieldPolicy = {
  businessReviewId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  dateRange?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  includePaths?: FieldPolicy<any> | FieldReadFunction<any>;
  includeSubdomains?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  versions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BusinessReviewReadKeySpecifier = (
  | 'count'
  | 'items'
  | BusinessReviewReadKeySpecifier
)[];
export type BusinessReviewReadFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancelAccountKeySpecifier = (
  | 'success'
  | CancelAccountKeySpecifier
)[];
export type CancelAccountFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancelSubscriptionSchemaKeySpecifier = (
  | 'success'
  | CancelSubscriptionSchemaKeySpecifier
)[];
export type CancelSubscriptionSchemaFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContentPlanClusterItemKeySpecifier = (
  | 'page'
  | 'potentialPosition'
  | 'potentialTrafficGain'
  | 'term'
  | ContentPlanClusterItemKeySpecifier
)[];
export type ContentPlanClusterItemFieldPolicy = {
  page?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTrafficGain?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContentPlanItemKeySpecifier = (
  | 'cluster'
  | 'clusterPotentialTrafficGainSum'
  | 'headPage'
  | 'headTerm'
  | 'headTermAuthority'
  | 'headTermPersonalizedDifficulty'
  | 'potentialPosition'
  | 'recommendedAction'
  | ContentPlanItemKeySpecifier
)[];
export type ContentPlanItemFieldPolicy = {
  cluster?: FieldPolicy<any> | FieldReadFunction<any>;
  clusterPotentialTrafficGainSum?: FieldPolicy<any> | FieldReadFunction<any>;
  headPage?: FieldPolicy<any> | FieldReadFunction<any>;
  headTerm?: FieldPolicy<any> | FieldReadFunction<any>;
  headTermAuthority?: FieldPolicy<any> | FieldReadFunction<any>;
  headTermPersonalizedDifficulty?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  recommendedAction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContentPlanPageKeySpecifier = (
  | 'position'
  | 'title'
  | 'url'
  | ContentPlanPageKeySpecifier
)[];
export type ContentPlanPageFieldPolicy = {
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContentPlanRecommendationsKeySpecifier = (
  | 'countCreate'
  | 'countOptimize'
  | ContentPlanRecommendationsKeySpecifier
)[];
export type ContentPlanRecommendationsFieldPolicy = {
  countCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  countOptimize?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CouponKeySpecifier = (
  | 'appliesToAllItems'
  | 'appliesToAllPlans'
  | 'appliesToNonPlanCharges'
  | 'code'
  | 'couponType'
  | 'createdAt'
  | 'discount'
  | 'duration'
  | 'expiredAt'
  | 'hostedPageDescription'
  | 'id'
  | 'invoiceDescription'
  | 'items'
  | 'maxRedemptions'
  | 'maxRedemptionsPerAccount'
  | 'name'
  | 'object'
  | 'plans'
  | 'redeemBy'
  | 'redemptionResource'
  | 'state'
  | 'temporalAmount'
  | 'temporalUnit'
  | 'uniqueCodeTemplate'
  | 'uniqueCouponCode'
  | 'uniqueCouponCodesCount'
  | 'updatedAt'
  | CouponKeySpecifier
)[];
export type CouponFieldPolicy = {
  appliesToAllItems?: FieldPolicy<any> | FieldReadFunction<any>;
  appliesToAllPlans?: FieldPolicy<any> | FieldReadFunction<any>;
  appliesToNonPlanCharges?: FieldPolicy<any> | FieldReadFunction<any>;
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  couponType?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  duration?: FieldPolicy<any> | FieldReadFunction<any>;
  expiredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  hostedPageDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  invoiceDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  maxRedemptions?: FieldPolicy<any> | FieldReadFunction<any>;
  maxRedemptionsPerAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  plans?: FieldPolicy<any> | FieldReadFunction<any>;
  redeemBy?: FieldPolicy<any> | FieldReadFunction<any>;
  redemptionResource?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  temporalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  temporalUnit?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueCodeTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueCouponCode?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueCouponCodesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CouponDiscountKeySpecifier = (
  | 'currencies'
  | 'percent'
  | 'trial'
  | 'type'
  | CouponDiscountKeySpecifier
)[];
export type CouponDiscountFieldPolicy = {
  currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  percent?: FieldPolicy<any> | FieldReadFunction<any>;
  trial?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CouponDiscountPricingKeySpecifier = (
  | 'amount'
  | 'currency'
  | CouponDiscountPricingKeySpecifier
)[];
export type CouponDiscountPricingFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CouponDiscountTrialKeySpecifier = (
  | 'length'
  | 'unit'
  | CouponDiscountTrialKeySpecifier
)[];
export type CouponDiscountTrialFieldPolicy = {
  length?: FieldPolicy<any> | FieldReadFunction<any>;
  unit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CouponMiniKeySpecifier = (
  | 'code'
  | 'couponType'
  | 'discount'
  | 'expiredAt'
  | 'id'
  | 'name'
  | 'object'
  | 'state'
  | CouponMiniKeySpecifier
)[];
export type CouponMiniFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  couponType?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  expiredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CouponRedemptionKeySpecifier = (
  | 'account'
  | 'coupon'
  | 'createdAt'
  | 'currency'
  | 'discounted'
  | 'id'
  | 'object'
  | 'removedAt'
  | 'state'
  | 'subscriptionId'
  | 'updatedAt'
  | CouponRedemptionKeySpecifier
)[];
export type CouponRedemptionFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  coupon?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  discounted?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  removedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CouponRedemptionMiniKeySpecifier = (
  | 'coupon'
  | 'createdAt'
  | 'discounted'
  | 'id'
  | 'object'
  | 'state'
  | CouponRedemptionMiniKeySpecifier
)[];
export type CouponRedemptionMiniFieldPolicy = {
  coupon?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  discounted?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreditPaymentKeySpecifier = (
  | 'account'
  | 'action'
  | 'amount'
  | 'appliedToInvoice'
  | 'createdAt'
  | 'currency'
  | 'id'
  | 'object'
  | 'originalCreditPaymentId'
  | 'originalInvoice'
  | 'refundTransaction'
  | 'updatedAt'
  | 'uuid'
  | 'voidedAt'
  | CreditPaymentKeySpecifier
)[];
export type CreditPaymentFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  appliedToInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  originalCreditPaymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  refundTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
  voidedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CustomFieldKeySpecifier = (
  | 'name'
  | 'value'
  | CustomFieldKeySpecifier
)[];
export type CustomFieldFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DashboardKeySpecifier = (
  | 'createdAt'
  | 'createdByUser'
  | 'description'
  | 'id'
  | 'layout'
  | 'modules'
  | 'org'
  | 'scope'
  | 'site'
  | 'title'
  | 'updatedAt'
  | 'updatedByUser'
  | 'users'
  | DashboardKeySpecifier
)[];
export type DashboardFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  layout?: FieldPolicy<any> | FieldReadFunction<any>;
  modules?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  scope?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteStatusKeySpecifier = (
  | 'message'
  | 'status'
  | DeleteStatusKeySpecifier
)[];
export type DeleteStatusFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentKeySpecifier = (
  | 'additionalData'
  | 'assigneeTo'
  | 'content'
  | 'createdAt'
  | 'createdBy'
  | 'id'
  | 'inputData'
  | 'org'
  | 'prioritization'
  | 'scope'
  | 'site'
  | 'status'
  | 'steps'
  | 'subtype'
  | 'tags'
  | 'title'
  | 'type'
  | 'updatedAt'
  | 'updatedBy'
  | DocumentKeySpecifier
)[];
export type DocumentFieldPolicy = {
  additionalData?: FieldPolicy<any> | FieldReadFunction<any>;
  assigneeTo?: FieldPolicy<any> | FieldReadFunction<any>;
  content?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inputData?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  prioritization?: FieldPolicy<any> | FieldReadFunction<any>;
  scope?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  steps?: FieldPolicy<any> | FieldReadFunction<any>;
  subtype?: FieldPolicy<any> | FieldReadFunction<any>;
  tags?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentAnalysisDataKeySpecifier = (
  | 'allClusterThemes'
  | 'clusters'
  | 'clustersTotalCount'
  | 'keywordUniverseCount'
  | 'processingTime'
  | 'serpsFetchedForKeywords'
  | DocumentAnalysisDataKeySpecifier
)[];
export type DocumentAnalysisDataFieldPolicy = {
  allClusterThemes?: FieldPolicy<any> | FieldReadFunction<any>;
  clusters?: FieldPolicy<any> | FieldReadFunction<any>;
  clustersTotalCount?: FieldPolicy<any> | FieldReadFunction<any>;
  keywordUniverseCount?: FieldPolicy<any> | FieldReadFunction<any>;
  processingTime?: FieldPolicy<any> | FieldReadFunction<any>;
  serpsFetchedForKeywords?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentBriefDataKeySpecifier = (
  | 'processingTime'
  | DocumentBriefDataKeySpecifier
)[];
export type DocumentBriefDataFieldPolicy = {
  processingTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentBriefUserInputKeySpecifier = (
  | 'buyerOrInformationJourney'
  | 'contextLinguistic'
  | 'expertise'
  | 'factStatements'
  | 'intentAnalysis'
  | 'marketingAndMessagingReferences'
  | 'personalExperience'
  | 'personalizationDetail'
  | 'pointOfView'
  | 'proofStatements'
  | 'structuring'
  | 'style'
  | 'targetMarket'
  | 'tone'
  | 'voice'
  | DocumentBriefUserInputKeySpecifier
)[];
export type DocumentBriefUserInputFieldPolicy = {
  buyerOrInformationJourney?: FieldPolicy<any> | FieldReadFunction<any>;
  contextLinguistic?: FieldPolicy<any> | FieldReadFunction<any>;
  expertise?: FieldPolicy<any> | FieldReadFunction<any>;
  factStatements?: FieldPolicy<any> | FieldReadFunction<any>;
  intentAnalysis?: FieldPolicy<any> | FieldReadFunction<any>;
  marketingAndMessagingReferences?: FieldPolicy<any> | FieldReadFunction<any>;
  personalExperience?: FieldPolicy<any> | FieldReadFunction<any>;
  personalizationDetail?: FieldPolicy<any> | FieldReadFunction<any>;
  pointOfView?: FieldPolicy<any> | FieldReadFunction<any>;
  proofStatements?: FieldPolicy<any> | FieldReadFunction<any>;
  structuring?: FieldPolicy<any> | FieldReadFunction<any>;
  style?: FieldPolicy<any> | FieldReadFunction<any>;
  targetMarket?: FieldPolicy<any> | FieldReadFunction<any>;
  tone?: FieldPolicy<any> | FieldReadFunction<any>;
  voice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentClusterDataKeySpecifier = (
  | 'contentGroups'
  | 'terms'
  | 'theme'
  | DocumentClusterDataKeySpecifier
)[];
export type DocumentClusterDataFieldPolicy = {
  contentGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  terms?: FieldPolicy<any> | FieldReadFunction<any>;
  theme?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentContentGroupsKeySpecifier = (
  | 'name'
  | 'personas'
  | DocumentContentGroupsKeySpecifier
)[];
export type DocumentContentGroupsFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  personas?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentContentOpportunitiesKeySpecifier = (
  | 'documentSubtype'
  | 'focusTerm'
  | 'funnelStage'
  | 'idea'
  | 'overview'
  | 'pointsToCover'
  | 'relatedTerms'
  | 'title'
  | DocumentContentOpportunitiesKeySpecifier
)[];
export type DocumentContentOpportunitiesFieldPolicy = {
  documentSubtype?: FieldPolicy<any> | FieldReadFunction<any>;
  focusTerm?: FieldPolicy<any> | FieldReadFunction<any>;
  funnelStage?: FieldPolicy<any> | FieldReadFunction<any>;
  idea?: FieldPolicy<any> | FieldReadFunction<any>;
  overview?: FieldPolicy<any> | FieldReadFunction<any>;
  pointsToCover?: FieldPolicy<any> | FieldReadFunction<any>;
  relatedTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentContentPlanDataKeySpecifier = (
  | 'opportunitiesCreate'
  | 'opportunitiesOptimize'
  | 'processingTime'
  | 'recommendedBriefs'
  | 'theme'
  | DocumentContentPlanDataKeySpecifier
)[];
export type DocumentContentPlanDataFieldPolicy = {
  opportunitiesCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  opportunitiesOptimize?: FieldPolicy<any> | FieldReadFunction<any>;
  processingTime?: FieldPolicy<any> | FieldReadFunction<any>;
  recommendedBriefs?: FieldPolicy<any> | FieldReadFunction<any>;
  theme?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentCreateEstimationKeySpecifier = (
  | 'documentSubtype'
  | 'documentType'
  | 'status'
  | DocumentCreateEstimationKeySpecifier
)[];
export type DocumentCreateEstimationFieldPolicy = {
  documentSubtype?: FieldPolicy<any> | FieldReadFunction<any>;
  documentType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentInputDataKeySpecifier = (
  | 'domain'
  | 'location'
  | 'terms'
  | 'urls'
  | DocumentInputDataKeySpecifier
)[];
export type DocumentInputDataFieldPolicy = {
  domain?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  terms?: FieldPolicy<any> | FieldReadFunction<any>;
  urls?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentOptimizeOpportunitiesKeySpecifier = (
  | 'term'
  | 'url'
  | DocumentOptimizeOpportunitiesKeySpecifier
)[];
export type DocumentOptimizeOpportunitiesFieldPolicy = {
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentsAllotmentKeySpecifier = (
  | 'analysisPlanning'
  | 'briefs'
  | 'resetDate'
  | DocumentsAllotmentKeySpecifier
)[];
export type DocumentsAllotmentFieldPolicy = {
  analysisPlanning?: FieldPolicy<any> | FieldReadFunction<any>;
  briefs?: FieldPolicy<any> | FieldReadFunction<any>;
  resetDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentsAllotmentInfoKeySpecifier = (
  | 'created'
  | 'total'
  | DocumentsAllotmentInfoKeySpecifier
)[];
export type DocumentsAllotmentInfoFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentsConnectionKeySpecifier = (
  | 'analysisPlanning'
  | 'briefs'
  | DocumentsConnectionKeySpecifier
)[];
export type DocumentsConnectionFieldPolicy = {
  analysisPlanning?: FieldPolicy<any> | FieldReadFunction<any>;
  briefs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentsConnectionInfoKeySpecifier = (
  | 'current'
  | 'total'
  | DocumentsConnectionInfoKeySpecifier
)[];
export type DocumentsConnectionInfoFieldPolicy = {
  current?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DocumentsResponseKeySpecifier = (
  | 'count'
  | 'items'
  | DocumentsResponseKeySpecifier
)[];
export type DocumentsResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExcelGenerationResponseKeySpecifier = (
  | 'data'
  | 'format'
  | ExcelGenerationResponseKeySpecifier
)[];
export type ExcelGenerationResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  format?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FilterKeySpecifier = (
  | 'createdAt'
  | 'createdBy'
  | 'data'
  | 'id'
  | 'inventoryType'
  | 'org'
  | 'site'
  | 'title'
  | 'updatedAt'
  | 'updatedBy'
  | FilterKeySpecifier
)[];
export type FilterFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryType?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FormKeySpecifier = (
  | 'createdAt'
  | 'createdBy'
  | 'groups'
  | 'id'
  | 'orderHidden'
  | 'orderVisible'
  | 'org'
  | 'title'
  | 'type'
  | 'updatedAt'
  | FormKeySpecifier
)[];
export type FormFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  orderHidden?: FieldPolicy<any> | FieldReadFunction<any>;
  orderVisible?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FormFieldKeySpecifier = (
  | 'createdAt'
  | 'fieldData'
  | 'group'
  | 'id'
  | 'note'
  | 'required'
  | 'type'
  | 'updatedAt'
  | FormFieldKeySpecifier
)[];
export type FormFieldFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  fieldData?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  required?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FormGroupKeySpecifier = (
  | 'createdAt'
  | 'fields'
  | 'form'
  | 'id'
  | 'orderHidden'
  | 'orderVisible'
  | 'title'
  | 'type'
  | 'updatedAt'
  | FormGroupKeySpecifier
)[];
export type FormGroupFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  fields?: FieldPolicy<any> | FieldReadFunction<any>;
  form?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  orderHidden?: FieldPolicy<any> | FieldReadFunction<any>;
  orderVisible?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FraudInfoKeySpecifier = (
  | 'decision'
  | 'riskRulesTriggered'
  | 'score'
  | FraudInfoKeySpecifier
)[];
export type FraudInfoFieldPolicy = {
  decision?: FieldPolicy<any> | FieldReadFunction<any>;
  riskRulesTriggered?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GenAiPromptResponseKeySpecifier = (
  | 'response'
  | GenAiPromptResponseKeySpecifier
)[];
export type GenAiPromptResponseFieldPolicy = {
  response?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GetAppDataResponseKeySpecifier = (
  | 'appRuns'
  | 'compareKgsNews'
  | 'keywords'
  | 'kg'
  | 'kgNews'
  | 'lr'
  | 'params'
  | 'qts'
  | 'scores'
  | 'scoresNews'
  | GetAppDataResponseKeySpecifier
)[];
export type GetAppDataResponseFieldPolicy = {
  appRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  compareKgsNews?: FieldPolicy<any> | FieldReadFunction<any>;
  keywords?: FieldPolicy<any> | FieldReadFunction<any>;
  kg?: FieldPolicy<any> | FieldReadFunction<any>;
  kgNews?: FieldPolicy<any> | FieldReadFunction<any>;
  lr?: FieldPolicy<any> | FieldReadFunction<any>;
  params?: FieldPolicy<any> | FieldReadFunction<any>;
  qts?: FieldPolicy<any> | FieldReadFunction<any>;
  scores?: FieldPolicy<any> | FieldReadFunction<any>;
  scoresNews?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GuestAccessKeySpecifier = (
  | 'createdAt'
  | 'createdBy'
  | 'disabled'
  | 'id'
  | 'options'
  | 'purpose'
  | 'token'
  | 'updatedAt'
  | GuestAccessKeySpecifier
)[];
export type GuestAccessFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  purpose?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GuestAccessCreateKeySpecifier = (
  | 'token'
  | GuestAccessCreateKeySpecifier
)[];
export type GuestAccessCreateFieldPolicy = {
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GuestAccessCreateManyKeySpecifier = (
  | 'tokens'
  | GuestAccessCreateManyKeySpecifier
)[];
export type GuestAccessCreateManyFieldPolicy = {
  tokens?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GuestAccessValidateKeySpecifier = (
  | 'guestAccess'
  | 'guestUser'
  | 'isValid'
  | GuestAccessValidateKeySpecifier
)[];
export type GuestAccessValidateFieldPolicy = {
  guestAccess?: FieldPolicy<any> | FieldReadFunction<any>;
  guestUser?: FieldPolicy<any> | FieldReadFunction<any>;
  isValid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HistoryKeySpecifier = (
  | 'volume'
  | 'yearMonth'
  | HistoryKeySpecifier
)[];
export type HistoryFieldPolicy = {
  volume?: FieldPolicy<any> | FieldReadFunction<any>;
  yearMonth?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InquirePremiumKeySpecifier = (
  | 'status'
  | InquirePremiumKeySpecifier
)[];
export type InquirePremiumFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IntentDataKeySpecifier = (
  | 'intentBreakdown'
  | 'primarySerpIntent'
  | IntentDataKeySpecifier
)[];
export type IntentDataFieldPolicy = {
  intentBreakdown?: FieldPolicy<any> | FieldReadFunction<any>;
  primarySerpIntent?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InventoryItemKeySpecifier = (
  | 'key'
  | 'page'
  | 'pageTopic'
  | 'project'
  | 'topic'
  | 'type'
  | InventoryItemKeySpecifier
)[];
export type InventoryItemFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  page?: FieldPolicy<any> | FieldReadFunction<any>;
  pageTopic?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InventoryItemPotentialKeySpecifier = (
  | 'page'
  | 'pageTopic'
  | 'topic'
  | 'versions'
  | InventoryItemPotentialKeySpecifier
)[];
export type InventoryItemPotentialFieldPolicy = {
  page?: FieldPolicy<any> | FieldReadFunction<any>;
  pageTopic?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  versions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InventoryItemsAddResponseKeySpecifier = (
  | 'projects'
  | 'status'
  | InventoryItemsAddResponseKeySpecifier
)[];
export type InventoryItemsAddResponseFieldPolicy = {
  projects?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InventoryItemsResponseKeySpecifier = (
  | 'count'
  | 'items'
  | InventoryItemsResponseKeySpecifier
)[];
export type InventoryItemsResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InventoryVersionKeySpecifier = (
  | 'failed'
  | 'id'
  | 'name'
  | 'status'
  | 'statusUpdatedAt'
  | 'traffic'
  | 'trafficDelta'
  | 'trafficDeltaPct'
  | 'trafficValue'
  | 'trafficValueDelta'
  | 'trafficValueDeltaPct'
  | 'visible'
  | InventoryVersionKeySpecifier
)[];
export type InventoryVersionFieldPolicy = {
  failed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusUpdatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  traffic?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDeltaPct?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValueDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValueDeltaPct?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceKeySpecifier = (
  | 'account'
  | 'address'
  | 'balance'
  | 'billingInfoId'
  | 'closedAt'
  | 'collectionMethod'
  | 'createdAt'
  | 'creditPayments'
  | 'currency'
  | 'customerNotes'
  | 'discount'
  | 'dueAt'
  | 'dunningCampaignId'
  | 'hasMoreLineItems'
  | 'id'
  | 'lineItems'
  | 'netTerms'
  | 'number'
  | 'object'
  | 'origin'
  | 'paid'
  | 'poNumber'
  | 'previousInvoiceId'
  | 'refundableAmount'
  | 'shippingAddress'
  | 'state'
  | 'subscriptionIds'
  | 'subtotal'
  | 'tax'
  | 'taxInfo'
  | 'termsAndConditions'
  | 'total'
  | 'transactions'
  | 'type'
  | 'updatedAt'
  | 'vatNumber'
  | 'vatReverseChargeNotes'
  | InvoiceKeySpecifier
)[];
export type InvoiceFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  billingInfoId?: FieldPolicy<any> | FieldReadFunction<any>;
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  creditPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  customerNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  dueAt?: FieldPolicy<any> | FieldReadFunction<any>;
  dunningCampaignId?: FieldPolicy<any> | FieldReadFunction<any>;
  hasMoreLineItems?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lineItems?: FieldPolicy<any> | FieldReadFunction<any>;
  netTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  number?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  origin?: FieldPolicy<any> | FieldReadFunction<any>;
  paid?: FieldPolicy<any> | FieldReadFunction<any>;
  poNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  previousInvoiceId?: FieldPolicy<any> | FieldReadFunction<any>;
  refundableAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shippingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  subtotal?: FieldPolicy<any> | FieldReadFunction<any>;
  tax?: FieldPolicy<any> | FieldReadFunction<any>;
  taxInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  termsAndConditions?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  vatNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  vatReverseChargeNotes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceAddressKeySpecifier = (
  | 'city'
  | 'company'
  | 'country'
  | 'firstName'
  | 'lastName'
  | 'nameOnAccount'
  | 'phone'
  | 'postalCode'
  | 'region'
  | 'street1'
  | 'street2'
  | InvoiceAddressKeySpecifier
)[];
export type InvoiceAddressFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  nameOnAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  street1?: FieldPolicy<any> | FieldReadFunction<any>;
  street2?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceCollectionKeySpecifier = (
  | 'chargeInvoice'
  | 'creditInvoices'
  | 'object'
  | InvoiceCollectionKeySpecifier
)[];
export type InvoiceCollectionFieldPolicy = {
  chargeInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  creditInvoices?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceMiniKeySpecifier = (
  | 'id'
  | 'number'
  | 'state'
  | 'type'
  | InvoiceMiniKeySpecifier
)[];
export type InvoiceMiniFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  number?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemMiniKeySpecifier = (
  | 'code'
  | 'description'
  | 'id'
  | 'name'
  | 'object'
  | 'state'
  | ItemMiniKeySpecifier
)[];
export type ItemMiniFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KGMetricsKeySpecifier = (
  | 'averageContentScore'
  | 'averageWordCount'
  | 'highestContentScore'
  | 'highestWordCount'
  | 'targetContentScore'
  | 'targetWordCount'
  | KGMetricsKeySpecifier
)[];
export type KGMetricsFieldPolicy = {
  averageContentScore?: FieldPolicy<any> | FieldReadFunction<any>;
  averageWordCount?: FieldPolicy<any> | FieldReadFunction<any>;
  highestContentScore?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWordCount?: FieldPolicy<any> | FieldReadFunction<any>;
  targetContentScore?: FieldPolicy<any> | FieldReadFunction<any>;
  targetWordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KnowledgeGraphKeySpecifier = (
  | 'knowledgeGraphItems'
  | 'metrics'
  | 'organicSerpItemPagesKgData'
  | 'userProvidedPage'
  | KnowledgeGraphKeySpecifier
)[];
export type KnowledgeGraphFieldPolicy = {
  knowledgeGraphItems?: FieldPolicy<any> | FieldReadFunction<any>;
  metrics?: FieldPolicy<any> | FieldReadFunction<any>;
  organicSerpItemPagesKgData?: FieldPolicy<any> | FieldReadFunction<any>;
  userProvidedPage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KnowledgeGraphItemKeySpecifier = (
  | 'existsInInventory'
  | 'recommendedMentions'
  | 'relevanceScore'
  | 'term'
  | 'userProvidedPageMentions'
  | 'variants'
  | KnowledgeGraphItemKeySpecifier
)[];
export type KnowledgeGraphItemFieldPolicy = {
  existsInInventory?: FieldPolicy<any> | FieldReadFunction<any>;
  recommendedMentions?: FieldPolicy<any> | FieldReadFunction<any>;
  relevanceScore?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  userProvidedPageMentions?: FieldPolicy<any> | FieldReadFunction<any>;
  variants?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LineItemKeySpecifier = (
  | 'account'
  | 'accountingCode'
  | 'addOnCode'
  | 'addOnId'
  | 'amount'
  | 'avalaraServiceType'
  | 'avalaraTransactionType'
  | 'billForAccountId'
  | 'createdAt'
  | 'creditApplied'
  | 'creditReasonCode'
  | 'currency'
  | 'description'
  | 'discount'
  | 'endDate'
  | 'externalSku'
  | 'id'
  | 'invoiceId'
  | 'invoiceNumber'
  | 'itemCode'
  | 'itemId'
  | 'legacyCategory'
  | 'object'
  | 'origin'
  | 'originalLineItemInvoiceId'
  | 'planCode'
  | 'planId'
  | 'previousLineItemId'
  | 'productCode'
  | 'prorationRate'
  | 'quantity'
  | 'refund'
  | 'refundedQuantity'
  | 'revenueScheduleType'
  | 'shippingAddress'
  | 'startDate'
  | 'state'
  | 'subscriptionId'
  | 'subtotal'
  | 'tax'
  | 'taxCode'
  | 'taxExempt'
  | 'taxInfo'
  | 'taxable'
  | 'type'
  | 'unitAmount'
  | 'unitAmountDecimal'
  | 'updatedAt'
  | 'uuid'
  | LineItemKeySpecifier
)[];
export type LineItemFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  accountingCode?: FieldPolicy<any> | FieldReadFunction<any>;
  addOnCode?: FieldPolicy<any> | FieldReadFunction<any>;
  addOnId?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  avalaraServiceType?: FieldPolicy<any> | FieldReadFunction<any>;
  avalaraTransactionType?: FieldPolicy<any> | FieldReadFunction<any>;
  billForAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  creditApplied?: FieldPolicy<any> | FieldReadFunction<any>;
  creditReasonCode?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  externalSku?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  invoiceId?: FieldPolicy<any> | FieldReadFunction<any>;
  invoiceNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  itemCode?: FieldPolicy<any> | FieldReadFunction<any>;
  itemId?: FieldPolicy<any> | FieldReadFunction<any>;
  legacyCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  origin?: FieldPolicy<any> | FieldReadFunction<any>;
  originalLineItemInvoiceId?: FieldPolicy<any> | FieldReadFunction<any>;
  planCode?: FieldPolicy<any> | FieldReadFunction<any>;
  planId?: FieldPolicy<any> | FieldReadFunction<any>;
  previousLineItemId?: FieldPolicy<any> | FieldReadFunction<any>;
  productCode?: FieldPolicy<any> | FieldReadFunction<any>;
  prorationRate?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
  refund?: FieldPolicy<any> | FieldReadFunction<any>;
  refundedQuantity?: FieldPolicy<any> | FieldReadFunction<any>;
  revenueScheduleType?: FieldPolicy<any> | FieldReadFunction<any>;
  shippingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  subtotal?: FieldPolicy<any> | FieldReadFunction<any>;
  tax?: FieldPolicy<any> | FieldReadFunction<any>;
  taxCode?: FieldPolicy<any> | FieldReadFunction<any>;
  taxExempt?: FieldPolicy<any> | FieldReadFunction<any>;
  taxInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  taxable?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmountDecimal?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LinkRecommendationKeySpecifier = (
  | 'title'
  | 'url'
  | LinkRecommendationKeySpecifier
)[];
export type LinkRecommendationFieldPolicy = {
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LinkingRecommendationForTermKeySpecifier = (
  | 'recommendations'
  | 'term'
  | LinkingRecommendationForTermKeySpecifier
)[];
export type LinkingRecommendationForTermFieldPolicy = {
  recommendations?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MentionItemKeySpecifier = (
  | 'count'
  | 'term'
  | MentionItemKeySpecifier
)[];
export type MentionItemFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ModuleKeySpecifier = (
  | 'createdAt'
  | 'createdByUser'
  | 'dashboard'
  | 'description'
  | 'forkSource'
  | 'id'
  | 'readOnly'
  | 'scope'
  | 'site'
  | 'title'
  | 'updatedAt'
  | 'updatedByUser'
  | ModuleKeySpecifier
)[];
export type ModuleFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  dashboard?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  forkSource?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  readOnly?: FieldPolicy<any> | FieldReadFunction<any>;
  scope?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByUser?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'addMember'
  | 'addSubheading'
  | 'adminSubscriptionAddonCreate'
  | 'adminSubscriptionCreate'
  | 'adminSubscriptionUpdate'
  | 'appQueryCreateOrUpdate'
  | 'appQueryUpdate'
  | 'businessReviewCreate'
  | 'cancelAccount'
  | 'cancelSubscriptionSite'
  | 'cancelSubscriptionUser'
  | 'createBasicReport'
  | 'createFreeSiteSubscription'
  | 'createPaidSiteSubscription'
  | 'createUserSubscription'
  | 'dashboardCreate'
  | 'dashboardUpdate'
  | 'deleteReport'
  | 'documentDelete'
  | 'documentUpdate'
  | 'documentsCreate'
  | 'documentsCreateBriefsFromPlan'
  | 'documentsCreatePlansFromAnalysis'
  | 'downgradeSiteSubscription'
  | 'filterCreate'
  | 'filterDelete'
  | 'filterUpdate'
  | 'formCreate'
  | 'formFieldCreate'
  | 'formFieldUpdate'
  | 'formGroupCreate'
  | 'formGroupUpdate'
  | 'formUpdate'
  | 'generateReport'
  | 'guestAccessCreate'
  | 'guestAccessCreateMany'
  | 'guestAccessValidate'
  | 'inquirePremium'
  | 'inventoryItemsAdd'
  | 'inventoryItemsUpdate'
  | 'moduleCreate'
  | 'moduleUpdate'
  | 'organizationCreate'
  | 'organizationUpdate'
  | 'permissionsWrite'
  | 'planCreate'
  | 'planDelete'
  | 'projectUpsert'
  | 'projectsCreateWithSavedWriting'
  | 'projectsDelete'
  | 'projectsPlaceOrders'
  | 'projectsUpsert'
  | 'purchaseAppRuns'
  | 'purchaseCredits'
  | 'recurlyCreateAccount'
  | 'recurlyUpsertBillingInfo'
  | 'removeProjectOrder'
  | 'savedViewCreate'
  | 'savedViewDelete'
  | 'savedViewUpdate'
  | 'setupAccount'
  | 'siteCreate'
  | 'siteCreateGovernedInventory'
  | 'siteDelete'
  | 'siteUpdate'
  | 'subscriptionAddonCancel'
  | 'subscriptionAddonCreate'
  | 'subscriptionAddonPriceSet'
  | 'subscriptionUpdate'
  | 'tagCreateOrUpdate'
  | 'tagDelete'
  | 'updateReport'
  | 'upgradeFreeToPaidSiteSubscription'
  | 'upgradeFreeToTrialSiteSubscription'
  | 'upgradeTrialToStandardSiteSubscription'
  | 'userCreate'
  | 'userDelete'
  | 'userUpdate'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  addMember?: FieldPolicy<any> | FieldReadFunction<any>;
  addSubheading?: FieldPolicy<any> | FieldReadFunction<any>;
  adminSubscriptionAddonCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  adminSubscriptionCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  adminSubscriptionUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  appQueryCreateOrUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  appQueryUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  businessReviewCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelSubscriptionSite?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelSubscriptionUser?: FieldPolicy<any> | FieldReadFunction<any>;
  createBasicReport?: FieldPolicy<any> | FieldReadFunction<any>;
  createFreeSiteSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  createPaidSiteSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  createUserSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  dashboardCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  dashboardUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteReport?: FieldPolicy<any> | FieldReadFunction<any>;
  documentDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  documentUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  documentsCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  documentsCreateBriefsFromPlan?: FieldPolicy<any> | FieldReadFunction<any>;
  documentsCreatePlansFromAnalysis?: FieldPolicy<any> | FieldReadFunction<any>;
  downgradeSiteSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  filterCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  filterDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  filterUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  formCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  formFieldCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  formFieldUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  formGroupCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  formGroupUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  formUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  generateReport?: FieldPolicy<any> | FieldReadFunction<any>;
  guestAccessCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  guestAccessCreateMany?: FieldPolicy<any> | FieldReadFunction<any>;
  guestAccessValidate?: FieldPolicy<any> | FieldReadFunction<any>;
  inquirePremium?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryItemsAdd?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryItemsUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  moduleUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  permissionsWrite?: FieldPolicy<any> | FieldReadFunction<any>;
  planCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  planDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  projectUpsert?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsCreateWithSavedWriting?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsPlaceOrders?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsUpsert?: FieldPolicy<any> | FieldReadFunction<any>;
  purchaseAppRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  purchaseCredits?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyCreateAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyUpsertBillingInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  removeProjectOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViewCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViewDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViewUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  setupAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  siteCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  siteCreateGovernedInventory?: FieldPolicy<any> | FieldReadFunction<any>;
  siteDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  siteUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionAddonCancel?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionAddonCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionAddonPriceSet?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  tagCreateOrUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  tagDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  updateReport?: FieldPolicy<any> | FieldReadFunction<any>;
  upgradeFreeToPaidSiteSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  upgradeFreeToTrialSiteSubscription?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  upgradeTrialToStandardSiteSubscription?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  userCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  userDelete?: FieldPolicy<any> | FieldReadFunction<any>;
  userUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OnPageMetricsKeySpecifier = (
  | 'externalLinkCount'
  | 'h2Count'
  | 'imagesCount'
  | 'internalLinkCount'
  | 'videoCount'
  | 'wordCount'
  | OnPageMetricsKeySpecifier
)[];
export type OnPageMetricsFieldPolicy = {
  externalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  h2Count?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  internalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  videoCount?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganicSerpItemKeySpecifier = (
  | 'description'
  | 'position'
  | 'title'
  | 'url'
  | OrganicSerpItemKeySpecifier
)[];
export type OrganicSerpItemFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganicSerpItemPageKeySpecifier = (
  | 'externalLinkCount'
  | 'h2Count'
  | 'imagesCount'
  | 'internalLinkCount'
  | 'position'
  | 'url'
  | 'videoCount'
  | 'wordCount'
  | OrganicSerpItemPageKeySpecifier
)[];
export type OrganicSerpItemPageFieldPolicy = {
  externalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  h2Count?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  internalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  videoCount?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganicSerpItemPageKgDataKeySpecifier = (
  | 'contentScore'
  | 'description'
  | 'mentions'
  | 'position'
  | 'title'
  | 'url'
  | 'wordCount'
  | OrganicSerpItemPageKgDataKeySpecifier
)[];
export type OrganicSerpItemPageKgDataFieldPolicy = {
  contentScore?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  mentions?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganicSerpItemWithIntentKeySpecifier = (
  | 'description'
  | 'intent'
  | 'position'
  | 'title'
  | 'url'
  | OrganicSerpItemWithIntentKeySpecifier
)[];
export type OrganicSerpItemWithIntentFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  intent?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationKeySpecifier = (
  | 'address'
  | 'backgroundColor'
  | 'bodyFont'
  | 'brandColor'
  | 'clientTier'
  | 'createdAt'
  | 'defaultForm'
  | 'documentAllotment'
  | 'headerFont'
  | 'highlightColor'
  | 'id'
  | 'logo'
  | 'maxUsers'
  | 'name'
  | 'phone'
  | 'primaryContact'
  | 'recurlyAccountCode'
  | 'recurlyAccountCodeTest'
  | 'selfReportOrdering'
  | 'sfdcAccountId'
  | 'sites'
  | 'slug'
  | 'subscription'
  | 'topicsMax'
  | 'updatedAt'
  | 'users'
  | OrganizationKeySpecifier
)[];
export type OrganizationFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  backgroundColor?: FieldPolicy<any> | FieldReadFunction<any>;
  bodyFont?: FieldPolicy<any> | FieldReadFunction<any>;
  brandColor?: FieldPolicy<any> | FieldReadFunction<any>;
  clientTier?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultForm?: FieldPolicy<any> | FieldReadFunction<any>;
  documentAllotment?: FieldPolicy<any> | FieldReadFunction<any>;
  headerFont?: FieldPolicy<any> | FieldReadFunction<any>;
  highlightColor?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logo?: FieldPolicy<any> | FieldReadFunction<any>;
  maxUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  primaryContact?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyAccountCode?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyAccountCodeTest?: FieldPolicy<any> | FieldReadFunction<any>;
  selfReportOrdering?: FieldPolicy<any> | FieldReadFunction<any>;
  sfdcAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
  sites?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  topicsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationsResponseKeySpecifier = (
  | 'count'
  | 'items'
  | OrganizationsResponseKeySpecifier
)[];
export type OrganizationsResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PageKeySpecifier = (
  | 'authority'
  | 'avgContentScore'
  | 'avgMmCompetitiveAdvantage'
  | 'avgMmDifficulty'
  | 'avgMmPersonalizedAdvantage'
  | 'avgPersonalizedMmDifficulty'
  | 'createdAt'
  | 'externalContentLinkCount'
  | 'externalContentLinks'
  | 'hiddenByCs'
  | 'hiddenByUser'
  | 'httpCode'
  | 'id'
  | 'internalContentLinkCount'
  | 'internalContentLinks'
  | 'inventoryId'
  | 'maxPotentialRank'
  | 'maxRank'
  | 'minPotentialRank'
  | 'minRank'
  | 'numRankingTopics'
  | 'numTopics'
  | 'pageType'
  | 'potentialTraffic'
  | 'potentialTrafficUpside'
  | 'potentialTrafficValue'
  | 'screenshotLink'
  | 'source'
  | 'sumContentMentions'
  | 'sumTitleMentions'
  | 'sumVolume'
  | 'title'
  | 'topValuedTopic'
  | 'traffic'
  | 'trafficDelta'
  | 'trafficDeltaPct'
  | 'trafficValue'
  | 'trafficValueDelta'
  | 'unrealizedTrafficValue'
  | 'updatedAt'
  | 'url'
  | 'urlHash'
  | 'userIntent'
  | 'version'
  | 'versionTrafficPct'
  | 'versionTrafficValuePct'
  | 'wordCount'
  | PageKeySpecifier
)[];
export type PageFieldPolicy = {
  authority?: FieldPolicy<any> | FieldReadFunction<any>;
  avgContentScore?: FieldPolicy<any> | FieldReadFunction<any>;
  avgMmCompetitiveAdvantage?: FieldPolicy<any> | FieldReadFunction<any>;
  avgMmDifficulty?: FieldPolicy<any> | FieldReadFunction<any>;
  avgMmPersonalizedAdvantage?: FieldPolicy<any> | FieldReadFunction<any>;
  avgPersonalizedMmDifficulty?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  externalContentLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  externalContentLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  hiddenByCs?: FieldPolicy<any> | FieldReadFunction<any>;
  hiddenByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  httpCode?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  internalContentLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  internalContentLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPotentialRank?: FieldPolicy<any> | FieldReadFunction<any>;
  maxRank?: FieldPolicy<any> | FieldReadFunction<any>;
  minPotentialRank?: FieldPolicy<any> | FieldReadFunction<any>;
  minRank?: FieldPolicy<any> | FieldReadFunction<any>;
  numRankingTopics?: FieldPolicy<any> | FieldReadFunction<any>;
  numTopics?: FieldPolicy<any> | FieldReadFunction<any>;
  pageType?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTraffic?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTrafficUpside?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTrafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  screenshotLink?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  sumContentMentions?: FieldPolicy<any> | FieldReadFunction<any>;
  sumTitleMentions?: FieldPolicy<any> | FieldReadFunction<any>;
  sumVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topValuedTopic?: FieldPolicy<any> | FieldReadFunction<any>;
  traffic?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDeltaPct?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValueDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  unrealizedTrafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  urlHash?: FieldPolicy<any> | FieldReadFunction<any>;
  userIntent?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
  versionTrafficPct?: FieldPolicy<any> | FieldReadFunction<any>;
  versionTrafficValuePct?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PageProcessorKeySpecifier = (
  | 'html'
  | 'statusCode'
  | 'url'
  | PageProcessorKeySpecifier
)[];
export type PageProcessorFieldPolicy = {
  html?: FieldPolicy<any> | FieldReadFunction<any>;
  statusCode?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PageTopicKeySpecifier = (
  | 'contentMentions'
  | 'hiddenByCs'
  | 'hiddenByUser'
  | 'id'
  | 'page'
  | 'pageId'
  | 'potentialRank'
  | 'potentialTraffic'
  | 'potentialTrafficUpside'
  | 'potentialTrafficValue'
  | 'prePriority'
  | 'rank'
  | 'rankDelta'
  | 'rankDeltaPotential'
  | 'titleMentions'
  | 'topic'
  | 'topicId'
  | 'traffic'
  | 'trafficDelta'
  | 'trafficDeltaPct'
  | 'trafficValue'
  | 'trafficValueDelta'
  | 'trafficValueDeltaPct'
  | 'unrealizedTrafficValue'
  | 'userIntent'
  | 'version'
  | 'versionId'
  | 'versionTrafficPct'
  | 'versionTrafficValuePct'
  | PageTopicKeySpecifier
)[];
export type PageTopicFieldPolicy = {
  contentMentions?: FieldPolicy<any> | FieldReadFunction<any>;
  hiddenByCs?: FieldPolicy<any> | FieldReadFunction<any>;
  hiddenByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  page?: FieldPolicy<any> | FieldReadFunction<any>;
  pageId?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialRank?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTraffic?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTrafficUpside?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTrafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  prePriority?: FieldPolicy<any> | FieldReadFunction<any>;
  rank?: FieldPolicy<any> | FieldReadFunction<any>;
  rankDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  rankDeltaPotential?: FieldPolicy<any> | FieldReadFunction<any>;
  titleMentions?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  topicId?: FieldPolicy<any> | FieldReadFunction<any>;
  traffic?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDeltaPct?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValueDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValueDeltaPct?: FieldPolicy<any> | FieldReadFunction<any>;
  unrealizedTrafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  userIntent?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
  versionId?: FieldPolicy<any> | FieldReadFunction<any>;
  versionTrafficPct?: FieldPolicy<any> | FieldReadFunction<any>;
  versionTrafficValuePct?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodKeySpecifier = (
  | 'accountType'
  | 'billingAgreementId'
  | 'cardType'
  | 'ccBinCountry'
  | 'expMonth'
  | 'expYear'
  | 'firstSix'
  | 'gatewayCode'
  | 'gatewayToken'
  | 'lastFour'
  | 'lastTwo'
  | 'nameOnAccount'
  | 'routingNumber'
  | 'routingNumberBank'
  | PaymentMethodKeySpecifier
)[];
export type PaymentMethodFieldPolicy = {
  accountType?: FieldPolicy<any> | FieldReadFunction<any>;
  billingAgreementId?: FieldPolicy<any> | FieldReadFunction<any>;
  cardType?: FieldPolicy<any> | FieldReadFunction<any>;
  ccBinCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  expMonth?: FieldPolicy<any> | FieldReadFunction<any>;
  expYear?: FieldPolicy<any> | FieldReadFunction<any>;
  firstSix?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayCode?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayToken?: FieldPolicy<any> | FieldReadFunction<any>;
  lastFour?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTwo?: FieldPolicy<any> | FieldReadFunction<any>;
  nameOnAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  routingNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  routingNumberBank?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PermissionKeySpecifier = (
  | 'action'
  | 'resource'
  | 'status'
  | PermissionKeySpecifier
)[];
export type PermissionFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  resource?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PermissionsEntityAdminKeySpecifier = (
  | 'permissions'
  | 'resourceActionPairs'
  | PermissionsEntityAdminKeySpecifier
)[];
export type PermissionsEntityAdminFieldPolicy = {
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
  resourceActionPairs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlanKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'projects'
  | 'site'
  | 'title'
  | 'updatedAt'
  | 'user'
  | PlanKeySpecifier
)[];
export type PlanFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  projects?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlanMiniKeySpecifier = (
  | 'code'
  | 'id'
  | 'name'
  | 'object'
  | PlanMiniKeySpecifier
)[];
export type PlanMiniFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectKeySpecifier = (
  | 'assignee'
  | 'briefCreatedAt'
  | 'briefId'
  | 'briefSelfServe'
  | 'briefStatus'
  | 'briefStatusError'
  | 'briefStatusLastSynced'
  | 'createdAt'
  | 'dueDate'
  | 'id'
  | 'notes'
  | 'plan'
  | 'publishedOn'
  | 'site'
  | 'topic'
  | 'updatedAt'
  | 'url'
  | 'writing'
  | 'writingCreatedAt'
  | 'writingReference'
  | 'writingUpdatedAt'
  | ProjectKeySpecifier
)[];
export type ProjectFieldPolicy = {
  assignee?: FieldPolicy<any> | FieldReadFunction<any>;
  briefCreatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  briefId?: FieldPolicy<any> | FieldReadFunction<any>;
  briefSelfServe?: FieldPolicy<any> | FieldReadFunction<any>;
  briefStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  briefStatusError?: FieldPolicy<any> | FieldReadFunction<any>;
  briefStatusLastSynced?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  dueDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  plan?: FieldPolicy<any> | FieldReadFunction<any>;
  publishedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  topic?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  writing?: FieldPolicy<any> | FieldReadFunction<any>;
  writingCreatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  writingReference?: FieldPolicy<any> | FieldReadFunction<any>;
  writingUpdatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectsPlaceOrdersResponseKeySpecifier = (
  | 'allSucceeded'
  | 'costActual'
  | 'costPredicted'
  | 'created'
  | 'creditsAfter'
  | 'creditsBefore'
  | 'dryRun'
  | ProjectsPlaceOrdersResponseKeySpecifier
)[];
export type ProjectsPlaceOrdersResponseFieldPolicy = {
  allSucceeded?: FieldPolicy<any> | FieldReadFunction<any>;
  costActual?: FieldPolicy<any> | FieldReadFunction<any>;
  costPredicted?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  creditsAfter?: FieldPolicy<any> | FieldReadFunction<any>;
  creditsBefore?: FieldPolicy<any> | FieldReadFunction<any>;
  dryRun?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseAppRunsKeySpecifier = (
  | 'credits'
  | 'current'
  | 'error'
  | 'errorCode'
  | 'extra'
  | 'lastReset'
  | 'limit'
  | 'nextReset'
  | 'orgId'
  | 'resetInterval'
  | 'warningCode'
  | PurchaseAppRunsKeySpecifier
)[];
export type PurchaseAppRunsFieldPolicy = {
  credits?: FieldPolicy<any> | FieldReadFunction<any>;
  current?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  errorCode?: FieldPolicy<any> | FieldReadFunction<any>;
  extra?: FieldPolicy<any> | FieldReadFunction<any>;
  lastReset?: FieldPolicy<any> | FieldReadFunction<any>;
  limit?: FieldPolicy<any> | FieldReadFunction<any>;
  nextReset?: FieldPolicy<any> | FieldReadFunction<any>;
  orgId?: FieldPolicy<any> | FieldReadFunction<any>;
  resetInterval?: FieldPolicy<any> | FieldReadFunction<any>;
  warningCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PurchaseCreditsKeySpecifier = (
  | 'newCredits'
  | 'site'
  | PurchaseCreditsKeySpecifier
)[];
export type PurchaseCreditsFieldPolicy = {
  newCredits?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'appQueries'
  | 'businessReviews'
  | 'dashboard'
  | 'dashboards'
  | 'document'
  | 'documents'
  | 'documentsCreateEstimate'
  | 'exportConnect'
  | 'exportContentPlanner'
  | 'exportHeatmap'
  | 'exportInventoryItems'
  | 'exportSerpXray'
  | 'exportTable'
  | 'filter'
  | 'filters'
  | 'form'
  | 'forms'
  | 'genaiChangeTone'
  | 'genaiCreateOutline'
  | 'genaiCreateSummary'
  | 'genaiCustomPrompt'
  | 'genaiExpandConcept'
  | 'genaiFixSpelling'
  | 'genaiFollowUpContinueWriting'
  | 'genaiFollowUpCustom'
  | 'genaiFollowUpMakeLonger'
  | 'genaiFollowUpTryAgain'
  | 'genaiImproveWriting'
  | 'genaiMakeLonger'
  | 'genaiMakeShorter'
  | 'getAppData'
  | 'getExcel'
  | 'getInventoryItemPotential'
  | 'inventoryItem'
  | 'inventoryItems'
  | 'inventorySubdomains'
  | 'inventoryVersion'
  | 'inventoryVersions'
  | 'module'
  | 'modules'
  | 'organization'
  | 'organizations'
  | 'permissions'
  | 'permissionsEntityAdmin'
  | 'plan'
  | 'plans'
  | 'project'
  | 'projects'
  | 'readReport'
  | 'readReports'
  | 'recurlyGetAccount'
  | 'recurlyGetCoupon'
  | 'recurlyGetCreditInvoices'
  | 'recurlyGetInvoice'
  | 'recurlyGetRedemptions'
  | 'recurlyGetSubscription'
  | 'recurlyGetSubscriptions'
  | 'recurlyGetTransactions'
  | 'savedView'
  | 'savedViews'
  | 'savedViewsEach'
  | 'seoAdwordsKeywords'
  | 'seoAdwordsQuestions'
  | 'seoBulkAdwordsKeywords'
  | 'seoConnect'
  | 'seoContentPlanner'
  | 'seoKnowledgeGraph'
  | 'seoPageProcessor'
  | 'seoSerpData'
  | 'seoSerpIntents'
  | 'seoSerpPageData'
  | 'seoSerpQuestions'
  | 'seoSerpQuestionsOld'
  | 'seoSerpUserIntentData'
  | 'seoWebsiteHeatmap'
  | 'signIn'
  | 'signProsperStackPayload'
  | 'site'
  | 'siteAggregateStats'
  | 'siteRecommendedTopics'
  | 'sites'
  | 'subscription'
  | 'subscriptionAddonTypesAvailable'
  | 'subscriptionPlans'
  | 'subscriptionRenewalInvoicePreview'
  | 'tags'
  | 'user'
  | 'users'
  | 'validateEmail'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  appQueries?: FieldPolicy<any> | FieldReadFunction<any>;
  businessReviews?: FieldPolicy<any> | FieldReadFunction<any>;
  dashboard?: FieldPolicy<any> | FieldReadFunction<any>;
  dashboards?: FieldPolicy<any> | FieldReadFunction<any>;
  document?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  documentsCreateEstimate?: FieldPolicy<any> | FieldReadFunction<any>;
  exportConnect?: FieldPolicy<any> | FieldReadFunction<any>;
  exportContentPlanner?: FieldPolicy<any> | FieldReadFunction<any>;
  exportHeatmap?: FieldPolicy<any> | FieldReadFunction<any>;
  exportInventoryItems?: FieldPolicy<any> | FieldReadFunction<any>;
  exportSerpXray?: FieldPolicy<any> | FieldReadFunction<any>;
  exportTable?: FieldPolicy<any> | FieldReadFunction<any>;
  filter?: FieldPolicy<any> | FieldReadFunction<any>;
  filters?: FieldPolicy<any> | FieldReadFunction<any>;
  form?: FieldPolicy<any> | FieldReadFunction<any>;
  forms?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiChangeTone?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiCreateOutline?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiCreateSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiCustomPrompt?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiExpandConcept?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiFixSpelling?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiFollowUpContinueWriting?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiFollowUpCustom?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiFollowUpMakeLonger?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiFollowUpTryAgain?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiImproveWriting?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiMakeLonger?: FieldPolicy<any> | FieldReadFunction<any>;
  genaiMakeShorter?: FieldPolicy<any> | FieldReadFunction<any>;
  getAppData?: FieldPolicy<any> | FieldReadFunction<any>;
  getExcel?: FieldPolicy<any> | FieldReadFunction<any>;
  getInventoryItemPotential?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryItem?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryItems?: FieldPolicy<any> | FieldReadFunction<any>;
  inventorySubdomains?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryVersion?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryVersions?: FieldPolicy<any> | FieldReadFunction<any>;
  module?: FieldPolicy<any> | FieldReadFunction<any>;
  modules?: FieldPolicy<any> | FieldReadFunction<any>;
  organization?: FieldPolicy<any> | FieldReadFunction<any>;
  organizations?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
  permissionsEntityAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
  plan?: FieldPolicy<any> | FieldReadFunction<any>;
  plans?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projects?: FieldPolicy<any> | FieldReadFunction<any>;
  readReport?: FieldPolicy<any> | FieldReadFunction<any>;
  readReports?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetCoupon?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetCreditInvoices?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetRedemptions?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetSubscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
  recurlyGetTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  savedView?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViews?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViewsEach?: FieldPolicy<any> | FieldReadFunction<any>;
  seoAdwordsKeywords?: FieldPolicy<any> | FieldReadFunction<any>;
  seoAdwordsQuestions?: FieldPolicy<any> | FieldReadFunction<any>;
  seoBulkAdwordsKeywords?: FieldPolicy<any> | FieldReadFunction<any>;
  seoConnect?: FieldPolicy<any> | FieldReadFunction<any>;
  seoContentPlanner?: FieldPolicy<any> | FieldReadFunction<any>;
  seoKnowledgeGraph?: FieldPolicy<any> | FieldReadFunction<any>;
  seoPageProcessor?: FieldPolicy<any> | FieldReadFunction<any>;
  seoSerpData?: FieldPolicy<any> | FieldReadFunction<any>;
  seoSerpIntents?: FieldPolicy<any> | FieldReadFunction<any>;
  seoSerpPageData?: FieldPolicy<any> | FieldReadFunction<any>;
  seoSerpQuestions?: FieldPolicy<any> | FieldReadFunction<any>;
  seoSerpQuestionsOld?: FieldPolicy<any> | FieldReadFunction<any>;
  seoSerpUserIntentData?: FieldPolicy<any> | FieldReadFunction<any>;
  seoWebsiteHeatmap?: FieldPolicy<any> | FieldReadFunction<any>;
  signIn?: FieldPolicy<any> | FieldReadFunction<any>;
  signProsperStackPayload?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  siteAggregateStats?: FieldPolicy<any> | FieldReadFunction<any>;
  siteRecommendedTopics?: FieldPolicy<any> | FieldReadFunction<any>;
  sites?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionAddonTypesAvailable?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionPlans?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionRenewalInvoicePreview?: FieldPolicy<any> | FieldReadFunction<any>;
  tags?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  validateEmail?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QuestionServiceItemKeySpecifier = (
  | 'similarity'
  | 'term'
  | QuestionServiceItemKeySpecifier
)[];
export type QuestionServiceItemFieldPolicy = {
  similarity?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QuestionTitleSuggestionsKeySpecifier = (
  | 'questions'
  | 'suggestedSubHeadingTerms'
  | 'suggestedTitleTerms'
  | 'topRankingSubHeadings'
  | 'topRankingTitles'
  | QuestionTitleSuggestionsKeySpecifier
)[];
export type QuestionTitleSuggestionsFieldPolicy = {
  questions?: FieldPolicy<any> | FieldReadFunction<any>;
  suggestedSubHeadingTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  suggestedTitleTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  topRankingSubHeadings?: FieldPolicy<any> | FieldReadFunction<any>;
  topRankingTitles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurlySubscriptionKeySpecifier = (
  | 'account'
  | 'activatedAt'
  | 'addOns'
  | 'addOnsTotal'
  | 'autoRenew'
  | 'bankAccountAuthorizedAt'
  | 'billingInfoId'
  | 'canceledAt'
  | 'collectionMethod'
  | 'couponRedemptions'
  | 'createdAt'
  | 'currency'
  | 'currentPeriodEndsAt'
  | 'currentPeriodStartedAt'
  | 'currentTermEndsAt'
  | 'currentTermStartedAt'
  | 'customFields'
  | 'customerNotes'
  | 'expirationReason'
  | 'expiresAt'
  | 'gatewayCode'
  | 'id'
  | 'netTerms'
  | 'object'
  | 'pausedAt'
  | 'pendingChange'
  | 'plan'
  | 'poNumber'
  | 'quantity'
  | 'remainingBillingCycles'
  | 'remainingPauseCycles'
  | 'renewalBillingCycles'
  | 'revenueScheduleType'
  | 'shipping'
  | 'state'
  | 'subtotal'
  | 'tax'
  | 'taxInfo'
  | 'termsAndConditions'
  | 'total'
  | 'totalBillingCycles'
  | 'trialEndsAt'
  | 'trialStartedAt'
  | 'unitAmount'
  | 'updatedAt'
  | 'uuid'
  | RecurlySubscriptionKeySpecifier
)[];
export type RecurlySubscriptionFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  activatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  addOns?: FieldPolicy<any> | FieldReadFunction<any>;
  addOnsTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  autoRenew?: FieldPolicy<any> | FieldReadFunction<any>;
  bankAccountAuthorizedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  billingInfoId?: FieldPolicy<any> | FieldReadFunction<any>;
  canceledAt?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  couponRedemptions?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  currentPeriodEndsAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currentPeriodStartedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currentTermEndsAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currentTermStartedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customFields?: FieldPolicy<any> | FieldReadFunction<any>;
  customerNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  expirationReason?: FieldPolicy<any> | FieldReadFunction<any>;
  expiresAt?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayCode?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  netTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  pausedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingChange?: FieldPolicy<any> | FieldReadFunction<any>;
  plan?: FieldPolicy<any> | FieldReadFunction<any>;
  poNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
  remainingBillingCycles?: FieldPolicy<any> | FieldReadFunction<any>;
  remainingPauseCycles?: FieldPolicy<any> | FieldReadFunction<any>;
  renewalBillingCycles?: FieldPolicy<any> | FieldReadFunction<any>;
  revenueScheduleType?: FieldPolicy<any> | FieldReadFunction<any>;
  shipping?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  subtotal?: FieldPolicy<any> | FieldReadFunction<any>;
  tax?: FieldPolicy<any> | FieldReadFunction<any>;
  taxInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  termsAndConditions?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  totalBillingCycles?: FieldPolicy<any> | FieldReadFunction<any>;
  trialEndsAt?: FieldPolicy<any> | FieldReadFunction<any>;
  trialStartedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurlySubscriptionAddonKeySpecifier = (
  | 'addOn'
  | 'addOnSource'
  | 'createdAt'
  | 'expiredAt'
  | 'id'
  | 'object'
  | 'quantity'
  | 'revenueScheduleType'
  | 'subscriptionId'
  | 'tierType'
  | 'tiers'
  | 'unitAmount'
  | 'unitAmountDecimal'
  | 'updatedAt'
  | 'usagePercentage'
  | RecurlySubscriptionAddonKeySpecifier
)[];
export type RecurlySubscriptionAddonFieldPolicy = {
  addOn?: FieldPolicy<any> | FieldReadFunction<any>;
  addOnSource?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  expiredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
  revenueScheduleType?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  tierType?: FieldPolicy<any> | FieldReadFunction<any>;
  tiers?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmountDecimal?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  usagePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequestStatusKeySpecifier = (
  | 'message'
  | 'status'
  | RequestStatusKeySpecifier
)[];
export type RequestStatusFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResetDateKeySpecifier = ('last' | 'next' | ResetDateKeySpecifier)[];
export type ResetDateFieldPolicy = {
  last?: FieldPolicy<any> | FieldReadFunction<any>;
  next?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResourceActionPairKeySpecifier = (
  | 'action'
  | 'resource'
  | ResourceActionPairKeySpecifier
)[];
export type ResourceActionPairFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  resource?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SEOConnectKeySpecifier = (
  | 'competitionLinkingRecommendations'
  | 'externalLinkingRecommendations'
  | 'internalLinkingRecommendations'
  | 'networkLinkingRecommendations'
  | SEOConnectKeySpecifier
)[];
export type SEOConnectFieldPolicy = {
  competitionLinkingRecommendations?: FieldPolicy<any> | FieldReadFunction<any>;
  externalLinkingRecommendations?: FieldPolicy<any> | FieldReadFunction<any>;
  internalLinkingRecommendations?: FieldPolicy<any> | FieldReadFunction<any>;
  networkLinkingRecommendations?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SavedViewKeySpecifier = (
  | 'createdAt'
  | 'createdBy'
  | 'description'
  | 'filters'
  | 'hidden'
  | 'id'
  | 'inventoryType'
  | 'order'
  | 'org'
  | 'pinned'
  | 'sharing'
  | 'site'
  | 'sorting'
  | 'title'
  | 'trackedByUsers'
  | 'updatedAt'
  | 'updatedBy'
  | SavedViewKeySpecifier
)[];
export type SavedViewFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  filters?: FieldPolicy<any> | FieldReadFunction<any>;
  hidden?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryType?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  pinned?: FieldPolicy<any> | FieldReadFunction<any>;
  sharing?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  sorting?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackedByUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SavedViewsEachResItemKeySpecifier = (
  | 'inventoryType'
  | 'items'
  | SavedViewsEachResItemKeySpecifier
)[];
export type SavedViewsEachResItemFieldPolicy = {
  inventoryType?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeoPageItemKeySpecifier = (
  | 'externalLinkCount'
  | 'h2Count'
  | 'imagesCount'
  | 'internalLinkCount'
  | 'position'
  | 'url'
  | 'videoCount'
  | 'wordCount'
  | SeoPageItemKeySpecifier
)[];
export type SeoPageItemFieldPolicy = {
  externalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  h2Count?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  internalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  videoCount?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeoPageMetricsKeySpecifier = (
  | 'externalLinkCount'
  | 'h2Count'
  | 'imagesCount'
  | 'internalLinkCount'
  | 'videoCount'
  | 'wordCount'
  | SeoPageMetricsKeySpecifier
)[];
export type SeoPageMetricsFieldPolicy = {
  externalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  h2Count?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  internalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  videoCount?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeoSerpPageResponseKeySpecifier = (
  | 'organicSerpItemPageAverages'
  | 'organicSerpItemPages'
  | 'userProvidedPage'
  | SeoSerpPageResponseKeySpecifier
)[];
export type SeoSerpPageResponseFieldPolicy = {
  organicSerpItemPageAverages?: FieldPolicy<any> | FieldReadFunction<any>;
  organicSerpItemPages?: FieldPolicy<any> | FieldReadFunction<any>;
  userProvidedPage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SerpDataKeySpecifier = (
  | 'intentData'
  | 'organicSerpItems'
  | 'serpFeaturesPresent'
  | 'userProvidedPage'
  | SerpDataKeySpecifier
)[];
export type SerpDataFieldPolicy = {
  intentData?: FieldPolicy<any> | FieldReadFunction<any>;
  organicSerpItems?: FieldPolicy<any> | FieldReadFunction<any>;
  serpFeaturesPresent?: FieldPolicy<any> | FieldReadFunction<any>;
  userProvidedPage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SerpIntentTopicKeySpecifier = (
  | 'infoComparison'
  | 'infoKnow'
  | 'infoKnowSimple'
  | 'local'
  | 'transactional'
  | 'websiteQuery'
  | SerpIntentTopicKeySpecifier
)[];
export type SerpIntentTopicFieldPolicy = {
  infoComparison?: FieldPolicy<any> | FieldReadFunction<any>;
  infoKnow?: FieldPolicy<any> | FieldReadFunction<any>;
  infoKnowSimple?: FieldPolicy<any> | FieldReadFunction<any>;
  local?: FieldPolicy<any> | FieldReadFunction<any>;
  transactional?: FieldPolicy<any> | FieldReadFunction<any>;
  websiteQuery?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SerpIntentsResponseKeySpecifier = (
  | 'intent'
  | 'urls'
  | SerpIntentsResponseKeySpecifier
)[];
export type SerpIntentsResponseFieldPolicy = {
  intent?: FieldPolicy<any> | FieldReadFunction<any>;
  urls?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SerpUserIntentDataResponseKeySpecifier = (
  | 'intentData'
  | 'organicSerpItemIntents'
  | 'userProvidedPage'
  | SerpUserIntentDataResponseKeySpecifier
)[];
export type SerpUserIntentDataResponseFieldPolicy = {
  intentData?: FieldPolicy<any> | FieldReadFunction<any>;
  organicSerpItemIntents?: FieldPolicy<any> | FieldReadFunction<any>;
  userProvidedPage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SetupAccountResultKeySpecifier = (
  | 'org'
  | 'permissions'
  | 'site'
  | 'subscription'
  | 'user'
  | SetupAccountResultKeySpecifier
)[];
export type SetupAccountResultFieldPolicy = {
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShareByRankKeySpecifier = (
  | 'rank'
  | 'value'
  | 'volume'
  | ShareByRankKeySpecifier
)[];
export type ShareByRankFieldPolicy = {
  rank?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  volume?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShippingAddressKeySpecifier = (
  | 'accountId'
  | 'city'
  | 'company'
  | 'country'
  | 'createdAt'
  | 'email'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'nickname'
  | 'object'
  | 'phone'
  | 'postalCode'
  | 'region'
  | 'street1'
  | 'street2'
  | 'updatedAt'
  | 'vatNumber'
  | ShippingAddressKeySpecifier
)[];
export type ShippingAddressFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  nickname?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  street1?: FieldPolicy<any> | FieldReadFunction<any>;
  street2?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  vatNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShippingMethodMiniKeySpecifier = (
  | 'code'
  | 'id'
  | 'name'
  | 'object'
  | ShippingMethodMiniKeySpecifier
)[];
export type ShippingMethodMiniFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SignInResponseKeySpecifier = (
  | 'org'
  | 'permissions'
  | 'site'
  | 'subscription'
  | 'user'
  | SignInResponseKeySpecifier
)[];
export type SignInResponseFieldPolicy = {
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SiteKeySpecifier = (
  | 'appQueries'
  | 'appRuns'
  | 'appRunsExtra'
  | 'appRunsLastReset'
  | 'appRunsLimit'
  | 'appRunsResetInterval'
  | 'appSumoCodes'
  | 'autoUpdate'
  | 'briefCredits'
  | 'cancelled'
  | 'cancelledAt'
  | 'competitorDomains'
  | 'createdAt'
  | 'createdBy'
  | 'defaultSerpCountry'
  | 'displayContentData'
  | 'documentBriefUserInput'
  | 'documentsConnection'
  | 'domain'
  | 'dunning'
  | 'dunningCardUpdated'
  | 'dunningExpired'
  | 'excludePaths'
  | 'id'
  | 'includePaths'
  | 'invId'
  | 'level'
  | 'networkDomains'
  | 'org'
  | 'plans'
  | 'projects'
  | 'recommendedTopics'
  | 'savedViews'
  | 'subdomains'
  | 'title'
  | 'topicsAllocated'
  | 'topicsInUse'
  | 'updatedAt'
  | 'valuePerVisit'
  | 'visible'
  | SiteKeySpecifier
)[];
export type SiteFieldPolicy = {
  appQueries?: FieldPolicy<any> | FieldReadFunction<any>;
  appRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  appRunsExtra?: FieldPolicy<any> | FieldReadFunction<any>;
  appRunsLastReset?: FieldPolicy<any> | FieldReadFunction<any>;
  appRunsLimit?: FieldPolicy<any> | FieldReadFunction<any>;
  appRunsResetInterval?: FieldPolicy<any> | FieldReadFunction<any>;
  appSumoCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  autoUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  briefCredits?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelled?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelledAt?: FieldPolicy<any> | FieldReadFunction<any>;
  competitorDomains?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultSerpCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  displayContentData?: FieldPolicy<any> | FieldReadFunction<any>;
  documentBriefUserInput?: FieldPolicy<any> | FieldReadFunction<any>;
  documentsConnection?: FieldPolicy<any> | FieldReadFunction<any>;
  domain?: FieldPolicy<any> | FieldReadFunction<any>;
  dunning?: FieldPolicy<any> | FieldReadFunction<any>;
  dunningCardUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  dunningExpired?: FieldPolicy<any> | FieldReadFunction<any>;
  excludePaths?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  includePaths?: FieldPolicy<any> | FieldReadFunction<any>;
  invId?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  networkDomains?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  plans?: FieldPolicy<any> | FieldReadFunction<any>;
  projects?: FieldPolicy<any> | FieldReadFunction<any>;
  recommendedTopics?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViews?: FieldPolicy<any> | FieldReadFunction<any>;
  subdomains?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  topicsAllocated?: FieldPolicy<any> | FieldReadFunction<any>;
  topicsInUse?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valuePerVisit?: FieldPolicy<any> | FieldReadFunction<any>;
  visible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SiteAggregateBriefStatsKeySpecifier = (
  | 'cart'
  | 'delivered'
  | 'inProgress'
  | 'qaReady'
  | SiteAggregateBriefStatsKeySpecifier
)[];
export type SiteAggregateBriefStatsFieldPolicy = {
  cart?: FieldPolicy<any> | FieldReadFunction<any>;
  delivered?: FieldPolicy<any> | FieldReadFunction<any>;
  inProgress?: FieldPolicy<any> | FieldReadFunction<any>;
  qaReady?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SiteAggregateStatsKeySpecifier = (
  | 'appRuns'
  | 'briefs'
  | 'briefsAll'
  | 'pageTopicsAll'
  | 'pagesAll'
  | 'plansAll'
  | 'projectsAll'
  | 'projectsWithPlan'
  | 'projectsWithWriting'
  | 'topicsAll'
  | SiteAggregateStatsKeySpecifier
)[];
export type SiteAggregateStatsFieldPolicy = {
  appRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  briefs?: FieldPolicy<any> | FieldReadFunction<any>;
  briefsAll?: FieldPolicy<any> | FieldReadFunction<any>;
  pageTopicsAll?: FieldPolicy<any> | FieldReadFunction<any>;
  pagesAll?: FieldPolicy<any> | FieldReadFunction<any>;
  plansAll?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsAll?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsWithPlan?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsWithWriting?: FieldPolicy<any> | FieldReadFunction<any>;
  topicsAll?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SiteRecommendedTopicsKeySpecifier = (
  | 'topicCount'
  | SiteRecommendedTopicsKeySpecifier
)[];
export type SiteRecommendedTopicsFieldPolicy = {
  topicCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscribeToPlanKeySpecifier = (
  | 'billingInfo'
  | 'org'
  | 'site'
  | 'subscription'
  | 'user'
  | SubscribeToPlanKeySpecifier
)[];
export type SubscribeToPlanFieldPolicy = {
  billingInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionAddOnTierKeySpecifier = (
  | 'endingQuantity'
  | 'unitAmount'
  | 'unitAmountDecimal'
  | 'usagePercentage'
  | SubscriptionAddOnTierKeySpecifier
)[];
export type SubscriptionAddOnTierFieldPolicy = {
  endingQuantity?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmountDecimal?: FieldPolicy<any> | FieldReadFunction<any>;
  usagePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionAddonKeySpecifier = (
  | 'addonState'
  | 'addonType'
  | 'id'
  | 'mode'
  | 'site'
  | 'subscription'
  | SubscriptionAddonKeySpecifier
)[];
export type SubscriptionAddonFieldPolicy = {
  addonState?: FieldPolicy<any> | FieldReadFunction<any>;
  addonType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mode?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionAddonPriceKeySpecifier = (
  | 'addonType'
  | 'price'
  | SubscriptionAddonPriceKeySpecifier
)[];
export type SubscriptionAddonPriceFieldPolicy = {
  addonType?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionAddonTypeKeySpecifier = (
  | 'description'
  | 'id'
  | 'price'
  | 'type'
  | 'validFromDate'
  | 'validUntilDate'
  | SubscriptionAddonTypeKeySpecifier
)[];
export type SubscriptionAddonTypeFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  validFromDate?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntilDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionAddonTypesResponseKeySpecifier = (
  | 'count'
  | 'items'
  | SubscriptionAddonTypesResponseKeySpecifier
)[];
export type SubscriptionAddonTypesResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionChangeKeySpecifier = (
  | 'activateAt'
  | 'activated'
  | 'addOns'
  | 'billingInfo'
  | 'createdAt'
  | 'customFields'
  | 'deletedAt'
  | 'id'
  | 'invoiceCollection'
  | 'object'
  | 'plan'
  | 'quantity'
  | 'revenueScheduleType'
  | 'shipping'
  | 'subscriptionId'
  | 'taxInclusive'
  | 'unitAmount'
  | 'updatedAt'
  | 'uuid'
  | SubscriptionChangeKeySpecifier
)[];
export type SubscriptionChangeFieldPolicy = {
  activateAt?: FieldPolicy<any> | FieldReadFunction<any>;
  activated?: FieldPolicy<any> | FieldReadFunction<any>;
  addOns?: FieldPolicy<any> | FieldReadFunction<any>;
  billingInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customFields?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  invoiceCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  plan?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
  revenueScheduleType?: FieldPolicy<any> | FieldReadFunction<any>;
  shipping?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  taxInclusive?: FieldPolicy<any> | FieldReadFunction<any>;
  unitAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionChangeBillingInfoKeySpecifier = (
  | 'threeDSecureActionResultTokenId'
  | SubscriptionChangeBillingInfoKeySpecifier
)[];
export type SubscriptionChangeBillingInfoFieldPolicy = {
  threeDSecureActionResultTokenId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionEntityKeySpecifier = (
  | 'addons'
  | 'conversionMethod'
  | 'createdAt'
  | 'demo'
  | 'dunning'
  | 'endsOnDate'
  | 'externalSubscriptionId'
  | 'id'
  | 'org'
  | 'price'
  | 'site'
  | 'startedOnDate'
  | 'state'
  | 'subscriptionAddonPrices'
  | 'subscriptionPlan'
  | SubscriptionEntityKeySpecifier
)[];
export type SubscriptionEntityFieldPolicy = {
  addons?: FieldPolicy<any> | FieldReadFunction<any>;
  conversionMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  demo?: FieldPolicy<any> | FieldReadFunction<any>;
  dunning?: FieldPolicy<any> | FieldReadFunction<any>;
  endsOnDate?: FieldPolicy<any> | FieldReadFunction<any>;
  externalSubscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  startedOnDate?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionAddonPrices?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionPlan?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionPlanKeySpecifier = (
  | 'active'
  | 'analysisAndPlanningDocumentAllocationLimit'
  | 'appQueryCount'
  | 'briefDocumentAllocationLimit'
  | 'id'
  | 'packageName'
  | 'planCode'
  | 'price'
  | 'term'
  | 'termLength'
  | 'type'
  | 'userCanDowngradePackage'
  | 'userCanUpgradePackage'
  | 'usersArePurchasable'
  | 'usersCountDefault'
  | 'writingDocumentAllocationLimit'
  | SubscriptionPlanKeySpecifier
)[];
export type SubscriptionPlanFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  analysisAndPlanningDocumentAllocationLimit?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  appQueryCount?: FieldPolicy<any> | FieldReadFunction<any>;
  briefDocumentAllocationLimit?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  packageName?: FieldPolicy<any> | FieldReadFunction<any>;
  planCode?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  termLength?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  userCanDowngradePackage?: FieldPolicy<any> | FieldReadFunction<any>;
  userCanUpgradePackage?: FieldPolicy<any> | FieldReadFunction<any>;
  usersArePurchasable?: FieldPolicy<any> | FieldReadFunction<any>;
  usersCountDefault?: FieldPolicy<any> | FieldReadFunction<any>;
  writingDocumentAllocationLimit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionPlansResponseKeySpecifier = (
  | 'count'
  | 'items'
  | SubscriptionPlansResponseKeySpecifier
)[];
export type SubscriptionPlansResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionShippingKeySpecifier = (
  | 'address'
  | 'amount'
  | 'method'
  | 'object'
  | SubscriptionShippingKeySpecifier
)[];
export type SubscriptionShippingFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubscriptionUpdateResponseKeySpecifier = (
  | 'org'
  | 'permissions'
  | 'subscription'
  | SubscriptionUpdateResponseKeySpecifier
)[];
export type SubscriptionUpdateResponseFieldPolicy = {
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
  subscription?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TagKeySpecifier = (
  | 'createdAt'
  | 'createdBy'
  | 'documents'
  | 'id'
  | 'site'
  | 'terms'
  | 'title'
  | 'updatedAt'
  | 'updatedBy'
  | 'urlTerms'
  | 'urls'
  | TagKeySpecifier
)[];
export type TagFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  documents?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  site?: FieldPolicy<any> | FieldReadFunction<any>;
  terms?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  urlTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  urls?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TagsResponseKeySpecifier = (
  | 'count'
  | 'items'
  | TagsResponseKeySpecifier
)[];
export type TagsResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaxDetailKeySpecifier = (
  | 'rate'
  | 'region'
  | 'tax'
  | 'type'
  | TaxDetailKeySpecifier
)[];
export type TaxDetailFieldPolicy = {
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  tax?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaxInfoKeySpecifier = (
  | 'rate'
  | 'region'
  | 'taxDetails'
  | 'type'
  | TaxInfoKeySpecifier
)[];
export type TaxInfoFieldPolicy = {
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  region?: FieldPolicy<any> | FieldReadFunction<any>;
  taxDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TopicKeySpecifier = (
  | 'avgPageAuthority'
  | 'competition'
  | 'cpc'
  | 'difficulty'
  | 'favouredUserIntent'
  | 'fracturedUserIntent'
  | 'hiddenByCs'
  | 'hiddenByUser'
  | 'id'
  | 'inventoryId'
  | 'kgKey'
  | 'maxPotentialRank'
  | 'maxRank'
  | 'minPotentialRank'
  | 'mmDifficulty'
  | 'mmPersonalizedAdvantage'
  | 'mmPersonalizedDifficulty'
  | 'monthlyTrends'
  | 'numPages'
  | 'potentialTraffic'
  | 'potentialTrafficUpside'
  | 'potentialTrafficValue'
  | 'serpFeatures'
  | 'serpFeaturesSet'
  | 'serpFeaturesTrafficShare'
  | 'source'
  | 'sumExternalContentLinks'
  | 'sumInternalContentLinks'
  | 'term'
  | 'topRankingPage'
  | 'topRankingPageTitle'
  | 'traffic'
  | 'trafficDelta'
  | 'trafficDeltaPct'
  | 'trafficValue'
  | 'trafficValueDelta'
  | 'trafficValueDeltaPct'
  | 'unrealizedTrafficValue'
  | 'userIntent'
  | 'valueShareByRank'
  | 'version'
  | 'versionTrafficPct'
  | 'versionTrafficValuePct'
  | 'volume'
  | 'volumeShareByRank'
  | TopicKeySpecifier
)[];
export type TopicFieldPolicy = {
  avgPageAuthority?: FieldPolicy<any> | FieldReadFunction<any>;
  competition?: FieldPolicy<any> | FieldReadFunction<any>;
  cpc?: FieldPolicy<any> | FieldReadFunction<any>;
  difficulty?: FieldPolicy<any> | FieldReadFunction<any>;
  favouredUserIntent?: FieldPolicy<any> | FieldReadFunction<any>;
  fracturedUserIntent?: FieldPolicy<any> | FieldReadFunction<any>;
  hiddenByCs?: FieldPolicy<any> | FieldReadFunction<any>;
  hiddenByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inventoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  kgKey?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPotentialRank?: FieldPolicy<any> | FieldReadFunction<any>;
  maxRank?: FieldPolicy<any> | FieldReadFunction<any>;
  minPotentialRank?: FieldPolicy<any> | FieldReadFunction<any>;
  mmDifficulty?: FieldPolicy<any> | FieldReadFunction<any>;
  mmPersonalizedAdvantage?: FieldPolicy<any> | FieldReadFunction<any>;
  mmPersonalizedDifficulty?: FieldPolicy<any> | FieldReadFunction<any>;
  monthlyTrends?: FieldPolicy<any> | FieldReadFunction<any>;
  numPages?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTraffic?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTrafficUpside?: FieldPolicy<any> | FieldReadFunction<any>;
  potentialTrafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  serpFeatures?: FieldPolicy<any> | FieldReadFunction<any>;
  serpFeaturesSet?: FieldPolicy<any> | FieldReadFunction<any>;
  serpFeaturesTrafficShare?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  sumExternalContentLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  sumInternalContentLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  topRankingPage?: FieldPolicy<any> | FieldReadFunction<any>;
  topRankingPageTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  traffic?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDeltaPct?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValueDelta?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficValueDeltaPct?: FieldPolicy<any> | FieldReadFunction<any>;
  unrealizedTrafficValue?: FieldPolicy<any> | FieldReadFunction<any>;
  userIntent?: FieldPolicy<any> | FieldReadFunction<any>;
  valueShareByRank?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
  versionTrafficPct?: FieldPolicy<any> | FieldReadFunction<any>;
  versionTrafficValuePct?: FieldPolicy<any> | FieldReadFunction<any>;
  volume?: FieldPolicy<any> | FieldReadFunction<any>;
  volumeShareByRank?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionKeySpecifier = (
  | 'account'
  | 'amount'
  | 'avsCheck'
  | 'backupPaymentMethodUsed'
  | 'billingAddress'
  | 'collectedAt'
  | 'collectionMethod'
  | 'createdAt'
  | 'currency'
  | 'customerMessage'
  | 'customerMessageLocale'
  | 'cvvCheck'
  | 'gatewayApprovalCode'
  | 'gatewayMessage'
  | 'gatewayReference'
  | 'gatewayResponseCode'
  | 'gatewayResponseTime'
  | 'gatewayResponseValues'
  | 'id'
  | 'invoice'
  | 'ipAddressCountry'
  | 'ipAddressV4'
  | 'object'
  | 'origin'
  | 'originalTransactionId'
  | 'paymentGateway'
  | 'paymentMethod'
  | 'refunded'
  | 'status'
  | 'statusCode'
  | 'statusMessage'
  | 'subscriptionIds'
  | 'success'
  | 'type'
  | 'updatedAt'
  | 'uuid'
  | 'voidedAt'
  | 'voidedByInvoice'
  | TransactionKeySpecifier
)[];
export type TransactionFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  avsCheck?: FieldPolicy<any> | FieldReadFunction<any>;
  backupPaymentMethodUsed?: FieldPolicy<any> | FieldReadFunction<any>;
  billingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  collectedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  customerMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  customerMessageLocale?: FieldPolicy<any> | FieldReadFunction<any>;
  cvvCheck?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayApprovalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayReference?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayResponseCode?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayResponseTime?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayResponseValues?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  invoice?: FieldPolicy<any> | FieldReadFunction<any>;
  ipAddressCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  ipAddressV4?: FieldPolicy<any> | FieldReadFunction<any>;
  object?: FieldPolicy<any> | FieldReadFunction<any>;
  origin?: FieldPolicy<any> | FieldReadFunction<any>;
  originalTransactionId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentGateway?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  refunded?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusCode?: FieldPolicy<any> | FieldReadFunction<any>;
  statusMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionIds?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
  voidedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  voidedByInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionPaymentGatewayKeySpecifier = (
  | 'id'
  | 'name'
  | 'type'
  | TransactionPaymentGatewayKeySpecifier
)[];
export type TransactionPaymentGatewayFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UrlTermKeySpecifier = ('term' | 'url' | UrlTermKeySpecifier)[];
export type UrlTermFieldPolicy = {
  term?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
  | 'appQueries'
  | 'createdAt'
  | 'defaultApplicationSerpCountry'
  | 'defaultDashboard'
  | 'displayExactDistribution'
  | 'email'
  | 'filtersCreated'
  | 'filtersUpdated'
  | 'firstName'
  | 'fullName'
  | 'guestAccessCreated'
  | 'id'
  | 'lastName'
  | 'onboardingProgress'
  | 'onboardingStep'
  | 'org'
  | 'orgPc'
  | 'orgRole'
  | 'phone'
  | 'projectsAssigned'
  | 'savedViewsCreated'
  | 'savedViewsTracked'
  | 'savedViewsUpdated'
  | 'sfdcContactId'
  | 'shouldReceiveBriefOrderedNotifications'
  | 'sitesCreated'
  | 'suspended'
  | 'updatedAt'
  | UserKeySpecifier
)[];
export type UserFieldPolicy = {
  appQueries?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultApplicationSerpCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultDashboard?: FieldPolicy<any> | FieldReadFunction<any>;
  displayExactDistribution?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  filtersCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  filtersUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  guestAccessCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  onboardingProgress?: FieldPolicy<any> | FieldReadFunction<any>;
  onboardingStep?: FieldPolicy<any> | FieldReadFunction<any>;
  org?: FieldPolicy<any> | FieldReadFunction<any>;
  orgPc?: FieldPolicy<any> | FieldReadFunction<any>;
  orgRole?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsAssigned?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViewsCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViewsTracked?: FieldPolicy<any> | FieldReadFunction<any>;
  savedViewsUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  sfdcContactId?: FieldPolicy<any> | FieldReadFunction<any>;
  shouldReceiveBriefOrderedNotifications?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  sitesCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  suspended?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserProvidedPageKeySpecifier = (
  | 'description'
  | 'externalLinkCount'
  | 'h2Count'
  | 'imagesCount'
  | 'internalLinkCount'
  | 'position'
  | 'title'
  | 'url'
  | 'videoCount'
  | 'wordCount'
  | UserProvidedPageKeySpecifier
)[];
export type UserProvidedPageFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  externalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  h2Count?: FieldPolicy<any> | FieldReadFunction<any>;
  imagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  internalLinkCount?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  videoCount?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValidateEmailResponseKeySpecifier = (
  | 'allowedDomain'
  | 'exists'
  | 'formatValid'
  | 'message'
  | 'valid'
  | ValidateEmailResponseKeySpecifier
)[];
export type ValidateEmailResponseFieldPolicy = {
  allowedDomain?: FieldPolicy<any> | FieldReadFunction<any>;
  exists?: FieldPolicy<any> | FieldReadFunction<any>;
  formatValid?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WebsiteSerpItemPagesKgDataKeySpecifier = (
  | 'contentScore'
  | 'description'
  | 'mentions'
  | 'position'
  | 'title'
  | 'url'
  | 'wordCount'
  | WebsiteSerpItemPagesKgDataKeySpecifier
)[];
export type WebsiteSerpItemPagesKgDataFieldPolicy = {
  contentScore?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  mentions?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  wordCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  Account?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AccountKeySpecifier
      | (() => undefined | AccountKeySpecifier);
    fields?: AccountFieldPolicy;
  };
  AccountInvoiceTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AccountInvoiceTemplateKeySpecifier
      | (() => undefined | AccountInvoiceTemplateKeySpecifier);
    fields?: AccountInvoiceTemplateFieldPolicy;
  };
  AccountMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AccountMiniKeySpecifier
      | (() => undefined | AccountMiniKeySpecifier);
    fields?: AccountMiniFieldPolicy;
  };
  AdWordsItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdWordsItemKeySpecifier
      | (() => undefined | AdWordsItemKeySpecifier);
    fields?: AdWordsItemFieldPolicy;
  };
  AdWordsItemWithVariants?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdWordsItemWithVariantsKeySpecifier
      | (() => undefined | AdWordsItemWithVariantsKeySpecifier);
    fields?: AdWordsItemWithVariantsFieldPolicy;
  };
  AddOnMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AddOnMiniKeySpecifier
      | (() => undefined | AddOnMiniKeySpecifier);
    fields?: AddOnMiniFieldPolicy;
  };
  Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AddressKeySpecifier
      | (() => undefined | AddressKeySpecifier);
    fields?: AddressFieldPolicy;
  };
  AddressWithName?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AddressWithNameKeySpecifier
      | (() => undefined | AddressWithNameKeySpecifier);
    fields?: AddressWithNameFieldPolicy;
  };
  AppDataParams?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AppDataParamsKeySpecifier
      | (() => undefined | AppDataParamsKeySpecifier);
    fields?: AppDataParamsFieldPolicy;
  };
  AppQueriesResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AppQueriesResponseKeySpecifier
      | (() => undefined | AppQueriesResponseKeySpecifier);
    fields?: AppQueriesResponseFieldPolicy;
  };
  AppQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AppQueryKeySpecifier
      | (() => undefined | AppQueryKeySpecifier);
    fields?: AppQueryFieldPolicy;
  };
  AppRuns?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AppRunsKeySpecifier
      | (() => undefined | AppRunsKeySpecifier);
    fields?: AppRunsFieldPolicy;
  };
  BillingInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BillingInfoKeySpecifier
      | (() => undefined | BillingInfoKeySpecifier);
    fields?: BillingInfoFieldPolicy;
  };
  BillingInfoUpdatedBy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BillingInfoUpdatedByKeySpecifier
      | (() => undefined | BillingInfoUpdatedByKeySpecifier);
    fields?: BillingInfoUpdatedByFieldPolicy;
  };
  Brief?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefKeySpecifier
      | (() => undefined | BriefKeySpecifier);
    fields?: BriefFieldPolicy;
  };
  BriefComment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefCommentKeySpecifier
      | (() => undefined | BriefCommentKeySpecifier);
    fields?: BriefCommentFieldPolicy;
  };
  BriefError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefErrorKeySpecifier
      | (() => undefined | BriefErrorKeySpecifier);
    fields?: BriefErrorFieldPolicy;
  };
  BriefLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefLinkKeySpecifier
      | (() => undefined | BriefLinkKeySpecifier);
    fields?: BriefLinkFieldPolicy;
  };
  BriefLinkList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefLinkListKeySpecifier
      | (() => undefined | BriefLinkListKeySpecifier);
    fields?: BriefLinkListFieldPolicy;
  };
  BriefMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefMetadataKeySpecifier
      | (() => undefined | BriefMetadataKeySpecifier);
    fields?: BriefMetadataFieldPolicy;
  };
  BriefPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefPageKeySpecifier
      | (() => undefined | BriefPageKeySpecifier);
    fields?: BriefPageFieldPolicy;
  };
  BriefQuestion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefQuestionKeySpecifier
      | (() => undefined | BriefQuestionKeySpecifier);
    fields?: BriefQuestionFieldPolicy;
  };
  BriefRankingTitle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefRankingTitleKeySpecifier
      | (() => undefined | BriefRankingTitleKeySpecifier);
    fields?: BriefRankingTitleFieldPolicy;
  };
  BriefRelatedTopic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefRelatedTopicKeySpecifier
      | (() => undefined | BriefRelatedTopicKeySpecifier);
    fields?: BriefRelatedTopicFieldPolicy;
  };
  BriefSubheading?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefSubheadingKeySpecifier
      | (() => undefined | BriefSubheadingKeySpecifier);
    fields?: BriefSubheadingFieldPolicy;
  };
  BriefSubheadingTerm?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefSubheadingTermKeySpecifier
      | (() => undefined | BriefSubheadingTermKeySpecifier);
    fields?: BriefSubheadingTermFieldPolicy;
  };
  BriefSubheadingTopic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefSubheadingTopicKeySpecifier
      | (() => undefined | BriefSubheadingTopicKeySpecifier);
    fields?: BriefSubheadingTopicFieldPolicy;
  };
  BriefSubtopic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefSubtopicKeySpecifier
      | (() => undefined | BriefSubtopicKeySpecifier);
    fields?: BriefSubtopicFieldPolicy;
  };
  BriefTitleTerm?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefTitleTermKeySpecifier
      | (() => undefined | BriefTitleTermKeySpecifier);
    fields?: BriefTitleTermFieldPolicy;
  };
  BriefTopic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefTopicKeySpecifier
      | (() => undefined | BriefTopicKeySpecifier);
    fields?: BriefTopicFieldPolicy;
  };
  BriefUserAudience?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefUserAudienceKeySpecifier
      | (() => undefined | BriefUserAudienceKeySpecifier);
    fields?: BriefUserAudienceFieldPolicy;
  };
  BriefUserIntent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefUserIntentKeySpecifier
      | (() => undefined | BriefUserIntentKeySpecifier);
    fields?: BriefUserIntentFieldPolicy;
  };
  BriefsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefsResponseKeySpecifier
      | (() => undefined | BriefsResponseKeySpecifier);
    fields?: BriefsResponseFieldPolicy;
  };
  BusinessReview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BusinessReviewKeySpecifier
      | (() => undefined | BusinessReviewKeySpecifier);
    fields?: BusinessReviewFieldPolicy;
  };
  BusinessReviewRead?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BusinessReviewReadKeySpecifier
      | (() => undefined | BusinessReviewReadKeySpecifier);
    fields?: BusinessReviewReadFieldPolicy;
  };
  CancelAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancelAccountKeySpecifier
      | (() => undefined | CancelAccountKeySpecifier);
    fields?: CancelAccountFieldPolicy;
  };
  CancelSubscriptionSchema?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancelSubscriptionSchemaKeySpecifier
      | (() => undefined | CancelSubscriptionSchemaKeySpecifier);
    fields?: CancelSubscriptionSchemaFieldPolicy;
  };
  ContentPlanClusterItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContentPlanClusterItemKeySpecifier
      | (() => undefined | ContentPlanClusterItemKeySpecifier);
    fields?: ContentPlanClusterItemFieldPolicy;
  };
  ContentPlanItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContentPlanItemKeySpecifier
      | (() => undefined | ContentPlanItemKeySpecifier);
    fields?: ContentPlanItemFieldPolicy;
  };
  ContentPlanPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContentPlanPageKeySpecifier
      | (() => undefined | ContentPlanPageKeySpecifier);
    fields?: ContentPlanPageFieldPolicy;
  };
  ContentPlanRecommendations?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContentPlanRecommendationsKeySpecifier
      | (() => undefined | ContentPlanRecommendationsKeySpecifier);
    fields?: ContentPlanRecommendationsFieldPolicy;
  };
  Coupon?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CouponKeySpecifier
      | (() => undefined | CouponKeySpecifier);
    fields?: CouponFieldPolicy;
  };
  CouponDiscount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CouponDiscountKeySpecifier
      | (() => undefined | CouponDiscountKeySpecifier);
    fields?: CouponDiscountFieldPolicy;
  };
  CouponDiscountPricing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CouponDiscountPricingKeySpecifier
      | (() => undefined | CouponDiscountPricingKeySpecifier);
    fields?: CouponDiscountPricingFieldPolicy;
  };
  CouponDiscountTrial?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CouponDiscountTrialKeySpecifier
      | (() => undefined | CouponDiscountTrialKeySpecifier);
    fields?: CouponDiscountTrialFieldPolicy;
  };
  CouponMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CouponMiniKeySpecifier
      | (() => undefined | CouponMiniKeySpecifier);
    fields?: CouponMiniFieldPolicy;
  };
  CouponRedemption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CouponRedemptionKeySpecifier
      | (() => undefined | CouponRedemptionKeySpecifier);
    fields?: CouponRedemptionFieldPolicy;
  };
  CouponRedemptionMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CouponRedemptionMiniKeySpecifier
      | (() => undefined | CouponRedemptionMiniKeySpecifier);
    fields?: CouponRedemptionMiniFieldPolicy;
  };
  CreditPayment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreditPaymentKeySpecifier
      | (() => undefined | CreditPaymentKeySpecifier);
    fields?: CreditPaymentFieldPolicy;
  };
  CustomField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CustomFieldKeySpecifier
      | (() => undefined | CustomFieldKeySpecifier);
    fields?: CustomFieldFieldPolicy;
  };
  Dashboard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DashboardKeySpecifier
      | (() => undefined | DashboardKeySpecifier);
    fields?: DashboardFieldPolicy;
  };
  DeleteStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteStatusKeySpecifier
      | (() => undefined | DeleteStatusKeySpecifier);
    fields?: DeleteStatusFieldPolicy;
  };
  Document?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentKeySpecifier
      | (() => undefined | DocumentKeySpecifier);
    fields?: DocumentFieldPolicy;
  };
  DocumentAnalysisData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentAnalysisDataKeySpecifier
      | (() => undefined | DocumentAnalysisDataKeySpecifier);
    fields?: DocumentAnalysisDataFieldPolicy;
  };
  DocumentBriefData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentBriefDataKeySpecifier
      | (() => undefined | DocumentBriefDataKeySpecifier);
    fields?: DocumentBriefDataFieldPolicy;
  };
  DocumentBriefUserInput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentBriefUserInputKeySpecifier
      | (() => undefined | DocumentBriefUserInputKeySpecifier);
    fields?: DocumentBriefUserInputFieldPolicy;
  };
  DocumentClusterData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentClusterDataKeySpecifier
      | (() => undefined | DocumentClusterDataKeySpecifier);
    fields?: DocumentClusterDataFieldPolicy;
  };
  DocumentContentGroups?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentContentGroupsKeySpecifier
      | (() => undefined | DocumentContentGroupsKeySpecifier);
    fields?: DocumentContentGroupsFieldPolicy;
  };
  DocumentContentOpportunities?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentContentOpportunitiesKeySpecifier
      | (() => undefined | DocumentContentOpportunitiesKeySpecifier);
    fields?: DocumentContentOpportunitiesFieldPolicy;
  };
  DocumentContentPlanData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentContentPlanDataKeySpecifier
      | (() => undefined | DocumentContentPlanDataKeySpecifier);
    fields?: DocumentContentPlanDataFieldPolicy;
  };
  DocumentCreateEstimation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentCreateEstimationKeySpecifier
      | (() => undefined | DocumentCreateEstimationKeySpecifier);
    fields?: DocumentCreateEstimationFieldPolicy;
  };
  DocumentInputData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentInputDataKeySpecifier
      | (() => undefined | DocumentInputDataKeySpecifier);
    fields?: DocumentInputDataFieldPolicy;
  };
  DocumentOptimizeOpportunities?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentOptimizeOpportunitiesKeySpecifier
      | (() => undefined | DocumentOptimizeOpportunitiesKeySpecifier);
    fields?: DocumentOptimizeOpportunitiesFieldPolicy;
  };
  DocumentsAllotment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentsAllotmentKeySpecifier
      | (() => undefined | DocumentsAllotmentKeySpecifier);
    fields?: DocumentsAllotmentFieldPolicy;
  };
  DocumentsAllotmentInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentsAllotmentInfoKeySpecifier
      | (() => undefined | DocumentsAllotmentInfoKeySpecifier);
    fields?: DocumentsAllotmentInfoFieldPolicy;
  };
  DocumentsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentsConnectionKeySpecifier
      | (() => undefined | DocumentsConnectionKeySpecifier);
    fields?: DocumentsConnectionFieldPolicy;
  };
  DocumentsConnectionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentsConnectionInfoKeySpecifier
      | (() => undefined | DocumentsConnectionInfoKeySpecifier);
    fields?: DocumentsConnectionInfoFieldPolicy;
  };
  DocumentsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DocumentsResponseKeySpecifier
      | (() => undefined | DocumentsResponseKeySpecifier);
    fields?: DocumentsResponseFieldPolicy;
  };
  ExcelGenerationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ExcelGenerationResponseKeySpecifier
      | (() => undefined | ExcelGenerationResponseKeySpecifier);
    fields?: ExcelGenerationResponseFieldPolicy;
  };
  Filter?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FilterKeySpecifier
      | (() => undefined | FilterKeySpecifier);
    fields?: FilterFieldPolicy;
  };
  Form?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FormKeySpecifier | (() => undefined | FormKeySpecifier);
    fields?: FormFieldPolicy;
  };
  FormField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FormFieldKeySpecifier
      | (() => undefined | FormFieldKeySpecifier);
    fields?: FormFieldFieldPolicy;
  };
  FormGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FormGroupKeySpecifier
      | (() => undefined | FormGroupKeySpecifier);
    fields?: FormGroupFieldPolicy;
  };
  FraudInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FraudInfoKeySpecifier
      | (() => undefined | FraudInfoKeySpecifier);
    fields?: FraudInfoFieldPolicy;
  };
  GenAiPromptResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GenAiPromptResponseKeySpecifier
      | (() => undefined | GenAiPromptResponseKeySpecifier);
    fields?: GenAiPromptResponseFieldPolicy;
  };
  GetAppDataResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GetAppDataResponseKeySpecifier
      | (() => undefined | GetAppDataResponseKeySpecifier);
    fields?: GetAppDataResponseFieldPolicy;
  };
  GuestAccess?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GuestAccessKeySpecifier
      | (() => undefined | GuestAccessKeySpecifier);
    fields?: GuestAccessFieldPolicy;
  };
  GuestAccessCreate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GuestAccessCreateKeySpecifier
      | (() => undefined | GuestAccessCreateKeySpecifier);
    fields?: GuestAccessCreateFieldPolicy;
  };
  GuestAccessCreateMany?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GuestAccessCreateManyKeySpecifier
      | (() => undefined | GuestAccessCreateManyKeySpecifier);
    fields?: GuestAccessCreateManyFieldPolicy;
  };
  GuestAccessValidate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GuestAccessValidateKeySpecifier
      | (() => undefined | GuestAccessValidateKeySpecifier);
    fields?: GuestAccessValidateFieldPolicy;
  };
  History?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | HistoryKeySpecifier
      | (() => undefined | HistoryKeySpecifier);
    fields?: HistoryFieldPolicy;
  };
  InquirePremium?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InquirePremiumKeySpecifier
      | (() => undefined | InquirePremiumKeySpecifier);
    fields?: InquirePremiumFieldPolicy;
  };
  IntentData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IntentDataKeySpecifier
      | (() => undefined | IntentDataKeySpecifier);
    fields?: IntentDataFieldPolicy;
  };
  InventoryItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InventoryItemKeySpecifier
      | (() => undefined | InventoryItemKeySpecifier);
    fields?: InventoryItemFieldPolicy;
  };
  InventoryItemPotential?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InventoryItemPotentialKeySpecifier
      | (() => undefined | InventoryItemPotentialKeySpecifier);
    fields?: InventoryItemPotentialFieldPolicy;
  };
  InventoryItemsAddResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InventoryItemsAddResponseKeySpecifier
      | (() => undefined | InventoryItemsAddResponseKeySpecifier);
    fields?: InventoryItemsAddResponseFieldPolicy;
  };
  InventoryItemsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InventoryItemsResponseKeySpecifier
      | (() => undefined | InventoryItemsResponseKeySpecifier);
    fields?: InventoryItemsResponseFieldPolicy;
  };
  InventoryVersion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InventoryVersionKeySpecifier
      | (() => undefined | InventoryVersionKeySpecifier);
    fields?: InventoryVersionFieldPolicy;
  };
  Invoice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceKeySpecifier
      | (() => undefined | InvoiceKeySpecifier);
    fields?: InvoiceFieldPolicy;
  };
  InvoiceAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceAddressKeySpecifier
      | (() => undefined | InvoiceAddressKeySpecifier);
    fields?: InvoiceAddressFieldPolicy;
  };
  InvoiceCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceCollectionKeySpecifier
      | (() => undefined | InvoiceCollectionKeySpecifier);
    fields?: InvoiceCollectionFieldPolicy;
  };
  InvoiceMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceMiniKeySpecifier
      | (() => undefined | InvoiceMiniKeySpecifier);
    fields?: InvoiceMiniFieldPolicy;
  };
  ItemMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ItemMiniKeySpecifier
      | (() => undefined | ItemMiniKeySpecifier);
    fields?: ItemMiniFieldPolicy;
  };
  KGMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KGMetricsKeySpecifier
      | (() => undefined | KGMetricsKeySpecifier);
    fields?: KGMetricsFieldPolicy;
  };
  KnowledgeGraph?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KnowledgeGraphKeySpecifier
      | (() => undefined | KnowledgeGraphKeySpecifier);
    fields?: KnowledgeGraphFieldPolicy;
  };
  KnowledgeGraphItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KnowledgeGraphItemKeySpecifier
      | (() => undefined | KnowledgeGraphItemKeySpecifier);
    fields?: KnowledgeGraphItemFieldPolicy;
  };
  LineItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LineItemKeySpecifier
      | (() => undefined | LineItemKeySpecifier);
    fields?: LineItemFieldPolicy;
  };
  LinkRecommendation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LinkRecommendationKeySpecifier
      | (() => undefined | LinkRecommendationKeySpecifier);
    fields?: LinkRecommendationFieldPolicy;
  };
  LinkingRecommendationForTerm?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LinkingRecommendationForTermKeySpecifier
      | (() => undefined | LinkingRecommendationForTermKeySpecifier);
    fields?: LinkingRecommendationForTermFieldPolicy;
  };
  MentionItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MentionItemKeySpecifier
      | (() => undefined | MentionItemKeySpecifier);
    fields?: MentionItemFieldPolicy;
  };
  Module?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ModuleKeySpecifier
      | (() => undefined | ModuleKeySpecifier);
    fields?: ModuleFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MutationKeySpecifier
      | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  OnPageMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OnPageMetricsKeySpecifier
      | (() => undefined | OnPageMetricsKeySpecifier);
    fields?: OnPageMetricsFieldPolicy;
  };
  OrganicSerpItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganicSerpItemKeySpecifier
      | (() => undefined | OrganicSerpItemKeySpecifier);
    fields?: OrganicSerpItemFieldPolicy;
  };
  OrganicSerpItemPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganicSerpItemPageKeySpecifier
      | (() => undefined | OrganicSerpItemPageKeySpecifier);
    fields?: OrganicSerpItemPageFieldPolicy;
  };
  OrganicSerpItemPageKgData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganicSerpItemPageKgDataKeySpecifier
      | (() => undefined | OrganicSerpItemPageKgDataKeySpecifier);
    fields?: OrganicSerpItemPageKgDataFieldPolicy;
  };
  OrganicSerpItemWithIntent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganicSerpItemWithIntentKeySpecifier
      | (() => undefined | OrganicSerpItemWithIntentKeySpecifier);
    fields?: OrganicSerpItemWithIntentFieldPolicy;
  };
  Organization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationKeySpecifier
      | (() => undefined | OrganizationKeySpecifier);
    fields?: OrganizationFieldPolicy;
  };
  OrganizationsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationsResponseKeySpecifier
      | (() => undefined | OrganizationsResponseKeySpecifier);
    fields?: OrganizationsResponseFieldPolicy;
  };
  Page?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PageKeySpecifier | (() => undefined | PageKeySpecifier);
    fields?: PageFieldPolicy;
  };
  PageProcessor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PageProcessorKeySpecifier
      | (() => undefined | PageProcessorKeySpecifier);
    fields?: PageProcessorFieldPolicy;
  };
  PageTopic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PageTopicKeySpecifier
      | (() => undefined | PageTopicKeySpecifier);
    fields?: PageTopicFieldPolicy;
  };
  PaymentMethod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodKeySpecifier
      | (() => undefined | PaymentMethodKeySpecifier);
    fields?: PaymentMethodFieldPolicy;
  };
  Permission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PermissionKeySpecifier
      | (() => undefined | PermissionKeySpecifier);
    fields?: PermissionFieldPolicy;
  };
  PermissionsEntityAdmin?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PermissionsEntityAdminKeySpecifier
      | (() => undefined | PermissionsEntityAdminKeySpecifier);
    fields?: PermissionsEntityAdminFieldPolicy;
  };
  Plan?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PlanKeySpecifier | (() => undefined | PlanKeySpecifier);
    fields?: PlanFieldPolicy;
  };
  PlanMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlanMiniKeySpecifier
      | (() => undefined | PlanMiniKeySpecifier);
    fields?: PlanMiniFieldPolicy;
  };
  Project?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectKeySpecifier
      | (() => undefined | ProjectKeySpecifier);
    fields?: ProjectFieldPolicy;
  };
  ProjectsPlaceOrdersResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectsPlaceOrdersResponseKeySpecifier
      | (() => undefined | ProjectsPlaceOrdersResponseKeySpecifier);
    fields?: ProjectsPlaceOrdersResponseFieldPolicy;
  };
  PurchaseAppRuns?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PurchaseAppRunsKeySpecifier
      | (() => undefined | PurchaseAppRunsKeySpecifier);
    fields?: PurchaseAppRunsFieldPolicy;
  };
  PurchaseCredits?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PurchaseCreditsKeySpecifier
      | (() => undefined | PurchaseCreditsKeySpecifier);
    fields?: PurchaseCreditsFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | QueryKeySpecifier
      | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  QuestionServiceItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | QuestionServiceItemKeySpecifier
      | (() => undefined | QuestionServiceItemKeySpecifier);
    fields?: QuestionServiceItemFieldPolicy;
  };
  QuestionTitleSuggestions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | QuestionTitleSuggestionsKeySpecifier
      | (() => undefined | QuestionTitleSuggestionsKeySpecifier);
    fields?: QuestionTitleSuggestionsFieldPolicy;
  };
  RecurlySubscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurlySubscriptionKeySpecifier
      | (() => undefined | RecurlySubscriptionKeySpecifier);
    fields?: RecurlySubscriptionFieldPolicy;
  };
  RecurlySubscriptionAddon?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurlySubscriptionAddonKeySpecifier
      | (() => undefined | RecurlySubscriptionAddonKeySpecifier);
    fields?: RecurlySubscriptionAddonFieldPolicy;
  };
  RequestStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RequestStatusKeySpecifier
      | (() => undefined | RequestStatusKeySpecifier);
    fields?: RequestStatusFieldPolicy;
  };
  ResetDate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResetDateKeySpecifier
      | (() => undefined | ResetDateKeySpecifier);
    fields?: ResetDateFieldPolicy;
  };
  ResourceActionPair?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResourceActionPairKeySpecifier
      | (() => undefined | ResourceActionPairKeySpecifier);
    fields?: ResourceActionPairFieldPolicy;
  };
  SEOConnect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SEOConnectKeySpecifier
      | (() => undefined | SEOConnectKeySpecifier);
    fields?: SEOConnectFieldPolicy;
  };
  SavedView?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SavedViewKeySpecifier
      | (() => undefined | SavedViewKeySpecifier);
    fields?: SavedViewFieldPolicy;
  };
  SavedViewsEachResItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SavedViewsEachResItemKeySpecifier
      | (() => undefined | SavedViewsEachResItemKeySpecifier);
    fields?: SavedViewsEachResItemFieldPolicy;
  };
  SeoPageItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeoPageItemKeySpecifier
      | (() => undefined | SeoPageItemKeySpecifier);
    fields?: SeoPageItemFieldPolicy;
  };
  SeoPageMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeoPageMetricsKeySpecifier
      | (() => undefined | SeoPageMetricsKeySpecifier);
    fields?: SeoPageMetricsFieldPolicy;
  };
  SeoSerpPageResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeoSerpPageResponseKeySpecifier
      | (() => undefined | SeoSerpPageResponseKeySpecifier);
    fields?: SeoSerpPageResponseFieldPolicy;
  };
  SerpData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SerpDataKeySpecifier
      | (() => undefined | SerpDataKeySpecifier);
    fields?: SerpDataFieldPolicy;
  };
  SerpIntentTopic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SerpIntentTopicKeySpecifier
      | (() => undefined | SerpIntentTopicKeySpecifier);
    fields?: SerpIntentTopicFieldPolicy;
  };
  SerpIntentsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SerpIntentsResponseKeySpecifier
      | (() => undefined | SerpIntentsResponseKeySpecifier);
    fields?: SerpIntentsResponseFieldPolicy;
  };
  SerpUserIntentDataResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SerpUserIntentDataResponseKeySpecifier
      | (() => undefined | SerpUserIntentDataResponseKeySpecifier);
    fields?: SerpUserIntentDataResponseFieldPolicy;
  };
  SetupAccountResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SetupAccountResultKeySpecifier
      | (() => undefined | SetupAccountResultKeySpecifier);
    fields?: SetupAccountResultFieldPolicy;
  };
  ShareByRank?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShareByRankKeySpecifier
      | (() => undefined | ShareByRankKeySpecifier);
    fields?: ShareByRankFieldPolicy;
  };
  ShippingAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShippingAddressKeySpecifier
      | (() => undefined | ShippingAddressKeySpecifier);
    fields?: ShippingAddressFieldPolicy;
  };
  ShippingMethodMini?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShippingMethodMiniKeySpecifier
      | (() => undefined | ShippingMethodMiniKeySpecifier);
    fields?: ShippingMethodMiniFieldPolicy;
  };
  SignInResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SignInResponseKeySpecifier
      | (() => undefined | SignInResponseKeySpecifier);
    fields?: SignInResponseFieldPolicy;
  };
  Site?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SiteKeySpecifier | (() => undefined | SiteKeySpecifier);
    fields?: SiteFieldPolicy;
  };
  SiteAggregateBriefStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SiteAggregateBriefStatsKeySpecifier
      | (() => undefined | SiteAggregateBriefStatsKeySpecifier);
    fields?: SiteAggregateBriefStatsFieldPolicy;
  };
  SiteAggregateStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SiteAggregateStatsKeySpecifier
      | (() => undefined | SiteAggregateStatsKeySpecifier);
    fields?: SiteAggregateStatsFieldPolicy;
  };
  SiteRecommendedTopics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SiteRecommendedTopicsKeySpecifier
      | (() => undefined | SiteRecommendedTopicsKeySpecifier);
    fields?: SiteRecommendedTopicsFieldPolicy;
  };
  SubscribeToPlan?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscribeToPlanKeySpecifier
      | (() => undefined | SubscribeToPlanKeySpecifier);
    fields?: SubscribeToPlanFieldPolicy;
  };
  SubscriptionAddOnTier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionAddOnTierKeySpecifier
      | (() => undefined | SubscriptionAddOnTierKeySpecifier);
    fields?: SubscriptionAddOnTierFieldPolicy;
  };
  SubscriptionAddon?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionAddonKeySpecifier
      | (() => undefined | SubscriptionAddonKeySpecifier);
    fields?: SubscriptionAddonFieldPolicy;
  };
  SubscriptionAddonPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionAddonPriceKeySpecifier
      | (() => undefined | SubscriptionAddonPriceKeySpecifier);
    fields?: SubscriptionAddonPriceFieldPolicy;
  };
  SubscriptionAddonType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionAddonTypeKeySpecifier
      | (() => undefined | SubscriptionAddonTypeKeySpecifier);
    fields?: SubscriptionAddonTypeFieldPolicy;
  };
  SubscriptionAddonTypesResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionAddonTypesResponseKeySpecifier
      | (() => undefined | SubscriptionAddonTypesResponseKeySpecifier);
    fields?: SubscriptionAddonTypesResponseFieldPolicy;
  };
  SubscriptionChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionChangeKeySpecifier
      | (() => undefined | SubscriptionChangeKeySpecifier);
    fields?: SubscriptionChangeFieldPolicy;
  };
  SubscriptionChangeBillingInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionChangeBillingInfoKeySpecifier
      | (() => undefined | SubscriptionChangeBillingInfoKeySpecifier);
    fields?: SubscriptionChangeBillingInfoFieldPolicy;
  };
  SubscriptionEntity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionEntityKeySpecifier
      | (() => undefined | SubscriptionEntityKeySpecifier);
    fields?: SubscriptionEntityFieldPolicy;
  };
  SubscriptionPlan?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionPlanKeySpecifier
      | (() => undefined | SubscriptionPlanKeySpecifier);
    fields?: SubscriptionPlanFieldPolicy;
  };
  SubscriptionPlansResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionPlansResponseKeySpecifier
      | (() => undefined | SubscriptionPlansResponseKeySpecifier);
    fields?: SubscriptionPlansResponseFieldPolicy;
  };
  SubscriptionShipping?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionShippingKeySpecifier
      | (() => undefined | SubscriptionShippingKeySpecifier);
    fields?: SubscriptionShippingFieldPolicy;
  };
  SubscriptionUpdateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubscriptionUpdateResponseKeySpecifier
      | (() => undefined | SubscriptionUpdateResponseKeySpecifier);
    fields?: SubscriptionUpdateResponseFieldPolicy;
  };
  Tag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TagKeySpecifier | (() => undefined | TagKeySpecifier);
    fields?: TagFieldPolicy;
  };
  TagsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TagsResponseKeySpecifier
      | (() => undefined | TagsResponseKeySpecifier);
    fields?: TagsResponseFieldPolicy;
  };
  TaxDetail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TaxDetailKeySpecifier
      | (() => undefined | TaxDetailKeySpecifier);
    fields?: TaxDetailFieldPolicy;
  };
  TaxInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TaxInfoKeySpecifier
      | (() => undefined | TaxInfoKeySpecifier);
    fields?: TaxInfoFieldPolicy;
  };
  Topic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TopicKeySpecifier
      | (() => undefined | TopicKeySpecifier);
    fields?: TopicFieldPolicy;
  };
  Transaction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionKeySpecifier
      | (() => undefined | TransactionKeySpecifier);
    fields?: TransactionFieldPolicy;
  };
  TransactionPaymentGateway?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionPaymentGatewayKeySpecifier
      | (() => undefined | TransactionPaymentGatewayKeySpecifier);
    fields?: TransactionPaymentGatewayFieldPolicy;
  };
  UrlTerm?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UrlTermKeySpecifier
      | (() => undefined | UrlTermKeySpecifier);
    fields?: UrlTermFieldPolicy;
  };
  User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier);
    fields?: UserFieldPolicy;
  };
  UserProvidedPage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserProvidedPageKeySpecifier
      | (() => undefined | UserProvidedPageKeySpecifier);
    fields?: UserProvidedPageFieldPolicy;
  };
  ValidateEmailResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ValidateEmailResponseKeySpecifier
      | (() => undefined | ValidateEmailResponseKeySpecifier);
    fields?: ValidateEmailResponseFieldPolicy;
  };
  WebsiteSerpItemPagesKgData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WebsiteSerpItemPagesKgDataKeySpecifier
      | (() => undefined | WebsiteSerpItemPagesKgDataKeySpecifier);
    fields?: WebsiteSerpItemPagesKgDataFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export const namedOperations = {
  Query: {
    SeoWebsiteHeatmap: 'SeoWebsiteHeatmap',
    AppQueries: 'AppQueries',
    Document: 'Document',
    DocumentStorageData: 'DocumentStorageData',
    Documents: 'Documents',
    DocumentsCreateEstimate: 'DocumentsCreateEstimate',
    GetExcel: 'GetExcel',
    ExportConnect: 'ExportConnect',
    ExportHeatmap: 'ExportHeatmap',
    ExportInventoryItems: 'ExportInventoryItems',
    ExportSerpXray: 'ExportSerpXray',
    ExportTopicNavigator: 'ExportTopicNavigator',
    GetForm: 'GetForm',
    GetForms: 'GetForms',
    GenaiChangeTone: 'GenaiChangeTone',
    GenaiCreateOutline: 'GenaiCreateOutline',
    GenaiCreateSummary: 'GenaiCreateSummary',
    GenaiCustomPrompt: 'GenaiCustomPrompt',
    genaiExpandConcept: 'genaiExpandConcept',
    GenaiFixSpelling: 'GenaiFixSpelling',
    GenaiFollowUpContinueWriting: 'GenaiFollowUpContinueWriting',
    GenaiFollowUpCustom: 'GenaiFollowUpCustom',
    GenaiFollowUpMakeLonger: 'GenaiFollowUpMakeLonger',
    GenaiFollowUpTryAgain: 'GenaiFollowUpTryAgain',
    GenaiImproveWriting: 'GenaiImproveWriting',
    GenaiMakeLonger: 'GenaiMakeLonger',
    GenaiMakeShorter: 'GenaiMakeShorter',
    GetAppData: 'GetAppData',
    GetSitesByOrg: 'GetSitesByOrg',
    InventoryItem: 'InventoryItem',
    InventoryItemsPageTopics: 'InventoryItemsPageTopics',
    InventoryItemsPages: 'InventoryItemsPages',
    InventoryItemsProjects: 'InventoryItemsProjects',
    InventoryItemsTopics: 'InventoryItemsTopics',
    InventoryPageSuggestions: 'InventoryPageSuggestions',
    InventorySeoTopics: 'InventorySeoTopics',
    InventorySeoPageTopic: 'InventorySeoPageTopic',
    InventorySubdomains: 'InventorySubdomains',
    GetInventoryVersion: 'GetInventoryVersion',
    GetInventoryVersions: 'GetInventoryVersions',
    GetOrg: 'GetOrg',
    OrganizationCounts: 'OrganizationCounts',
    GetOrgs: 'GetOrgs',
    Permissions: 'Permissions',
    PermissionsEntityAdmin: 'PermissionsEntityAdmin',
    GetPlans: 'GetPlans',
    PlansAndProjects: 'PlansAndProjects',
    GetProject: 'GetProject',
    GetProjects: 'GetProjects',
    GetProjectsByBriefId: 'GetProjectsByBriefId',
    RecurlyGetAccount: 'RecurlyGetAccount',
    RecurlyGetCoupon: 'RecurlyGetCoupon',
    RecurlyGetCreditInvoices: 'RecurlyGetCreditInvoices',
    RecurlyGetRedemptions: 'RecurlyGetRedemptions',
    RecurlyGetSubscriptions: 'RecurlyGetSubscriptions',
    RecurlyGetTransactions: 'RecurlyGetTransactions',
    ReportRead: 'ReportRead',
    ReportsRead: 'ReportsRead',
    GetSavedViews: 'GetSavedViews',
    GetSavedViewsEach: 'GetSavedViewsEach',
    getSeoAdwordsKeywords: 'getSeoAdwordsKeywords',
    getSeoAdwordsQuestions: 'getSeoAdwordsQuestions',
    SeoBulkAdwordsKeywordsComplete: 'SeoBulkAdwordsKeywordsComplete',
    SeoBulkAdwordsKeywordsMinimal: 'SeoBulkAdwordsKeywordsMinimal',
    seoConnect: 'seoConnect',
    SeoKnowledgeGraphComplete: 'SeoKnowledgeGraphComplete',
    SeoKnowledgeGraphResearch: 'SeoKnowledgeGraphResearch',
    SeoPageProcessor: 'SeoPageProcessor',
    seoSerpData: 'seoSerpData',
    seoSerpPageData: 'seoSerpPageData',
    getSeoSerpQuestions: 'getSeoSerpQuestions',
    SeoSerpQuestionsOld: 'SeoSerpQuestionsOld',
    seoSerpUserIntentData: 'seoSerpUserIntentData',
    SignIn: 'SignIn',
    SignProsperStackPayload: 'SignProsperStackPayload',
    Site: 'Site',
    SiteAggregateAppRuns: 'SiteAggregateAppRuns',
    SiteAggregateBriefs: 'SiteAggregateBriefs',
    SiteAggregatePlans: 'SiteAggregatePlans',
    SiteAggregateProjects: 'SiteAggregateProjects',
    SiteAggregateStats: 'SiteAggregateStats',
    SiteRecommendedTopics: 'SiteRecommendedTopics',
    Subscription: 'Subscription',
    SubscriptionAddonTypesAvailable: 'SubscriptionAddonTypesAvailable',
    SubscriptionPlans: 'SubscriptionPlans',
    SubscriptionRenewalInvoicePreview: 'SubscriptionRenewalInvoicePreview',
    TagsPrimaryAttributes: 'TagsPrimaryAttributes',
    Tags: 'Tags',
    GetUser: 'GetUser',
    GhostAsUser: 'GhostAsUser',
    GetUsers: 'GetUsers',
    ValidateEmail: 'ValidateEmail',
  },
  Mutation: {
    AddFromResearch: 'AddFromResearch',
    AddMember: 'AddMember',
    AdminPermissionsUpdate: 'AdminPermissionsUpdate',
    AdminSubscriptionAddonCreate: 'AdminSubscriptionAddonCreate',
    AdminSubscriptionCreate: 'AdminSubscriptionCreate',
    AdminSubscriptionUpdate: 'AdminSubscriptionUpdate',
    AppQueryCreateOrUpdate: 'AppQueryCreateOrUpdate',
    AppQueryUpdate: 'AppQueryUpdate',
    DocumentDelete: 'DocumentDelete',
    DocumentUpdate: 'DocumentUpdate',
    DocumentsCreate: 'DocumentsCreate',
    DocumentsCreateBriefsFromPlan: 'DocumentsCreateBriefsFromPlan',
    DocumentsCreatePlansFromAnalysis: 'DocumentsCreatePlansFromAnalysis',
    FormCreate: 'FormCreate',
    FormFieldCreate: 'FormFieldCreate',
    FormFieldUpdate: 'FormFieldUpdate',
    FormGroupCreate: 'FormGroupCreate',
    FormGroupUpdate: 'FormGroupUpdate',
    FormUpdate: 'FormUpdate',
    GuestAccessCreate: 'GuestAccessCreate',
    GuestAccessCreateMany: 'GuestAccessCreateMany',
    GuestAccessValidate: 'GuestAccessValidate',
    InquirePremium: 'InquirePremium',
    InventoryItemsAdd: 'InventoryItemsAdd',
    InventoryItemsUpdate: 'InventoryItemsUpdate',
    OrganizationCreate: 'OrganizationCreate',
    OrganizationUpdate: 'OrganizationUpdate',
    PlanDelete: 'PlanDelete',
    ProjectOrderRemove: 'ProjectOrderRemove',
    ProjectsDelete: 'ProjectsDelete',
    PurchaseAppRuns: 'PurchaseAppRuns',
    PurchaseCredits: 'PurchaseCredits',
    RecurlyAccountCreate: 'RecurlyAccountCreate',
    RecurlyUpsertBillingInfo: 'RecurlyUpsertBillingInfo',
    ReportDelete: 'ReportDelete',
    ReportGenerate: 'ReportGenerate',
    ReportSubheadingAdd: 'ReportSubheadingAdd',
    SavedViewCreate: 'SavedViewCreate',
    SavedViewDelete: 'SavedViewDelete',
    SavedViewUpdate: 'SavedViewUpdate',
    SetupAccount: 'SetupAccount',
    InventoryUpdate: 'InventoryUpdate',
    InventoryCreate: 'InventoryCreate',
    InventoryDelete: 'InventoryDelete',
    SiteCreateGovernedInventory: 'SiteCreateGovernedInventory',
    SubscriptionAddonCancel: 'SubscriptionAddonCancel',
    SubscriptionAddonCreate: 'SubscriptionAddonCreate',
    SubscriptionUpdate: 'SubscriptionUpdate',
    TagCreateOrUpdate: 'TagCreateOrUpdate',
    TagDelete: 'TagDelete',
    UserDelete: 'UserDelete',
    UserUpdate: 'UserUpdate',
  },
  Fragment: {
    AppDataParamsFields: 'AppDataParamsFields',
    AppRunsFields: 'AppRunsFields',
    GetAppDataResponseFields: 'GetAppDataResponseFields',
    AppQueryFieldsBase: 'AppQueryFieldsBase',
    AppQueryFields: 'AppQueryFields',
    BriefCommentFields: 'BriefCommentFields',
    BriefLinkListFields: 'BriefLinkListFields',
    BriefQuestionFields: 'BriefQuestionFields',
    BriefSubheadingFields: 'BriefSubheadingFields',
    BriefSubtopicFields: 'BriefSubtopicFields',
    BriefTopicFields: 'BriefTopicFields',
    BriefFieldsBase: 'BriefFieldsBase',
    BriefFields: 'BriefFields',
    BriefFieldsAll: 'BriefFieldsAll',
    DocumentFieldsBase: 'DocumentFieldsBase',
    DocumentFields: 'DocumentFields',
    FormFields: 'FormFields',
    FormFieldFields: 'FormFieldFields',
    FormGroupFields: 'FormGroupFields',
    InventoryItemFieldsBase: 'InventoryItemFieldsBase',
    InventoryPage: 'InventoryPage',
    InventoryPageTopic: 'InventoryPageTopic',
    InventoryTopic: 'InventoryTopic',
    InventoryVersionFields: 'InventoryVersionFields',
    OrganizationFieldsBase: 'OrganizationFieldsBase',
    DocumentsAllotmentFields: 'DocumentsAllotmentFields',
    OrganizationFields: 'OrganizationFields',
    PermissionFields: 'PermissionFields',
    PlanFields: 'PlanFields',
    ProjectBaseFields: 'ProjectBaseFields',
    ProjectMainFields: 'ProjectMainFields',
    RecurlyAccountFields: 'RecurlyAccountFields',
    RecurlyAccountMiniFields: 'RecurlyAccountMiniFields',
    RecurlyCouponFields: 'RecurlyCouponFields',
    RecurlyCouponRedemptionFields: 'RecurlyCouponRedemptionFields',
    RecurlyCouponDiscountFields: 'RecurlyCouponDiscountFields',
    RecurlyCouponMiniFields: 'RecurlyCouponMiniFields',
    RecurlyCouponRedemptionsFields: 'RecurlyCouponRedemptionsFields',
    RecurlyInvoiceFields: 'RecurlyInvoiceFields',
    RecurlyInvoiceCollectionFields: 'RecurlyInvoiceCollectionFields',
    RecurlyShippingAddressFields: 'RecurlyShippingAddressFields',
    RecurlyTransactionFields: 'RecurlyTransactionFields',
    RecurlyTaxInfoFields: 'RecurlyTaxInfoFields',
    RecurlyInvoiceMiniFields: 'RecurlyInvoiceMiniFields',
    SubscriptionFieldsUser: 'SubscriptionFieldsUser',
    SubscriptionFieldsSite: 'SubscriptionFieldsSite',
    RecurlySubscriptionFields: 'RecurlySubscriptionFields',
    RecurlySubscriptionAddOnFields: 'RecurlySubscriptionAddOnFields',
    RecurlySubscriptionChangeFields: 'RecurlySubscriptionChangeFields',
    RecurlySubscriptionShippingFields: 'RecurlySubscriptionShippingFields',
    SubscriptionAddonTypeFields: 'SubscriptionAddonTypeFields',
    SubscriptionPlanFields: 'SubscriptionPlanFields',
    SubscriptionEntityFields: 'SubscriptionEntityFields',
    RecurlyAddressFields: 'RecurlyAddressFields',
    RecurlyBillingInfoFields: 'RecurlyBillingInfoFields',
    RecurlyPaymentMethodFields: 'RecurlyPaymentMethodFields',
    RecurlyPlanMiniFields: 'RecurlyPlanMiniFields',
    AdWordsItemFields: 'AdWordsItemFields',
    KGItem: 'KGItem',
    OrganicSerpItemPageKgDataFields: 'OrganicSerpItemPageKgDataFields',
    SavedViewFields: 'SavedViewFields',
    SiteFieldsBase: 'SiteFieldsBase',
    SiteFields: 'SiteFields',
    SiteFieldsAll: 'SiteFieldsAll',
    SiteInventoryDataFields: 'SiteInventoryDataFields',
    UserFieldsBase: 'UserFieldsBase',
    UserFields: 'UserFields',
    HistoryFields: 'HistoryFields',
  },
};
